import { Validadores } from 'src/app/componentes/formMat/Validatores';

import { Component, OnInit } from '@angular/core';
import {  Validators } from '@angular/forms';
import { FormField } from 'src/app/componentes/formMat/form.component';

@Component({
  selector: 'app-alteracao-pedidos',
  templateUrl: './alteracao-pedidos.component.html',
  styleUrls: ['./alteracao-pedidos.component.css'],
})
export class AlteracaoPedidosComponent implements OnInit {
  fields: FormField[] = [];

  constructor() {}

  ngOnInit(): void {
    this.fields = [
      {
        label: 'Empresa',
        validators: Validators.required,
        name: 'empresa',

        select: [
          {
            type: 'option',

            name: 'Agrosema',

            value: 'Agrosema',
          },

          {
            type: 'option',

            name: 'Nutrien',

            value: 'Nutrien',
          },

          {
            type: 'option',

            name: 'Marca Agro',

            value: 'Marca Agro',
          },

          {
            type: 'option',

            name: 'Tec Agro',

            value: 'Tec Agro',
          },

          {
            type: 'option',

            name: 'Terra Nova',

            value: 'Terra Nova',
          },
        ],
        type: 'radioVertical',
      },
      {
        label: 'Sistema do pedido',
        validators: Validators.required,
        name: 'sistema_do_pedido',

        select: [
          {
            type: 'option',

            name: 'Protheus / TOTVs Agrosema',

            value: 'Protheus / TOTVs Agrosema',
          },

          {
            type: 'option',

            name: 'Protheus / TOTVs TecAgro',

            value: 'Protheus / TOTVs TecAgro',
          },
          {
            type: 'option',

            name: 'SAP',

            value: 'SAP',
          },

          {
            type: 'option',

            name: 'Siagri',

            value: 'Siagri',
          },
        ],
        type: 'radioVertical',
      },
      {
        label: 'Número do pedido no ERP',
        validators: [Validators.required, Validators.maxLength(1000)],
        name: 'n_mero_do_pedido_no_erp',
        type: 'text',
      },
      {
        label: 'Tipo de solicitação de alteração',
        validators: Validators.required,
        name: 'tipo_de_solicita_o_de_altera_o',

        select: [
          {
            type: 'option',
            name: 'Alterar data de entrega',
            value: 'Alterar data de entrega',
            fields: [
              {
                label: 'Alterar data do pedido completo?',
                validators: Validators.required,
                name: 'alterar_data_do_pedido_completo',

                value: 'Não',

                select: [
                  {
                    type: 'option',

                    name: 'Sim',

                    value: 'Sim',
                    fields: [
                      {
                        label: 'Nova data de entrega do pedido',
                        validators: Validators.required,
                        name: 'nova_data_de_entrega_do_pedido',

                        type: 'date',
                      },
                    ],
                  },

                  {
                    type: 'option',

                    name: 'Não',

                    value: '',
                    fields: [
                      {
                        label: 'Número de produtos que deverão ser alterados',
                        validators: Validators.required,
                        name: 'n_mero_de_produtos_que_dever_o_ser_alterados',

                        select: [
                          {
                            type: 'option',

                            name: '1',

                            value: '1',
                            fields: [
                              {
                                label: 'Código do produto a ser alterado 1',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_1',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 1',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_1',
                                type: 'date',
                              },
                            ],
                          },

                          {
                            type: 'option',

                            name: '2',
                            value: '2',
                            fields: [
                              {
                                label: 'Código do produto a ser alterado 1',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_1',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 1',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_1',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 2',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_2',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 2',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_2',
                                type: 'date',
                              },
                            ],
                          },

                          {
                            type: 'option',

                            name: '3',

                            value: '3',
                            fields: [
                              {
                                label: 'Código do produto a ser alterado 1',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_1',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 1',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_1',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 2',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_2',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 2',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_2',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 3',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_3',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 3',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_3',
                                type: 'date',
                              },
                            ],
                          },

                          {
                            type: 'option',

                            name: '4',

                            value: '4',
                            fields: [
                              {
                                label: 'Código do produto a ser alterado 1',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_1',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 1',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_1',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 2',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_2',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 2',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_2',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 3',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_3',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 3',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_3',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 4',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_4',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 4',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_4',
                                type: 'date',
                              },
                            ],
                          },

                          {
                            type: 'option',

                            name: '5',

                            value: '5',
                            fields: [
                              {
                                label: 'Código do produto a ser alterado 1',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_1',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 1',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_1',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 2',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_2',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 2',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_2',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 3',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_3',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 3',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_3',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 4',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_4',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 4',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_4',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 5',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_5',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 5',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_5',
                                type: 'date',
                              },
                            ],
                          },

                          {
                            type: 'option',

                            name: '6',

                            value: '6',
                            fields: [
                              {
                                label: 'Código do produto a ser alterado 1',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_1',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 1',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_1',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 2',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_2',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 2',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_2',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 3',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_3',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 3',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_3',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 4',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_4',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 4',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_4',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 5',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_5',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 5',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_5',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 6',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_6',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 6',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_6',
                                type: 'date',
                              },
                            ],
                          },

                          {
                            type: 'option',

                            name: '7',

                            value: '7',

                            fields: [
                              {
                                label: 'Código do produto a ser alterado 1',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_1',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 1',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_1',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 2',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_2',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 2',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_2',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 3',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_3',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 3',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_3',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 4',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_4',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 4',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_4',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 5',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_5',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 5',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_5',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 6',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_6',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 6',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_6',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 7',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_7',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 7',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_7',
                                type: 'date',
                              },
                            ],
                          },

                          {
                            type: 'option',

                            name: '8',

                            value: '8',

                            fields: [
                              {
                                label: 'Código do produto a ser alterado 1',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_1',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 1',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_1',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 2',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_2',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 2',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_2',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 3',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_3',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 3',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_3',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 4',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_4',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 4',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_4',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 5',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_5',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 5',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_5',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 6',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_6',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 6',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_6',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 7',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_7',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 7',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_7',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 8',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_8',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 8',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_8',
                                type: 'date',
                              },
                            ],
                          },

                          {
                            type: 'option',

                            name: '9',

                            value: '9',

                            fields: [
                              {
                                label: 'Código do produto a ser alterado 1',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_1',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 1',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_1',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 2',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_2',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 2',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_2',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 3',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_3',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 3',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_3',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 4',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_4',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 4',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_4',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 5',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_5',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 5',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_5',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 6',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_6',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 6',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_6',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 7',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_7',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 7',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_7',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 8',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_8',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 8',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_8',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 9',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_9',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 9',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_9',
                                type: 'date',
                              },
                            ],
                          },

                          {
                            type: 'option',

                            name: '10',

                            value: '10',
                            fields: [
                              {
                                label: 'Código do produto a ser alterado 1',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_1',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 1',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_1',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 2',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_2',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 2',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_2',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 3',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_3',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 3',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_3',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 4',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_4',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 4',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_4',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 5',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_5',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 5',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_5',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 6',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_6',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 6',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_6',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 7',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_7',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 7',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_7',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 8',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_8',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 8',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_8',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 9',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_9',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 9',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_9',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 10',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_10',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 10',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_10',
                                type: 'date',
                              },
                            ],
                          },

                          {
                            type: 'option',

                            name: '11',

                            value: '11',

                            fields: [
                              {
                                label: 'Código do produto a ser alterado 1',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_1',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 1',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_1',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 2',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_2',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 2',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_2',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 3',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_3',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 3',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_3',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 4',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_4',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 4',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_4',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 5',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_5',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 5',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_5',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 6',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_6',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 6',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_6',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 7',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_7',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 7',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_7',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 8',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_8',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 8',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_8',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 9',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_9',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 9',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_9',
                                type: 'date',
                              },

                              {
                                label: 'Código do produto a ser alterado 10',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_10',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 10',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_10',
                                type: 'date',
                              },

                              {
                                label: 'Código do produto a ser alterado 11',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_11',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 11',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_11',
                                type: 'date',
                              },
                            ],
                          },

                          {
                            type: 'option',

                            name: '12',

                            value: '12',
                            fields: [
                              {
                                label: 'Código do produto a ser alterado 1',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_1',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 1',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_1',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 2',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_2',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 2',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_2',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 3',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_3',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 3',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_3',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 4',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_4',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 4',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_4',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 5',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_5',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 5',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_5',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 6',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_6',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 6',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_6',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 7',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_7',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 7',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_7',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 8',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_8',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 8',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_8',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 9',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_9',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 9',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_9',
                                type: 'date',
                              },

                              {
                                label: 'Código do produto a ser alterado 10',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_10',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 10',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_10',
                                type: 'date',
                              },

                              {
                                label: 'Código do produto a ser alterado 11',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_11',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 11',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_11',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 12',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_12',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 12',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_12',
                                type: 'date',
                              },
                            ],
                          },

                          {
                            type: 'option',

                            name: '13',

                            value: '13',
                            fields: [
                              {
                                label: 'Código do produto a ser alterado 1',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_1',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 1',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_1',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 2',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_2',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 2',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_2',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 3',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_3',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 3',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_3',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 4',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_4',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 4',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_4',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 5',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_5',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 5',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_5',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 6',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_6',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 6',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_6',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 7',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_7',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 7',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_7',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 8',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_8',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 8',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_8',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 9',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_9',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 9',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_9',
                                type: 'date',
                              },

                              {
                                label: 'Código do produto a ser alterado 10',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_10',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 10',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_10',
                                type: 'date',
                              },

                              {
                                label: 'Código do produto a ser alterado 11',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_11',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 11',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_11',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 12',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_12',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 12',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_12',
                                type: 'date',
                              },

                              {
                                label: 'Código do produto a ser alterado 13',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_13',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 13',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_13',
                                type: 'date',
                              },
                            ],
                          },

                          {
                            type: 'option',

                            name: '14',

                            value: '14',
                            fields: [
                              {
                                label: 'Código do produto a ser alterado 1',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_1',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 1',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_1',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 2',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_2',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 2',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_2',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 3',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_3',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 3',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_3',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 4',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_4',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 4',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_4',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 5',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_5',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 5',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_5',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 6',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_6',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 6',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_6',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 7',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_7',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 7',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_7',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 8',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_8',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 8',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_8',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 9',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_9',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 9',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_9',
                                type: 'date',
                              },

                              {
                                label: 'Código do produto a ser alterado 10',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_10',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 10',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_10',
                                type: 'date',
                              },

                              {
                                label: 'Código do produto a ser alterado 11',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_11',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 11',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_11',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 12',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_12',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 12',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_12',
                                type: 'date',
                              },

                              {
                                label: 'Código do produto a ser alterado 13',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_13',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 13',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_13',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 14',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_14',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 14',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_14',
                                type: 'date',
                              },
                            ],
                          },

                          {
                            type: 'option',

                            name: '15',

                            value: '15',

                            fields: [
                              {
                                label: 'Código do produto a ser alterado 1',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_1',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 1',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_1',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 2',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_2',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 2',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_2',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 3',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_3',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 3',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_3',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 4',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_4',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 4',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_4',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 5',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_5',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 5',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_5',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 6',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_6',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 6',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_6',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 7',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_7',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 7',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_7',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 8',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_8',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 8',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_8',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 9',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_9',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 9',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_9',
                                type: 'date',
                              },

                              {
                                label: 'Código do produto a ser alterado 10',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_10',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 10',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_10',
                                type: 'date',
                              },

                              {
                                label: 'Código do produto a ser alterado 11',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_11',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 11',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_11',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 12',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_12',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 12',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_12',
                                type: 'date',
                              },

                              {
                                label: 'Código do produto a ser alterado 13',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_13',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 13',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_13',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 14',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_14',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 14',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_14',
                                type: 'date',
                              },
                              {
                                label: 'Nova data de entrega do pedido 14',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_14',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 15',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_15',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 15',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_15',
                                type: 'date',
                              },
                            ],
                          },

                          {
                            type: 'option',

                            name: '16',

                            value: '16',

                            fields: [
                              {
                                label: 'Código do produto a ser alterado 1',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_1',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 1',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_1',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 2',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_2',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 2',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_2',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 3',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_3',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 3',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_3',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 4',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_4',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 4',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_4',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 5',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_5',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 5',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_5',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 6',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_6',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 6',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_6',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 7',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_7',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 7',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_7',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 8',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_8',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 8',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_8',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 9',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_9',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 9',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_9',
                                type: 'date',
                              },

                              {
                                label: 'Código do produto a ser alterado 10',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_10',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 10',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_10',
                                type: 'date',
                              },

                              {
                                label: 'Código do produto a ser alterado 11',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_11',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 11',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_11',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 12',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_12',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 12',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_12',
                                type: 'date',
                              },

                              {
                                label: 'Código do produto a ser alterado 13',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_13',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 13',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_13',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 14',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_14',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 14',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_14',
                                type: 'date',
                              },
                              {
                                label: 'Nova data de entrega do pedido 14',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_14',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 15',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_15',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 15',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_15',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 16',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_16',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 16',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_16',
                                type: 'date',
                              },
                            ],
                          },

                          {
                            type: 'option',

                            name: '17',

                            value: '17',

                            fields: [
                              {
                                label: 'Código do produto a ser alterado 1',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_1',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 1',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_1',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 2',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_2',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 2',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_2',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 3',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_3',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 3',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_3',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 4',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_4',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 4',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_4',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 5',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_5',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 5',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_5',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 6',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_6',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 6',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_6',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 7',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_7',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 7',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_7',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 8',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_8',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 8',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_8',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 9',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_9',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 9',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_9',
                                type: 'date',
                              },

                              {
                                label: 'Código do produto a ser alterado 10',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_10',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 10',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_10',
                                type: 'date',
                              },

                              {
                                label: 'Código do produto a ser alterado 11',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_11',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 11',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_11',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 12',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_12',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 12',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_12',
                                type: 'date',
                              },

                              {
                                label: 'Código do produto a ser alterado 13',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_13',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 13',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_13',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 14',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_14',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 14',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_14',
                                type: 'date',
                              },
                              {
                                label: 'Nova data de entrega do pedido 14',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_14',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 15',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_15',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 15',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_15',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 16',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_16',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 16',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_16',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 17',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_17',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 17',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_17',
                                type: 'date',
                              },
                            ],
                          },

                          {
                            type: 'option',

                            name: '18',

                            value: '18',

                            fields: [
                              {
                                label: 'Código do produto a ser alterado 1',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_1',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 1',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_1',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 2',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_2',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 2',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_2',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 3',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_3',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 3',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_3',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 4',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_4',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 4',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_4',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 5',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_5',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 5',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_5',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 6',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_6',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 6',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_6',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 7',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_7',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 7',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_7',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 8',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_8',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 8',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_8',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 9',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_9',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 9',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_9',
                                type: 'date',
                              },

                              {
                                label: 'Código do produto a ser alterado 10',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_10',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 10',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_10',
                                type: 'date',
                              },

                              {
                                label: 'Código do produto a ser alterado 11',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_11',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 11',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_11',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 12',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_12',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 12',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_12',
                                type: 'date',
                              },

                              {
                                label: 'Código do produto a ser alterado 13',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_13',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 13',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_13',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 14',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_14',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 14',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_14',
                                type: 'date',
                              },
                              {
                                label: 'Nova data de entrega do pedido 14',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_14',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 15',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_15',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 15',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_15',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 16',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_16',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 16',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_16',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 17',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_17',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 17',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_17',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 18',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_18',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 18',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_18',
                                type: 'date',
                              },
                            ],
                          },

                          {
                            type: 'option',

                            name: '19',

                            value: '19',

                            fields: [
                              {
                                label: 'Código do produto a ser alterado 1',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_1',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 1',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_1',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 2',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_2',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 2',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_2',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 3',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_3',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 3',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_3',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 4',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_4',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 4',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_4',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 5',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_5',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 5',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_5',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 6',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_6',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 6',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_6',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 7',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_7',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 7',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_7',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 8',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_8',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 8',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_8',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 9',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_9',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 9',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_9',
                                type: 'date',
                              },

                              {
                                label: 'Código do produto a ser alterado 10',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_10',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 10',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_10',
                                type: 'date',
                              },

                              {
                                label: 'Código do produto a ser alterado 11',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_11',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 11',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_11',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 12',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_12',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 12',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_12',
                                type: 'date',
                              },

                              {
                                label: 'Código do produto a ser alterado 13',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_13',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 13',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_13',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 14',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_14',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 14',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_14',
                                type: 'date',
                              },
                              {
                                label: 'Nova data de entrega do pedido 14',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_14',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 15',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_15',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 15',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_15',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 16',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_16',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 16',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_16',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 17',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_17',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 17',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_17',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 18',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_18',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 18',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_18',
                                type: 'date',
                              },

                              {
                                label: 'Código do produto a ser alterado 19',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_19',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 19',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_19',
                                type: 'date',
                              },
                            ],
                          },

                          {
                            type: 'option',

                            name: '20',

                            value: '20',

                            fields: [
                              {
                                label: 'Código do produto a ser alterado 1',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_1',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 1',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_1',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 2',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_2',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 2',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_2',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 3',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_3',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 3',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_3',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 4',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_4',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 4',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_4',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 5',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_5',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 5',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_5',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 6',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_6',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 6',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_6',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 7',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_7',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 7',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_7',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 8',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_8',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 8',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_8',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 9',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_9',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 9',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_9',
                                type: 'date',
                              },

                              {
                                label: 'Código do produto a ser alterado 10',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_10',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 10',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_10',
                                type: 'date',
                              },

                              {
                                label: 'Código do produto a ser alterado 11',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_11',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 11',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_11',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 12',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_12',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 12',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_12',
                                type: 'date',
                              },

                              {
                                label: 'Código do produto a ser alterado 13',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_13',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 13',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_13',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 14',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_14',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 14',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_14',
                                type: 'date',
                              },
                              {
                                label: 'Nova data de entrega do pedido 14',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_14',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 15',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_15',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 15',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_15',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 16',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_16',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 16',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_16',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 17',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_17',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 17',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_17',
                                type: 'date',
                              },
                              {
                                label: 'Código do produto a ser alterado 18',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_18',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 18',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_18',
                                type: 'date',
                              },

                              {
                                label: 'Código do produto a ser alterado 19',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_19',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 19',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_19',
                                type: 'date',
                              },

                              {
                                label: 'Código do produto a ser alterado 20',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_alterado_20',
                                type: 'text',
                              },
                              {
                                label: 'Nova data de entrega do pedido 20',
                                validators: Validators.required,
                                name: 'nova_data_de_entrega_do_pedido_20',
                                type: 'date',
                              },
                            ],
                          },
                        ],
                        type: 'select',
                      },
                    ],
                  },
                ],
                type: 'radioHorizontal',
              },
            ],
          },
          {
            type: 'option',
            name: 'Alterar data de vencimento do pedido',
            value: 'Alterar data de vencimento do pedido',
            fields: [
              {
                label: 'Nova data de vencimento do pedido',
                validators: Validators.required,
                name: 'nova_data_de_vencimento_do_pedido',

                type: 'date',
              },

              {
                label: 'Anexar autorização GT ou GR',
                validators: Validators.required,
                name: 'anexar_autoriza_o_gt_ou_gr',

                type: 'attachment',
              },
            ],
          },
          {
            type: 'option',
            name: 'Alterar quantidade',
            value: 'Alterar quantidade',

            fields: [
              {
                label: 'Número de produtos que deverão ser alterados',
                validators: Validators.required,
                name: 'n_mero_de_produtos_que_dever_o_ser_alterados',
                select: [
                  {
                    type: 'option',

                    name: '1',

                    value: '1',
                    fields: [
                      {
                        label: 'Código do produto a ser alterado 1',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_1',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 1',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_1',
                        type: 'number',
                      },
                    ],
                  },

                  {
                    type: 'option',

                    name: '2',
                    value: '2',
                    fields: [
                      {
                        label: 'Código do produto a ser alterado 1',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_1',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 1',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_1',
                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 2',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_2',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 2',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_2',
                        type: 'number',
                      },
                    ],
                  },

                  {
                    type: 'option',

                    name: '3',

                    value: '3',
                    fields: [
                      {
                        label: 'Código do produto a ser alterado 1',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_1',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 1',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_1',
                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 2',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_2',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 2',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_2',
                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 3',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_3',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 3',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_3',

                        type: 'number',
                      },
                    ],
                  },

                  {
                    type: 'option',

                    name: '4',

                    value: '4',
                    fields: [
                      {
                        label: 'Código do produto a ser alterado 1',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_1',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 1',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_1',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 2',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_2',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 2',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_2',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 3',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_3',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 3',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_3',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 4',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_4',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 4',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_4',

                        type: 'number',
                      },
                    ],
                  },

                  {
                    type: 'option',

                    name: '5',

                    value: '5',
                    fields: [
                      {
                        label: 'Código do produto a ser alterado 1',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_1',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 1',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_1',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 2',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_2',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 2',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_2',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 3',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_3',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 3',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_3',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 4',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_4',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 4',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_4',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 5',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_5',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 5',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_5',

                        type: 'number',
                      },
                    ],
                  },

                  {
                    type: 'option',

                    name: '6',

                    value: '6',
                    fields: [
                      {
                        label: 'Código do produto a ser alterado 1',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_1',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 1',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_1',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 2',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_2',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 2',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_2',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 3',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_3',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 3',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_3',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 4',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_4',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 4',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_4',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 5',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_5',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 5',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_5',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 6',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_6',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 6',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_6',

                        type: 'number',
                      },
                    ],
                  },

                  {
                    type: 'option',

                    name: '7',

                    value: '7',

                    fields: [
                      {
                        label: 'Código do produto a ser alterado 1',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_1',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 1',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_1',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 2',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_2',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 2',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_2',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 3',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_3',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 3',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_3',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 4',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_4',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 4',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_4',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 5',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_5',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 5',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_5',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 6',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_6',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 6',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_6',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 7',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_7',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 7',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_7',

                        type: 'number',
                      },
                    ],
                  },

                  {
                    type: 'option',

                    name: '8',

                    value: '8',

                    fields: [
                      {
                        label: 'Código do produto a ser alterado 1',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_1',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 1',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_1',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 2',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_2',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 2',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_2',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 3',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_3',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 3',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_3',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 4',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_4',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 4',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_4',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 5',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_5',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 5',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_5',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 6',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_6',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 6',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_6',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 7',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_7',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 7',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_7',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 8',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_8',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 8',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_8',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 9',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_9',
                        type: 'text',
                      },
                    ],
                  },

                  {
                    type: 'option',

                    name: '9',

                    value: '9',

                    fields: [
                      {
                        label: 'Código do produto a ser alterado 1',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_1',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 1',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_1',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 2',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_2',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 2',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_2',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 3',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_3',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 3',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_3',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 4',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_4',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 4',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_4',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 5',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_5',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 5',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_5',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 6',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_6',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 6',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_6',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 7',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_7',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 7',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_7',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 8',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_8',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 8',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_8',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 9',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_9',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 9',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_9',

                        type: 'number',
                      },
                    ],
                  },

                  {
                    type: 'option',

                    name: '10',

                    value: '10',
                    fields: [
                      {
                        label: 'Código do produto a ser alterado 1',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_1',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 1',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_1',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 2',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_2',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 2',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_2',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 3',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_3',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 3',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_3',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 4',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_4',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 4',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_4',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 5',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_5',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 5',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_5',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 6',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_6',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 6',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_6',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 7',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_7',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 7',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_7',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 8',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_8',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 8',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_8',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 9',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_9',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 9',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_9',

                        type: 'number',
                      },

                      {
                        label: 'Código do produto a ser alterado 10',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_10',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 10',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_10',

                        type: 'number',
                      },
                    ],
                  },

                  {
                    type: 'option',

                    name: '11',

                    value: '11',

                    fields: [
                      {
                        label: 'Código do produto a ser alterado 1',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_1',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 1',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_1',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 2',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_2',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 2',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_2',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 3',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_3',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 3',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_3',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 4',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_4',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 4',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_4',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 5',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_5',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 5',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_5',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 6',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_6',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 6',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_6',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 7',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_7',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 7',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_7',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 8',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_8',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 8',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_8',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 9',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_9',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 9',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_9',

                        type: 'number',
                      },

                      {
                        label: 'Código do produto a ser alterado 10',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_10',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 10',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_10',

                        type: 'number',
                      },

                      {
                        label: 'Código do produto a ser alterado 11',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_11',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 11',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_11',

                        type: 'number',
                      },
                    ],
                  },

                  {
                    type: 'option',

                    name: '12',

                    value: '12',
                    fields: [
                      {
                        label: 'Código do produto a ser alterado 1',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_1',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 1',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_1',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 2',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_2',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 2',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_2',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 3',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_3',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 3',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_3',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 4',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_4',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 4',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_4',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 5',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_5',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 5',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_5',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 6',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_6',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 6',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_6',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 7',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_7',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 7',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_7',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 8',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_8',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 8',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_8',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 9',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_9',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 9',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_9',

                        type: 'number',
                      },

                      {
                        label: 'Código do produto a ser alterado 10',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_10',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 10',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_10',

                        type: 'number',
                      },

                      {
                        label: 'Código do produto a ser alterado 11',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_11',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 11',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_11',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 12',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_12',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 12',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_12',

                        type: 'number',
                      },
                    ],
                  },

                  {
                    type: 'option',

                    name: '13',

                    value: '13',
                    fields: [
                      {
                        label: 'Código do produto a ser alterado 1',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_1',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 1',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_1',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 2',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_2',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 2',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_2',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 3',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_3',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 3',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_3',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 4',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_4',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 4',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_4',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 5',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_5',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 5',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_5',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 6',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_6',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 6',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_6',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 7',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_7',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 7',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_7',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 8',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_8',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 8',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_8',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 9',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_9',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 9',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_9',

                        type: 'number',
                      },

                      {
                        label: 'Código do produto a ser alterado 10',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_10',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 10',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_10',

                        type: 'number',
                      },

                      {
                        label: 'Código do produto a ser alterado 11',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_11',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 11',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_11',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 12',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_12',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 12',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_12',

                        type: 'number',
                      },

                      {
                        label: 'Código do produto a ser alterado 13',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_13',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 13',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_13',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 14',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_14',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 14',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_14',

                        type: 'number',
                      },
                    ],
                  },
                  {
                    type: 'option',

                    name: '14',

                    value: '14',

                    fields: [
                      {
                        label: 'Código do produto a ser alterado 1',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_1',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 1',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_1',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 2',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_2',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 2',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_2',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 3',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_3',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 3',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_3',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 4',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_4',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 4',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_4',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 5',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_5',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 5',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_5',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 6',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_6',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 6',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_6',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 7',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_7',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 7',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_7',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 8',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_8',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 8',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_8',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 9',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_9',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 9',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_9',

                        type: 'number',
                      },

                      {
                        label: 'Código do produto a ser alterado 10',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_10',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 10',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_10',

                        type: 'number',
                      },

                      {
                        label: 'Código do produto a ser alterado 11',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_11',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 11',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_11',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 12',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_12',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 12',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_12',

                        type: 'number',
                      },

                      {
                        label: 'Código do produto a ser alterado 13',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_13',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 13',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_13',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 14',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_14',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 14',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_14',

                        type: 'number',
                      },
                    ],
                  },
                  {
                    type: 'option',

                    name: '15',

                    value: '15',

                    fields: [
                      {
                        label: 'Código do produto a ser alterado 1',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_1',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 1',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_1',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 2',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_2',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 2',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_2',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 3',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_3',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 3',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_3',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 4',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_4',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 4',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_4',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 5',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_5',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 5',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_5',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 6',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_6',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 6',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_6',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 7',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_7',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 7',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_7',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 8',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_8',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 8',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_8',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 9',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_9',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 9',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_9',

                        type: 'number',
                      },

                      {
                        label: 'Código do produto a ser alterado 10',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_10',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 10',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_10',

                        type: 'number',
                      },

                      {
                        label: 'Código do produto a ser alterado 11',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_11',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 11',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_11',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 12',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_12',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 12',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_12',

                        type: 'number',
                      },

                      {
                        label: 'Código do produto a ser alterado 13',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_13',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 13',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_13',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 14',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_14',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 14',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_14',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 15',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_15',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 15',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_15',

                        type: 'number',
                      },
                    ],
                  },

                  {
                    type: 'option',

                    name: '16',

                    value: '16',

                    fields: [
                      {
                        label: 'Código do produto a ser alterado 1',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_1',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 1',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_1',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 2',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_2',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 2',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_2',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 3',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_3',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 3',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_3',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 4',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_4',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 4',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_4',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 5',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_5',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 5',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_5',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 6',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_6',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 6',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_6',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 7',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_7',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 7',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_7',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 8',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_8',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 8',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_8',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 9',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_9',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 9',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_9',

                        type: 'number',
                      },

                      {
                        label: 'Código do produto a ser alterado 10',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_10',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 10',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_10',

                        type: 'number',
                      },

                      {
                        label: 'Código do produto a ser alterado 11',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_11',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 11',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_11',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 12',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_12',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 12',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_12',

                        type: 'number',
                      },

                      {
                        label: 'Código do produto a ser alterado 13',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_13',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 13',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_13',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 14',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_14',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 14',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_14',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 15',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_15',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 15',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_15',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 16',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_16',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 16',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_16',

                        type: 'number',
                      },
                    ],
                  },

                  {
                    type: 'option',

                    name: '17',

                    value: '17',

                    fields: [
                      {
                        label: 'Código do produto a ser alterado 1',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_1',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 1',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_1',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 2',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_2',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 2',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_2',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 3',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_3',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 3',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_3',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 4',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_4',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 4',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_4',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 5',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_5',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 5',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_5',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 6',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_6',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 6',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_6',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 7',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_7',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 7',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_7',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 8',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_8',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 8',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_8',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 9',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_9',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 9',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_9',

                        type: 'number',
                      },

                      {
                        label: 'Código do produto a ser alterado 10',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_10',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 10',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_10',

                        type: 'number',
                      },

                      {
                        label: 'Código do produto a ser alterado 11',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_11',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 11',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_11',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 12',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_12',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 12',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_12',

                        type: 'number',
                      },

                      {
                        label: 'Código do produto a ser alterado 13',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_13',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 13',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_13',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 14',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_14',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 14',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_14',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 15',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_15',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 15',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_15',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 16',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_16',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 16',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_16',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 17',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_17',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 17',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_17',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 18',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_18',
                        type: 'text',
                      },
                    ],
                  },

                  {
                    type: 'option',

                    name: '18',

                    value: '18',

                    fields: [
                      {
                        label: 'Código do produto a ser alterado 1',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_1',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 1',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_1',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 2',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_2',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 2',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_2',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 3',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_3',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 3',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_3',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 4',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_4',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 4',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_4',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 5',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_5',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 5',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_5',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 6',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_6',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 6',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_6',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 7',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_7',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 7',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_7',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 8',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_8',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 8',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_8',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 9',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_9',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 9',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_9',

                        type: 'number',
                      },

                      {
                        label: 'Código do produto a ser alterado 10',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_10',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 10',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_10',

                        type: 'number',
                      },

                      {
                        label: 'Código do produto a ser alterado 11',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_11',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 11',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_11',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 12',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_12',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 12',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_12',

                        type: 'number',
                      },

                      {
                        label: 'Código do produto a ser alterado 13',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_13',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 13',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_13',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 14',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_14',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 14',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_14',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 15',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_15',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 15',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_15',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 16',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_16',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 16',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_16',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 17',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_17',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 17',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_17',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 18',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_18',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 18',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_18',

                        type: 'number',
                      },
                    ],
                  },

                  {
                    type: 'option',

                    name: '19',

                    value: '19',

                    fields: [
                      {
                        label: 'Código do produto a ser alterado 1',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_1',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 1',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_1',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 2',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_2',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 2',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_2',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 3',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_3',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 3',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_3',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 4',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_4',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 4',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_4',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 5',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_5',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 5',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_5',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 6',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_6',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 6',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_6',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 7',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_7',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 7',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_7',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 8',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_8',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 8',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_8',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 9',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_9',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 9',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_9',

                        type: 'number',
                      },

                      {
                        label: 'Código do produto a ser alterado 10',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_10',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 10',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_10',

                        type: 'number',
                      },

                      {
                        label: 'Código do produto a ser alterado 11',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_11',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 11',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_11',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 12',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_12',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 12',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_12',

                        type: 'number',
                      },

                      {
                        label: 'Código do produto a ser alterado 13',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_13',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 13',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_13',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 14',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_14',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 14',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_14',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 15',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_15',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 15',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_15',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 16',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_16',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 16',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_16',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 17',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_17',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 17',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_17',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 18',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_18',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 18',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_18',

                        type: 'number',
                      },

                      {
                        label: 'Código do produto a ser alterado 19',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_19',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 19',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_19',

                        type: 'number',
                      },
                    ],
                  },

                  {
                    type: 'option',

                    name: '20',

                    value: '20',

                    fields: [
                      {
                        label: 'Código do produto a ser alterado 1',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_1',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 1',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_1',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 2',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_2',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 2',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_2',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 3',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_3',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 3',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_3',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 4',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_4',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 4',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_4',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 5',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_5',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 5',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_5',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 6',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_6',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 6',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_6',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 7',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_7',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 7',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_7',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 8',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_8',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 8',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_8',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 9',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_9',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 9',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_9',

                        type: 'number',
                      },

                      {
                        label: 'Código do produto a ser alterado 10',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_10',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 10',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_10',

                        type: 'number',
                      },

                      {
                        label: 'Código do produto a ser alterado 11',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_11',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 11',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_11',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 12',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_12',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 12',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_12',

                        type: 'number',
                      },

                      {
                        label: 'Código do produto a ser alterado 13',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_13',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 13',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_13',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 14',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_14',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 14',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_14',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 15',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_15',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 15',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_15',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 16',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_16',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 16',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_16',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 17',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_17',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 17',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_17',

                        type: 'number',
                      },
                      {
                        label: 'Código do produto a ser alterado 18',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_18',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 18',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_18',

                        type: 'number',
                      },

                      {
                        label: 'Código do produto a ser alterado 19',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_19',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 19',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_19',

                        type: 'number',
                      },

                      {
                        label: 'Código do produto a ser alterado 20',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_20',
                        type: 'text',
                      },
                      {
                        label: 'Nova quantidade do produto 20',
                        validators: Validators.required,
                        name: 'nova_quantidade_do_produto_20',

                        type: 'number',
                      },
                    ],
                  },
                ],
                type: 'select',
              },
              {
                label: 'Justificativa para alteração / cancelamento do pedido',
                validators: Validators.required,
                name: 'justificativa_para_altera_o_cancelamento_do_pedido',
                select: [
                  {
                    type: 'option',

                    name: 'Atraso na inclusão da OV no Sistema',

                    value: 'Atraso na inclusão da OV no Sistema',
                  },

                  {
                    type: 'option',

                    name: 'Baixa de saldo de CO/PO Mae',

                    value: 'Baixa de saldo de CO/PO Mae',
                  },

                  {
                    type: 'option',

                    name: 'Cadastro do cliente (Master Dt)',

                    value: 'Cadastro do cliente (Master Dt)',
                  },

                  {
                    type: 'option',

                    name: 'Cadastro Material (Master Dt)',

                    value: 'Cadastro Material (Master Dt)',
                  },

                  {
                    type: 'option',

                    name: 'Cliente com cadastro incompleto',

                    value: 'Cliente com cadastro incompleto',
                  },

                  {
                    type: 'option',

                    name: 'Cliente reduziu área plantada',

                    value: 'Cliente reduziu área plantada',
                  },

                  {
                    type: 'option',

                    name: 'Crédito Financeiro',

                    value: 'Crédito Financeiro',
                  },

                  {
                    type: 'option',

                    name: 'Desfazimento',

                    value: 'Desfazimento',
                  },

                  {
                    type: 'option',

                    name: 'Desistência do cliente',

                    value: 'Desistência do cliente',
                  },

                  {
                    type: 'option',

                    name: 'Endereço do local de entrega incorreto',

                    value: 'Endereço do local de entrega incorreto',
                  },

                  {
                    type: 'option',

                    name: 'Erro na digitação da OV',

                    value: 'Erro na digitação da OV',
                  },

                  {
                    type: 'option',

                    name: 'Erro nas informações do pedido',

                    value: 'Erro nas informações do pedido',
                  },

                  {
                    type: 'option',

                    name: 'Falha na negociação',

                    value: 'Falha na negociação',
                  },

                  {
                    type: 'option',

                    name: 'Falta de produto (Stock Out)',

                    value: 'Falta de produto (Stock Out)',
                  },

                  {
                    type: 'option',

                    name: 'Pedido duplicado',

                    value: 'Pedido duplicado',
                  },

                  {
                    type: 'option',

                    name: 'Pedido teste',

                    value: 'Pedido teste',
                  },

                  {
                    type: 'option',

                    name: 'Performance/Qualidade',

                    value: 'Performance/Qualidade',
                  },

                  {
                    type: 'option',

                    name: 'Substituição do preoduto/embalagem',

                    value: 'Substituição do preoduto/embalagem',
                  },

                  {
                    type: 'option',

                    name: 'Suspensão de vendas regulatória',

                    value: 'Suspensão de vendas regulatória',
                  },

                  {
                    type: 'option',

                    name: 'Validade da negociação expirada',

                    value: 'Validade da negociação expirada',
                  },
                ],
                type: 'select',
              },
              {
                label: 'Justificativa complementar',
                name: 'justificativa_complementar',
                validators: Validators.maxLength(1000),
                type: 'textArea',
              },
            ],
          },
          {
            type: 'option',
            name: 'Alterar valor do pedido',
            value: 'Alterar valor do pedido',
            fields: [
              {
                label: 'Número de produtos que deverão ser alterados',
                validators: Validators.required,
                name: 'n_mero_de_produtos_que_dever_o_ser_alterados',

                select: [
                  {
                    type: 'option',

                    name: '1',

                    value: '1',
                    fields: [
                      {
                        label: 'Código do produto a ser alterado 1',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_1',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 1',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_1',

                        type: 'text',
                      },
                    ],
                  },

                  {
                    type: 'option',

                    name: '2',
                    value: '2',
                    fields: [
                      {
                        label: 'Código do produto a ser alterado 1',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_1',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 1',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_1',

                        type: 'text',
                      },
                      {
                        label: 'Código do produto a ser alterado 2',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_2',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 2',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_2',

                        type: 'text',
                      },
                    ],
                  },

                  {
                    type: 'option',

                    name: '3',

                    value: '3',
                    fields: [
                      {
                        label: 'Código do produto a ser alterado 1',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_1',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 1',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_1',

                        type: 'text',
                      },
                      {
                        label: 'Código do produto a ser alterado 2',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_2',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 2',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_2',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 3',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_3',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 3',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_3',

                        type: 'text',
                      },
                    ],
                  },

                  {
                    type: 'option',

                    name: '4',

                    value: '4',
                    fields: [
                      {
                        label: 'Código do produto a ser alterado 1',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_1',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 1',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_1',

                        type: 'text',
                      },
                      {
                        label: 'Código do produto a ser alterado 2',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_2',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 2',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_2',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 3',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_3',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 3',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_3',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 4',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_4',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 4',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_4',

                        type: 'text',
                      },
                    ],
                  },

                  {
                    type: 'option',

                    name: '5',

                    value: '5',
                    fields: [
                      {
                        label: 'Código do produto a ser alterado 1',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_1',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 1',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_1',

                        type: 'text',
                      },
                      {
                        label: 'Código do produto a ser alterado 2',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_2',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 2',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_2',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 3',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_3',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 3',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_3',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 4',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_4',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 4',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_4',

                        type: 'text',
                      },
                      {
                        label: 'Código do produto a ser alterado 5',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_5',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 5',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_5',

                        type: 'text',
                      },
                    ],
                  },

                  {
                    type: 'option',

                    name: '6',

                    value: '6',
                    fields: [
                      {
                        label: 'Código do produto a ser alterado 1',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_1',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 1',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_1',

                        type: 'text',
                      },
                      {
                        label: 'Código do produto a ser alterado 2',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_2',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 2',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_2',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 3',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_3',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 3',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_3',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 4',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_4',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 4',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_4',

                        type: 'text',
                      },
                      {
                        label: 'Código do produto a ser alterado 5',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_5',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 5',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_5',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 6',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_6',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 6',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_6',

                        type: 'text',
                      },

                    ],
                  },

                  {
                    type: 'option',

                    name: '7',

                    value: '7',

                    fields: [
                      {
                        label: 'Código do produto a ser alterado 1',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_1',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 1',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_1',

                        type: 'text',
                      },
                      {
                        label: 'Código do produto a ser alterado 2',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_2',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 2',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_2',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 3',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_3',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 3',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_3',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 4',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_4',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 4',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_4',

                        type: 'text',
                      },
                      {
                        label: 'Código do produto a ser alterado 5',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_5',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 5',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_5',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 6',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_6',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 6',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_6',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 7',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_7',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 7',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_7',

                        type: 'text',
                      },
                    ],
                  },

                  {
                    type: 'option',

                    name: '8',

                    value: '8',

                    fields: [
                      {
                        label: 'Código do produto a ser alterado 1',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_1',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 1',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_1',

                        type: 'text',
                      },
                      {
                        label: 'Código do produto a ser alterado 2',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_2',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 2',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_2',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 3',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_3',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 3',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_3',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 4',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_4',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 4',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_4',

                        type: 'text',
                      },
                      {
                        label: 'Código do produto a ser alterado 5',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_5',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 5',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_5',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 6',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_6',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 6',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_6',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 7',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_7',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 7',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_7',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 8',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_8',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 8',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_8',

                        type: 'text',
                      },
                    ],
                  },

                  {
                    type: 'option',

                    name: '9',

                    value: '9',

                    fields: [
                      {
                        label: 'Código do produto a ser alterado 1',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_1',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 1',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_1',

                        type: 'text',
                      },
                      {
                        label: 'Código do produto a ser alterado 2',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_2',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 2',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_2',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 3',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_3',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 3',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_3',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 4',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_4',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 4',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_4',

                        type: 'text',
                      },
                      {
                        label: 'Código do produto a ser alterado 5',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_5',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 5',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_5',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 6',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_6',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 6',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_6',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 7',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_7',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 7',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_7',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 8',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_8',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 8',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_8',

                        type: 'text',
                      },
                      {
                        label: 'Código do produto a ser alterado 9',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_9',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 9',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_9',

                        type: 'text',
                      },
                    ],
                  },

                  {
                    type: 'option',

                    name: '10',

                    value: '10',
                    fields: [
                      {
                        label: 'Código do produto a ser alterado 1',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_1',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 1',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_1',

                        type: 'text',
                      },
                      {
                        label: 'Código do produto a ser alterado 2',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_2',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 2',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_2',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 3',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_3',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 3',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_3',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 4',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_4',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 4',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_4',

                        type: 'text',
                      },
                      {
                        label: 'Código do produto a ser alterado 5',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_5',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 5',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_5',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 6',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_6',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 6',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_6',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 7',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_7',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 7',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_7',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 8',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_8',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 8',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_8',

                        type: 'text',
                      },
                      {
                        label: 'Código do produto a ser alterado 9',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_9',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 9',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_9',

                        type: 'text',
                      },
                      {
                        label: 'Código do produto a ser alterado 10',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_10',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 10',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_10',

                        type: 'text',
                      },
                    ],
                  },

                  {
                    type: 'option',

                    name: '11',

                    value: '11',

                    fields: [
                      {
                        label: 'Código do produto a ser alterado 1',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_1',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 1',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_1',

                        type: 'text',
                      },
                      {
                        label: 'Código do produto a ser alterado 2',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_2',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 2',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_2',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 3',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_3',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 3',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_3',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 4',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_4',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 4',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_4',

                        type: 'text',
                      },
                      {
                        label: 'Código do produto a ser alterado 5',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_5',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 5',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_5',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 6',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_6',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 6',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_6',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 7',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_7',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 7',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_7',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 8',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_8',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 8',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_8',

                        type: 'text',
                      },
                      {
                        label: 'Código do produto a ser alterado 9',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_9',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 9',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_9',

                        type: 'text',
                      },
                      {
                        label: 'Código do produto a ser alterado 10',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_10',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 10',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_10',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 11',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_11',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 11',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_11',

                        type: 'text',
                      },
                    ],
                  },

                  {
                    type: 'option',

                    name: '12',

                    value: '12',
                    fields: [
                      {
                        label: 'Código do produto a ser alterado 1',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_1',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 1',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_1',

                        type: 'text',
                      },
                      {
                        label: 'Código do produto a ser alterado 2',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_2',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 2',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_2',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 3',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_3',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 3',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_3',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 4',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_4',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 4',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_4',

                        type: 'text',
                      },
                      {
                        label: 'Código do produto a ser alterado 5',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_5',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 5',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_5',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 6',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_6',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 6',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_6',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 7',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_7',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 7',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_7',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 8',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_8',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 8',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_8',

                        type: 'text',
                      },
                      {
                        label: 'Código do produto a ser alterado 9',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_9',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 9',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_9',

                        type: 'text',
                      },
                      {
                        label: 'Código do produto a ser alterado 10',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_10',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 10',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_10',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 11',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_11',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 11',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_11',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 12',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_12',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 12',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_12',

                        type: 'text',
                      },
                    ],
                  },

                  {
                    type: 'option',

                    name: '13',

                    value: '13',
                        fields: [
                      {
                        label: 'Código do produto a ser alterado 1',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_1',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 1',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_1',

                        type: 'text',
                      },
                      {
                        label: 'Código do produto a ser alterado 2',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_2',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 2',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_2',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 3',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_3',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 3',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_3',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 4',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_4',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 4',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_4',

                        type: 'text',
                      },
                      {
                        label: 'Código do produto a ser alterado 5',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_5',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 5',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_5',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 6',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_6',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 6',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_6',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 7',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_7',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 7',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_7',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 8',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_8',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 8',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_8',

                        type: 'text',
                      },
                      {
                        label: 'Código do produto a ser alterado 9',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_9',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 9',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_9',

                        type: 'text',
                      },
                      {
                        label: 'Código do produto a ser alterado 10',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_10',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 10',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_10',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 11',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_11',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 11',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_11',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 12',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_12',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 12',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_12',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 13',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_13',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 13',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_13',

                        type: 'text',
                      },
                    ],
                  },
                  {
                    type: 'option',

                    name: '14',

                    value: '14',

                    fields: [
                      {
                        label: 'Código do produto a ser alterado 1',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_1',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 1',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_1',

                        type: 'text',
                      },
                      {
                        label: 'Código do produto a ser alterado 2',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_2',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 2',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_2',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 3',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_3',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 3',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_3',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 4',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_4',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 4',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_4',

                        type: 'text',
                      },
                      {
                        label: 'Código do produto a ser alterado 5',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_5',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 5',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_5',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 6',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_6',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 6',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_6',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 7',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_7',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 7',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_7',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 8',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_8',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 8',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_8',

                        type: 'text',
                      },
                      {
                        label: 'Código do produto a ser alterado 9',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_9',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 9',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_9',

                        type: 'text',
                      },
                      {
                        label: 'Código do produto a ser alterado 10',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_10',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 10',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_10',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 11',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_11',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 11',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_11',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 12',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_12',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 12',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_12',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 13',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_13',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 13',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_13',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 14',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_14',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 14',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_14',

                        type: 'text',
                      },
                    ],
                  },
                  {
                    type: 'option',

                    name: '15',

                    value: '15',

                    fields: [
                      {
                        label: 'Código do produto a ser alterado 1',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_1',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 1',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_1',

                        type: 'text',
                      },
                      {
                        label: 'Código do produto a ser alterado 2',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_2',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 2',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_2',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 3',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_3',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 3',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_3',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 4',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_4',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 4',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_4',

                        type: 'text',
                      },
                      {
                        label: 'Código do produto a ser alterado 5',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_5',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 5',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_5',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 6',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_6',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 6',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_6',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 7',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_7',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 7',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_7',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 8',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_8',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 8',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_8',

                        type: 'text',
                      },
                      {
                        label: 'Código do produto a ser alterado 9',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_9',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 9',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_9',

                        type: 'text',
                      },
                      {
                        label: 'Código do produto a ser alterado 10',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_10',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 10',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_10',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 11',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_11',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 11',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_11',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 12',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_12',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 12',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_12',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 13',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_13',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 13',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_13',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 14',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_14',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 14',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_14',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 15',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_15',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 15',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_15',

                        type: 'text',
                      },
                    ],
                  },

                  {
                    type: 'option',

                    name: '16',

                    value: '16',

                    fields: [
                      {
                        label: 'Código do produto a ser alterado 1',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_1',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 1',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_1',

                        type: 'text',
                      },
                      {
                        label: 'Código do produto a ser alterado 2',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_2',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 2',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_2',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 3',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_3',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 3',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_3',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 4',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_4',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 4',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_4',

                        type: 'text',
                      },
                      {
                        label: 'Código do produto a ser alterado 5',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_5',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 5',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_5',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 6',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_6',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 6',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_6',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 7',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_7',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 7',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_7',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 8',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_8',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 8',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_8',

                        type: 'text',
                      },
                      {
                        label: 'Código do produto a ser alterado 9',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_9',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 9',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_9',

                        type: 'text',
                      },
                      {
                        label: 'Código do produto a ser alterado 10',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_10',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 10',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_10',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 11',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_11',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 11',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_11',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 12',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_12',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 12',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_12',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 13',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_13',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 13',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_13',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 14',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_14',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 14',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_14',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 15',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_15',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 15',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_15',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 16',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_16',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 16',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_16',

                        type: 'text',
                      },
                    ],
                  },

                  {
                    type: 'option',

                    name: '17',

                    value: '17',

                    fields: [
                      {
                        label: 'Código do produto a ser alterado 1',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_1',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 1',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_1',

                        type: 'text',
                      },
                      {
                        label: 'Código do produto a ser alterado 2',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_2',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 2',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_2',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 3',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_3',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 3',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_3',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 4',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_4',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 4',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_4',

                        type: 'text',
                      },
                      {
                        label: 'Código do produto a ser alterado 5',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_5',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 5',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_5',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 6',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_6',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 6',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_6',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 7',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_7',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 7',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_7',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 8',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_8',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 8',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_8',

                        type: 'text',
                      },
                      {
                        label: 'Código do produto a ser alterado 9',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_9',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 9',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_9',

                        type: 'text',
                      },
                      {
                        label: 'Código do produto a ser alterado 10',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_10',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 10',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_10',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 11',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_11',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 11',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_11',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 12',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_12',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 12',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_12',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 13',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_13',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 13',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_13',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 14',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_14',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 14',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_14',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 15',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_15',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 15',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_15',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 16',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_16',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 16',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_16',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 17',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_17',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 17',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_17',

                        type: 'text',
                      },
                    ],
                  },

                  {
                    type: 'option',

                    name: '18',

                    value: '18',

                    fields: [
                      {
                        label: 'Código do produto a ser alterado 1',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_1',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 1',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_1',

                        type: 'text',
                      },
                      {
                        label: 'Código do produto a ser alterado 2',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_2',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 2',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_2',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 3',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_3',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 3',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_3',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 4',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_4',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 4',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_4',

                        type: 'text',
                      },
                      {
                        label: 'Código do produto a ser alterado 5',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_5',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 5',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_5',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 6',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_6',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 6',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_6',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 7',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_7',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 7',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_7',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 8',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_8',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 8',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_8',

                        type: 'text',
                      },
                      {
                        label: 'Código do produto a ser alterado 9',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_9',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 9',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_9',

                        type: 'text',
                      },
                      {
                        label: 'Código do produto a ser alterado 10',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_10',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 10',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_10',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 11',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_11',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 11',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_11',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 12',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_12',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 12',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_12',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 13',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_13',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 13',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_13',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 14',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_14',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 14',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_14',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 15',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_15',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 15',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_15',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 16',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_16',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 16',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_16',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 17',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_17',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 17',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_17',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 18',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_18',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 18',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_18',

                        type: 'text',
                      },
                    ],
                  },

                  {
                    type: 'option',

                    name: '19',

                    value: '19',

                    fields: [
                      {
                        label: 'Código do produto a ser alterado 1',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_1',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 1',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_1',

                        type: 'text',
                      },
                      {
                        label: 'Código do produto a ser alterado 2',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_2',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 2',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_2',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 3',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_3',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 3',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_3',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 4',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_4',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 4',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_4',

                        type: 'text',
                      },
                      {
                        label: 'Código do produto a ser alterado 5',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_5',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 5',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_5',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 6',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_6',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 6',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_6',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 7',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_7',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 7',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_7',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 8',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_8',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 8',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_8',

                        type: 'text',
                      },
                      {
                        label: 'Código do produto a ser alterado 9',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_9',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 9',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_9',

                        type: 'text',
                      },
                      {
                        label: 'Código do produto a ser alterado 10',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_10',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 10',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_10',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 11',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_11',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 11',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_11',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 12',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_12',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 12',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_12',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 13',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_13',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 13',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_13',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 14',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_14',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 14',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_14',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 15',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_15',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 15',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_15',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 16',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_16',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 16',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_16',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 17',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_17',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 17',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_17',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 18',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_18',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 18',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_18',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 19',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_19',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 19',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_19',

                        type: 'text',
                      },
                    ],
                  },

                  {
                    type: 'option',

                    name: '20',

                    value: '20',

                    fields: [
                      {
                        label: 'Código do produto a ser alterado 1',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_1',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 1',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_1',

                        type: 'text',
                      },
                      {
                        label: 'Código do produto a ser alterado 2',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_2',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 2',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_2',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 3',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_3',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 3',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_3',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 4',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_4',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 4',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_4',

                        type: 'text',
                      },
                      {
                        label: 'Código do produto a ser alterado 5',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_5',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 5',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_5',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 6',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_6',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 6',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_6',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 7',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_7',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 7',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_7',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 8',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_8',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 8',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_8',

                        type: 'text',
                      },
                      {
                        label: 'Código do produto a ser alterado 9',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_9',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 9',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_9',

                        type: 'text',
                      },
                      {
                        label: 'Código do produto a ser alterado 10',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_10',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 10',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_10',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 11',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_11',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 11',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_11',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 12',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_12',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 12',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_12',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 13',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_13',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 13',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_13',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 14',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_14',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 14',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_14',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 15',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_15',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 15',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_15',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 16',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_16',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 16',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_16',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 17',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_17',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 17',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_17',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 18',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_18',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 18',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_18',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 19',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_19',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 19',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_19',

                        type: 'text',
                      },

                      {
                        label: 'Código do produto a ser alterado 20',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'c_digo_do_produto_a_ser_alterado_20',
                        type: 'text',
                      },
                      {
                        label: 'Novo valor do produto 20',
                        validators: [Validators.required, Validators.maxLength(1000)],
                        name: 'novo_valor_do_produto_20',

                        type: 'text',
                      },
                    ],
                  },
                ],
                type: 'select',
              },

              {
                label: 'Anexar autorização GT ou GR',
                validators: Validators.required,
                name: 'anexar_autoriza_o_gt_ou_gr',

                type: 'attachment',
              },

              {
                label: 'Justificativa para alteração / cancelamento do pedido',
                validators: Validators.required,
                name: 'justificativa_para_altera_o_cancelamento_do_pedido',
                select: [
                  {
                    type: 'option',

                    name: 'Atraso na inclusão da OV no Sistema',

                    value: 'Atraso na inclusão da OV no Sistema',
                  },

                  {
                    type: 'option',

                    name: 'Baixa de saldo de CO/PO Mae',

                    value: 'Baixa de saldo de CO/PO Mae',
                  },

                  {
                    type: 'option',

                    name: 'Cadastro do cliente (Master Dt)',

                    value: 'Cadastro do cliente (Master Dt)',
                  },

                  {
                    type: 'option',

                    name: 'Cadastro Material (Master Dt)',

                    value: 'Cadastro Material (Master Dt)',
                  },

                  {
                    type: 'option',

                    name: 'Cliente com cadastro incompleto',

                    value: 'Cliente com cadastro incompleto',
                  },

                  {
                    type: 'option',

                    name: 'Cliente reduziu área plantada',

                    value: 'Cliente reduziu área plantada',
                  },

                  {
                    type: 'option',

                    name: 'Crédito Financeiro',

                    value: 'Crédito Financeiro',
                  },

                  {
                    type: 'option',

                    name: 'Desfazimento',

                    value: 'Desfazimento',
                  },

                  {
                    type: 'option',

                    name: 'Desistência do cliente',

                    value: 'Desistência do cliente',
                  },

                  {
                    type: 'option',

                    name: 'Endereço do local de entrega incorreto',

                    value: 'Endereço do local de entrega incorreto',
                  },

                  {
                    type: 'option',

                    name: 'Erro na digitação da OV',

                    value: 'Erro na digitação da OV',
                  },

                  {
                    type: 'option',

                    name: 'Erro nas informações do pedido',

                    value: 'Erro nas informações do pedido',
                  },

                  {
                    type: 'option',

                    name: 'Falha na negociação',

                    value: 'Falha na negociação',
                  },

                  {
                    type: 'option',

                    name: 'Falta de produto (Stock Out)',

                    value: 'Falta de produto (Stock Out)',
                  },

                  {
                    type: 'option',

                    name: 'Pedido duplicado',

                    value: 'Pedido duplicado',
                  },

                  {
                    type: 'option',

                    name: 'Pedido teste',

                    value: 'Pedido teste',
                  },

                  {
                    type: 'option',

                    name: 'Performance/Qualidade',

                    value: 'Performance/Qualidade',
                  },

                  {
                    type: 'option',

                    name: 'Substituição do preoduto/embalagem',

                    value: 'Substituição do preoduto/embalagem',
                  },

                  {
                    type: 'option',

                    name: 'Suspensão de vendas regulatória',

                    value: 'Suspensão de vendas regulatória',
                  },

                  {
                    type: 'option',

                    name: 'Validade da negociação expirada',

                    value: 'Validade da negociação expirada',
                  },
                ],
                type: 'select',
              },
              {
                label: 'Justificativa complementar',
                name: 'justificativa_para_altera_o_do_pedido',
                validators: Validators.maxLength(1000),
                type: 'textArea',
              },
            ],
          },

          {
            type: 'option',
            name: 'Alterar outro dado do pedido',
            value: 'Alterar outro dado do pedido',
            fields: [
              {
                label: 'Qual a alteração desejada?',
                validators: [Validators.required, Validators.maxLength(1000)],
                name: 'qual_a_altera_o_desejada',

                type: 'text',
              },
              {
                label: 'Descreva a alteração desejada',
                validators: [Validators.required, Validators.maxLength(1000)],
                name: 'descreva_a_altera_o_desejada',

                type: 'textArea',
              },
              {
                label: 'Justificativa para alteração / cancelamento do pedido',
                validators: Validators.required,
                name: 'justificativa_para_altera_o_cancelamento_do_pedido',
                select: [
                  {
                    type: 'option',

                    name: 'Atraso na inclusão da OV no Sistema',

                    value: 'Atraso na inclusão da OV no Sistema',
                  },

                  {
                    type: 'option',

                    name: 'Baixa de saldo de CO/PO Mae',

                    value: 'Baixa de saldo de CO/PO Mae',
                  },

                  {
                    type: 'option',

                    name: 'Cadastro do cliente (Master Dt)',

                    value: 'Cadastro do cliente (Master Dt)',
                  },

                  {
                    type: 'option',

                    name: 'Cadastro Material (Master Dt)',

                    value: 'Cadastro Material (Master Dt)',
                  },

                  {
                    type: 'option',

                    name: 'Cliente com cadastro incompleto',

                    value: 'Cliente com cadastro incompleto',
                  },

                  {
                    type: 'option',

                    name: 'Cliente reduziu área plantada',

                    value: 'Cliente reduziu área plantada',
                  },

                  {
                    type: 'option',

                    name: 'Crédito Financeiro',

                    value: 'Crédito Financeiro',
                  },

                  {
                    type: 'option',

                    name: 'Desfazimento',

                    value: 'Desfazimento',
                  },

                  {
                    type: 'option',

                    name: 'Desistência do cliente',

                    value: 'Desistência do cliente',
                  },

                  {
                    type: 'option',

                    name: 'Endereço do local de entrega incorreto',

                    value: 'Endereço do local de entrega incorreto',
                  },

                  {
                    type: 'option',

                    name: 'Erro na digitação da OV',

                    value: 'Erro na digitação da OV',
                  },

                  {
                    type: 'option',

                    name: 'Erro nas informações do pedido',

                    value: 'Erro nas informações do pedido',
                  },

                  {
                    type: 'option',

                    name: 'Falha na negociação',

                    value: 'Falha na negociação',
                  },

                  {
                    type: 'option',

                    name: 'Falta de produto (Stock Out)',

                    value: 'Falta de produto (Stock Out)',
                  },

                  {
                    type: 'option',

                    name: 'Pedido duplicado',

                    value: 'Pedido duplicado',
                  },

                  {
                    type: 'option',

                    name: 'Pedido teste',

                    value: 'Pedido teste',
                  },

                  {
                    type: 'option',

                    name: 'Performance/Qualidade',

                    value: 'Performance/Qualidade',
                  },

                  {
                    type: 'option',

                    name: 'Substituição do preoduto/embalagem',

                    value: 'Substituição do preoduto/embalagem',
                  },

                  {
                    type: 'option',

                    name: 'Suspensão de vendas regulatória',

                    value: 'Suspensão de vendas regulatória',
                  },

                  {
                    type: 'option',

                    name: 'Validade da negociação expirada',

                    value: 'Validade da negociação expirada',
                  },
                ],
                type: 'select',
              },
              {
                label: 'Justificativa complementar',
                name: 'justificativa_para_altera_o_do_pedido',
                validators: Validators.maxLength(1000),
                type: 'textArea',
              },
            ],
          },
          {
            type: 'option',
            name: 'Cancelar pedido',
            value: 'Cancelar pedido',
            fields: [
              {
                label: 'Cancelar pedido completo?',
                validators: Validators.required,
                name: 'cancelar_pedido_completo',

                value: '',

                select: [
                  {
                    type: 'option',

                    name: 'Sim',

                    value: 'Sim',
                  },

                  {
                    type: 'option',

                    name: 'Não',

                    value: 'Não',
                    fields: [
                      {
                        label: 'Número de produtos que deverão ser cancelados',
                        validators: Validators.required,
                        name: 'n_mero_de_produtos_que_dever_o_ser_cancelados',

                        select: [
                          {
                            type: 'option',

                            name: '1',

                            value: '1',
                            fields: [
                              {
                                label: 'Código do produto a ser cancelado 1',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_1',

                                type: 'text',
                              },
                            ],
                          },

                          {
                            type: 'option',

                            name: '2',

                            value: '2',
                            fields: [
                              {
                                label: 'Código do produto a ser cancelado 1',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_1',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 2',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_2',

                                type: 'text',
                              },
                            ],
                          },

                          {
                            type: 'option',

                            name: '3',

                            value: '3',
                            fields: [
                              {
                                label: 'Código do produto a ser cancelado 1',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_1',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 2',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_2',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 3',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_3',

                                type: 'text',
                              },
                            ],
                          },

                          {
                            type: 'option',

                            name: '4',

                            value: '4',

                            fields: [
                              {
                                label: 'Código do produto a ser cancelado 1',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_1',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 2',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_2',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 3',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_3',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 4',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_4',

                                type: 'text',
                              },
                            ],
                          },

                          {
                            type: 'option',

                            name: '5',

                            value: '5',

                            fields: [
                              {
                                label: 'Código do produto a ser cancelado 1',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_1',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 2',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_2',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 3',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_3',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 4',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_4',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 5',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_5',

                                type: 'text',
                              },
                            ],
                          },

                          {
                            type: 'option',

                            name: '6',

                            value: '6',

                            fields: [
                              {
                                label: 'Código do produto a ser cancelado 1',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_1',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 2',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_2',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 3',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_3',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 4',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_4',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 5',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_5',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 6',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_6',

                                type: 'text',
                              },
                            ],
                          },

                          {
                            type: 'option',

                            name: '7',

                            value: '7',

                            fields: [
                              {
                                label: 'Código do produto a ser cancelado 1',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_1',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 2',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_2',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 3',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_3',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 4',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_4',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 5',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_5',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 6',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_6',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 7',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_7',

                                type: 'text',
                              },
                            ],
                          },

                          {
                            type: 'option',

                            name: '8',

                            value: '8',
                            fields: [
                              {
                                label: 'Código do produto a ser cancelado 1',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_1',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 2',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_2',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 3',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_3',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 4',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_4',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 5',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_5',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 6',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_6',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 7',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_7',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 8',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_8',

                                type: 'text',
                              },
                            ],
                          },

                          {
                            type: 'option',

                            name: '9',

                            value: '9',
                            fields: [
                              {
                                label: 'Código do produto a ser cancelado 1',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_1',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 2',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_2',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 3',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_3',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 4',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_4',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 5',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_5',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 6',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_6',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 7',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_7',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 8',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_8',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 9',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_9',

                                type: 'text',
                              },
                            ],
                          },

                          {
                            type: 'option',

                            name: '10',

                            value: '10',

                            fields: [
                              {
                                label: 'Código do produto a ser cancelado 1',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_1',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 2',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_2',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 3',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_3',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 4',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_4',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 5',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_5',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 6',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_6',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 7',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_7',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 8',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_8',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 9',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_9',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 10',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_10',

                                type: 'text',
                              },
                            ],
                          },

                          {
                            type: 'option',

                            name: '11',

                            value: '11',

                            fields: [
                              {
                                label: 'Código do produto a ser cancelado 1',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_1',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 2',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_2',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 3',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_3',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 4',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_4',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 5',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_5',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 6',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_6',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 7',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_7',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 8',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_8',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 9',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_9',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 10',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_10',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 11',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_11',

                                type: 'text',
                              },
                            ],
                          },

                          {
                            type: 'option',

                            name: '12',

                            value: '12',

                            fields: [
                              {
                                label: 'Código do produto a ser cancelado 1',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_1',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 2',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_2',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 3',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_3',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 4',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_4',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 5',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_5',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 6',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_6',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 7',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_7',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 8',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_8',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 9',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_9',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 10',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_10',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 11',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_11',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 12',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_12',

                                type: 'text',
                              },
                            ],
                          },

                          {
                            type: 'option',

                            name: '13',

                            value: '13',

                            fields: [
                              {
                                label: 'Código do produto a ser cancelado 1',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_1',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 2',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_2',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 3',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_3',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 4',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_4',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 5',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_5',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 6',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_6',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 7',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_7',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 8',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_8',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 9',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_9',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 10',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_10',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 11',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_11',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 12',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_12',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 13',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_13',

                                type: 'text',
                              },
                            ],
                          },

                          {
                            type: 'option',

                            name: '14',

                            value: '14',

                            fields: [
                              {
                                label: 'Código do produto a ser cancelado 1',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_1',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 2',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_2',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 3',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_3',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 4',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_4',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 5',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_5',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 6',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_6',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 7',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_7',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 8',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_8',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 9',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_9',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 10',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_10',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 11',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_11',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 12',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_12',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 13',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_13',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 14',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_14',

                                type: 'text',
                              },
                            ],
                          },

                          {
                            type: 'option',

                            name: '15',

                            value: '15',

                            fields: [
                              {
                                label: 'Código do produto a ser cancelado 1',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_1',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 2',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_2',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 3',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_3',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 4',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_4',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 5',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_5',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 6',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_6',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 7',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_7',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 8',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_8',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 9',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_9',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 10',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_10',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 11',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_11',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 12',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_12',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 13',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_13',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 14',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_14',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 15',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_15',

                                type: 'text',
                              },
                            ],
                          },

                          {
                            type: 'option',

                            name: '16',

                            value: '16',

                            fields: [
                              {
                                label: 'Código do produto a ser cancelado 1',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_1',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 2',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_2',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 3',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_3',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 4',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_4',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 5',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_5',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 6',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_6',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 7',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_7',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 8',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_8',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 9',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_9',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 10',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_10',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 11',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_11',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 12',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_12',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 13',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_13',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 14',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_14',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 15',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_15',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 16',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_16',

                                type: 'text',
                              },
                            ],
                          },

                          {
                            type: 'option',

                            name: '17',

                            value: '17',

                            fields: [
                              {
                                label: 'Código do produto a ser cancelado 1',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_1',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 2',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_2',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 3',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_3',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 4',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_4',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 5',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_5',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 6',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_6',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 7',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_7',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 8',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_8',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 9',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_9',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 10',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_10',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 11',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_11',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 12',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_12',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 13',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_13',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 14',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_14',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 15',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_15',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 16',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_16',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 17',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_17',

                                type: 'text',
                              },
                            ],
                          },

                          {
                            type: 'option',

                            name: '18',

                            value: '18',

                            fields: [
                              {
                                label: 'Código do produto a ser cancelado 1',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_1',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 2',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_2',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 3',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_3',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 4',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_4',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 5',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_5',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 6',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_6',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 7',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_7',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 8',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_8',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 9',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_9',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 10',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_10',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 11',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_11',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 12',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_12',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 13',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_13',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 14',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_14',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 15',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_15',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 16',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_16',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 17',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_17',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 18',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_18',

                                type: 'text',
                              },
                            ],
                          },

                          {
                            type: 'option',

                            name: '19',

                            value: '19',

                            fields: [
                              {
                                label: 'Código do produto a ser cancelado 1',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_1',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 2',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_2',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 3',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_3',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 4',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_4',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 5',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_5',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 6',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_6',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 7',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_7',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 8',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_8',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 9',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_9',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 10',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_10',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 11',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_11',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 12',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_12',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 13',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_13',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 14',
                                validators: Validators.required,
                                name: 'c_digo_do_produto_a_ser_cancelado_14',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 15',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_15',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 16',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_16',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 17',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_17',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 18',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_18',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 19',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_19',

                                type: 'text',
                              },
                            ],
                          },

                          {
                            type: 'option',

                            name: '20',

                            value: '20',

                            fields: [
                              {
                                label: 'Código do produto a ser cancelado 1',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_1',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 2',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_2',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 3',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_3',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 4',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_4',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 5',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_5',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 6',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_6',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 7',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_7',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 8',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_8',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 9',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_9',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 10',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_10',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 11',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_11',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 12',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_12',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 13',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_13',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 14',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_14',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 15',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_15',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 16',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_16',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 17',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_17',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 18',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_18',

                                type: 'text',
                              },

                              {
                                label: 'Código do produto a ser cancelado 19',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_19',

                                type: 'text',
                              },
                              {
                                label: 'Código do produto a ser cancelado 20',
                                validators: [Validators.required, Validators.maxLength(1000)],
                                name: 'c_digo_do_produto_a_ser_cancelado_20',

                                type: 'text',
                              },
                            ],
                          },
                        ],
                        type: 'select',
                      },
                    ],
                  },
                ],

                type: 'radioHorizontal',
              },
              {
                label: 'Justificativa para alteração / cancelamento do pedido',
                validators: Validators.required,
                name: 'justificativa_para_altera_o_cancelamento_do_pedido',
                select: [
                  {
                    type: 'option',

                    name: 'Atraso na inclusão da OV no Sistema',

                    value: 'Atraso na inclusão da OV no Sistema',
                  },

                  {
                    type: 'option',

                    name: 'Baixa de saldo de CO/PO Mae',

                    value: 'Baixa de saldo de CO/PO Mae',
                  },

                  {
                    type: 'option',

                    name: 'Cadastro do cliente (Master Dt)',

                    value: 'Cadastro do cliente (Master Dt)',
                  },

                  {
                    type: 'option',

                    name: 'Cadastro Material (Master Dt)',

                    value: 'Cadastro Material (Master Dt)',
                  },

                  {
                    type: 'option',

                    name: 'Cliente com cadastro incompleto',

                    value: 'Cliente com cadastro incompleto',
                  },

                  {
                    type: 'option',

                    name: 'Cliente reduziu área plantada',

                    value: 'Cliente reduziu área plantada',
                  },

                  {
                    type: 'option',

                    name: 'Crédito Financeiro',

                    value: 'Crédito Financeiro',
                  },

                  {
                    type: 'option',

                    name: 'Desfazimento',

                    value: 'Desfazimento',
                  },

                  {
                    type: 'option',

                    name: 'Desistência do cliente',

                    value: 'Desistência do cliente',
                  },

                  {
                    type: 'option',

                    name: 'Endereço do local de entrega incorreto',

                    value: 'Endereço do local de entrega incorreto',
                  },

                  {
                    type: 'option',

                    name: 'Erro na digitação da OV',

                    value: 'Erro na digitação da OV',
                  },

                  {
                    type: 'option',

                    name: 'Erro nas informações do pedido',

                    value: 'Erro nas informações do pedido',
                  },

                  {
                    type: 'option',

                    name: 'Falha na negociação',

                    value: 'Falha na negociação',
                  },

                  {
                    type: 'option',

                    name: 'Falta de produto (Stock Out)',

                    value: 'Falta de produto (Stock Out)',
                  },

                  {
                    type: 'option',

                    name: 'Pedido duplicado',

                    value: 'Pedido duplicado',
                  },

                  {
                    type: 'option',

                    name: 'Pedido teste',

                    value: 'Pedido teste',
                  },

                  {
                    type: 'option',

                    name: 'Performance/Qualidade',

                    value: 'Performance/Qualidade',
                  },

                  {
                    type: 'option',

                    name: 'Substituição do preoduto/embalagem',

                    value: 'Substituição do preoduto/embalagem',
                  },

                  {
                    type: 'option',

                    name: 'Suspensão de vendas regulatória',

                    value: 'Suspensão de vendas regulatória',
                  },

                  {
                    type: 'option',

                    name: 'Validade da negociação expirada',

                    value: 'Validade da negociação expirada',
                  },
                ],
                type: 'select',
              },
              {
                label: 'Justificativa complementar',
                name: 'justificativa_para_altera_o_do_pedido',
                validators: Validators.maxLength(1000),
                type: 'textArea',
              },
              {
                label: 'Anexar autorização GT ou GR',
                validators: Validators.required,
                name: 'anexar_autoriza_o_gt_ou_gr',

                type: 'attachment',
              },
            ],
          },
        ],

        type: 'radioVertical',
      },
      {
        label: 'Observações',
        name: 'observa_es_1',
        validators: Validators.maxLength(1000),
        type: 'textArea',
      },
      {
        label: 'E-mail solicitante',
        validators: Validators.required,
        name: 'e_mail_solicitante',
        type: 'email',
      },
      {
        label: 'E-mail secundário',
        name: 'e_mail_secund_rio',
        type: 'email',
      },
      {
        label: 'Cidade / Estado Nutrien',
        validators: [Validators.required, Validators.maxLength(1000)],
        name: 'cidade_estado_nutrien',
        type: 'text',
      },
      {
        type: 'buttonSubmit',
        name: 'Enviar',
      },
    ];
  }

  enviarFormulario(event: any) {}
}
