import { Component, OnInit } from '@angular/core';
import { AzureService } from 'src/app/autenticacao/azure.service';
import { CommomServicesService } from 'src/app/services/commom-services.service';
@Component({
  selector: 'app-accouting',
  templateUrl: './accouting.component.html',
  styleUrls: ['./accouting.component.css']
})
export class AccoutingComponent implements OnInit {
  isUserLoggedIn: boolean = false;


  constructor(private azureService: AzureService, private commomS: CommomServicesService) { }

  ngOnInit(): void {
    this.azureService.isUserLoggedIn.subscribe(
      x=>{
        this.isUserLoggedIn=x;
      }
    )
  }

  faqLink() {
    this.commomS.openNewWindow('https://app.pipefy.com/public/form/FZZSofCP');
  }

}
