import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TrataInputsService } from 'src/app/services/trata-inputs.service';
import { tiposDeNotas } from './tipo-de-nota';
import { empresas } from './empresas';
import { quantidadeItens } from './quantidadeItens';
import { pipe_fields_ids } from './pipe_fields_ids';
import { HttpErrorResponse } from '@angular/common/http';
import { PipefyService } from 'src/app/services/pipefy.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { pipe_fields_incidentes } from '../../pipe_fields_incidentes';
import { MsalService } from '@azure/msal-angular';
import { status } from '../../../consulta-de-chamados/status';
import { UserInfosService } from 'src/app/autenticacao/usuario/user-infos.service';
import { lista_cnpjs_empresa_nutrien } from './lista_cnpjs_empresa_nutrien';
import { Validadores } from 'src/app/componentes/formMat/Validatores';
import { tipos_de_notas } from './tipos-de-notas';
import { quantidade_pedidos } from './quantidade_pedidos';
import { environment } from 'src/environments/environment';
import { estados } from './estados';
import { parcelaAluguel } from './parcelaAluguel';
import { mesAluguel } from './mesAluguel';
import { anoAluguel } from './anoAluguel';
import { CommomServicesService } from 'src/app/services/commom-services.service';


@Component({
  selector: 'app-solicitacao-de-lancamentos-de-notas-ocr',
  templateUrl: './solicitacao-de-lancamentos-de-notas-ocr.component.html',
  styleUrls: ['./solicitacao-de-lancamentos-de-notas-ocr.component.css']
})
export class SolicitacaoDeLancamentosDeNotasOcrComponent implements OnInit {



  formulario!: FormGroup;
  arquivosDeRateio: any[] = [];
  notas: any[] = [];
  boletos: any[] = [];
  xml: any[] = []
  documentacaoSuporte: any[] = [];
  nfProdutoOriginal: any[] = []

  listaDeItensDaPo: any[] = [];
  riscoSacado: boolean = false;
  dacteCorreto: boolean = false;
  notaComRateios: boolean = false;
  tiposDeNotas = tiposDeNotas;
  empresas = empresas;
  quantidadeDeItens = quantidadeItens;
  tipos_de_notas = tipos_de_notas;
  quantidade_pedidos = quantidade_pedidos;

  notaComRateioDeValoresString: string = ''
  riscoSacadoString: string = ''
  dacteCorretoString: string = ''

  arquivosDeRateioUrl: any[] = []
  notasUrl: any[] = []
  boletosUrl: any[] = []
  xmlUrl: any[] = []
  documentacaoSuporteUrl: any[] = []
  nfProdutoOriginalUrl: any[] = []
  listaDeItensDaPoUrl: any[] = []
  arquivosNpo: any[] = [];
  arquivosNpoUrl: any[] = [];

  error_counter: number = 0;
  erro: boolean = false

  emailApi: string = ''
  userNameApi: string = ''

  custoComplementar: boolean = false;
  debitoPosterior: boolean = false
  informacoesComplementaresPo: any[] = []

  listaEmpresasCnpjs = lista_cnpjs_empresa_nutrien;

  dataAtual: any = '';
  valorTotalNfAlterado: any = 0;


  chaveAcesso: string = '';
  listaFornecedores: any[] = [];
  listaFornecedoresOriginal: any[] = [];
  listaTomadores: any[] = [];
  listaTomadoresOriginal: any[] = [];
  valorFaturaAlterado: any = 0;

  fileName2: string = '';
  fileName3: string = '';

  fornecedorSelecionado: any;
  tomadorSelecionado: any;
  json_most: any;
  estados = estados;
  parcelaAluguel = parcelaAluguel;
  mesAluguel = mesAluguel;
  anoAluguel = anoAluguel;

  numero_do_pedido_1: string = ''
  numero_do_pedido_2: string = ''
  numero_do_pedido_3: string = ''
  numero_do_pedido_4: string = ''
  numero_do_pedido_5: string = ''
  numero_do_pedido_6: string = ''
  numero_do_pedido_7: string = ''
  numero_do_pedido_8: string = ''
  numero_do_pedido_9: string = ''
  numero_do_pedido_10: string = ''


  horaInicioChamadaMost: string =''
  horaFimChamadaMost: string = ''

  inicioMostMilisegundos: any
  fimMostMiligsegundos: any
  diferencaMost: any




  constructor(private formBuilder: FormBuilder,
    private trataInput: TrataInputsService,
    private pipefy: PipefyService,
    private spinner: NgxSpinnerService,
    private msalService: MsalService,
    private userInfo: UserInfosService,
    private commomS: CommomServicesService) { }


  ngOnInit(): void {

    this.formulario = this.formBuilder.group({
      nome: ['', [Validators.required, Validators.maxLength(1000)]],
      email: ['', [Validators.required, Validators.maxLength(1000)]],
      // risco_sacado: ['', []],
      qual_a_quantidade_de_cte: ['', [Validators.maxLength(1000)]],
      tipo_de_nota: ['', [Validators.required, Validators.maxLength(1000)]],
      conta_contabil: ['', [Validators.maxLength(1000)]],
      documento_do_fornecedor: ['', [Validators.maxLength(1000)]], //Validators.required
      cpf_do_fornecedor: ['', [Validadores.cpf, Validators.maxLength(1000)]],
      cnpj_fornecedor: ['', [Validadores.cnpj, Validators.maxLength(1000)]],
      nome_do_fornecedor: ['', [Validators.required, Validators.maxLength(1000)]],
      cnpj_da_empresa_nutrien: ['', [Validators.required, Validadores.cnpj,Validators.maxLength(1000)]],
      nome_empresa_nutrien: ['', [Validators.required, Validators.maxLength(1000)]],
      nome_empresa_nutrien_ocr: ['', [Validators.maxLength(1000)]],
      nro_nota: ['', [Validators.maxLength(1000)]],//Validators.required
      parcela_do_aluguel: ['', [Validators.maxLength(1000)]],
      m_s_do_aluguel: ['', [Validators.maxLength(1000)]],
      ano_do_aluguel: ['', [Validators.maxLength(1000)]],
      data_de_envio_de_nf: ['', [Validators.required, Validators.maxLength(1000)]],
      valor_total_da_nf: ['', [Validators.required, Validators.maxLength(1000)]],
      nota_com_rasteio_de_valores: [, [Validators.maxLength(1000)]],
      arquivo_de_rateio: ['', []],
      data_de_vencimento_da_nota: ['', [Validators.required]],
      departamento: ['', [Validators.maxLength(1000)]],
      numero_de_pedido: ['', [Validators.maxLength(1000)]],
      quantidade_de_itens_do_pedido: ['', [Validators.maxLength(1000)]],
      numero_do_item_da_po: ['', [Validators.maxLength(1000)]],
      lista_de_itens_da_po: ['', [Validators.maxLength(1000)]],
      centro_de_custo: ['', [Validators.maxLength(1000)]],
      anexo_nota_ou_boleto: ['', []],//Validators.required
      anexo_boleto: ['', []],
      anexo_xml: ['', []],
      anexo_documentacao_suporte: ['', []],
      nota_para_pagamento_fora_da_politica: ['', [Validators.maxLength(1000)]],
      nota_para_pagamento_urgente: ['', [Validators.maxLength(1000)]],
      motivo_da_excecao: ['', [Validators.maxLength(1000)]],
      observacao: ['', [Validators.maxLength(1000)]],
      informacoes_complementares_da_po: ['', [Validators.maxLength(1000)]],

      qtde_pedidos: ['', [Validators.maxLength(1000)]],
      numero_linha_pedido_1: ['', [Validators.maxLength(1000)]],
      numero_linha_pedido_2: ['', [Validators.maxLength(1000)]],
      numero_linha_pedido_3: ['', [Validators.maxLength(1000)]],
      numero_linha_pedido_4: ['', [Validators.maxLength(1000)]],
      numero_linha_pedido_5: ['', [Validators.maxLength(1000)]],
      numero_linha_pedido_6: ['', [Validators.maxLength(1000)]],
      numero_linha_pedido_7: ['', [Validators.maxLength(1000)]],
      numero_linha_pedido_8: ['', [Validators.maxLength(1000)]],
      numero_linha_pedido_9: ['', [Validators.maxLength(1000)]],
      numero_linha_pedido_10: ['', [Validators.maxLength(1000)]],
      lista_pedidos: ['', [Validators.maxLength(1000)]],

      nf_do_produto_original: ['', [Validators.maxLength(1000)]],
      tipo_de_custo_planejado: ['', [Validators.maxLength(1000)]],
      n_mero_de_cte: ['', [Validators.maxLength(1000)]],
      opex_e_capex: ['', [Validators.maxLength(1000)]],
      tipo_de_importa_o: ['', [Validators.maxLength(1000)]],
      tipo_de_pagamento: ['', [Validators.maxLength(1000)]],

      n_mero_afe: ['', [Validators.maxLength(1000)]],
      estado_de_origem: ['', [Validators.maxLength(1000)]],
      estado_de_destino: ['', [Validators.maxLength(1000)]],


      certeza_que_incluiu_o_arquivo_conforme_orienta_o: ['', [Validators.maxLength(1000)]],
      migo: ['', [Validators.maxLength(1000)]],
      folha_servicos: ['', [Validators.maxLength(1000)]],
      anexo_documenta_o_suporte: ['', []],
      categoria: ['', [Validators.maxLength(1000)]],
      subcategoria_po: ['', [Validators.maxLength(1000)]],
      tipo_operacao: ['', [Validators.maxLength(1000)]],
      anexo_npo_modelo_excel: ['', [Validators.maxLength(1000)]],
      grc: ['', [Validators.maxLength(1000)]],
      baixa_de_adiantamento:['',[Validators.maxLength(1000)]]

    })


    this.emailApi = this.userInfo.getUserEmail()
    this.userNameApi = this.userInfo.getName()

    this.formulario.get('email')?.setValue(this.emailApi)
    this.formulario.get('nome')?.setValue(this.userNameApi)


    if (this.formulario.getRawValue().email != '') {
      // this.formulario.controls['email'].disable()
    }
    if (this.formulario.getRawValue().email == undefined) {
      // this.formulario.controls['email'].enable()
      this.formulario.get('email')?.setValue('')

    }

    if (this.formulario.getRawValue().nome != '') {
      // this.formulario.controls['nome'].disable()
    }
    if (this.formulario.getRawValue().nome == undefined) {
      // this.formulario.controls['nome'].enable()
      this.formulario.get('nome')?.setValue('')
    }

    this.getDataAtual();
    this.formataValorDecimal('0');
    this.getUserEmail();

    this.formulario.valueChanges.subscribe(v => {
      // console.log(v);
    })
  }


  enviarFormulario() {

    this.spinner.show()

    const criarBody = async () => {



      await this.getPipesUrlArquivoRateio(this.arquivosDeRateio);
      if (this.arquivosDeRateio.length > 0) {
        while (this.arquivosDeRateio.length != this.arquivosDeRateioUrl.length) {
          // console.log("esperando1....")
          await this.delay(300);
          if (this.erro) {
            break;
          }
        }
        if (this.erro) {
          return
        }
      }

      if (this.arquivosDeRateio.length > 0) {
        var arquivosDeRateioUrl2 = ''
        arquivosDeRateioUrl2 = '['
        for (var i = 0; i < this.arquivosDeRateioUrl.length; i++) {
          arquivosDeRateioUrl2 = arquivosDeRateioUrl2 + '"' + this.arquivosDeRateioUrl[i] + '",'
        }
        arquivosDeRateioUrl2 = arquivosDeRateioUrl2.slice(0, -1)
        arquivosDeRateioUrl2 = arquivosDeRateioUrl2 + "]"

      } else {
        arquivosDeRateioUrl2 = ''
      }

      await this.getPipesUrlNota(this.notas);
      // console.log(this.notas)
      // console.log(this.notas.length)
      // console.log(this.notasUrl)

      if (this.notas.length > 0) {
        while (this.notas.length != this.notasUrl.length) {
          // console.log("esperando2....")
          await this.delay(300);
          if (this.erro) {
            break;
          }
        }
        if (this.erro) {
          return
        }
      }


      if (this.notas.length > 0) {
        var notasUrl2 = ''
        notasUrl2 = '['
        for (var i = 0; i < this.notasUrl.length; i++) {
          notasUrl2 = notasUrl2 + '"' + this.notasUrl[i] + '",'
        }
        notasUrl2 = notasUrl2.slice(0, -1)
        notasUrl2 = notasUrl2 + "]"

      } else {
        notasUrl2 = ''
      }

      await this.getPipesUrlBoleto(this.boletos);
      // console.log(this.boletos)
      // console.log(this.boletos.length)
      // console.log(this.boletosUrl)

      if (this.boletos.length > 0) {
        while (this.boletos.length != this.boletosUrl.length) {
          // console.log("esperando3....")
          await this.delay(300);
          if (this.erro) {
            break;
          }
        }
        if (this.erro) {
          return
        }
      }

      if (this.boletos.length > 0) {
        var boletosUrl2 = ''
        boletosUrl2 = '['
        for (var i = 0; i < this.boletosUrl.length; i++) {
          boletosUrl2 = boletosUrl2 + '"' + this.boletosUrl[i] + '",'
        }
        boletosUrl2 = boletosUrl2.slice(0, -1)
        boletosUrl2 = boletosUrl2 + "]"

      } else {
        boletosUrl2 = ''
      }

      await this.getPipesUrlXML(this.xml);
      // console.log(this.xml)
      // console.log(this.xml.length)
      // console.log(this.xmlUrl)

      if (this.xml.length > 0) {
        while (this.xml.length != this.xmlUrl.length) {
          // console.log("esperando4....")
          await this.delay(300);
          if (this.erro) {
            break;
          }
        }
        if (this.erro) {
          return
        }
      }

      if (this.xml.length > 0) {
        var xmlUrl2 = ''
        xmlUrl2 = '['
        for (var i = 0; i < this.xmlUrl.length; i++) {
          xmlUrl2 = xmlUrl2 + '"' + this.xmlUrl[i] + '",'
        }
        xmlUrl2 = xmlUrl2.slice(0, -1)
        xmlUrl2 = xmlUrl2 + "]"

      } else {
        xmlUrl2 = ''
      }


      await this.getPipesUrlDocumentacaoSuporte(this.documentacaoSuporte);
      if (this.documentacaoSuporte.length > 0) {
        while (this.documentacaoSuporte.length != this.documentacaoSuporteUrl.length) {
          // console.log("esperando5....")
          await this.delay(300);
          if (this.erro) {
            break;
          }
        }
        if (this.erro) {
          return
        }
      }

      if (this.documentacaoSuporte.length > 0) {
        var documentacaoSuporteUrl2 = ''
        documentacaoSuporteUrl2 = '['
        for (var i = 0; i < this.documentacaoSuporteUrl.length; i++) {
          documentacaoSuporteUrl2 = documentacaoSuporteUrl2 + '"' + this.documentacaoSuporteUrl[i] + '",'
        }
        documentacaoSuporteUrl2 = documentacaoSuporteUrl2.slice(0, -1)
        documentacaoSuporteUrl2 = documentacaoSuporteUrl2 + "]"

      } else {
        documentacaoSuporteUrl2 = ''
      }

      await this.getPipesUrlNfProdutoOriginal(this.nfProdutoOriginal);
      if (this.nfProdutoOriginal.length > 0) {
        while (this.nfProdutoOriginal.length != this.nfProdutoOriginalUrl.length) {
          // console.log("esperando6....")
          await this.delay(300);
          if (this.erro) {
            break;
          }
        }
        if (this.erro) {
          return
        }
      }

      if (this.nfProdutoOriginal.length > 0) {
        var nfProdutoOriginalUrl2 = ''
        nfProdutoOriginalUrl2 = '['
        for (var i = 0; i < this.nfProdutoOriginalUrl.length; i++) {
          nfProdutoOriginalUrl2 = nfProdutoOriginalUrl2 + '"' + this.nfProdutoOriginalUrl[i] + '",'
        }
        nfProdutoOriginalUrl2 = nfProdutoOriginalUrl2.slice(0, -1)
        nfProdutoOriginalUrl2 = nfProdutoOriginalUrl2 + "]"

      } else {
        nfProdutoOriginalUrl2 = ''
      }

      await this.getPipesUrlListaItensDaPo(this.listaDeItensDaPo);
      if (this.listaDeItensDaPo.length > 0) {
        while (this.listaDeItensDaPo.length != this.listaDeItensDaPoUrl.length) {
          // console.log("esperando7....")
          await this.delay(300);
          if (this.erro) {
            break;
          }
        }
        if (this.erro) {
          return
        }
      }

      if (this.listaDeItensDaPo.length > 0) {
        var listaDeItensDaPoUrl2 = ''
        listaDeItensDaPoUrl2 = '['
        for (var i = 0; i < this.listaDeItensDaPoUrl.length; i++) {
          listaDeItensDaPoUrl2 = listaDeItensDaPoUrl2 + '"' + this.listaDeItensDaPoUrl[i] + '",'
        }
        listaDeItensDaPoUrl2 = listaDeItensDaPoUrl2.slice(0, -1)
        listaDeItensDaPoUrl2 = listaDeItensDaPoUrl2 + "]"

      } else {
        listaDeItensDaPoUrl2 = ''
      }

      await this.getPipesUrlArquivoNpo(this.arquivosNpo);
      // console.log('function getPipesUrlArquivoNpo')
      if (this.arquivosNpo.length > 0) {
        // console.log('arquivosNpo.length >0')
        while (this.arquivosNpo.length != this.arquivosNpoUrl.length) {
          // console.log("esperando arquivo npo....")
          // console.log('inicio delay getPipesUrlArquivoNpo')
          await this.delay(300);
          // console.log('termino delay getPipesUrlArquivoNpo')
          if (this.erro) {
            // console.log('this.erro arquivosNpo', this.erro)
            break;
          }
        }
        if (this.erro) {
          // console.log('this.erro arquivosXml', this.erro)
          return
        }
      }

      if (this.arquivosNpo.length > 0) {
        var arquivosNpoUrl2 = ''
        arquivosNpoUrl2 = '['
        for (var i = 0; i < this.arquivosNpoUrl.length; i++) {
          arquivosNpoUrl2 = arquivosNpoUrl2 + '"' + this.arquivosNpoUrl[i] + '",'
        }
        arquivosNpoUrl2 = arquivosNpoUrl2.slice(0, -1)
        arquivosNpoUrl2 = arquivosNpoUrl2 + "]"

      } else {
        arquivosNpoUrl2 = ''
      }

      let nome = this.trataInput.removeCaracters(this.formulario.getRawValue().nome)
      let email = this.trataInput.removeCaracters(this.formulario.getRawValue().email) + '@nutrien.com';
      // let risco_sacado = this.trataInput.removeCaracters(this.formulario.getRawValue().risco_sacado)
      let tipo_de_nota = this.trataInput.removeCaracters(this.formulario.getRawValue().tipo_de_nota)
      let qual_a_quantidade_de_cte = this.trataInput.removeCaracters(this.formulario.getRawValue().qual_a_quantidade_de_cte)
      let conta_contabil = this.trataInput.removeCaracters(this.formulario.getRawValue().conta_contabil)
      let documento_do_fornecedor = this.trataInput.removeCaracters(this.formulario.getRawValue().documento_do_fornecedor)
      let cpf_do_fornecedor = this.trataInput.removeCaracters(this.formulario.getRawValue().cpf_do_fornecedor)
      let cnpj_fornecedor = this.trataInput.removeCaracters(this.formulario.getRawValue().cnpj_fornecedor)
      let nome_do_fornecedor = this.trataInput.removeCaracters(this.formulario.getRawValue().nome_do_fornecedor)
      let cnpj_da_empresa_nutrien = this.formulario.getRawValue().cnpj_da_empresa_nutrien.replaceAll('.', '').replaceAll("/", "").replaceAll("-", "");
      let nome_empresa_nutrien = this.trataInput.removeCaracters(this.formulario.getRawValue().nome_empresa_nutrien)
      let nro_nota = this.trataInput.removeCaracters(this.formulario.getRawValue().nro_nota)
      let data_de_envio_de_nf = this.trataInput.removeCaracters(this.formulario.getRawValue().data_de_envio_de_nf)
      let valor_total_da_nf = this.valorTotalNfAlterado;
      let data_de_vencimento_da_nota = this.trataInput.ajustaData(this.trataInput.removeCaracters(this.formulario.getRawValue().data_de_vencimento_da_nota))
      let departamento = this.trataInput.removeCaracters(this.formulario.getRawValue().departamento)
      let numero_de_pedido = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_de_pedido)
      let quantidade_de_itens_do_pedido = this.trataInput.removeCaracters(this.formulario.getRawValue().quantidade_de_itens_do_pedido)
      let numero_do_item_da_po = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_do_item_da_po)

      let centro_de_custo = this.trataInput.removeCaracters(this.formulario.getRawValue().centro_de_custo)
      let nota_para_pagamento_fora_da_politica = this.trataInput.removeCaracters(this.formulario.getRawValue().nota_para_pagamento_fora_da_politica)
      let nota_para_pagamento_urgente = this.trataInput.removeCaracters(this.formulario.getRawValue().nota_para_pagamento_urgente)
      let motivo_da_excecao = this.trataInput.removeCaracters(this.formulario.getRawValue().motivo_da_excecao)
      let observacao = this.trataInput.removeCaracters(this.formulario.getRawValue().observacao)
      let informacoes_complementares_da_po = '[' + this.informacoesComplementaresPo + ']'

      let qtde_pedidos = this.trataInput.removeCaracters(this.formulario.getRawValue().qtde_pedidos);
      let numero_linha_pedido_1 = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_linha_pedido_1);
      let numero_linha_pedido_2 = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_linha_pedido_2);
      let numero_linha_pedido_3 = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_linha_pedido_3);
      let numero_linha_pedido_4 = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_linha_pedido_4);
      let numero_linha_pedido_5 = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_linha_pedido_5);
      let numero_linha_pedido_6 = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_linha_pedido_6);
      let numero_linha_pedido_7 = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_linha_pedido_7);
      let numero_linha_pedido_8 = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_linha_pedido_8);
      let numero_linha_pedido_9 = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_linha_pedido_9);
      let numero_linha_pedido_10 = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_linha_pedido_10);
      let lista_pedidos = this.trataInput.removeCaracters(this.formulario.getRawValue().lista_pedidos);

      let tipo_de_custo_planejado = this.trataInput.removeCaracters(this.formulario.getRawValue().tipo_de_custo_planejado)
      let n_mero_de_cte = this.trataInput.removeCaracters(this.formulario.getRawValue().n_mero_de_cte)
      let opex_e_capex = this.trataInput.removeCaracters(this.formulario.getRawValue().opex_e_capex)
      let tipo_de_importa_o = this.trataInput.removeCaracters(this.formulario.getRawValue().tipo_de_importa_o)
      let tipo_de_pagamento = this.trataInput.removeCaracters(this.formulario.getRawValue().tipo_de_pagamento)

      let n_mero_afe = this.trataInput.removeCaracters(this.formulario.getRawValue().n_mero_afe)

      let categoria = this.trataInput.removeCaracters(this.formulario.getRawValue().categoria)
      let subcategoria_po = this.trataInput.removeCaracters(this.formulario.getRawValue().subcategoria_po)
      let tipo_operacao = this.trataInput.removeCaracters(this.formulario.getRawValue().tipo_operacao)
      let migo = this.trataInput.removeCaracters(this.formulario.getRawValue().migo)
      let tomador = !!this.listaTomadores[0]?.cardId ? this.listaTomadores[0]?.cardId : 666469142;
      let fornecedor = !!this.listaFornecedores[0]?.cardId ? this.listaFornecedores[0]?.cardId : 666469115;
      let estado_de_origem = this.trataInput.removeCaracters(this.formulario.getRawValue().estado_de_origem)
      let estado_de_destino = this.trataInput.removeCaracters(this.formulario.getRawValue().estado_de_destino)
      let grc = this.trataInput.removeCaracters(this.formulario.getRawValue().grc)
      let parcela_do_aluguel = this.trataInput.removeCaracters(this.formulario.getRawValue().parcela_do_aluguel)
      let m_s_do_aluguel = this.trataInput.removeCaracters(this.formulario.getRawValue().m_s_do_aluguel)
      let ano_do_aluguel = this.trataInput.removeCaracters(this.formulario.getRawValue().ano_do_aluguel)
      let baixa_de_adiantamento = this.trataInput.removeCaracters(this.formulario.getRawValue().baixa_de_adiantamento)
      // console.log('departamento', departamento)


      this.preencheNumeroDoPedido(qtde_pedidos,numero_de_pedido)

      let pipe_fields = pipe_fields_ids
      let fields_values = [
        nome,
        email,
        // this.riscoSacadoString,
        tipo_de_nota,
        qual_a_quantidade_de_cte,
        conta_contabil,
        documento_do_fornecedor,
        cpf_do_fornecedor,
        cnpj_fornecedor
        , nome_do_fornecedor,
        cnpj_da_empresa_nutrien,
        nome_empresa_nutrien,
        nro_nota,
        data_de_envio_de_nf,
        valor_total_da_nf,
        this.notaComRateioDeValoresString,
        arquivosDeRateioUrl2,
        data_de_vencimento_da_nota,
        departamento, numero_de_pedido,
        quantidade_de_itens_do_pedido,
        numero_do_item_da_po,
        listaDeItensDaPoUrl2,
        centro_de_custo,
        notasUrl2,
        this.dacteCorretoString,
        documentacaoSuporteUrl2,
        nota_para_pagamento_fora_da_politica,
        nota_para_pagamento_urgente,
        motivo_da_excecao,
        observacao,
        informacoes_complementares_da_po,

        qtde_pedidos,
        numero_linha_pedido_1,
        numero_linha_pedido_2,
        numero_linha_pedido_3,
        numero_linha_pedido_4,
        numero_linha_pedido_5,
        numero_linha_pedido_6,
        numero_linha_pedido_7,
        numero_linha_pedido_8,
        numero_linha_pedido_9,
        numero_linha_pedido_10,
        lista_pedidos,

        boletosUrl2,
        xmlUrl2,
        nfProdutoOriginalUrl2,
        tipo_de_custo_planejado,
        n_mero_de_cte,
        opex_e_capex,
        tipo_de_importa_o,
        tipo_de_pagamento,
        n_mero_afe,

        // this.arquivosDeRateioUrl,
        // this.listaDeItensDaPoUrl,
        // this.notasUrl,
        // this.documentacaoSuporteUrl,
        // this.xmlUrl,
        // this.boletosUrl,
        // this.nfProdutoOriginalUrl,
        1,
        categoria,
        subcategoria_po,
        tipo_operacao,
        migo,
        arquivosNpoUrl2,
        "Portal NBS",
        "JSON.stringify(this.json_most)",
        tomador,
        fornecedor,
        estado_de_origem,
        estado_de_destino,
        grc,
        parcela_do_aluguel,
        m_s_do_aluguel,
        ano_do_aluguel,
        this.numero_do_pedido_1,
        this.numero_do_pedido_2,
        this.numero_do_pedido_3,
        this.numero_do_pedido_4,
        this.numero_do_pedido_5,
        this.numero_do_pedido_6,
        this.numero_do_pedido_7,
        this.numero_do_pedido_8,
        this.numero_do_pedido_9,
        this.numero_do_pedido_10,
        this.diferencaMost,
        this.horaInicioChamadaMost,
        this.horaFimChamadaMost,
        baixa_de_adiantamento

      ]

      // 303357816

      this.pipefy.criaBody(pipe_fields, fields_values, '303572465')


      this.pipefy.createCard2(this.pipefy.criaBody(pipe_fields, fields_values, '303572465')).subscribe((res) => {
        // console.log(res)
        this.spinner.hide()

        if (res.data.result_creation_card.id != undefined) {
          let card_id = res.data.result_creation_card.id
          Swal.fire({
            title: 'Chamado aberto com Sucesso!',
            text: 'O chamado ' + card_id + ' foi aberto com sucesso. Nossa equipe irá tratar e retornar em breve. Obrigado!',
            // html: 'O chamado ' + card_id + ' foi aberto com sucesso.<br><br> Sua solicitação está sendo incluída fora do prazo comunicado pela empresa, seu lançamento apenas entrará na fila a partir de 02/Jan/2023.',
            icon: 'success',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#88D600',
          })
          this.resetform()
        }

        if (res.data.result_creation_card.errors != undefined) {
          var erro = res.data.result_creation_card.errors[0].message
          Swal.fire({
            title: 'Desculpe!',
            html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/FZZSofCP" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação.</span></br>',
            text: erro,
            icon: 'error',
            confirmButtonColor: '#88D600',
          })
          this.arquivosDeRateioUrl = []
          this.notasUrl = []
          this.boletosUrl = []
          this.xmlUrl = []
          this.documentacaoSuporteUrl = []
          this.listaDeItensDaPoUrl = []
          this.nfProdutoOriginalUrl = []



          let pipe_fields_incidentes_values = ['Incidente', 'Correção', 'Portal NBS', 'F&A', 'Finanças', "Finanças - criação de card -- Solicitação de Lançamento de Notas", erro, 'Portal NBS Error', 'incidente@portal.com',
            'Sim', 'Outros', 'criação de card via portal', 'Página web com problemas', this.emailApi, this.userInfo.getVersion()]

          var pipe_fields_incidentes_values2 = []
          for (var i = 0; i < pipe_fields_incidentes_values.length; i++) {
            pipe_fields_incidentes_values2.push(this.trataInput.removeCaractersPipeIncidentes(pipe_fields_incidentes_values[i]))
          }
          // console.log(pipe_fields_incidentes_values2)

          let pipe_incidentes_id = '302487533'

          this.pipefy.createCard(this.pipefy.criaBody(pipe_fields_incidentes, pipe_fields_incidentes_values2, pipe_incidentes_id)).subscribe((res) => {
            // console.log(res)
          })
        }
      }, (error: any) => {
        this.spinner.hide()
        // console.log(error.status)
        this.error_counter = this.error_counter + 1
        if (this.error_counter < 2) {
          if (error.status == 401) {
            // console.log("entrei em error.status")
            Swal.fire({
              title: 'Ops, sua sessão expirou!',
              text: 'Por favor, saia e logue novamente.',
              icon: 'error',
              confirmButtonText: 'Sair',
              confirmButtonColor: '#ff6961',
              allowOutsideClick: false,
              allowEscapeKey: false
            })
              .then(() => {
                this.msalService.logoutRedirect()
              }
              )
          } else {
            Swal.fire({
              title: 'Desculpe!',
              text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
              icon: 'error',
              confirmButtonText: 'Ok',
              confirmButtonColor: '#88D600',
              allowOutsideClick: false,
              allowEscapeKey: false
            })
          }
        }
        else {
          this.spinner.hide()
          Swal.fire({
            title: 'Desculpe!',
            html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/FZZSofCP" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
            icon: 'error',
            confirmButtonColor: '#88D600',
          })
          this.arquivosDeRateioUrl = []
          this.notasUrl = []
          this.boletosUrl = []
          this.xmlUrl = []
          this.documentacaoSuporteUrl = []
          this.listaDeItensDaPoUrl = []
          this.nfProdutoOriginalUrl = []
        }
      })

    }
    criarBody()
  }

  delay(time: any) {
    return new Promise(resolve => setTimeout(resolve, time));
  }


  saveArquivoRateio(event: any): void {
    var files = event.target.files;

    for (var i = 0; i < files.length; i++) {
      this.arquivosDeRateio.push(files[i])
    }
  }

  removeFileArquivoRateio(file: File) {
    const index: number = this.arquivosDeRateio.indexOf(file);
    this.arquivosDeRateio.splice(index, 1)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500
    })
    if (this.arquivosDeRateio.length == 0) {
      this.formulario.controls['arquivo_de_rateio'].markAsTouched()
      this.formulario.get('arquivo_de_rateio')?.setValue('')
    }
  }

  async getPipesUrlArquivoRateio(files: any) {
    this.erro = false
    for (var i = 0; i < files.length; i++) {
      var fileContent = files[i]
      let fileName = files[i].name
      const reader = new FileReader();
      reader.readAsDataURL(fileContent);
      reader.onload = async () => {
        fileContent = reader.result?.toString()
        var fileContentStart = fileContent.indexOf(",")

        fileContent = fileContent.substring(fileContentStart + 1)
        fileContent = fileContent.toString()

        var binaryString = window.atob(fileContent);
        var byteArray = new Uint8Array(binaryString.length);
        for (var i = 0; i < binaryString.length; i++) {
          byteArray[i] += binaryString.charCodeAt(i);
        }/*from w  ww .  j a v a 2  s  .c  o  m*/

        let urlResposta: any = await this.pipefy.getFileUrl3(byteArray, fileName)


        var fileUrlTemp = "";
        var startIndex = 0;

        if (urlResposta.indexOf("orgs") > 0) {
          startIndex = urlResposta.indexOf("orgs/");
        } else {
          startIndex = urlResposta.indexOf("uploads/");
        }

        var endIndex = urlResposta.indexOf("?");
        fileUrlTemp = urlResposta.substring(startIndex, endIndex);

        this.arquivosDeRateioUrl.push(fileUrlTemp)
      },
        (error: any) => {
          this.erro = true
          this.spinner.hide()
          // console.log(error.status)
          this.error_counter = this.error_counter + 1
          if (this.error_counter < 2) {
            if (error.status == 401) {
              // console.log("entrei em error.status")
              Swal.fire({
                title: 'Ops, sua sessão expirou!',
                text: 'Por favor, saia e logue novamente.',
                icon: 'error',
                confirmButtonText: 'Sair',
                confirmButtonColor: '#ff6961',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
                .then(() => {
                  this.msalService.logoutRedirect()
                }
                )
            } else {
              Swal.fire({
                title: 'Desculpe!',
                text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#88D600',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
            }
          }
          else {
            this.spinner.hide()
            Swal.fire({
              title: 'Desculpe!',
              html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/FZZSofCP" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
              icon: 'error',
              confirmButtonColor: '#88D600',
            })
            this.arquivosDeRateioUrl = []
          }
        }
    }
    return new Promise<void>((resolve) => { resolve(); });
  }

  saveAnexoNota(event: any): void {
    var files = event.target.files;

    for (var i = 0; i < files.length; i++) {
      this.notas.push(files[i])
    }
  }

  removeFileNota(file: File) {
    const index: number = this.notas.indexOf(file);
    this.notas.splice(index, 1)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500
    })
    if (this.notas.length == 0) {
      this.formulario.controls['anexo_nota_ou_boleto'].markAsTouched()
      this.formulario.get('anexo_nota_ou_boleto')?.setValue('')

    }
  }

  async getPipesUrlNota(files: any) {
    this.erro = false
    for (var i = 0; i < files.length; i++) {
      var fileContent = files[i]
      let fileName = files[i].name
      const reader = new FileReader();
      reader.readAsDataURL(fileContent);
      reader.onload = async () => {
        fileContent = reader.result?.toString()
        var fileContentStart = fileContent.indexOf(",")

        fileContent = fileContent.substring(fileContentStart + 1)
        fileContent = fileContent.toString()

        var binaryString = window.atob(fileContent);
        var byteArray = new Uint8Array(binaryString.length);
        for (var i = 0; i < binaryString.length; i++) {
          byteArray[i] += binaryString.charCodeAt(i);
        }/*from w  ww .  j a v a 2  s  .c  o  m*/

        let urlResposta: any = await this.pipefy.getFileUrl3(byteArray, fileName)
        // let url = res.data.attachments_url_pipefy[0]
        // this.arquivosNfBoletoUrl.push(url)
        // console.log(urlResposta)
        // urlResposta = urlResposta.body.data.createPresignedUrl.url

        var fileUrlTemp = "";
        var startIndex = 0;

        if (urlResposta.indexOf("orgs") > 0) {
          startIndex = urlResposta.indexOf("orgs/");
        } else {
          startIndex = urlResposta.indexOf("uploads/");
        }

        var endIndex = urlResposta.indexOf("?");
        fileUrlTemp = urlResposta.substring(startIndex, endIndex);

        this.notasUrl.push(fileUrlTemp)
      },
        (error: any) => {
          this.erro = true
          this.spinner.hide()
          // console.log(error.status)
          this.error_counter = this.error_counter + 1
          if (this.error_counter < 2) {
            if (error.status == 401) {
              // console.log("entrei em error.status")
              Swal.fire({
                title: 'Ops, sua sessão expirou!',
                text: 'Por favor, saia e logue novamente.',
                icon: 'error',
                confirmButtonText: 'Sair',
                confirmButtonColor: '#ff6961',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
                .then(() => {
                  this.msalService.logoutRedirect()
                }
                )
            } else {
              Swal.fire({
                title: 'Desculpe!',
                text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#88D600',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
            }
          }
          else {
            this.spinner.hide()
            Swal.fire({
              title: 'Desculpe!',
              html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/FZZSofCP" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
              icon: 'error',
              confirmButtonColor: '#88D600',
            })
            this.notasUrl = []
          }


        }
    }
    return new Promise<void>((resolve) => { resolve(); });
  }

  saveAnexoBoleto(event: any): void {
    var files = event.target.files;

    for (var i = 0; i < files.length; i++) {
      this.boletos.push(files[i])
    }
  }

  removeFileBoleto(file: File) {
    const index: number = this.boletos.indexOf(file);
    this.boletos.splice(index, 1)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500
    })
    if (this.boletos.length == 0) {
      this.formulario.controls['anexo_boleto'].markAsTouched()
      this.formulario.get('anexo_boleto')?.setValue('')
    }
  }

  async getPipesUrlBoleto(files: any) {
    this.erro = false
    for (var i = 0; i < files.length; i++) {
      var fileContent = files[i]
      let fileName = files[i].name
      const reader = new FileReader();
      reader.readAsDataURL(fileContent);
      reader.onload = async () => {
        fileContent = reader.result?.toString()
        var fileContentStart = fileContent.indexOf(",")

        fileContent = fileContent.substring(fileContentStart + 1)
        fileContent = fileContent.toString()

        var binaryString = window.atob(fileContent);
        var byteArray = new Uint8Array(binaryString.length);
        for (var i = 0; i < binaryString.length; i++) {
          byteArray[i] += binaryString.charCodeAt(i);
        }/*from w  ww .  j a v a 2  s  .c  o  m*/

        let urlResposta: any = await this.pipefy.getFileUrl3(byteArray, fileName)


        var fileUrlTemp = "";
        var startIndex = 0;

        if (urlResposta.indexOf("orgs") > 0) {
          startIndex = urlResposta.indexOf("orgs/");
        } else {
          startIndex = urlResposta.indexOf("uploads/");
        }

        var endIndex = urlResposta.indexOf("?");
        fileUrlTemp = urlResposta.substring(startIndex, endIndex);

        this.boletosUrl.push(fileUrlTemp)
      },
        (error: any) => {
          this.erro = true
          this.spinner.hide()
          // console.log(error.status)
          this.error_counter = this.error_counter + 1
          if (this.error_counter < 2) {
            if (error.status == 401) {
              // console.log("entrei em error.status")
              Swal.fire({
                title: 'Ops, sua sessão expirou!',
                text: 'Por favor, saia e logue novamente.',
                icon: 'error',
                confirmButtonText: 'Sair',
                confirmButtonColor: '#ff6961',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
                .then(() => {
                  this.msalService.logoutRedirect()
                }
                )
            } else {
              Swal.fire({
                title: 'Desculpe!',
                text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#88D600',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
            }
          }
          else {
            this.spinner.hide()
            Swal.fire({
              title: 'Desculpe!',
              html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/FZZSofCP" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
              icon: 'error',
              confirmButtonColor: '#88D600',
            })
            this.boletosUrl = []
          }
        }
    }
    return new Promise<void>((resolve) => { resolve(); });
  }

  saveAnexoXML(event: any): void {
    var files = event.target.files;

    for (var i = 0; i < files.length; i++) {
      this.xml.push(files[i])
    }
  }

  removeFileXML(file: File) {
    const index: number = this.xml.indexOf(file);
    this.xml.splice(index, 1)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500
    })
    if (this.xml.length == 0) {
      this.formulario.controls['anexo_xml'].markAsTouched()
      this.formulario.get('anexo_xml')?.setValue('')
    }
  }

  async getPipesUrlXML(files: any) {
    this.erro = false
    for (var i = 0; i < files.length; i++) {
      var fileContent = files[i]
      let fileName = files[i].name
      const reader = new FileReader();
      reader.readAsDataURL(fileContent);
      reader.onload = async () => {
        fileContent = reader.result?.toString()
        var fileContentStart = fileContent.indexOf(",")

        fileContent = fileContent.substring(fileContentStart + 1)
        fileContent = fileContent.toString()

        var binaryString = window.atob(fileContent);
        var byteArray = new Uint8Array(binaryString.length);
        for (var i = 0; i < binaryString.length; i++) {
          byteArray[i] += binaryString.charCodeAt(i);
        }/*from w  ww .  j a v a 2  s  .c  o  m*/

        let urlResposta: any = await this.pipefy.getFileUrl3(byteArray, fileName)


        var fileUrlTemp = "";
        var startIndex = 0;

        if (urlResposta.indexOf("orgs") > 0) {
          startIndex = urlResposta.indexOf("orgs/");
        } else {
          startIndex = urlResposta.indexOf("uploads/");
        }

        var endIndex = urlResposta.indexOf("?");
        fileUrlTemp = urlResposta.substring(startIndex, endIndex);

        this.xmlUrl.push(fileUrlTemp)
      },
        (error: any) => {
          this.erro = true
          this.spinner.hide()
          // console.log(error.status)
          this.error_counter = this.error_counter + 1
          if (this.error_counter < 2) {
            if (error.status == 401) {
              // console.log("entrei em error.status")
              Swal.fire({
                title: 'Ops, sua sessão expirou!',
                text: 'Por favor, saia e logue novamente.',
                icon: 'error',
                confirmButtonText: 'Sair',
                confirmButtonColor: '#ff6961',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
                .then(() => {
                  this.msalService.logoutRedirect()
                }
                )
            } else {
              Swal.fire({
                title: 'Desculpe!',
                text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#88D600',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
            }
          }
          else {
            this.spinner.hide()
            Swal.fire({
              title: 'Desculpe!',
              html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/FZZSofCP" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
              icon: 'error',
              confirmButtonColor: '#88D600',
            })
            this.xmlUrl = []
          }
        }
    }
    return new Promise<void>((resolve) => { resolve(); });
  }

  saveAnexoDocumentacaoSuporte(event: any): void {
    var files = event.target.files;

    for (var i = 0; i < files.length; i++) {
      this.documentacaoSuporte.push(files[i])
    }
  }

  removeFilDocumentacaoSuporte(file: File) {
    const index: number = this.documentacaoSuporte.indexOf(file);
    this.documentacaoSuporte.splice(index, 1)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500
    })
    if (this.documentacaoSuporte.length == 0) {
      this.formulario.controls['anexo_documentacao_suporte'].markAsTouched()
      this.formulario.get('anexo_documentacao_suporte')?.setValue('')
    }
  }

  async getPipesUrlDocumentacaoSuporte(files: any) {
    this.erro = false
    for (var i = 0; i < files.length; i++) {
      var fileContent = files[i]
      let fileName = files[i].name
      const reader = new FileReader();
      reader.readAsDataURL(fileContent);
      reader.onload = async () => {
        fileContent = reader.result?.toString()
        var fileContentStart = fileContent.indexOf(",")

        fileContent = fileContent.substring(fileContentStart + 1)
        fileContent = fileContent.toString()

        var binaryString = window.atob(fileContent);
        var byteArray = new Uint8Array(binaryString.length);
        for (var i = 0; i < binaryString.length; i++) {
          byteArray[i] += binaryString.charCodeAt(i);
        }/*from w  ww .  j a v a 2  s  .c  o  m*/

        let urlResposta: any = await this.pipefy.getFileUrl3(byteArray, fileName)


        var fileUrlTemp = "";
        var startIndex = 0;

        if (urlResposta.indexOf("orgs") > 0) {
          startIndex = urlResposta.indexOf("orgs/");
        } else {
          startIndex = urlResposta.indexOf("uploads/");
        }

        var endIndex = urlResposta.indexOf("?");
        fileUrlTemp = urlResposta.substring(startIndex, endIndex);

        this.documentacaoSuporteUrl.push(fileUrlTemp)
      },
        (error: any) => {
          this.erro = true
          this.spinner.hide()
          // console.log(error.status)
          this.error_counter = this.error_counter + 1
          if (this.error_counter < 2) {
            if (error.status == 401) {
              // console.log("entrei em error.status")
              Swal.fire({
                title: 'Ops, sua sessão expirou!',
                text: 'Por favor, saia e logue novamente.',
                icon: 'error',
                confirmButtonText: 'Sair',
                confirmButtonColor: '#ff6961',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
                .then(() => {
                  this.msalService.logoutRedirect()
                }
                )
            } else {
              Swal.fire({
                title: 'Desculpe!',
                text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#88D600',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
            }
          }
          else {
            this.spinner.hide()
            Swal.fire({
              title: 'Desculpe!',
              html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/FZZSofCP" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
              icon: 'error',
              confirmButtonColor: '#88D600',
            })
            this.documentacaoSuporteUrl = []
          }
        }
    }
    return new Promise<void>((resolve) => { resolve(); });
  }


  saveAnexoNfProdutoOriginal(event: any): void {
    var files = event.target.files;

    for (var i = 0; i < files.length; i++) {
      this.nfProdutoOriginal.push(files[i])
    }
  }

  removeFileNfProdutoOriginal(file: File) {
    const index: number = this.nfProdutoOriginal.indexOf(file);
    this.nfProdutoOriginal.splice(index, 1)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500
    })
    if (this.nfProdutoOriginal.length == 0) {
      this.formulario.controls['nfProdutoOriginal'].markAsTouched()
      this.formulario.get('nfProdutoOriginal')?.setValue('')
    }
  }

  async getPipesUrlNfProdutoOriginal(files: any) {
    this.erro = false
    for (var i = 0; i < files.length; i++) {
      var fileContent = files[i]
      let fileName = files[i].name
      const reader = new FileReader();
      reader.readAsDataURL(fileContent);
      reader.onload = async () => {
        fileContent = reader.result?.toString()
        var fileContentStart = fileContent.indexOf(",")

        fileContent = fileContent.substring(fileContentStart + 1)
        fileContent = fileContent.toString()

        var binaryString = window.atob(fileContent);
        var byteArray = new Uint8Array(binaryString.length);
        for (var i = 0; i < binaryString.length; i++) {
          byteArray[i] += binaryString.charCodeAt(i);
        }/*from w  ww .  j a v a 2  s  .c  o  m*/

        let urlResposta: any = await this.pipefy.getFileUrl3(byteArray, fileName)


        var fileUrlTemp = "";
        var startIndex = 0;

        if (urlResposta.indexOf("orgs") > 0) {
          startIndex = urlResposta.indexOf("orgs/");
        } else {
          startIndex = urlResposta.indexOf("uploads/");
        }

        var endIndex = urlResposta.indexOf("?");
        fileUrlTemp = urlResposta.substring(startIndex, endIndex);

        this.nfProdutoOriginalUrl.push(fileUrlTemp)
      },
        (error: any) => {
          this.erro = true
          this.spinner.hide()
          // console.log(error.status)
          this.error_counter = this.error_counter + 1
          if (this.error_counter < 2) {
            if (error.status == 401) {
              // console.log("entrei em error.status")
              Swal.fire({
                title: 'Ops, sua sessão expirou!',
                text: 'Por favor, saia e logue novamente.',
                icon: 'error',
                confirmButtonText: 'Sair',
                confirmButtonColor: '#ff6961',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
                .then(() => {
                  this.msalService.logoutRedirect()
                }
                )
            } else {
              Swal.fire({
                title: 'Desculpe!',
                text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#88D600',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
            }
          }
          else {
            this.spinner.hide()
            Swal.fire({
              title: 'Desculpe!',
              html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/FZZSofCP" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
              icon: 'error',
              confirmButtonColor: '#88D600',
            })
            this.nfProdutoOriginalUrl = []
          }
        }
    }
    return new Promise<void>((resolve) => { resolve(); });
  }

  saveListaDeItensDaPO(event: any): void {
    var files = event.target.files;

    for (var i = 0; i < files.length; i++) {
      this.listaDeItensDaPo.push(files[i])
    }
  }

  removeListaDeItensDaPO(file: File) {
    const index: number = this.listaDeItensDaPo.indexOf(file);
    this.listaDeItensDaPo.splice(index, 1)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500
    })
    if (this.listaDeItensDaPo.length == 0) {
      this.formulario.controls['lista_de_itens_da_po'].markAsTouched()
      this.formulario.get('lista_de_itens_da_po')?.setValue('')
    }
  }

  async getPipesUrlListaItensDaPo(files: any) {
    this.erro = false
    for (var i = 0; i < files.length; i++) {
      var fileContent = files[i]
      let fileName = files[i].name
      const reader = new FileReader();
      reader.readAsDataURL(fileContent);
      reader.onload = async () => {
        fileContent = reader.result?.toString()
        var fileContentStart = fileContent.indexOf(",")

        fileContent = fileContent.substring(fileContentStart + 1)
        fileContent = fileContent.toString()

        var binaryString = window.atob(fileContent);
        var byteArray = new Uint8Array(binaryString.length);
        for (var i = 0; i < binaryString.length; i++) {
          byteArray[i] += binaryString.charCodeAt(i);
        }/*from w  ww .  j a v a 2  s  .c  o  m*/

        let urlResposta: any = await this.pipefy.getFileUrl3(byteArray, fileName)


        var fileUrlTemp = "";
        var startIndex = 0;

        if (urlResposta.indexOf("orgs") > 0) {
          startIndex = urlResposta.indexOf("orgs/");
        } else {
          startIndex = urlResposta.indexOf("uploads/");
        }

        var endIndex = urlResposta.indexOf("?");
        fileUrlTemp = urlResposta.substring(startIndex, endIndex);

        this.listaDeItensDaPoUrl.push(fileUrlTemp)
      },
        (error: any) => {
          this.erro = true
          this.spinner.hide()
          // console.log(error.status)
          this.error_counter = this.error_counter + 1
          if (this.error_counter < 2) {
            if (error.status == 401) {
              // console.log("entrei em error.status")
              Swal.fire({
                title: 'Ops, sua sessão expirou!',
                text: 'Por favor, saia e logue novamente.',
                icon: 'error',
                confirmButtonText: 'Sair',
                confirmButtonColor: '#ff6961',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
                .then(() => {
                  this.msalService.logoutRedirect()
                }
                )
            } else {
              Swal.fire({
                title: 'Desculpe!',
                text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#88D600',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
            }
          }
          else {
            this.spinner.hide()
            Swal.fire({
              title: 'Desculpe!',
              html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/FZZSofCP" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
              icon: 'error',
              confirmButtonColor: '#88D600',
            })
            this.listaDeItensDaPoUrl = []
          }
        }
    }
    return new Promise<void>((resolve) => { resolve(); });
  }

  saveArquivoNpo(event: any): void {
    var files = event.target.files;

    for (var i = 0; i < files.length; i++) {
      this.arquivosNpo.push(files[i])
    }
  }

  removeFileArquivoNpo(file: File) {
    const index: number = this.arquivosNpo.indexOf(file);
    this.arquivosNpo.splice(index, 1)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500
    })
    if (this.arquivosNpo.length == 0) {
      this.formulario.controls['anexo_npo_modelo_excel'].markAsTouched()
      this.formulario.get('anexo_npo_modelo_excel')?.setValue('')
    }
  }

  async getPipesUrlArquivoNpo(files: any) {
    this.erro = false
    for (var i = 0; i < files.length; i++) {
      var fileContent = files[i]
      let fileName = files[i].name
      const reader = new FileReader();
      reader.readAsDataURL(fileContent);
      reader.onload = () => {
        fileContent = reader.result?.toString()
        var fileContentStart = fileContent.indexOf(",")

        fileContent = fileContent.substring(fileContentStart + 1)
        fileContent = fileContent.toString()

        var binaryString = window.atob(fileContent);
        var byteArray = new Uint8Array(binaryString.length);
        for (var i = 0; i < binaryString.length; i++) {
          byteArray[i] += binaryString.charCodeAt(i);
        } (async () => {
          let urlResposta: any = await this.pipefy.getFileUrl3(byteArray, fileName)

          var fileUrlTemp = "";
          var startIndex = 0;

          if (urlResposta.indexOf("orgs") > 0) {
            startIndex = urlResposta.indexOf("orgs/");
          } else {
            startIndex = urlResposta.indexOf("uploads/");
          }

          var endIndex = urlResposta.indexOf("?");
          fileUrlTemp = urlResposta.substring(startIndex, endIndex);

          this.arquivosNpoUrl.push(fileUrlTemp)
        })();
      },
        (error: any) => {
          this.erro = true
          this.spinner.hide()
          console.log(error.status)
          this.error_counter = this.error_counter + 1
          if (this.error_counter < 2) {
            if (error.status == 401) {
              // console.log("entrei em error.status")
              Swal.fire({
                title: 'Ops, sua sessão expirou!',
                text: 'Por favor, saia e logue novamente.',
                icon: 'error',
                confirmButtonText: 'Sair',
                confirmButtonColor: '#ff6961',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
                .then(() => {
                  this.msalService.logoutRedirect()
                }
                )
            } else {
              Swal.fire({
                title: 'Desculpe!',
                text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#88D600',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
            }
          }
          else {
            this.spinner.hide()
            Swal.fire({
              title: 'Desculpe!',
              html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/_rj6N2Po" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
              icon: 'error',
              confirmButtonColor: '#88D600',
            })
            this.arquivosNpoUrl = []
          }
        }
    }
    return new Promise<void>((resolve) => { resolve(); });
  }

  inserirNoArray(event: any) {
    // console.log(this.formulario.getRawValue().informacoes_complementares_da_po)
    let valor: any = event.target.value
    // console.log(valor)
    if (this.formulario.getRawValue().informacoes_complementares_da_po) {
      let valorTratado = '"' + valor + '"'
      this.informacoesComplementaresPo.push(valorTratado)
      // console.log(this.informacoesComplementaresPo)
    }
    if (!this.formulario.getRawValue().informacoes_complementares_da_po) {
      const index: number = this.informacoesComplementaresPo.indexOf(valor);
      this.informacoesComplementaresPo.splice(index, 1)
      // console.log(this.informacoesComplementaresPo)
    }
  }

  notaComRateiosValue(event: any) {
    var valor = event.target
    if (valor.checked) {
      this.notaComRateioDeValoresString = 'Sim'
      // console.log(this.notaComRateioDeValoresString)
    } else {
      this.notaComRateioDeValoresString = ''
      // console.log('vazio',this.notaComRateioDeValoresString)
    }
  }

  riscoSacadoValue(event: any) {
    var valor = event.target
    if (valor.checked) {
      this.riscoSacadoString = 'Sim'
      // console.log(this.riscoSacadoString)
    } else {
      this.riscoSacadoString = ''
      // console.log('vazio',this.riscoSacadoString)
    }
  }

  dacteCorretoValue(event: any) {
    var valor = event.target
    if (valor.checked) {
      this.dacteCorretoString = 'Sim'
      // console.log(this.dacteCorretoString)
    } else {
      this.dacteCorretoString = ''
      // console.log('vazio',this.riscoSacadoString)
    }

  }


  onDocumentoChange(event: any) {
    var valor = event.target.value
    // console.log(valor)
    if (valor == "CPF") {
      // console.log("cpf checked")
      this.formulario.get('cpf_do_fornecedor')?.setValidators([Validators.required, Validadores.cpf])
      this.formulario.get('cpf_do_fornecedor')?.updateValueAndValidity();

      this.formulario.get('cnpj_fornecedor')?.clearValidators()
      this.formulario.get('cnpj_fornecedor')?.updateValueAndValidity();

    }

    if (valor == "CNPJ") {
      // console.log("cnpj checked")
      this.formulario.get('cnpj_fornecedor')?.setValidators([Validators.required, Validadores.cnpj])
      this.formulario.get('cnpj_fornecedor')?.updateValueAndValidity();

      this.formulario.get('cpf_do_fornecedor')?.clearValidators()
      this.formulario.get('cpf_do_fornecedor')?.updateValueAndValidity();
    }
  }


  onEmpresaChange(event: any) {
    let empresa = event.target.value
    // console.log(empresa)

    // MOREIRA

    if (empresa === 'Agrichem') {
      this.formulario.get('departamento')?.setValidators([Validators.required])
      this.formulario.get('departamento')?.updateValueAndValidity();
    }

    if (empresa === '' || empresa === 'TecAgro (FAG)' || empresa === 'TecAgro (SGL)' || empresa === 'TecAgro (FCR)' || empresa === 'Nutrien') {
      this.formulario.get('departamento')?.clearValidators()
      this.formulario.get('departamento')?.updateValueAndValidity();
    }
  }

  onCnpjEmpresaNutrienChange(event: any) {
    let cnpjSelecionado = event.target.value;
    let empresaSelecionada = this.listaEmpresasCnpjs.filter(nome => nome.cnpj === cnpjSelecionado);
    this.formulario.get('nome_empresa_nutrien')?.setValue(empresaSelecionada[0].nome);
  }

  onPagamentoUrgenteChange(event: any) {
    let pagamentoUrgente = event.target.value
    // console.log("Pagamento Urgente : ", pagamentoUrgente)

    if (pagamentoUrgente === 'Sim') {
      this.formulario.get('motivo_da_excecao')?.setValidators([Validators.required])
      this.formulario.get('motivo_da_excecao')?.updateValueAndValidity();
    }

    if (pagamentoUrgente === 'Não') {
      this.formulario.get('motivo_da_excecao')?.clearValidators()
      this.formulario.get('motivo_da_excecao')?.updateValueAndValidity();
    }
  }

  onTipoDeNotaChange(event: any) {
    // console.log(event)
    let tipo_de_nota = event.target.value
    // console.log(tipo_de_nota)

    // if(tipo_de_nota === 'Reembolso' || tipo_de_nota === 'Aluguel' || tipo_de_nota === 'Guias'){
    if (tipo_de_nota === 'Jurídico' || tipo_de_nota === 'Aluguel' || tipo_de_nota === 'Guias/Imposto/Taxas') {

      // console.log("entrei aqui REEMBOLSO")
      this.formulario.get('conta_contabil')?.setValidators([Validators.required])
      this.formulario.get('conta_contabil')?.updateValueAndValidity()
      this.formulario.get('qual_a_quantidade_de_cte')?.clearValidators()
      this.formulario.get('qual_a_quantidade_de_cte')?.updateValueAndValidity()
    }
    // if(tipo_de_nota === 'Frete' || tipo_de_nota === 'CTE') {
    if (tipo_de_nota === 'Frete/CTE') {

      // console.log("entrei aqui FRETE")
      this.formulario.get('qual_a_quantidade_de_cte')?.setValidators([Validators.required])
      this.formulario.get('qual_a_quantidade_de_cte')?.updateValueAndValidity()
      this.formulario.get('conta_contabil')?.clearValidators()
      this.formulario.get('conta_contabil')?.updateValueAndValidity()
    }

    // if(tipo_de_nota == '' ||
    //    tipo_de_nota == 'Pagamento serviço PO' ||
    //    tipo_de_nota == 'Pagamento material PO com NFE e XML' ||
    //    tipo_de_nota == 'Pagamento material PO com NFE' ||
    //    tipo_de_nota == 'Pagamento material sem PO' ||
    //    tipo_de_nota == 'Boleto' ||
    //    tipo_de_nota == 'Materia Prima' ||
    //    tipo_de_nota == 'Sem valor fiscal' ||
    //    tipo_de_nota == 'Uso e consumo' ||
    //    tipo_de_nota == 'Importação' ||
    //    tipo_de_nota == 'Ativo fixo (AFE)' ||
    //    tipo_de_nota == 'Retorno Armazem' ||
    //    tipo_de_nota == 'Outras NFs sem PO' ||
    //    tipo_de_nota == 'DANFE' ||
    //    tipo_de_nota == 'NFPS' ||
    //    tipo_de_nota == 'DANFE (DIFAL)' ||
    //    tipo_de_nota == 'Fatura' ||
    //    tipo_de_nota == 'Imposto' ||
    //    tipo_de_nota == 'Cartório' ||
    //    tipo_de_nota == 'Conta de consumo' ){

    if (tipo_de_nota == '' ||
      tipo_de_nota == 'Ativo fixo (AFE)' ||
      tipo_de_nota == 'Benefícios' ||
      tipo_de_nota == 'Cartório' ||
      tipo_de_nota == 'Contas de consumo (Luz, água, telefone, internet, gás)' ||
      tipo_de_nota == 'Correio' ||
      tipo_de_nota == 'Escrituração - Não gera pagamento' ||
      tipo_de_nota == 'Importação' ||
      tipo_de_nota == 'Incentivo - Doação' ||
      tipo_de_nota == 'Industrialização' ||
      tipo_de_nota == 'Jurídico' ||
      tipo_de_nota == 'Material (DANFE)' ||
      tipo_de_nota == 'Nota de Crédito' ||
      tipo_de_nota == 'Outras NFs sem PO' ||
      tipo_de_nota == 'Sem valor fiscal' ||
      tipo_de_nota == 'Serviço'
    ) {

      // console.log("oi")
      this.formulario.get('conta_contabil')?.clearValidators()
      this.formulario.get('conta_contabil')?.updateValueAndValidity()
      this.formulario.get('qual_a_quantidade_de_cte')?.clearValidators()
      this.formulario.get('qual_a_quantidade_de_cte')?.updateValueAndValidity()
    }

    if (tipo_de_nota == 'Aluguel') {
      Swal.fire({
        // title: 'Você será redirecionado!',
        text: 'Para contas que não estiverem em nome da Nutrien, favor, anexar o contrato de referência no campo anexo documentação suporte.',
        icon: 'info',
        confirmButtonText: 'Ok',
        confirmButtonColor: '#88D600',
      }).then(() => {
        this.formulario.get('anexo_documentacao_suporte')?.setValidators([Validators.required])
        this.formulario.get('anexo_documentacao_suporte')?.updateValueAndValidity();
      })
    }

    if (tipo_de_nota != 'Aluguel') {
      this.formulario.get('anexo_documentacao_suporte')?.clearValidators();
      this.formulario.get('anexo_documentacao_suporte')?.updateValueAndValidity();
    }

    if (tipo_de_nota == '' ||
      tipo_de_nota == 'Aluguel' ||
      tipo_de_nota == 'Ativo fixo (AFE)' ||
      tipo_de_nota == 'Benefícios' ||
      tipo_de_nota == 'Contas de consumo (Luz, água, telefone, internet, gás)' ||
      tipo_de_nota == 'Correio' ||
      tipo_de_nota == 'Escrituração - Não gera pagamento' ||
      tipo_de_nota === 'Frete/CTE' ||
      tipo_de_nota == 'Importação' ||
      tipo_de_nota == 'Incentivo - Doação' ||
      tipo_de_nota == 'Industrialização' ||
      tipo_de_nota == 'Material (DANFE)' ||
      tipo_de_nota == 'Sem valor fiscal' ||
      tipo_de_nota == 'Serviço'
    ) {
      // console.log("entrei em numero do pedido obrigatorio")
      this.formulario.get('numero_de_pedido')?.setValidators([Validators.required])
      this.formulario.get('numero_de_pedido')?.updateValueAndValidity();
    }

    if (tipo_de_nota == '' ||
      tipo_de_nota == 'Cartório' ||
      tipo_de_nota == 'Devolução de Cliente' ||
      tipo_de_nota == 'Guias/Imposto/Taxas' ||
      tipo_de_nota == 'Jurídico' ||
      tipo_de_nota === 'Nota de Crédito' ||
      tipo_de_nota == 'Outras NFs sem PO') {

      // console.log("entrei em numero do pedido nao obrigatorio")
      this.formulario.get('numero_de_pedido')?.setValue("");
      this.formulario.get('numero_de_pedido')?.clearValidators()
      this.formulario.get('numero_de_pedido')?.updateValueAndValidity();
    }
  }

  onDataDeVencimentoChange(event: any) {
    var data = event.target.value
    Swal.fire({
      // title: 'Atenção',
      width: 600,
      html: '<h6 style="font-size:20px;color:#88D600;font-weight:900">Pagamentos Gerais  (Fornecedores)</h6><br>' +
        '<h6 style="font-size:20px;color:#88D600;font-weight:900">Criação de solicitação de pagamento:</h6>' +
        '<p style="font-size:15px;text-align:justify"><span>&#8226;&nbsp;&nbsp;</span>Mínimo de 10 dias de antecedência às datas de pagamento.</p>' +
        '<p style="font-size:15px;text-align:justify"><span>&#8226;&nbsp;&nbsp;</span>É requerido o envio imediato dos documentos após seu recebimento.</p><br>' +
        '<h6 style="font-size:20px;color:#88D600;font-weight:900">Condição de Pagamento</h6>' +
        '<p style="font-size:15px;text-align:justify"><span>&#8226;&nbsp;&nbsp;</span>Estabelecido no pedido de compra a contar da data de emissão do documento.</p><br>' +
        '<h6 style="font-size:20px;color:#88D600;font-weight:900">Efetivação do Pagamento</h6>' +
        '<p style="font-size:15px;text-align:justify"><span>&#8226;&nbsp;&nbsp;</span>Atendidas as condições acima e o processo de abertura no NBS/pedido não apresente inconsistências, ocorrerá na próxima data de pagamento (15 ou 25) (feriado ou fim de semana no próximo dia útil).</p>',
      icon: 'info',
      confirmButtonText: 'Ok',
      confirmButtonColor: '#88D600',
      padding: '10px 30px 30px',
      iconColor: '#88D600'
    })
  }


  // ************************************* TODO *******************************************************************


  onTipoChange() {
    let quantidadeDeItens = this.formulario.getRawValue().qtde_pedidos
    this.clearValidatorsDetalhes()
    this.limpaValoresProdutos(quantidadeDeItens);

    switch (quantidadeDeItens) {
      // @ts-ignore
      case '1':
        this.formulario.controls['numero_linha_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_1']?.updateValueAndValidity();
        break;

      case '2':
        this.formulario.controls['numero_linha_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_1']?.updateValueAndValidity();

        this.formulario.controls['numero_linha_pedido_2']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_2']?.updateValueAndValidity();
        break;

      case '3':
        this.formulario.controls['numero_linha_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_1']?.updateValueAndValidity();

        this.formulario.controls['numero_linha_pedido_2']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_2']?.updateValueAndValidity();

        this.formulario.controls['numero_linha_pedido_3']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_3']?.updateValueAndValidity();
        break;

      case '4':
        this.formulario.controls['numero_linha_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_1']?.updateValueAndValidity();

        this.formulario.controls['numero_linha_pedido_2']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_2']?.updateValueAndValidity();

        this.formulario.controls['numero_linha_pedido_3']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_3']?.updateValueAndValidity();

        this.formulario.controls['numero_linha_pedido_4']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_4']?.updateValueAndValidity();
        break;

      case '5':
        this.formulario.controls['numero_linha_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_1']?.updateValueAndValidity();

        this.formulario.controls['numero_linha_pedido_2']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_2']?.updateValueAndValidity();

        this.formulario.controls['numero_linha_pedido_3']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_3']?.updateValueAndValidity();

        this.formulario.controls['numero_linha_pedido_4']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_4']?.updateValueAndValidity();

        this.formulario.controls['numero_linha_pedido_5']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_5']?.updateValueAndValidity();
        break;

      case '6':
        this.formulario.controls['numero_linha_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_1']?.updateValueAndValidity();

        this.formulario.controls['numero_linha_pedido_2']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_2']?.updateValueAndValidity();

        this.formulario.controls['numero_linha_pedido_3']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_3']?.updateValueAndValidity();

        this.formulario.controls['numero_linha_pedido_4']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_4']?.updateValueAndValidity();

        this.formulario.controls['numero_linha_pedido_5']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_5']?.updateValueAndValidity();

        this.formulario.controls['numero_linha_pedido_6']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_6']?.updateValueAndValidity();
        break;

      case '7':
        this.formulario.controls['numero_linha_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_1']?.updateValueAndValidity();

        this.formulario.controls['numero_linha_pedido_2']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_2']?.updateValueAndValidity();

        this.formulario.controls['numero_linha_pedido_3']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_3']?.updateValueAndValidity();

        this.formulario.controls['numero_linha_pedido_4']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_4']?.updateValueAndValidity();

        this.formulario.controls['numero_linha_pedido_5']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_5']?.updateValueAndValidity();

        this.formulario.controls['numero_linha_pedido_6']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_6']?.updateValueAndValidity();

        this.formulario.controls['numero_linha_pedido_7']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_7']?.updateValueAndValidity();
        break;

      case '8':
        this.formulario.controls['numero_linha_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_1']?.updateValueAndValidity();

        this.formulario.controls['numero_linha_pedido_2']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_2']?.updateValueAndValidity();

        this.formulario.controls['numero_linha_pedido_3']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_3']?.updateValueAndValidity();

        this.formulario.controls['numero_linha_pedido_4']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_4']?.updateValueAndValidity();

        this.formulario.controls['numero_linha_pedido_5']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_5']?.updateValueAndValidity();

        this.formulario.controls['numero_linha_pedido_6']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_6']?.updateValueAndValidity();

        this.formulario.controls['numero_linha_pedido_7']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_7']?.updateValueAndValidity();

        this.formulario.controls['numero_linha_pedido_8']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_8']?.updateValueAndValidity();
        break;

      case '9':
        this.formulario.controls['numero_linha_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_1']?.updateValueAndValidity();

        this.formulario.controls['numero_linha_pedido_2']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_2']?.updateValueAndValidity();

        this.formulario.controls['numero_linha_pedido_3']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_3']?.updateValueAndValidity();

        this.formulario.controls['numero_linha_pedido_4']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_4']?.updateValueAndValidity();

        this.formulario.controls['numero_linha_pedido_5']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_5']?.updateValueAndValidity();

        this.formulario.controls['numero_linha_pedido_6']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_6']?.updateValueAndValidity();

        this.formulario.controls['numero_linha_pedido_7']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_7']?.updateValueAndValidity();

        this.formulario.controls['numero_linha_pedido_8']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_8']?.updateValueAndValidity();

        this.formulario.controls['numero_linha_pedido_9']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_9']?.updateValueAndValidity();
        break;

      case '10':
        this.formulario.controls['numero_linha_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_1']?.updateValueAndValidity();

        this.formulario.controls['numero_linha_pedido_2']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_2']?.updateValueAndValidity();

        this.formulario.controls['numero_linha_pedido_3']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_3']?.updateValueAndValidity();

        this.formulario.controls['numero_linha_pedido_4']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_4']?.updateValueAndValidity();

        this.formulario.controls['numero_linha_pedido_5']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_5']?.updateValueAndValidity();

        this.formulario.controls['numero_linha_pedido_6']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_6']?.updateValueAndValidity();

        this.formulario.controls['numero_linha_pedido_7']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_7']?.updateValueAndValidity();

        this.formulario.controls['numero_linha_pedido_8']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_8']?.updateValueAndValidity();

        this.formulario.controls['numero_linha_pedido_9']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_9']?.updateValueAndValidity();

        this.formulario.controls['numero_linha_pedido_10']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_10']?.updateValueAndValidity();
        break;

      case 'Mais que 10':
        this.formulario.controls['lista_pedidos']?.setValidators([Validators.required])
        this.formulario.controls['lista_pedidos']?.updateValueAndValidity();
        break;

      default:
        this.formulario.controls['numero_linha_pedido_1']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_1']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_2']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_2']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_3']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_3']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_4']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_4']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_5']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_5']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_6']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_6']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_7']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_7']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_8']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_8']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_9']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_9']?.updateValueAndValidity();
        this.formulario.controls['numero_linha_pedido_10']?.setValidators([Validators.required]);
        this.formulario.controls['numero_linha_pedido_10']?.updateValueAndValidity();
        this.formulario.controls['lista_pedidos']?.setValidators([Validators.required])
        this.formulario.controls['lista_pedidos']?.updateValueAndValidity();
        break;
    }
  }

  changeCategoria(event: any) {
    // console.log("Entrei change categoria")
    this.zerarTodosAnexos();
    switch (event) {
      case 'PO':
        this.categSelecionadaPO();
        this.changeTipoOperacao('');
        this.changeTipoLancamento('');
        break;
      case 'NPO':
        this.categSelecionadaNPO();
        // this.changeTipoOperacao('');
        // this.changeTipoLancamento('');
        break
      default:
        this.categSelecionadaVazio();
        this.changeTipoOperacao('');
        this.changeTipoLancamento('');
        break;
    }
  }

  changeSubcategoria(event: any) {
    //TO DO
    // console.log("subcategoria: ", event)
    this.zerarTodosCamposSubcategoria();
    this.zerarTodosAnexos();
    switch (event) {
      case 'Materiais':

        // console.log("entrei em materiais")
        // this.formulario.controls['tipo_operacao']?.setValidators([Validators.required]);
        // this.formulario.controls['tipo_operacao']?.updateValueAndValidity();

        this.formulario.controls['qtde_pedidos']?.setValidators([Validators.required]);
        this.formulario.controls['qtde_pedidos']?.updateValueAndValidity();

        this.formulario.controls['anexo_nota_ou_boleto']?.setValidators([Validators.required]);
        this.formulario.controls['anexo_nota_ou_boleto']?.updateValueAndValidity();

        if (this.formulario.getRawValue().tipo_de_nota == "Material (DANFE)") { // TO DO
          this.formulario.controls['tipo_operacao']?.setValue("Compras/Aquisição")
        }

        if (this.formulario.getRawValue().tipo_de_nota == "Material Compras e aquisição") {
          this.formulario.controls['tipo_operacao']?.setValue("Compras/Aquisição")
        }

        break;
      case 'Serviços':

        // console.log("entrei em serviço")

        // this.formulario.controls['boleto_para_pagamento']?.setValidators([Validators.required]);
        // this.formulario.controls['boleto_para_pagamento']?.updateValueAndValidity();

        // this.formulario.controls['folha_servicos']?.setValidators([Validators.required]);
        // this.formulario.controls['folha_servicos']?.updateValueAndValidity();

        this.formulario.controls['qtde_pedidos']?.setValidators([Validators.required]);
        this.formulario.controls['qtde_pedidos']?.updateValueAndValidity();

        this.formulario.controls['anexo_nota_ou_boleto']?.setValidators([Validators.required]);
        this.formulario.controls['anexo_nota_ou_boleto']?.updateValueAndValidity();
        break

      case 'Frete':
        // this.formulario.controls['anexo_nota_fiscal_ou_boleto']?.setValidators([Validators.required]);
        // this.formulario.controls['anexo_nota_fiscal_ou_boleto']?.updateValueAndValidity();
        break

      case 'Benefícios':
        // console.log("entrei em beneficios")
        this.formulario.controls['tipo_de_nota']?.setValue('Benefícios');
        break;
      case 'Boleto':
      case 'Nota de Débito':
      case 'Telefonia':
      case 'Água':
      case 'Energia':
      case 'Fatura':
      case 'Aluguel':
      case 'Correios':
        // console.log("entrei em outros")
        this.formulario.controls['anexo_nota_ou_boleto']?.setValidators([Validators.required]);
        this.formulario.controls['anexo_nota_ou_boleto']?.updateValueAndValidity();
        this.formulario.controls['qtde_pedidos']?.setValidators([Validators.required]);
        this.formulario.controls['qtde_pedidos']?.updateValueAndValidity();

        break
      default:
        break;
    }
  }

  changeTipoOperacao(event: any) {
    //TO DO
    let elem = event.target ? event.target.value : event;
    switch (elem) {
      case 'Matéria Prima':
        this.formulario.controls['migo']?.setValidators([Validators.required]);
        this.formulario.controls['migo']?.updateValueAndValidity();
        break;
      default:
        this.formulario.controls['migo']?.setValue('');
        this.formulario.controls['migo']?.clearValidators();
        this.formulario.controls['migo']?.markAsUntouched();
        this.formulario.controls['migo']?.updateValueAndValidity();
        break;
    }
  }

  changeTipoLancamento(event: any) {
    let elem = event.target ? event.target.value : event;
    switch (elem) {
      // case 'Depósito Judicial':
      //   this.formulario.controls['n_mero_do_processo']?.setValidators([Validators.required]);
      //   this.formulario.controls['n_mero_do_processo']?.updateValueAndValidity();
      //   break;
      default:
        // this.formulario.controls['n_mero_do_processo']?.setValue('');
        // this.formulario.controls['n_mero_do_processo']?.clearValidators();
        // this.formulario.controls['n_mero_do_processo']?.markAsUntouched();
        // this.formulario.controls['n_mero_do_processo']?.updateValueAndValidity();

        break;
    }
  }

  categSelecionadaVazio() {
    //TO DO

    this.formulario.controls['subcategoria_po']?.setValue('');
    this.formulario.controls['subcategoria_po']?.clearValidators();
    this.formulario.controls['subcategoria_po']?.markAsUntouched();
    this.formulario.controls['subcategoria_po']?.updateValueAndValidity();

    this.formulario.controls['tipo_de_lan_amento']?.setValue('');
    this.formulario.controls['tipo_de_lan_amento']?.clearValidators();
    this.formulario.controls['tipo_de_lan_amento']?.markAsUntouched();
    this.formulario.controls['tipo_de_lan_amento']?.updateValueAndValidity();

    this.formulario.controls['numero_da_nota_fiscal']?.setValue('');
    this.formulario.controls['numero_da_nota_fiscal']?.clearValidators();
    this.formulario.controls['numero_da_nota_fiscal']?.markAsUntouched();
    this.formulario.controls['numero_da_nota_fiscal']?.updateValueAndValidity();

    this.zerarTodosAnexos();

    this.zerarTodosCamposQtdePedidos();

    this.formulario.controls['boleto_para_pagamento']?.setValue('');
    this.formulario.controls['boleto_para_pagamento']?.clearValidators();
    this.formulario.controls['boleto_para_pagamento']?.markAsUntouched();
    this.formulario.controls['boleto_para_pagamento']?.updateValueAndValidity();

    this.formulario.controls['forma_de_pagamento']?.setValue('');
    this.formulario.controls['forma_de_pagamento']?.updateValueAndValidity();

    // this.formulario.controls['favorecido']?.setValue('');
    // this.formulario.controls['favorecido']?.clearValidators();
    // this.formulario.controls['favorecido']?.markAsUntouched();
    // this.formulario.controls['favorecido']?.updateValueAndValidity();

    // this.formulario.controls['valor_do_fi']?.setValue('');
    // this.formulario.controls['valor_do_fi']?.clearValidators();
    // this.formulario.controls['valor_do_fi']?.markAsUntouched();
    // this.formulario.controls['valor_do_fi']?.updateValueAndValidity();

    this.formulario.controls['setor']?.setValue('');
    this.formulario.controls['setor']?.updateValueAndValidity();
  }

  categSelecionadaNPO() {
    //TO DO
    this.formulario.controls['tipo_de_lan_amento']?.setValidators([Validators.required]);
    this.formulario.controls['tipo_de_lan_amento']?.updateValueAndValidity();

    this.formulario.controls['anexo_npo_modelo_excel']?.setValidators([Validators.required]);
    this.formulario.controls['anexo_npo_modelo_excel']?.updateValueAndValidity();

    this.formulario.controls['boleto_para_pagamento']?.setValidators([Validators.required]);
    this.formulario.controls['boleto_para_pagamento']?.updateValueAndValidity();

    // this.formulario.controls['favorecido']?.setValidators([Validators.required]);
    // this.formulario.controls['favorecido']?.updateValueAndValidity();

    // this.formulario.controls['valor_do_fi']?.setValidators([Validators.required]);
    // this.formulario.controls['valor_do_fi']?.updateValueAndValidity();

    this.formulario.controls['subcategoria_po']?.setValue('');
    this.formulario.controls['subcategoria_po']?.clearValidators();
    this.formulario.controls['subcategoria_po']?.markAsUntouched();
    this.formulario.controls['subcategoria_po']?.updateValueAndValidity();

    this.formulario.controls['numero_da_nota_fiscal']?.setValue('');
    this.formulario.controls['numero_da_nota_fiscal']?.clearValidators();
    this.formulario.controls['numero_da_nota_fiscal']?.markAsUntouched();
    this.formulario.controls['numero_da_nota_fiscal']?.updateValueAndValidity();

    this.zerarTodosCamposQtdePedidos();
  }

  categSelecionadaPO() {
    //TO DO
    this.formulario.controls['subcategoria_po']?.setValidators([Validators.required]);
    this.formulario.controls['subcategoria_po']?.updateValueAndValidity();

    this.formulario.controls['qtde_pedidos']?.setValidators([Validators.required]);
    this.formulario.controls['qtde_pedidos']?.updateValueAndValidity();

    this.formulario.controls['numero_da_nota_fiscal']?.setValidators([Validators.required]);
    this.formulario.controls['numero_da_nota_fiscal']?.updateValueAndValidity();

    this.formulario.controls['tipo_de_lan_amento']?.setValue('');
    this.formulario.controls['tipo_de_lan_amento']?.clearValidators();
    this.formulario.controls['tipo_de_lan_amento']?.markAsUntouched();
    this.formulario.controls['tipo_de_lan_amento']?.updateValueAndValidity();

    this.formulario.controls['boleto_para_pagamento']?.setValue('');
    this.formulario.controls['boleto_para_pagamento']?.clearValidators();
    this.formulario.controls['boleto_para_pagamento']?.markAsUntouched();
    this.formulario.controls['boleto_para_pagamento']?.updateValueAndValidity();

    this.formulario.controls['forma_de_pagamento']?.setValue('');
    this.formulario.controls['forma_de_pagamento']?.updateValueAndValidity();

    // this.formulario.controls['favorecido']?.setValue('');
    // this.formulario.controls['favorecido']?.clearValidators();
    // this.formulario.controls['favorecido']?.markAsUntouched();
    // this.formulario.controls['favorecido']?.updateValueAndValidity();

    // this.formulario.controls['valor_do_fi']?.setValue('');
    // this.formulario.controls['valor_do_fi']?.clearValidators();
    // this.formulario.controls['valor_do_fi']?.markAsUntouched();
    // this.formulario.controls['valor_do_fi']?.updateValueAndValidity();

    this.formulario.controls['setor']?.setValue('');
    this.formulario.controls['setor']?.updateValueAndValidity();
  }

  changeTipoDeNota(event: any) {
    let valor = event.target.value
    // console.log(valor)

    switch (valor) {

      case "Aluguel":
        this.formulario.controls['categoria']?.setValue('PO');
        this.formulario.controls['subcategoria_po']?.setValue('Aluguel');
        this.changeCategoria('PO')
        this.changeSubcategoria('Aluguel')
        this.ajustaMoeda("Aluguel")
        this.camposOcr('Aluguel')
        break

      case "Ativo fixo (AFE)":
        this.formulario.controls['categoria']?.setValue('PO');
        this.formulario.controls['subcategoria_po']?.setValue('Materiais');
        this.formulario.controls['tipo_operacao']?.setValue('Compras/Aquisição');
        this.changeCategoria('PO')
        this.changeSubcategoria('Ativo fixo (AFE)')
        this.ajustaMoeda("Ativo fixo (AFE)")
        this.camposOcr("Ativo fixo (AFE)")
        break

      case "Benefícios":
        this.formulario.controls['categoria']?.setValue('PO');
        this.formulario.controls['subcategoria_po']?.setValue('Serviços');
        this.changeCategoria('PO')
        this.changeSubcategoria('Serviços')
        this.ajustaMoeda("Benefícios")
        this.camposOcr("Benefícios")
        break

      // Inserido como Água categoria e subcategoria qq um dos consumos seguindo orientação do Silvio

      case "Contas de consumo (Luz, água, telefone, internet, gás)":
        this.formulario.controls['categoria']?.setValue('PO');
        this.formulario.controls['subcategoria_po']?.setValue('Água');
        this.changeCategoria('PO')
        this.changeSubcategoria('Água')
        this.ajustaMoeda('Água')
        this.camposOcr("Contas de consumo (Luz, água, telefone, internet, gás)")
        break

      case "Correio":
        this.formulario.controls['categoria']?.setValue('PO');
        this.formulario.controls['subcategoria_po']?.setValue('Correios');
        this.changeCategoria('PO')
        this.changeSubcategoria('Correios')
        this.ajustaMoeda("Correio")
        this.camposOcr("Correio")
        break

      case "Escrituração - Não gera pagamento":
        this.formulario.controls['categoria']?.setValue('PO');
        this.formulario.controls['subcategoria_po']?.setValue('Serviços');
        this.changeCategoria('PO')
        this.changeSubcategoria('Serviços')
        this.ajustaMoeda("Escrituração - Não gera pagamento")
        this.camposOcr("Escrituração - Não gera pagamento")
        break

      case "Importação":
        this.formulario.controls['categoria']?.setValue('PO');
        this.formulario.controls['subcategoria_po']?.setValue('Serviços');
        this.changeCategoria('PO')
        this.changeSubcategoria('Serviços')
        this.ajustaMoeda("Importação")
        this.camposOcr("Importação")
        break

      case "Material (DANFE)":
        // console.log("Entrei em material Danfe")
        this.formulario.controls['categoria']?.setValue('PO');
        this.formulario.controls['subcategoria_po']?.setValue('Materiais');
        this.formulario.controls['tipo_operacao']?.setValue('Compras/Aquisição');

        this.changeCategoria('PO')
        this.changeSubcategoria('Materiais')
        this.ajustaMoeda("Material (DANFE)")
        this.camposOcr("Material (DANFE)")
        break

      case "Material Compras e aquisição":
        this.formulario.controls['categoria']?.setValue('PO');
        this.formulario.controls['subcategoria_po']?.setValue('Materiais');
        this.formulario.controls['tipo_operacao']?.setValue('Compras/Aquisição')
        this.changeCategoria('PO')
        this.changeSubcategoria('Materiais')
        this.ajustaMoeda("Material Compras e aquisição")
        this.camposOcr("Material Compras e aquisição")
        break

      case "Sem valor fiscal":
        this.formulario.controls['categoria']?.setValue('PO');
        this.formulario.controls['subcategoria_po']?.setValue('Serviços');
        this.changeCategoria('PO')
        this.changeSubcategoria('Serviços')
        this.ajustaMoeda("Sem valor fiscal")
        this.camposOcr("Sem valor fiscal")
        break

      case "Serviço":
        this.formulario.controls['categoria']?.setValue('PO');
        this.formulario.controls['subcategoria_po']?.setValue('Serviços');
        this.changeCategoria('PO')
        this.changeSubcategoria('Serviços')
        this.ajustaMoeda("Serviço")
        this.camposOcr("Serviço")
        // this.fornecedorSelecionado = ''
        break

        case "Frete/CTE":
          this.formulario.controls['tipo_de_nota'].setValue('Frete/CTE');
          this.formulario.controls['categoria']?.setValue('PO');
          this.formulario.controls['subcategoria_po']?.setValue('Materiais');
          this.formulario.controls['tipo_operacao']?.setValue('Compras/Aquisição');
          this.changeCategoria('PO')
          this.changeSubcategoria('Materiais')
          this.ajustaMoeda("Material (DANFE)")
          this.camposOcr("Material (DANFE)")
        break


      case "Cartório":
        this.formulario.controls['categoria']?.setValue('NPO');
        this.formulario.controls['tipo_de_lan_amento']?.setValue('Cartório');
        this.changeCategoria('NPO')
        this.ajustaMoeda("Cartório")
        this.camposOcr("Cartório")
        break

      case "Devolução de Cliente":
        this.formulario.controls['categoria']?.setValue('NPO');
        this.formulario.controls['tipo_de_lan_amento']?.setValue('Devolução a clientes');
        this.changeCategoria('NPO')
        this.ajustaMoeda("Devolução de Cliente")
        this.camposOcr("Devolução de Cliente")
        break

      case "Guias/Imposto/Taxas":
        this.formulario.controls['categoria']?.setValue('NPO');
        this.formulario.controls['tipo_de_lan_amento']?.setValue('Taxas, Guias e Imposto');
        this.changeCategoria('NPO')
        this.ajustaMoeda("Guias/Imposto/Taxas")
        this.camposOcr("Guias/Imposto/Taxas")
        break

      case "Incentivo - Doação":
        this.formulario.controls['categoria']?.setValue('NPO');
        this.formulario.controls['tipo_de_lan_amento']?.setValue('Doações');
        this.changeCategoria('NPO')
        this.ajustaMoeda("Incentivo - Doação")
        this.camposOcr("Incentivo - Doação")
        break

      case "Jurídico":
        this.formulario.controls['categoria']?.setValue('NPO');
        this.formulario.controls['tipo_de_lan_amento']?.setValue('Jurídico');
        this.changeCategoria('NPO')
        this.ajustaMoeda("Jurídico")
        this.camposOcr("Jurídico")
        break

      case "Nota de Crédito":
        this.formulario.controls['categoria']?.setValue('NPO');
        this.formulario.controls['tipo_de_lan_amento']?.setValue('Nota de Crédito');
        this.changeCategoria('NPO')
        this.ajustaMoeda("Nota de Crédito")
        this.camposOcr("Nota de Crédito")
        break

      case "Notas de Débito":
        this.formulario.controls['categoria']?.setValue('NPO');
        this.formulario.controls['tipo_de_lan_amento']?.setValue('Notas de Débito');
        this.changeCategoria('NPO')
        this.ajustaMoeda("Notas de Débito")
        this.camposOcr("Notas de Débito")
        break

      case "Remessa":
        this.formulario.controls['categoria']?.setValue('NPO');
        this.formulario.controls['tipo_de_lan_amento']?.setValue('Remessa');
        this.changeCategoria('NPO')
        this.ajustaMoeda("Remessa")
        this.camposOcr("Remessa")
        break

      default:
        this.formulario.controls['categoria']?.setValue('');
    }
  }

  ajustaMoeda(tipoDeNota: string) {

    if (tipoDeNota == "Importação") {
      this.formulario.controls['moeda']?.setValue('USD');
    }
    else {
      this.formulario.controls['moeda']?.setValue('BRL');
    }
  }

  camposOcr(tipoDeNota: string) {

    //TO DO

    if (tipoDeNota == "Material Compras e aquisição"
      || tipoDeNota == "Serviço"
      || tipoDeNota == "Benefícios" //TO DO
      || tipoDeNota == "Escrituração - Não gera pagamento"
      || tipoDeNota == "Importação"
      || tipoDeNota == "Sem valor fiscal"
    ) {
      this.formulario.controls['data_de_emiss_o']?.clearValidators();
      this.formulario.controls['data_de_emiss_o']?.updateValueAndValidity();

      this.formulario.controls['data_de_vencimento']?.clearValidators();
      this.formulario.controls['data_de_vencimento']?.updateValueAndValidity();

      this.formulario.controls['numero_da_nota_fiscal']?.clearValidators();
      this.formulario.controls['numero_da_nota_fiscal']?.updateValueAndValidity();

      this.formulario.controls['valor_da_fatura']?.clearValidators();
      this.formulario.controls['valor_da_fatura']?.updateValueAndValidity();

    } else {

      if (this.formulario.getRawValue().categoria == "PO") {
        // console.log("entrei na validação PO")
        this.formulario.controls['data_de_emiss_o']?.setValidators([Validators.required]);
        this.formulario.controls['data_de_emiss_o']?.updateValueAndValidity();

        this.formulario.controls['data_de_vencimento']?.setValidators([Validators.required]);
        this.formulario.controls['data_de_vencimento']?.updateValueAndValidity();

        this.formulario.controls['numero_da_nota_fiscal']?.setValidators([Validators.required]);
        this.formulario.controls['numero_da_nota_fiscal']?.updateValueAndValidity();

        this.formulario.controls['valor_da_fatura']?.setValidators([Validators.required]);
        this.formulario.controls['valor_da_fatura']?.updateValueAndValidity();

      }

      if (this.formulario.getRawValue().categoria == "NPO") {
        // console.log("entrei na validação NPO")
        this.formulario.controls['data_de_emiss_o']?.setValidators([Validators.required]);
        this.formulario.controls['data_de_emiss_o']?.updateValueAndValidity();

        this.formulario.controls['data_de_vencimento']?.setValidators([Validators.required]);
        this.formulario.controls['data_de_vencimento']?.updateValueAndValidity();

        // this.formulario.controls['numero_da_nota_fiscal']?.setValidators([Validators.required]);
        // this.formulario.controls['numero_da_nota_fiscal']?.updateValueAndValidity();

        this.formulario.controls['valor_da_fatura']?.setValidators([Validators.required]);
        this.formulario.controls['valor_da_fatura']?.updateValueAndValidity();
      }
    }
  }

  getDataAtual() {
    let newDate = new Date(),
      month = '' + (newDate.getMonth() + 1),
      day = '' + newDate.getDate(),
      year = newDate.getFullYear();
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    this.dataAtual = [year, month, day].join('-');
  }

  formataValorDecimal(input: any) {
    input = input.replace(/[^0-9]/g, ''); // remove all the characters except the numeric values
    if (input.length == 0) input = "0.00"; // set the default value
    else if (input.length == 1) input = "0.0" + input;
    else if (input.length == 2) input = "0." + input;
    else input = input.substring(0, input.length - 2) + '.' + input.substring(input.length - 2, input.length);
    input = new Number(input); // set the precision
    input = input.toFixed(2);    // only works with the "."
    input = input.replace(/\./g, ','); // change the splitter to ","
    let x = input.split(','); // format the amount
    let valor = x[0];
    let decimal = x.length > 1 ? ',' + x[1] : '';
    let rgx = /(\d+)(\d{3})/;
    while (rgx.test(valor)) {
      valor = valor.replace(rgx, '$1' + '.' + '$2');
    }
    this.valorTotalNfAlterado = valor + decimal
  }

  getUserEmail() {
    let email = this.userInfo.getUserEmail();
    if (email && email.indexOf('@nutrien.com') !== -1) {
      let name = email.substring(0, email.lastIndexOf("@"));
      this.formulario.controls['email']?.setValue(name);
    }
  }

  validaCampoEmail() {
    let input = this.formulario.getRawValue().email
    var regex = new RegExp("^[a-zA-Z0-9-Z+\\.\\_\\-\b]+$");
    if (!regex.test(input))
      input = (input.substring(0, (input.length - 1)))
    this.formulario.controls['email']?.setValue(input);
  }

  limpaValoresProdutos(quantidadeDeItens: any) {
    switch (quantidadeDeItens) {
      // @ts-ignore
      case '1':
        this.formulario.controls['numero_linha_pedido_2']?.setValue('');
        this.formulario.controls['numero_linha_pedido_2'].markAsUntouched();

        this.formulario.controls['numero_linha_pedido_3']?.setValue('');
        this.formulario.controls['numero_linha_pedido_3'].markAsUntouched();

        this.formulario.controls['numero_linha_pedido_4']?.setValue('');
        this.formulario.controls['numero_linha_pedido_4'].markAsUntouched();

        this.formulario.controls['numero_linha_pedido_5']?.setValue('');
        this.formulario.controls['numero_linha_pedido_5'].markAsUntouched();

        this.formulario.controls['numero_linha_pedido_6']?.setValue('');
        this.formulario.controls['numero_linha_pedido_6'].markAsUntouched();

        this.formulario.controls['numero_linha_pedido_7']?.setValue('');
        this.formulario.controls['numero_linha_pedido_7'].markAsUntouched();

        this.formulario.controls['numero_linha_pedido_8']?.setValue('');
        this.formulario.controls['numero_linha_pedido_8'].markAsUntouched();

        this.formulario.controls['numero_linha_pedido_9']?.setValue('');
        this.formulario.controls['numero_linha_pedido_9'].markAsUntouched();

        this.formulario.controls['numero_linha_pedido_10']?.setValue('');
        this.formulario.controls['numero_linha_pedido_10'].markAsUntouched();

        this.formulario.controls['lista_pedidos']?.setValue('');
        this.formulario.controls['lista_pedidos'].markAsUntouched();
        break;

      case '2':
        this.formulario.controls['numero_linha_pedido_3']?.setValue('');
        this.formulario.controls['numero_linha_pedido_3'].markAsUntouched();

        this.formulario.controls['numero_linha_pedido_4']?.setValue('');
        this.formulario.controls['numero_linha_pedido_4'].markAsUntouched();

        this.formulario.controls['numero_linha_pedido_5']?.setValue('');
        this.formulario.controls['numero_linha_pedido_5'].markAsUntouched();

        this.formulario.controls['numero_linha_pedido_6']?.setValue('');
        this.formulario.controls['numero_linha_pedido_6'].markAsUntouched();

        this.formulario.controls['numero_linha_pedido_7']?.setValue('');
        this.formulario.controls['numero_linha_pedido_7'].markAsUntouched();

        this.formulario.controls['numero_linha_pedido_8']?.setValue('');
        this.formulario.controls['numero_linha_pedido_8'].markAsUntouched();

        this.formulario.controls['numero_linha_pedido_9']?.setValue('');
        this.formulario.controls['numero_linha_pedido_9'].markAsUntouched();

        this.formulario.controls['numero_linha_pedido_10']?.setValue('');
        this.formulario.controls['numero_linha_pedido_10'].markAsUntouched();

        this.formulario.controls['lista_pedidos']?.setValue('');
        this.formulario.controls['lista_pedidos'].markAsUntouched();
        break;

      case '3':
        this.formulario.controls['numero_linha_pedido_4']?.setValue('');
        this.formulario.controls['numero_linha_pedido_4'].markAsUntouched();

        this.formulario.controls['numero_linha_pedido_5']?.setValue('');
        this.formulario.controls['numero_linha_pedido_5'].markAsUntouched();

        this.formulario.controls['numero_linha_pedido_6']?.setValue('');
        this.formulario.controls['numero_linha_pedido_6'].markAsUntouched();

        this.formulario.controls['numero_linha_pedido_7']?.setValue('');
        this.formulario.controls['numero_linha_pedido_7'].markAsUntouched();

        this.formulario.controls['numero_linha_pedido_8']?.setValue('');
        this.formulario.controls['numero_linha_pedido_8'].markAsUntouched();

        this.formulario.controls['numero_linha_pedido_9']?.setValue('');
        this.formulario.controls['numero_linha_pedido_9'].markAsUntouched();

        this.formulario.controls['numero_linha_pedido_10']?.setValue('');
        this.formulario.controls['numero_linha_pedido_10'].markAsUntouched();

        this.formulario.controls['lista_pedidos']?.setValue('');
        this.formulario.controls['lista_pedidos'].markAsUntouched();
        break;

      case '4':
        this.formulario.controls['numero_linha_pedido_5']?.setValue('');
        this.formulario.controls['numero_linha_pedido_5'].markAsUntouched();

        this.formulario.controls['numero_linha_pedido_6']?.setValue('');
        this.formulario.controls['numero_linha_pedido_6'].markAsUntouched();

        this.formulario.controls['numero_linha_pedido_7']?.setValue('');
        this.formulario.controls['numero_linha_pedido_7'].markAsUntouched();

        this.formulario.controls['numero_linha_pedido_8']?.setValue('');
        this.formulario.controls['numero_linha_pedido_8'].markAsUntouched();

        this.formulario.controls['numero_linha_pedido_9']?.setValue('');
        this.formulario.controls['numero_linha_pedido_9'].markAsUntouched();

        this.formulario.controls['numero_linha_pedido_10']?.setValue('');
        this.formulario.controls['numero_linha_pedido_10'].markAsUntouched();

        this.formulario.controls['lista_pedidos']?.setValue('');
        this.formulario.controls['lista_pedidos'].markAsUntouched();
        break;

      case '5':
        this.formulario.controls['numero_linha_pedido_6']?.setValue('');
        this.formulario.controls['numero_linha_pedido_6'].markAsUntouched();

        this.formulario.controls['numero_linha_pedido_7']?.setValue('');
        this.formulario.controls['numero_linha_pedido_7'].markAsUntouched();

        this.formulario.controls['numero_linha_pedido_8']?.setValue('');
        this.formulario.controls['numero_linha_pedido_8'].markAsUntouched();

        this.formulario.controls['numero_linha_pedido_9']?.setValue('');
        this.formulario.controls['numero_linha_pedido_9'].markAsUntouched();

        this.formulario.controls['numero_linha_pedido_10']?.setValue('');
        this.formulario.controls['numero_linha_pedido_10'].markAsUntouched();

        this.formulario.controls['lista_pedidos']?.setValue('');
        this.formulario.controls['lista_pedidos'].markAsUntouched();
        break;

      case '6':
        this.formulario.controls['numero_linha_pedido_7']?.setValue('');
        this.formulario.controls['numero_linha_pedido_7'].markAsUntouched();

        this.formulario.controls['numero_linha_pedido_8']?.setValue('');
        this.formulario.controls['numero_linha_pedido_8'].markAsUntouched();

        this.formulario.controls['numero_linha_pedido_9']?.setValue('');
        this.formulario.controls['numero_linha_pedido_9'].markAsUntouched();

        this.formulario.controls['numero_linha_pedido_10']?.setValue('');
        this.formulario.controls['numero_linha_pedido_10'].markAsUntouched();

        this.formulario.controls['lista_pedidos']?.setValue('');
        this.formulario.controls['lista_pedidos'].markAsUntouched();
        break;

      case '7':
        this.formulario.controls['numero_linha_pedido_8']?.setValue('');
        this.formulario.controls['numero_linha_pedido_8'].markAsUntouched();

        this.formulario.controls['numero_linha_pedido_9']?.setValue('');
        this.formulario.controls['numero_linha_pedido_9'].markAsUntouched();

        this.formulario.controls['numero_linha_pedido_10']?.setValue('');
        this.formulario.controls['numero_linha_pedido_10'].markAsUntouched();

        this.formulario.controls['lista_pedidos']?.setValue('');
        this.formulario.controls['lista_pedidos'].markAsUntouched();
        break;

      case '8':
        this.formulario.controls['numero_linha_pedido_9']?.setValue('');
        this.formulario.controls['numero_linha_pedido_9'].markAsUntouched();

        this.formulario.controls['numero_linha_pedido_10']?.setValue('');
        this.formulario.controls['numero_linha_pedido_10'].markAsUntouched();

        this.formulario.controls['lista_pedidos']?.setValue('');
        this.formulario.controls['lista_pedidos'].markAsUntouched();
        break;

      case '9':
        this.formulario.controls['numero_linha_pedido_10']?.setValue('');
        this.formulario.controls['numero_linha_pedido_10'].markAsUntouched();

        this.formulario.controls['lista_pedidos']?.setValue('');
        this.formulario.controls['lista_pedidos'].markAsUntouched();
        break;

      case '10':
        this.formulario.controls['lista_pedidos']?.setValue('');
        this.formulario.controls['lista_pedidos'].markAsUntouched();
        break;

      case 'Mais que 10':
        this.formulario.controls['numero_linha_pedido_1']?.setValue('');
        this.formulario.controls['numero_linha_pedido_1'].markAsUntouched();

        this.formulario.controls['numero_linha_pedido_2']?.setValue('');
        this.formulario.controls['numero_linha_pedido_2'].markAsUntouched();

        this.formulario.controls['numero_linha_pedido_3']?.setValue('');
        this.formulario.controls['numero_linha_pedido_3'].markAsUntouched();

        this.formulario.controls['numero_linha_pedido_4']?.setValue('');
        this.formulario.controls['numero_linha_pedido_4'].markAsUntouched();

        this.formulario.controls['numero_linha_pedido_5']?.setValue('');
        this.formulario.controls['numero_linha_pedido_5'].markAsUntouched();

        this.formulario.controls['numero_linha_pedido_6']?.setValue('');
        this.formulario.controls['numero_linha_pedido_6'].markAsUntouched();

        this.formulario.controls['numero_linha_pedido_7']?.setValue('');
        this.formulario.controls['numero_linha_pedido_7'].markAsUntouched();

        this.formulario.controls['numero_linha_pedido_8']?.setValue('');
        this.formulario.controls['numero_linha_pedido_8'].markAsUntouched();

        this.formulario.controls['numero_linha_pedido_9']?.setValue('');
        this.formulario.controls['numero_linha_pedido_9'].markAsUntouched();

        this.formulario.controls['numero_linha_pedido_10']?.setValue('');
        this.formulario.controls['numero_linha_pedido_10'].markAsUntouched();
        break;

      default:
        this.formulario.controls['numero_linha_pedido_1']?.setValue('');
        this.formulario.controls['numero_linha_pedido_1'].markAsUntouched();

        this.formulario.controls['numero_linha_pedido_2']?.setValue('');
        this.formulario.controls['numero_linha_pedido_2'].markAsUntouched();

        this.formulario.controls['numero_linha_pedido_3']?.setValue('');
        this.formulario.controls['numero_linha_pedido_3'].markAsUntouched();

        this.formulario.controls['numero_linha_pedido_4']?.setValue('');
        this.formulario.controls['numero_linha_pedido_4'].markAsUntouched();

        this.formulario.controls['numero_linha_pedido_5']?.setValue('');
        this.formulario.controls['numero_linha_pedido_5'].markAsUntouched();

        this.formulario.controls['numero_linha_pedido_6']?.setValue('');
        this.formulario.controls['numero_linha_pedido_6'].markAsUntouched();

        this.formulario.controls['numero_linha_pedido_7']?.setValue('');
        this.formulario.controls['numero_linha_pedido_7'].markAsUntouched();

        this.formulario.controls['numero_linha_pedido_8']?.setValue('');
        this.formulario.controls['numero_linha_pedido_8'].markAsUntouched();

        this.formulario.controls['numero_linha_pedido_9']?.setValue('');
        this.formulario.controls['numero_linha_pedido_9'].markAsUntouched();

        this.formulario.controls['numero_linha_pedido_10']?.setValue('');
        this.formulario.controls['numero_linha_pedido_10'].markAsUntouched();

        this.formulario.controls['lista_pedidos']?.setValue('');
        this.formulario.controls['lista_pedidos'].markAsUntouched();
        break;
    }
  }

  zerarTodosAnexos() {
    this.formulario.controls['arquivo_de_rateio'].clearValidators();
    this.formulario.controls['arquivo_de_rateio']?.setValue('');
    this.formulario.controls['arquivo_de_rateio'].setErrors(null);
    this.formulario.controls['arquivo_de_rateio'].markAsUntouched();
    this.formulario.controls['arquivo_de_rateio'].updateValueAndValidity;

    this.formulario.controls['anexo_nota_ou_boleto'].clearValidators();
    this.formulario.controls['anexo_nota_ou_boleto']?.setValue('');
    this.formulario.controls['anexo_nota_ou_boleto'].setErrors(null);
    this.formulario.controls['anexo_nota_ou_boleto'].markAsUntouched();
    this.formulario.controls['anexo_nota_ou_boleto'].updateValueAndValidity;

    this.formulario.controls['anexo_boleto'].clearValidators();
    this.formulario.controls['anexo_boleto']?.setValue('');
    this.formulario.controls['anexo_boleto'].setErrors(null);
    this.formulario.controls['anexo_boleto'].markAsUntouched();
    this.formulario.controls['anexo_boleto'].updateValueAndValidity;

    this.formulario.controls['anexo_xml'].clearValidators();
    this.formulario.controls['anexo_xml']?.setValue('');
    this.formulario.controls['anexo_xml'].setErrors(null);
    this.formulario.controls['anexo_xml'].markAsUntouched();
    this.formulario.controls['anexo_xml'].updateValueAndValidity;

    this.formulario.controls['anexo_documenta_o_suporte'].clearValidators();
    this.formulario.controls['anexo_documenta_o_suporte']?.setValue('');
    this.formulario.controls['anexo_documenta_o_suporte'].setErrors(null);
    this.formulario.controls['anexo_documenta_o_suporte'].markAsUntouched();
    this.formulario.controls['anexo_documenta_o_suporte'].updateValueAndValidity;

    this.formulario.controls['nf_do_produto_original'].clearValidators();
    this.formulario.controls['nf_do_produto_original']?.setValue('');
    this.formulario.controls['nf_do_produto_original'].setErrors(null);
    this.formulario.controls['nf_do_produto_original'].markAsUntouched();
    this.formulario.controls['nf_do_produto_original'].updateValueAndValidity;

    this.formulario.controls['lista_de_itens_da_po'].clearValidators();
    this.formulario.controls['lista_de_itens_da_po']?.setValue('');
    this.formulario.controls['lista_de_itens_da_po'].setErrors(null);
    this.formulario.controls['lista_de_itens_da_po'].markAsUntouched();
    this.formulario.controls['lista_de_itens_da_po'].updateValueAndValidity;


    this.arquivosDeRateio = [];
    this.arquivosDeRateioUrl = [];

    this.notas = [];
    this.notasUrl = [];

    this.boletos = [];
    this.boletosUrl = [];

    this.xml = [];
    this.xmlUrl = [];

    this.documentacaoSuporte = [];
    this.documentacaoSuporteUrl = [];

    this.nfProdutoOriginal = [];
    this.nfProdutoOriginalUrl = [];

    this.listaDeItensDaPo = [];
    this.listaDeItensDaPoUrl = [];
  }

  zerarTodosCamposSubcategoria() {

    //TO DO

    this.zerarTodosCamposQtdePedidos();

    // this.formulario.controls['tipo_operacao'].setValue('');
    // this.formulario.controls['tipo_operacao'].clearValidators();
    // this.formulario.controls['tipo_operacao'].setErrors(null);
    // this.formulario.controls['tipo_operacao'].markAsUntouched();
    // this.formulario.controls['tipo_operacao'].updateValueAndValidity();

    // this.formulario.controls['marque_todas_as_op_es_que_se_aplicam'].setValue('');

    // this.formulario.controls['boleto_para_pagamento'].setValue('');
    // this.formulario.controls['boleto_para_pagamento'].clearValidators();
    // this.formulario.controls['boleto_para_pagamento'].setErrors(null);
    // this.formulario.controls['boleto_para_pagamento'].markAsUntouched();
    // this.formulario.controls['boleto_para_pagamento'].updateValueAndValidity();

    // this.formulario.controls['folha_servicos'].setValue('');
    // this.formulario.controls['folha_servicos'].clearValidators();
    // this.formulario.controls['folha_servicos'].setErrors(null);
    // this.formulario.controls['folha_servicos'].markAsUntouched();
    // this.formulario.controls['folha_servicos'].updateValueAndValidity();
  }

  zerarTodosCamposQtdePedidos() {
    this.formulario.controls['qtde_pedidos'].setValue('');
    this.formulario.controls['qtde_pedidos'].clearValidators();
    this.formulario.controls['qtde_pedidos'].setErrors(null);
    this.formulario.controls['qtde_pedidos'].markAsUntouched();
    this.formulario.controls['qtde_pedidos'].updateValueAndValidity();

    this.formulario.controls['numero_linha_pedido_1'].setValue('');
    this.formulario.controls['numero_linha_pedido_1'].clearValidators();
    this.formulario.controls['numero_linha_pedido_1'].setErrors(null);
    this.formulario.controls['numero_linha_pedido_1'].markAsUntouched();
    this.formulario.controls['numero_linha_pedido_1'].updateValueAndValidity();

    this.formulario.controls['numero_linha_pedido_2'].setValue('');
    this.formulario.controls['numero_linha_pedido_2'].clearValidators();
    this.formulario.controls['numero_linha_pedido_2'].setErrors(null);
    this.formulario.controls['numero_linha_pedido_2'].markAsUntouched();
    this.formulario.controls['numero_linha_pedido_2'].updateValueAndValidity();

    this.formulario.controls['numero_linha_pedido_3'].setValue('');
    this.formulario.controls['numero_linha_pedido_3'].clearValidators();
    this.formulario.controls['numero_linha_pedido_3'].setErrors(null);
    this.formulario.controls['numero_linha_pedido_3'].markAsUntouched();
    this.formulario.controls['numero_linha_pedido_3'].updateValueAndValidity();

    this.formulario.controls['numero_linha_pedido_4'].setValue('');
    this.formulario.controls['numero_linha_pedido_4'].clearValidators();
    this.formulario.controls['numero_linha_pedido_4'].setErrors(null);
    this.formulario.controls['numero_linha_pedido_4'].markAsUntouched();
    this.formulario.controls['numero_linha_pedido_4'].updateValueAndValidity();

    this.formulario.controls['numero_linha_pedido_5'].setValue('');
    this.formulario.controls['numero_linha_pedido_5'].clearValidators();
    this.formulario.controls['numero_linha_pedido_5'].setErrors(null);
    this.formulario.controls['numero_linha_pedido_5'].markAsUntouched();
    this.formulario.controls['numero_linha_pedido_5'].updateValueAndValidity();

    this.formulario.controls['numero_linha_pedido_6'].setValue('');
    this.formulario.controls['numero_linha_pedido_6'].clearValidators();
    this.formulario.controls['numero_linha_pedido_6'].setErrors(null);
    this.formulario.controls['numero_linha_pedido_6'].markAsUntouched();
    this.formulario.controls['numero_linha_pedido_6'].updateValueAndValidity();

    this.formulario.controls['numero_linha_pedido_7'].setValue('');
    this.formulario.controls['numero_linha_pedido_7'].clearValidators();
    this.formulario.controls['numero_linha_pedido_7'].setErrors(null);
    this.formulario.controls['numero_linha_pedido_7'].markAsUntouched();
    this.formulario.controls['numero_linha_pedido_7'].updateValueAndValidity();

    this.formulario.controls['numero_linha_pedido_8'].setValue('');
    this.formulario.controls['numero_linha_pedido_8'].clearValidators();
    this.formulario.controls['numero_linha_pedido_8'].setErrors(null);
    this.formulario.controls['numero_linha_pedido_8'].markAsUntouched();
    this.formulario.controls['numero_linha_pedido_8'].updateValueAndValidity();

    this.formulario.controls['numero_linha_pedido_9'].setValue('');
    this.formulario.controls['numero_linha_pedido_9'].clearValidators();
    this.formulario.controls['numero_linha_pedido_9'].setErrors(null);
    this.formulario.controls['numero_linha_pedido_9'].markAsUntouched();
    this.formulario.controls['numero_linha_pedido_9'].updateValueAndValidity();

    this.formulario.controls['numero_linha_pedido_10'].setValue('');
    this.formulario.controls['numero_linha_pedido_10'].clearValidators();
    this.formulario.controls['numero_linha_pedido_10'].setErrors(null);
    this.formulario.controls['numero_linha_pedido_10'].markAsUntouched();
    this.formulario.controls['numero_linha_pedido_10'].updateValueAndValidity();

    this.formulario.controls['lista_pedidos'].setValue('');
    this.formulario.controls['lista_pedidos'].clearValidators();
    this.formulario.controls['lista_pedidos'].setErrors(null);
    this.formulario.controls['lista_pedidos'].markAsUntouched();
    this.formulario.controls['lista_pedidos'].updateValueAndValidity();
  }

  clearValidatorsDetalhes() {
    this.formulario.controls['numero_linha_pedido_1']?.clearValidators();
    this.formulario.controls['numero_linha_pedido_1']?.markAsUntouched();
    this.formulario.controls['numero_linha_pedido_1']?.updateValueAndValidity();

    this.formulario.controls['numero_linha_pedido_2']?.clearValidators();
    this.formulario.controls['numero_linha_pedido_2']?.markAsUntouched();
    this.formulario.controls['numero_linha_pedido_2']?.updateValueAndValidity();

    this.formulario.controls['numero_linha_pedido_3']?.clearValidators();
    this.formulario.controls['numero_linha_pedido_3']?.markAsUntouched();
    this.formulario.controls['numero_linha_pedido_3']?.updateValueAndValidity();

    this.formulario.controls['numero_linha_pedido_4']?.clearValidators();
    this.formulario.controls['numero_linha_pedido_4']?.markAsUntouched();
    this.formulario.controls['numero_linha_pedido_4']?.updateValueAndValidity();

    this.formulario.controls['numero_linha_pedido_5']?.clearValidators();
    this.formulario.controls['numero_linha_pedido_5']?.markAsUntouched();
    this.formulario.controls['numero_linha_pedido_5']?.updateValueAndValidity();

    this.formulario.controls['numero_linha_pedido_6']?.clearValidators();
    this.formulario.controls['numero_linha_pedido_6']?.markAsUntouched();
    this.formulario.controls['numero_linha_pedido_6']?.updateValueAndValidity();

    this.formulario.controls['numero_linha_pedido_7']?.clearValidators();
    this.formulario.controls['numero_linha_pedido_7']?.markAsUntouched();
    this.formulario.controls['numero_linha_pedido_7']?.updateValueAndValidity();

    this.formulario.controls['numero_linha_pedido_8']?.clearValidators();
    this.formulario.controls['numero_linha_pedido_8']?.markAsUntouched();
    this.formulario.controls['numero_linha_pedido_8']?.updateValueAndValidity();

    this.formulario.controls['numero_linha_pedido_9']?.clearValidators();
    this.formulario.controls['numero_linha_pedido_9']?.markAsUntouched();
    this.formulario.controls['numero_linha_pedido_9']?.updateValueAndValidity();

    this.formulario.controls['numero_linha_pedido_10']?.clearValidators();
    this.formulario.controls['numero_linha_pedido_10']?.markAsUntouched();
    this.formulario.controls['numero_linha_pedido_10']?.updateValueAndValidity();

    this.formulario.controls['lista_pedidos']?.clearValidators();
    this.formulario.controls['lista_pedidos']?.markAsUntouched();
    this.formulario.controls['lista_pedidos']?.updateValueAndValidity();
  }



  resetform() {
    for (let field in this.formulario.controls) {
      this.formulario.controls[field].setValue('');
    }
    this.formulario.markAsUntouched()



    this.arquivosDeRateio = [];
    this.notas = [];
    this.boletos = []
    this.xml = []
    this.documentacaoSuporte = [];
    this.listaDeItensDaPo = [];
    this.nfProdutoOriginal = []

    this.arquivosDeRateioUrl = [];
    this.notasUrl = []
    this.boletosUrl = []
    this.xmlUrl = []
    this.documentacaoSuporteUrl = [];
    this.listaDeItensDaPoUrl = [];
    this.nfProdutoOriginalUrl = []

    this.notaComRateios = false;

    this.formulario.get('cnpj_fornecedor')?.clearValidators()
    this.formulario.get('cnpj_fornecedor')?.updateValueAndValidity();
    this.formulario.get('cpf_do_fornecedor')?.clearValidators()
    this.formulario.get('cpf_do_fornecedor')?.updateValueAndValidity();
    this.formulario.get('departamento')?.clearValidators()
    this.formulario.get('departamento')?.updateValueAndValidity();

    this.notaComRateioDeValoresString = ''
    this.riscoSacadoString = ''

    this.emailApi = this.userInfo.getUserEmail()
    this.userNameApi = this.userInfo.getName()

    this.formulario.get('email')?.setValue(this.emailApi)
    this.formulario.get('nome')?.setValue(this.userNameApi)


    if (this.formulario.getRawValue().email != '') {
      // this.formulario.controls['email'].disable()
    }
    if (this.formulario.getRawValue().email == undefined) {
      // this.formulario.controls['email'].enable()
      this.formulario.get('email')?.setValue('')

    }

    if (this.formulario.getRawValue().nome != '') {
      // this.formulario.controls['nome'].disable()
    }
    if (this.formulario.getRawValue().nome == undefined) {
      // this.formulario.controls['nome'].enable()
      this.formulario.get('nome')?.setValue('')
    }
    this.getUserEmail();
    this.json_most = [];
  }

  abrirPipePublico() {
    this.commomS.openNewWindow('https://app.pipefy.com/public/form/FZZSofCP');
  }



  // ************************************* REGRAS OCR *******************************************************************

  // save 2
  saveNotaFiscalOCR(event: any): void {
    // console.log('inicio save2')
    this.horaInicioChamadaMost = this.getHoraInicioMost()
    this.clearFieldsOCR();
    var files = event.target.files;
    // console.log('arquivosNf: ', this.notas)
    // console.log('arquivosNfUrl: ', this.notasUrl)

    for (var i = 0; i < files.length; i++) {
      for (var i = 0; i < files.length; i++) {
        var fileContent = files[i]
        let fileName = files[i].name
        this.fileName2 = files[i].name;
        const reader = new FileReader();
        reader.readAsDataURL(fileContent);
        reader.onload = () => {
          fileContent = reader.result?.toString()
          var fileContentStart = fileContent.indexOf(",")
          fileContent = fileContent.substring(fileContentStart + 1)
          // console.log(fileContent)
          // console.log(fileName)
          this.spinner.show()
          this.pipefy.testeMost(fileContent).subscribe((res: any) => {
            // console.log(res)
            this.horaFimChamadaMost = this.getHoraFimMost()
            this.getTotalTempoMost(this.fimMostMiligsegundos, this.inicioMostMilisegundos)


            if (res.body.result.length > 0) {

              this.json_most = res.body.result[0]
              delete this.json_most.tables

              let tipo = this.formulario.getRawValue().tipo_de_nota
              let chave = res.body.result[0].fields.filter((t: { name: string; score: number }) => t.name == 'chave_acesso' && t.score >= 0.8)
              this.chaveAcesso = chave[0] && chave[0].value ? chave[0].value : '';

              if (tipo == 'Material Compras e aquisição' || tipo == 'Material (DANFE)') {
                let tag = res.body.result[0].tags.findIndex((element: string | string[]) => element.includes("bra-danfe"))
                // console.log(tag)
                if (tag == -1) {
                  Swal.fire({
                    title: 'Atenção!',
                    text: 'A nota fiscal inserida não é do tipo informado.',
                    icon: 'info',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#88D600',
                    allowOutsideClick: false,
                    allowEscapeKey: false
                  })
                }
              } else if (tipo == 'Serviço') {
                let tag = res.body.result[0].tags.findIndex((element: string | string[]) => element.includes("carta-correcao-eletronica") || element.includes("bra-nfe"))
                // console.log(tag)
                if (tag == -1) {
                  Swal.fire({
                    title: 'Atenção!',
                    text: 'A nota fiscal inserida não é do tipo informado.',
                    icon: 'info',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#88D600',
                    allowOutsideClick: false,
                    allowEscapeKey: false
                  })
                }
              }

              switch (tipo) {

                // testar outros tipos de nova e inserir no filtro

                case "Contas de consumo (Luz, água, telefone, internet, gás)":
                  var cpf_cnpj_emitente = res.body.result[0].fields.filter((t: { name: string; score: number }) => (t.name == 'cnpj' && t.score >= 0.8) || t.name == 'cpf_cnpj_emitente' || (t.name == 'cpf_cnpj_prestador' || t.name == 'cpf_cnpj_transportador' || t.name == 'cpf_cnpj_beneficiario' && t.score >= 0.8))
                  var cpf_cnpj = res.body.result[0].fields.filter((t: { name: string; score: number }) => t.name == 'cpf_cnpj' && t.score >= 0.8)
                  var dataEmissao = res.body.result[0].fields.filter((t: { name: string; score: number }) => t.name == 'data_emissao' && t.score >= 0.8)
                  var dataVencimento = res.body.result[0].fields.filter((t: { name: string; score: number }) => t.name == 'data_vencimento' && t.score >= 0.8)
                  var valorTotal = res.body.result[0].fields.filter((t: { name: string; score: number }) => (t.name == 'valor_total' || t.name == 'valor_total_nota' || t.name == 'valor_a_pagar' || t.name == 'valor_liquido' || t.name == 'valor_total_servico' || t.name == 'valor_servico') && t.score >= 0.8)
                  var numNotaFiscal = res.body.result[0].fields.filter((t: { name: string; score: number }) => (t.name == 'numero_documento' || t.name == 'numero_fatura' && t.score >= 0.8) || (t.name == 'numero_nota_fiscal' && t.score >= 0.8))

                  cpf_cnpj_emitente = this.verificaCampoOcr(cpf_cnpj_emitente)
                  cpf_cnpj = this.verificaCampoOcr(cpf_cnpj)
                  dataEmissao = this.verificaCampoOcr(dataEmissao)
                  dataVencimento = this.verificaCampoOcr(dataVencimento)
                  valorTotal = this.verificaCampoOcr(valorTotal)
                  numNotaFiscal = this.verificaCampoOcr(numNotaFiscal)

                  console.log(cpf_cnpj_emitente)
                  console.log(cpf_cnpj)
                  console.log(dataEmissao)
                  console.log(dataVencimento)
                  console.log(valorTotal)
                  console.log(numNotaFiscal)

                  this.formulario.controls['data_de_envio_de_nf']?.setValue(dataEmissao[0].value);
                  this.formulario.controls['data_de_vencimento_da_nota']?.setValue(dataVencimento[0].value);
                  this.formataValorDecimal(valorTotal[0].value);
                  this.formulario.controls['valor_total_da_nf']?.setValue(this.valorFaturaAlterado);
                  this.formulario.controls['nro_nota']?.setValue(numNotaFiscal[0].value);

                  cpf_cnpj_emitente && cpf_cnpj_emitente[0].value !== '' ? this.searchFornecedorOCR(cpf_cnpj_emitente[0].value) : this.spinner.hide();
                  cpf_cnpj && cpf_cnpj[0].value !== '' ? this.searchTomadorOCR(cpf_cnpj[0].value) : this.spinner.hide();
                  break;

                case "Material Compras e aquisição":
                case "Material (DANFE)":
                case "Serviço":
                case "Sem valor fiscal":
                case "Benefícios":
                case "Benefícios":
                case "Escrituração - Não gera pagamento":
                case "Importação":
                case "Frete/CTE":

                  // serviço = sem valor fiscal = Importação = Escrituração = Benefícios
                  var cpf_cnpj_emitente = res.body.result[0].fields.filter((t: { name: string; score: number }) => (t.name == 'cpf_cnpj_emitente' || t.name == 'cnpj' || t.name == 'cpf_cnpj_prestador' || t.name == 'cpf_cnpj_transportador' || t.name == 'cpf_cnpj_beneficiario') && t.score >= 0.8)
                  var cpf_cnpj = res.body.result[0].fields.filter((t: { name: string; score: number }) => (t.name == 'cpf_cnpj') && t.score >= 0.8)
                  var dataEmissao = res.body.result[0].fields.filter((t: { name: string; score: number }) => t.name == 'data_emissao' && t.score >= 0.8)
                  var dataVencimento = res.body.result[0].fields.filter((t: { name: string; score: number }) => t.name == 'data_vencimento' && t.score >= 0.8)
                  var valorTotal = res.body.result[0].fields.filter((t: { name: string; score: number }) => (t.name == 'valor_total' || t.name == 'valor_total_nota' || t.name == 'valor_a_pagar' || t.name == 'valor_liquido' || t.name == 'valor_total_servico' || t.name == 'valor_servico') && t.score >= 0.8)
                  var numNotaFiscal = res.body.result[0].fields.filter((t: { name: string; score: number }) => t.name == 'numero_documento' || t.name == 'numero_fatura' || t.name == 'numero_nota_fiscal' && t.score >= 0.8)

                  cpf_cnpj_emitente = this.verificaCampoOcr(cpf_cnpj_emitente)
                  cpf_cnpj = this.verificaCampoOcr(cpf_cnpj)
                  dataEmissao = this.verificaCampoOcr(dataEmissao)
                  dataVencimento = this.verificaCampoOcr(dataVencimento)
                  valorTotal = this.verificaCampoOcr(valorTotal)
                  numNotaFiscal = this.verificaCampoOcr(numNotaFiscal)

                  this.formulario.controls['data_de_envio_de_nf']?.setValue(dataEmissao[0].value);
                  this.formulario.controls['data_de_vencimento_da_nota']?.setValue(dataVencimento[0].value);
                  this.formataValorDecimal(valorTotal[0].value);
                  this.formulario.controls['valor_total_da_nf']?.setValue(this.valorFaturaAlterado);
                  this.formulario.controls['nro_nota']?.setValue(numNotaFiscal[0].value);

                  cpf_cnpj_emitente && cpf_cnpj_emitente[0].value !== '' ? this.searchFornecedorOCR(cpf_cnpj_emitente[0].value) : this.spinner.hide();
                  cpf_cnpj && cpf_cnpj[0].value !== '' ? this.searchTomadorOCR(cpf_cnpj[0].value) : this.spinner.hide();
                  break;

                case "Aluguel":
                  var cpf_cnpj_emitente = res.body.result[0].fields.filter((t: { name: string; score: number }) => (t.name == 'cpf_cnpj_emitente' || t.name == 'cnpj' && t.score >= 0.8) || (t.name == 'cpf_cnpj_prestador' || t.name == 'cpf_cnpj_transportador' && t.score >= 0.8) || (t.name == 'cpf_cnpj_beneficiario' && t.score >= 0.8))
                  var cpf_cnpj = res.body.result[0].fields.filter((t: { name: string; score: number }) => t.name == 'cpf_cnpj' && t.score >= 0.8)
                  var dataEmissao = res.body.result[0].fields.filter((t: { name: string; score: number }) => t.name == 'data_emissao' && t.score >= 0.8)
                  var dataVencimento = res.body.result[0].fields.filter((t: { name: string; score: number }) => t.name == 'data_vencimento' && t.score >= 0.8)
                  var valorTotal = res.body.result[0].fields.filter((t: { name: string; score: number }) => (t.name == 'valor_total' || t.name == 'valor_total_nota' || t.name == 'valor_a_pagar' || t.name == 'valor_liquido' || t.name == 'valor_total_servico' || t.name == 'valor_servico') && t.score >= 0.8)
                  var numNotaFiscal = res.body.result[0].fields.filter((t: { name: string; score: number }) => (t.name == 'numero_documento' || t.name == 'numero_fatura' && t.score >= 0.8) || (t.name == 'numero_nota_fiscal' && t.score >= 0.8))

                  cpf_cnpj_emitente = this.verificaCampoOcr(cpf_cnpj_emitente)
                  cpf_cnpj = this.verificaCampoOcr(cpf_cnpj)
                  dataEmissao = this.verificaCampoOcr(dataEmissao)
                  dataVencimento = this.verificaCampoOcr(dataVencimento)
                  valorTotal = this.verificaCampoOcr(valorTotal)
                  numNotaFiscal = this.verificaCampoOcr(numNotaFiscal)

                  console.log(cpf_cnpj_emitente)
                  console.log(cpf_cnpj)
                  console.log(dataEmissao)
                  console.log(dataVencimento)
                  console.log(valorTotal)
                  console.log(numNotaFiscal)

                  this.formulario.controls['data_de_envio_de_nf']?.setValue(dataEmissao[0].value);
                  this.formulario.controls['data_de_vencimento_da_nota']?.setValue(dataVencimento[0].value);
                  this.formataValorDecimal(valorTotal[0].value);
                  this.formulario.controls['valor_total_da_nf']?.setValue(this.valorFaturaAlterado);


                  this.formulario.controls['nro_nota']?.setValue(numNotaFiscal[0].value);

                  cpf_cnpj_emitente && cpf_cnpj_emitente[0].value !== '' ? this.searchFornecedorOCR(cpf_cnpj_emitente[0].value) : this.spinner.hide();
                  cpf_cnpj && cpf_cnpj[0].value !== '' ? this.searchTomadorOCR(cpf_cnpj[0].value) : this.spinner.hide();
                  break;

                // case "Importação":

                // var dataVencimento = res.body.result[0].fields.filter((t: { name: string; }) => t.name == 'data_vencimento')
                // var numNotaFiscal = res.body.result[0].fields.filter((t: { name: string; }) => t.name == 'numero_documento' || t.name == 'numero_fatura')
                // var valorTotal = res.body.result[0].fields.filter((t: { name: string; }) => t.name == 'valor_total_nota')


                // dataVencimento =this.verificaCampoOcr(dataVencimento)
                // valorTotal = this.verificaCampoOcr(valorTotal)
                // numNotaFiscal = this.verificaCampoOcr(numNotaFiscal)

                // console.log(dataVencimento)
                // console.log(valorTotal)

                // this.formulario.controls['data_de_vencimento_da_nota']?.setValue(dataVencimento[0].value);
                // this.formulario.controls['valor_total_da_nf']?.setValue(this.formataValorDecimal(valorTotal[0].value))
                // this.formulario.controls['nro_nota']?.setValue(numNotaFiscal[0].value);


                // this.spinner.hide()
                // break;


                default:
                  this.spinner.hide()
                  break;
              }
            } else {
              this.spinner.hide()
              Swal.fire({
                title: 'Desculpe!',
                text: 'Não foi possível fazer a leitura do documento enviado. Por favor, faça o preenchimento manualmente.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#88D600',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
            }
          }, (error: any) => {
            this.spinner.hide()
            console.log(error.status)
            console.log(error)
            Swal.fire({
              title: 'Ops!',
              text: 'Ocorreu um erro na sua solicitação, verifique seu arquivo e tente novamente.',
              icon: 'error',
              confirmButtonText: 'Ok',
              confirmButtonColor: '#88D600',
              allowOutsideClick: false,
              allowEscapeKey: false
            })
          })
        }
      }
    }
    console.log('fim save2')

  }

  // save 3
  saveBoletoOCR(event: any): void {
    console.log('inicio save3')
    console.log('boletos: ', this.boletos)
    console.log('boletosUrl: ', this.boletosUrl)
    this.boletos = [];
    this.boletosUrl = [];
    var files = event.target.files;
    for (var i = 0; i < files.length; i++) {
      for (var i = 0; i < files.length; i++) {
        var fileContent = files[i]
        let fileName = files[i].name
        this.fileName3 = files[i].name;

        const reader = new FileReader();
        reader.readAsDataURL(fileContent);
        reader.onload = () => {
          fileContent = reader.result?.toString()
          var fileContentStart = fileContent.indexOf(",")
          fileContent = fileContent.substring(fileContentStart + 1)
          this.spinner.show()
          this.pipefy.testeMost(fileContent).subscribe((res: any) => {
            console.log(res)
            if (res.body.result.length > 0) {
              this.json_most = res.body.result[0];

              var dataVencimento = res.body.result[0].fields.filter((t: { name: string; score: number }) => t.name == 'data_vencimento' && t.score >= 0.8)
              var valorTotal = res.body.result[0].fields.filter((t: { name: string; score: number }) => (t.name == 'valor_total' || t.name == 'valor_total_nota' || t.name == 'valor_a_pagar' || t.name == 'valor_liquido' || t.name == 'valor_total_servico' || t.name == 'valor_servico') && t.score >= 0.8)

              dataVencimento = this.verificaCampoOcr(dataVencimento)
              valorTotal = this.verificaCampoOcr(valorTotal)

              console.log(dataVencimento)
              console.log(valorTotal)

              this.formulario.controls['data_de_vencimento_da_nota']?.setValue(dataVencimento[0].value);
              if (valorTotal[0].value &&
                (this.formulario.getRawValue().valor_da_fatura == '' || this.formulario.getRawValue().valor_da_fatura == '0,00' || this.formulario.getRawValue().valor_da_fatura == undefined)
              ) {
                this.formataValorDecimal(valorTotal[0].value);
                this.formulario.controls['valor_total_da_nf']?.setValue(this.valorFaturaAlterado);
              }

              res.body.result[0].fields.forEach(function (item: any) { delete item.stdName });
              // this.resBoletoJsonMost = res.body.result[0].fields;

              this.spinner.hide()

            } else {
              this.spinner.hide()
              Swal.fire({
                title: 'Desculpe!',
                text: 'Não foi possível fazer a leitura do documento enviado. Por favor, faça o preenchimento manualmente.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#88D600',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
            }
          }, (error: any) => {
            this.spinner.hide()
            console.log(error.status)
            console.log(error)
            Swal.fire({
              title: 'Ops!',
              text: 'Ocorreu um erro na sua solicitação, verifique seu arquivo e tente novamente.',
              icon: 'error',
              confirmButtonText: 'Ok',
              confirmButtonColor: '#88D600',
              allowOutsideClick: false,
              allowEscapeKey: false
            })
          })
        }
      }
    }
    console.log('fim save3')

  }

  searchFornecedorOCR(valor: any) {
    this.listaFornecedores = !this.listaFornecedores.length ? this.listaFornecedoresOriginal : this.listaFornecedores;
    let filtro = valor.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '');
    if (filtro && filtro.length >= 11) {
      this.spinner.show();
      this.listaFornecedores = [];

      let key = 'Emitente';
      this.pipefy.buscaTabelasMasterdata(key, filtro).then((res: any) => {
        let retorno = res.data.masterdata;
        if (retorno.length > 0) {
          for (let index = 0; index < retorno.length; index++) {
            let label = retorno[index].label;
            let newLabel = label.split('_');

            this.listaFornecedores.push({
              nome: newLabel[0],
              codErp: newLabel[1],
              cnpj: newLabel[2],
              cardId: retorno[index].card_id
            })
            console.log('cnpj forn', this.listaFornecedores[0].cnpj);
            console.log('nome forn', this.listaFornecedores[0].nome);
            this.formulario.controls['cnpj_fornecedor']?.setValue(this.listaFornecedores[0].cnpj);
            this.formulario.controls['nome_do_fornecedor']?.setValue(this.listaFornecedores[0].nome);

            this.listaFornecedores[0].cnpj.length > 11 ?
              this.formulario.controls['documento_do_fornecedor']?.setValue("CNPJ") :
              this.formulario.controls['documento_do_fornecedor']?.setValue("CPF");
          }
        } else {
          this.listaFornecedores = [];
          this.listaFornecedores.push({
            nome: 'NÃO CADASTRADO/ENCONTRADO',
            cardId: '666469115'
          })
        }
        // this.spinner.hide();
      },
        (error: any) => {
          this.spinner.hide();
          console.log(error.message)
          this.listaFornecedores = [];
          this.listaFornecedores.push({
            nome: 'NÃO CADASTRADO/ENCONTRADO',
            cardId: '666469115'
          })
        })
    } else {
      this.listaFornecedores = this.listaFornecedoresOriginal;
    }
  }

  searchTomadorOCR(valor: any) {
    this.listaTomadores = !this.listaTomadores.length ? this.listaTomadoresOriginal : this.listaTomadores;
    let filtro = valor.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '');
    if (filtro && filtro.length >= 11) {
      this.spinner.show();
      this.listaTomadores = [];

      let key = 'Tomador';
      this.pipefy.buscaTabelasMasterdata(key, filtro).then((res: any) => {
        let retorno = res.data.masterdata;
        if (retorno.length > 0) {
          for (let index = 0; index < retorno.length; index++) {
            let label = retorno[index].label;
            let newLabel = label.split('_');

            this.listaTomadores.push({
              nome: newLabel[0],
              codErp: newLabel[1],
              cnpj: newLabel[2],
              cardId: retorno[index].card_id
            })
          }
          console.log('cnpj emp', this.listaTomadores[0].cnpj);
          console.log('nome emp', this.listaTomadores[0].nome);
          this.formulario.controls['cnpj_da_empresa_nutrien']?.setValue(this.listaTomadores[0].cnpj);
          this.formulario.controls['nome_empresa_nutrien_ocr']?.setValue(this.listaTomadores[0].nome);
        } else {
          this.listaTomadores = [];
          this.listaTomadores.push({
            nome: 'NÃO CADASTRADO/ENCONTRADO',
            cardId: '666469142'
          })
        }
        this.spinner.hide();
      },
        (error: any) => {
          this.spinner.hide();
          console.log(error.message)
          this.listaTomadores = [];
          this.listaTomadores.push({
            nome: 'NÃO CADASTRADO/ENCONTRADO',
            cardId: '666469142'
          })
        })

    } else {
      this.listaTomadores = this.listaTomadoresOriginal;
    }
  }

  clearFieldsOCR() {
    console.log('entrei clear fields')
    console.log('1 this.arquivosNf:', this.notas)
    console.log('1 this.arquivosNfUrl:', this.notasUrl)
    console.log('1 this.arquivosNfBoleto:', this.boletos)
    console.log('1 this.arquivosNfBoletoUrl:', this.boletosUrl)

    this.notas = [];
    this.notasUrl = [];
    this.boletos = [];
    this.boletosUrl = [];
    this.formulario.controls['cnpj_fornecedor']?.setValue("");
    this.formulario.controls['nome_do_fornecedor']?.setValue("");
    this.formulario.controls['cnpj_da_empresa_nutrien']?.setValue("");
    this.formulario.controls['nome_empresa_nutrien_ocr']?.setValue("");
    this.formulario.controls['documento_do_fornecedor']?.setValue("");
    this.listaFornecedores = [];
    this.listaTomadores = [];
    this.valorTotalNfAlterado = '';
    this.formulario.controls['valor_total_da_nf']?.setValue('');
    this.formulario.controls['data_de_envio_de_nf']?.setValue('');
    this.formulario.controls['data_de_vencimento_da_nota']?.setValue('');
    this.formulario.controls['nro_nota']?.setValue('');

    console.log('2 this.arquivosNf:', this.notas)
    console.log('2 this.arquivosNfUrl:', this.notasUrl)
    console.log('2 this.arquivosNfBoleto:', this.boletos)
    console.log('2 this.arquivosNfBoletoUrl:', this.boletosUrl)
  }

  verificaCampoOcr(campoOcr: any) {
    var retorno = campoOcr
    console.log(retorno)
    if (retorno.length == 0) {
      console.log("entrei aqui")
      retorno = [
        {
          name: '',
          value: ''
        }
      ]
      return retorno
    }
    return retorno
  }


  // SOLICITACAO MOREIRA


  onGrcChange(event: any) {
    if (event.target.value == "Sim") {
      this.formulario.get('estado_de_origem')?.setValidators([Validators.required])
      this.formulario.get('estado_de_origem')?.updateValueAndValidity();
      this.formulario.get('estado_de_destino')?.setValidators([Validators.required])
      this.formulario.get('estado_de_destino')?.updateValueAndValidity();
    } else {
      this.formulario.get('estado_de_origem')?.clearValidators();
      this.formulario.get('estado_de_origem')?.updateValueAndValidity();
      this.formulario.get('estado_de_destino')?.clearValidators();
      this.formulario.get('estado_de_destino')?.updateValueAndValidity();
    }
  }

  onEstadoOrigemChange(event: any) {
    this.formulario.get('estado_de_origem')?.setValue(event.target.value);
  }
  onEstadoDestinoChange(event: any) {
    this.formulario.get('estado_de_destino')?.setValue(event.target.value);
  }


  preencheNumeroDoPedido(quantidade_de_pedidos: string, numero_do_pedido: string) {

    var quantidade = parseInt(quantidade_de_pedidos)
    switch (quantidade) {
      case 1:
        this.numero_do_pedido_1 = numero_do_pedido
        break;

      case 2:
        this.numero_do_pedido_1 = numero_do_pedido
        this.numero_do_pedido_2 = numero_do_pedido
        break;

      case 3:
        this.numero_do_pedido_1 = numero_do_pedido
        this.numero_do_pedido_2 = numero_do_pedido
        this.numero_do_pedido_3 = numero_do_pedido
        break;

      case 4:
        this.numero_do_pedido_1 = numero_do_pedido
        this.numero_do_pedido_2 = numero_do_pedido
        this.numero_do_pedido_3 = numero_do_pedido
        this.numero_do_pedido_4 = numero_do_pedido
        break;

      case 5:
        this.numero_do_pedido_1 = numero_do_pedido
        this.numero_do_pedido_2 = numero_do_pedido
        this.numero_do_pedido_3 = numero_do_pedido
        this.numero_do_pedido_4 = numero_do_pedido
        this.numero_do_pedido_5 = numero_do_pedido
        break;


      case 6:
        this.numero_do_pedido_1 = numero_do_pedido
        this.numero_do_pedido_2 = numero_do_pedido
        this.numero_do_pedido_3 = numero_do_pedido
        this.numero_do_pedido_4 = numero_do_pedido
        this.numero_do_pedido_5 = numero_do_pedido
        this.numero_do_pedido_6 = numero_do_pedido
        break;

      case 7:
        this.numero_do_pedido_1 = numero_do_pedido
        this.numero_do_pedido_2 = numero_do_pedido
        this.numero_do_pedido_3 = numero_do_pedido
        this.numero_do_pedido_4 = numero_do_pedido
        this.numero_do_pedido_5 = numero_do_pedido
        this.numero_do_pedido_6 = numero_do_pedido
        this.numero_do_pedido_7 = numero_do_pedido
        break;

      case 8:
        this.numero_do_pedido_1 = numero_do_pedido
        this.numero_do_pedido_2 = numero_do_pedido
        this.numero_do_pedido_3 = numero_do_pedido
        this.numero_do_pedido_4 = numero_do_pedido
        this.numero_do_pedido_5 = numero_do_pedido
        this.numero_do_pedido_6 = numero_do_pedido
        this.numero_do_pedido_7 = numero_do_pedido
        this.numero_do_pedido_8 = numero_do_pedido
        break;

      case 9:
        this.numero_do_pedido_1 = numero_do_pedido
        this.numero_do_pedido_2 = numero_do_pedido
        this.numero_do_pedido_3 = numero_do_pedido
        this.numero_do_pedido_4 = numero_do_pedido
        this.numero_do_pedido_5 = numero_do_pedido
        this.numero_do_pedido_6 = numero_do_pedido
        this.numero_do_pedido_7 = numero_do_pedido
        this.numero_do_pedido_8 = numero_do_pedido
        this.numero_do_pedido_9 = numero_do_pedido
        break;

      case 10:
        this.numero_do_pedido_1 = numero_do_pedido
        this.numero_do_pedido_2 = numero_do_pedido
        this.numero_do_pedido_3 = numero_do_pedido
        this.numero_do_pedido_4 = numero_do_pedido
        this.numero_do_pedido_5 = numero_do_pedido
        this.numero_do_pedido_6 = numero_do_pedido
        this.numero_do_pedido_7 = numero_do_pedido
        this.numero_do_pedido_8 = numero_do_pedido
        this.numero_do_pedido_9 = numero_do_pedido
        this.numero_do_pedido_10 = numero_do_pedido
        break;




    }
  }


  getHoraInicioMost(){
    console.log("entreiii aquiiii")
    var horaSolicitacao = new Date
    this.inicioMostMilisegundos = Date.now()
    console.log('hora Inicio:' + horaSolicitacao)
    console.log('hora inicio milisegundos: ', this.inicioMostMilisegundos)
    return horaSolicitacao.getHours()+':'+horaSolicitacao.getMinutes()+':'+horaSolicitacao.getSeconds()
  }

  getHoraFimMost(){
    var horaSolicitacao = new Date()
    this.fimMostMiligsegundos = Date.now()
    console.log('hora Fim:' + horaSolicitacao)
    return horaSolicitacao.getHours()+':'+horaSolicitacao.getMinutes()+':'+horaSolicitacao.getSeconds()
  }

  getTotalTempoMost(tempoInicio:any, tempoFim:any){
    const diff = Math.max(tempoInicio,tempoFim) - Math.min(tempoInicio,tempoFim)
    const SEC = 1000, MIN = 60 * SEC, HRS = 60 * MIN

  const hrs = Math.floor(diff/HRS)
  const min = Math.floor((diff%HRS)/MIN).toLocaleString('pt-BR', {minimumIntegerDigits: 2})
  const sec = Math.floor((diff%MIN)/SEC).toLocaleString('pt-BR', {minimumIntegerDigits: 2})
  const ms = Math.floor(diff % SEC).toLocaleString('pt-BR', {minimumIntegerDigits: 4, useGrouping: false})

  console.log(`${hrs}:${min}:${sec}`)

  this.diferencaMost = `${hrs}:${min}:${sec}`
}


  getHours(){
    var horaSolicitacao = new Date()

    return horaSolicitacao.getHours()+':'+horaSolicitacao.getMinutes()+':'+horaSolicitacao.getSeconds()
  }







}
