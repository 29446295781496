export const filiais_agrichem = [
  {
    nome:''
  },
  {
    nome:'Agrichem'
  },
  {
    nome:'Filial-Agrichem -Ribeirão Preto-0004-35-Sp'
  },
  {
    nome:'Matriz-Agrichem -Ribeirão Preto-0001-92-Sp'
  }
]
