export const empresas = [
  {
    nome:''
  },
  {
    nome:'Nutrien'
  },
  {
    nome:'Tec Agro'
  }
]
