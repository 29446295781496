import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MsalService } from '@azure/msal-angular';
import { NgxSpinnerService } from 'ngx-spinner';
import { PipefyService } from 'src/app/services/pipefy.service';
import { TrataInputsService } from 'src/app/services/trata-inputs.service';
import { pipe_fields_incidentes } from 'src/app/servicos-financeiros/formularios/pipe_fields_incidentes';
import Swal from 'sweetalert2';
import { pipe_fields_id_alteracao_dados_bancarios } from './../pipe_fields_alteracao_dados_bancarios';
import { tiposDeConta } from './tipo_de_conta';
import { FormField } from 'src/app/componentes/formMat/form.component';

@Component({
  selector: 'app-alteracao-dados-bancarios',
  templateUrl: './alteracao-dados-bancarios.component.html',
  styleUrls: ['./alteracao-dados-bancarios.component.css']
})
export class AlteracaoDadosBancariosComponent implements OnInit {

  fields: FormField[] = [];

  constructor() {

  }

  ngOnInit(): void {

    this.fields = [

      {
        "type": "text",
        "name": "nome_do_solicitante",
        "label": "Nome do solicitante",
        "validators": Validators.maxLength(1000)
      },
      {
        "type": "email",
        "name": "e_mail_do_solicitante",
        "label": "E-mail do solicitante"
      },
      {
        "type": "cpf",
        "name": "cpf",
        "label": "CPF",
        "validators": Validators.required
      },
      {
        "type": "select",
        "name": "empresa",
        "label": "Empresa",
        "validators": Validators.required,
        "select": [
          {
            "type": "option",
            "name": "AGRICHEM DO BRASIL SA",
            "value": "AGRICHEM DO BRASIL SA"
          },
          {
            "type": "option",
            "name": "AGROSEMA COMERCIAL AGRICOLA LTDA",
            "value": "AGROSEMA COMERCIAL AGRICOLA LTDA"
          },
          {
            "type": "option",
            "name": "BIO RURAL COMERCIO E REPRESENTACOES LTDA",
            "value": "BIO RURAL COMERCIO E REPRESENTACOES LTDA"
          },
          {
            "type": "option",
            "name": "CONFIANÇA AGRÍCOLA LTDA",
            "value": "CONFIANÇA AGRÍCOLA LTDA"
          },
          {
            "type": "option",
            "name": "FRONTEIRA COMÉRCIO E REPRESENTAÇÃO DE PRODUTOS AGROPECUÁRIOS LTDA",
            "value": "FRONTEIRA COMÉRCIO E REPRESENTAÇÃO DE PRODUTOS AGROPECUÁRIOS LTDA"
          },
          {
            "type": "option",
            "name": "NUTRIEN SOLUÇÕES AGRÍCOLAS LTDA",
            "value": "NUTRIEN SOLUÇÕES AGRÍCOLAS LTDA"
          },
          {
            "type": "option",
            "name": "SEMENTES GOIÁS LTDA",
            "value": "SEMENTES GOIÁS LTDA"
          },
          {
            "type": "option",
            "name": "TERRA NOVA COM REP E ASSIS TÉC AGRIC LTD",
            "value": "TERRA NOVA COM REP E ASSIS TÉC AGRIC LTD"
          }
        ]
      },
      {
        "type": "select",
        "name": "processo",
        "label": "Processo",
        "value": "Cadastro",
        "_disabled": true,
        "select": [
          {
            "type": "option",
            "name": "Cadastro",
            "value": "Cadastro"
          }
        ]
      },
      {
        "type": "select",
        "name": "tipos_de_d_vidas_cadastro",
        "label": "Tipos de dúvidas cadastro",
        "value": "Alteração de dados bancários",
        "_disabled": true,
        "select": [
          {
            "type": "option",
            "name": "Alteração de dados bancários",
            "value": "Alteração de dados bancários"
          }
        ]
      },
      {
        "type": "textArea",
        "name": "descri_o",
        "label": "Descrição",
        "validators": Validators.required
      },
      {
        "type": "attachment",
        "name": "documenta_o_e_ou_anexo",
        "label": "Documentação e/ou Anexo",
        "validators": Validators.required
      },
      {
        "type": "select",
        "label": "Tipo de conta",
        "name": "tipo_de_conta",
        "validators": Validators.required,
        "select": [
          {
            "type": "option",
            "name": "Conta Corrente",
            "value": "Conta Corrente",
            "fields": [
              {
                "label": "Nome do banco",
                "name": "nome_do_banco",
                "type": "text",
                "validators": [Validators.required, Validators.maxLength(1000)]
              },
              {
                "label": "Numero da agência com dígito",
                "name": "numero_da_ag_ncia_com_d_gito",
                "type": "number",
                "validators": Validators.required
              },
              {
                "label": "Numero da conta com dígito",
                "name": "numero_da_conta_com_d_gito",
                "type": "number",
                "validators": Validators.required
              }
            ]
          },
          {
            "type": "option",
            "name": "Conta Salário",
            "value": "Conta Salário",
            "fields": [
              {
                "label": "Nome do banco (Conta salário)",
                "name": "nome_do_banco_conta_sal_rio",
                "type": "text",
                "validators": [Validators.required, Validators.maxLength(1000)]
              },
              {
                "label": "Numero da agência com dígito (Conta salário)",
                "name": "numero_da_ag_ncia_com_d_gito_conta_sal_rio",
                "type": "number",
                "validators": Validators.required
              },
              {
                "label": "Numero da conta com dígito (Conta salário)",
                "name": "numero_da_conta_com_d_gito_conta_sal_rio",
                "type": "number",
                "validators": Validators.required
              }
            ]
          }
        ]
      },
      {
        "type": "buttonSubmit",
        "name": "Enviar"
      }
    ]
  }

}
