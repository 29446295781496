<app-header></app-header>
<app-nbs-banner url="../../../assets/img/background_lojas.png" descricao="teste" titulo="Lojas"
  subtitulo="Centros de Experiência"></app-nbs-banner>



<div class="container">


  <app-formularios-titulo url="../../../assets/img/auditoria.png" descricao="Payment"
    titulo="Baixa de Títulos"></app-formularios-titulo>

    <div class="mensagem-topo">
      <h6 class="text-center">Preencha o formulário abaixo para enviar sua solicitação.</h6>
      <p class="text-center">Os campos marcados com (*) são de preenchimento obrigatório.</p>
    </div>

  <form [formGroup]="formulario" (submit)="enviarFormulario()">

    <div class="form-group row" >
      <label for="sistema_t_tulo_em_aberto" class="col-sm-4 col-form-label">*Sistema Título em aberto</label>
      <div class="col-sm-8">
        <!-- <label for="tipo" class='form-check-label radio-titulo'>
          <input type="radio" formControlName='sistema_t_tulo_em_aberto' value="Protheus / TOTVs Agrosema" class='form-check-input' (change)="onSistemaTituloChange($event)" >&nbsp;Protheus / TOTVs Agrosema
        </label> -->
        <label for="tipo" class='form-check-label radio-titulo'>
          <input type="radio" formControlName='sistema_t_tulo_em_aberto' value='Protheus / TOTVs TecAgro' class='form-check-input '(change)="onSistemaTituloChange($event)">&nbsp;Protheus / TOTVs TecAgro
        </label>
        <label for="tipo" class='form-check-label radio-titulo'>
          <input type="radio" formControlName='sistema_t_tulo_em_aberto' value='SAP' class='form-check-input' (change)="onSistemaTituloChange($event)">&nbsp;SAP
        </label>
        <label for="tipo" class='form-check-label radio-titulo'>
          <input type="radio" formControlName='sistema_t_tulo_em_aberto' value='Siagri' class='form-check-input ' (change)="onSistemaTituloChange($event)">&nbsp;Siagri
        </label>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('sistema_t_tulo_em_aberto')?.errors?.required && formulario.get('sistema_t_tulo_em_aberto')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="nome_do_cliente" class="col-sm-4 col-form-label">*Nome do Cliente: </label>
      <div class="col-sm-8">
        <input type="email" class="form-control" formControlName="nome_do_cliente" >
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('nome_do_cliente')?.errors?.required && formulario.get('nome_do_cliente')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('nome_do_cliente')?.errors?.email && formulario.get('nome_do_cliente')?.touched"
          mensagem="Formato de e-mail inválido"></app-mensagem>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('nome_do_cliente')?.errors?.maxlength && formulario.get('nome_do_cliente')?.touched"
          mensagem="A quantidade máxima de caracteres permitida é 1000"></app-mensagem>
      </div>
    </div>

    <div class="form-group row" *ngIf="this.formulario.getRawValue().sistema_t_tulo_em_aberto!='SAP'">
      <label for="c_digo_do_cliente" class="col-sm-4 col-form-label">Código do Cliente: </label>
      <div class="col-sm-8">
        <input type="email" class="form-control" formControlName="c_digo_do_cliente" >
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('c_digo_do_cliente')?.errors?.maxlength && formulario.get('c_digo_do_cliente')?.touched"
          mensagem="A quantidade máxima de caracteres permitida é 1000"></app-mensagem>
      </div>
    </div>

    <div class="form-group row" *ngIf="this.formulario.getRawValue().sistema_t_tulo_em_aberto=='SAP'">
      <label for="c_digo_do_cliente_sap" class="col-sm-4 col-form-label">*Código do Cliente SAP </label>
      <div class="col-sm-8">
        <input type="email" class="form-control" formControlName="c_digo_do_cliente_sap" >
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('c_digo_do_cliente_sap')?.errors?.required && formulario.get('c_digo_do_cliente_sap')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('c_digo_do_cliente_sap')?.errors?.maxlength && formulario.get('c_digo_do_cliente_sap')?.touched"
          mensagem="A quantidade máxima de caracteres permitida é 1000"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="n_mero_de_notas_fiscais_a_serem_baixadas" class="col-sm-4 col-form-label">*Número de Notas Fiscais a serem baixadas</label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="n_mero_de_notas_fiscais_a_serem_baixadas" (change)="onNumeroDeNotasChange($event)">

          <option *ngFor="let quantidade of quantidadeDeNotas" [value]="quantidade.quantidade">{{quantidade.quantidade}}
          </option>
        </select>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('n_mero_de_notas_fiscais_a_serem_baixadas')?.errors?.required && formulario.get('n_mero_de_notas_fiscais_a_serem_baixadas')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <!-- Quantidade = 1 -->

<div *ngIf="formulario.getRawValue().n_mero_de_notas_fiscais_a_serem_baixadas>=1">

  <div class="form-group row">
    <label for="n_mero_da_nota_fiscal_nota_1" class="col-sm-4 col-form-label">*Número da Nota Fiscal (Nota 1) </label>
    <div class="col-sm-8">
      <input type="text" class="form-control" formControlName="n_mero_da_nota_fiscal_nota_1" >
      <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('n_mero_da_nota_fiscal_nota_1')?.errors?.required && formulario.get('n_mero_da_nota_fiscal_nota_1')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('n_mero_da_nota_fiscal_nota_1')?.errors?.maxlength && formulario.get('n_mero_da_nota_fiscal_nota_1')?.touched"
          mensagem="A quantidade máxima de caracteres permitida é 1000"></app-mensagem>
    </div>
  </div>

    <div class="form-group row">
      <label for="baixar_valor_total_da_nota_fiscal_nota_1" class="col-sm-4 col-form-label">*Baixar Valor Total da Nota Fiscal? (Nota 1)</label>
      <div class="col-sm-8">
        <label for="baixar_valor_total_da_nota_fiscal_nota_1" class=' radio-inline'>
          <input type="radio" formControlName='baixar_valor_total_da_nota_fiscal_nota_1' value="Sim" class='radio-btn' (change)="onChangeBaixarValorTotalNota1($event)"
            >&nbsp;Sim
        </label>
        <label for="baixar_valor_total_da_nota_fiscal_nota_1" class='radio-inline'>
          <input type="radio" formControlName='baixar_valor_total_da_nota_fiscal_nota_1' value='Não' class='radio-btn' (change)="onChangeBaixarValorTotalNota1($event)"
            >&nbsp;Não
        </label>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('baixar_valor_total_da_nota_fiscal_nota_1')?.errors?.required && formulario.get('baixar_valor_total_da_nota_fiscal_nota_1')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>



    <div class="form-group row" *ngIf="formulario.getRawValue().baixar_valor_total_da_nota_fiscal_nota_1=='Não'">
      <label for="valor_parcial_a_ser_baixado_nota_1" class="col-sm-4 col-form-label">*Valor Parcial a ser baixado (Nota 1) </label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="valor_parcial_a_ser_baixado_nota_1" mask="separator.2" thousandSeparator="." [validation]="false">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('valor_parcial_a_ser_baixado_nota_1')?.errors?.required && formulario.get('valor_parcial_a_ser_baixado_nota_1')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('valor_parcial_a_ser_baixado_nota_1')?.errors?.maxlength && formulario.get('valor_parcial_a_ser_baixado_nota_1')?.touched"
          mensagem="A quantidade máxima de caracteres permitida é 1000"></app-mensagem>
      </div>
    </div>


    <div class="form-group row">
      <label for="desconto_concedido_nota_1" class="col-sm-4 col-form-label">*Desconto concedido? (Nota 1)</label>
      <div class="col-sm-8">
        <label for="desconto_concedido_nota_1" class=' radio-inline'>
          <input type="radio" formControlName='desconto_concedido_nota_1' value="Sim" class='radio-btn' (change)="onDescontoConcedidoChange1($event)"
            >&nbsp;Sim
        </label>
        <label for="desconto_concedido_nota_1" class='radio-inline'>
          <input type="radio" formControlName='desconto_concedido_nota_1' value='Não' class='radio-btn' (change)="onDescontoConcedidoChange1($event)"
            >&nbsp;Não
        </label>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('desconto_concedido_nota_1')?.errors?.required && formulario.get('desconto_concedido_nota_1')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row" *ngIf="formulario.getRawValue().desconto_concedido_nota_1=='Sim'">
      <label for="valor_do_desconto_nota_1" class="col-sm-4 col-form-label">*Valor do desconto (Nota 1) </label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="valor_do_desconto_nota_1" mask="separator.2" thousandSeparator="." [validation]="false">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('valor_do_desconto_nota_1')?.errors?.required && formulario.get('valor_do_desconto_nota_1')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('valor_do_desconto_nota_1')?.errors?.maxlength && formulario.get('valor_do_desconto_nota_1')?.touched"
          mensagem="A quantidade máxima de caracteres permitida é 1000"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="juros_aplicado_nota_1" class="col-sm-4 col-form-label">*Juros aplicado? (Nota 1)</label>
      <div class="col-sm-8">
        <label for="juros_aplicado_nota_1" class=' radio-inline'>
          <input type="radio" formControlName='juros_aplicado_nota_1' value="Sim" class='radio-btn' (change)="onJurosAplicadoChange1($event)"
            >&nbsp;Sim
        </label>
        <label for="juros_aplicado_nota_1" class='radio-inline'>
          <input type="radio" formControlName='juros_aplicado_nota_1' value='Não' class='radio-btn' (change)="onJurosAplicadoChange1($event)"
            >&nbsp;Não
        </label>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('juros_aplicado_nota_1')?.errors?.required && formulario.get('juros_aplicado_nota_1')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row" *ngIf="formulario.getRawValue().juros_aplicado_nota_1=='Sim'">
      <label for="valor_dos_juros_nota_1" class="col-sm-4 col-form-label">*Valor dos juros (Nota 1) </label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="valor_dos_juros_nota_1" mask="separator.2" thousandSeparator="." [validation]="false">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('valor_dos_juros_nota_1')?.errors?.required && formulario.get('valor_dos_juros_nota_1')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('valor_dos_juros_nota_1')?.errors?.maxlength && formulario.get('valor_dos_juros_nota_1')?.touched"
          mensagem="A quantidade máxima de caracteres permitida é 1000"></app-mensagem>
      </div>
    </div>

  </div>

   <!-- Quantidade = 2 -->

<div *ngIf="formulario.getRawValue().n_mero_de_notas_fiscais_a_serem_baixadas>=2">

  <div class="form-group row">
    <label for="n_mero_da_nota_fiscal_nota_2" class="col-sm-4 col-form-label">*Número da Nota Fiscal (Nota 2)</label>
    <div class="col-sm-8">
      <input type="text" class="form-control" formControlName="n_mero_da_nota_fiscal_nota_2" >
      <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('n_mero_da_nota_fiscal_nota_2')?.errors?.required && formulario.get('n_mero_da_nota_fiscal_nota_2')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('n_mero_da_nota_fiscal_nota_2')?.errors?.maxlength && formulario.get('n_mero_da_nota_fiscal_nota_2')?.touched"
          mensagem="A quantidade máxima de caracteres permitida é 1000"></app-mensagem>
    </div>
  </div>

    <div class="form-group row">
      <label for="baixar_valor_total_da_nota_fiscal_nota_2" class="col-sm-4 col-form-label">*Baixar Valor Total da Nota Fiscal? (Nota 2)</label>
      <div class="col-sm-8">
        <label for="baixar_valor_total_da_nota_fiscal_nota_2" class=' radio-inline'>
          <input type="radio" formControlName='baixar_valor_total_da_nota_fiscal_nota_2' value="Sim" class='radio-btn' (change)="onChangeBaixarValorTotalNota2($event)"
            >&nbsp;Sim
        </label>
        <label for="baixar_valor_total_da_nota_fiscal_nota_2" class='radio-inline'>
          <input type="radio" formControlName='baixar_valor_total_da_nota_fiscal_nota_2' value='Não' class='radio-btn' (change)="onChangeBaixarValorTotalNota2($event)"
            >&nbsp;Não
        </label>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('baixar_valor_total_da_nota_fiscal_nota_2')?.errors?.required && formulario.get('baixar_valor_total_da_nota_fiscal_nota_2')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>



    <div class="form-group row" *ngIf="formulario.getRawValue().baixar_valor_total_da_nota_fiscal_nota_2=='Não'">
      <label for="valor_parcial_a_ser_baixado_nota_2" class="col-sm-4 col-form-label">*Valor Parcial a ser baixado (Nota 2) </label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="valor_parcial_a_ser_baixado_nota_2" mask="separator.2" thousandSeparator="." [validation]="false">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('valor_parcial_a_ser_baixado_nota_2')?.errors?.required && formulario.get('valor_parcial_a_ser_baixado_nota_2')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('valor_parcial_a_ser_baixado_nota_2')?.errors?.maxlength && formulario.get('valor_parcial_a_ser_baixado_nota_2')?.touched"
          mensagem="A quantidade máxima de caracteres permitida é 1000"></app-mensagem>
      </div>
    </div>


    <div class="form-group row">
      <label for="desconto_concedido_nota_2" class="col-sm-4 col-form-label">*Desconto concedido? (Nota 2)</label>
      <div class="col-sm-8">
        <label for="desconto_concedido_nota_2" class=' radio-inline'>
          <input type="radio" formControlName='desconto_concedido_nota_2' value="Sim" class='radio-btn' (change)="onDescontoConcedidoChange2($event)"
            >&nbsp;Sim
        </label>
        <label for="desconto_concedido_nota_2" class='radio-inline'>
          <input type="radio" formControlName='desconto_concedido_nota_2' value='Não' class='radio-btn' (change)="onDescontoConcedidoChange2($event)"
            >&nbsp;Não
        </label>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('desconto_concedido_nota_2')?.errors?.required && formulario.get('desconto_concedido_nota_2')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row" *ngIf="formulario.getRawValue().desconto_concedido_nota_2=='Sim'">
      <label for="valor_do_desconto_nota_2" class="col-sm-4 col-form-label">*Valor do desconto (Nota 2) </label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="valor_do_desconto_nota_2" mask="separator.2" thousandSeparator="." [validation]="false">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('valor_do_desconto_nota_2')?.errors?.required && formulario.get('valor_do_desconto_nota_2')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('valor_do_desconto_nota_2')?.errors?.maxlength && formulario.get('valor_do_desconto_nota_2')?.touched"
          mensagem="A quantidade máxima de caracteres permitida é 1000"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="juros_aplicado_nota_2" class="col-sm-4 col-form-label">*Juros aplicado? (Nota 2)</label>
      <div class="col-sm-8">
        <label for="juros_aplicado_nota_2" class=' radio-inline'>
          <input type="radio" formControlName='juros_aplicado_nota_2' value="Sim" class='radio-btn' (change)="onJurosAplicadoChange2($event)"
            >&nbsp;Sim
        </label>
        <label for="juros_aplicado_nota_2" class='radio-inline'>
          <input type="radio" formControlName='juros_aplicado_nota_2' value='Não' class='radio-btn' (change)="onJurosAplicadoChange2($event)"
            >&nbsp;Não
        </label>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('juros_aplicado_nota_2')?.errors?.required && formulario.get('juros_aplicado_nota_2')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row" *ngIf="formulario.getRawValue().juros_aplicado_nota_2=='Sim'">
      <label for="valor_dos_juros_nota_2" class="col-sm-4 col-form-label">*Valor dos juros (Nota 2) </label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="valor_dos_juros_nota_2" mask="separator.2" thousandSeparator="." [validation]="false">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('valor_dos_juros_nota_2')?.errors?.required && formulario.get('valor_dos_juros_nota_2')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('valor_dos_juros_nota_2')?.errors?.maxlength && formulario.get('valor_dos_juros_nota_2')?.touched"
          mensagem="A quantidade máxima de caracteres permitida é 1000"></app-mensagem>
      </div>
    </div>

  </div>

   <!-- Quantidade = 3 -->

<div *ngIf="formulario.getRawValue().n_mero_de_notas_fiscais_a_serem_baixadas>=3">

  <div class="form-group row">
    <label for="n_mero_da_nota_fiscal_nota_3" class="col-sm-4 col-form-label">*Número da Nota Fiscal (Nota 3)</label>
    <div class="col-sm-8">
      <input type="text" class="form-control" formControlName="n_mero_da_nota_fiscal_nota_3" >
      <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('n_mero_da_nota_fiscal_nota_3')?.errors?.required && formulario.get('n_mero_da_nota_fiscal_nota_3')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('n_mero_da_nota_fiscal_nota_3')?.errors?.maxlength && formulario.get('n_mero_da_nota_fiscal_nota_3')?.touched"
          mensagem="A quantidade máxima de caracteres permitida é 1000"></app-mensagem>
    </div>
  </div>

    <div class="form-group row">
      <label for="baixar_valor_total_da_nota_fiscal_nota_3" class="col-sm-4 col-form-label">*Baixar Valor Total da Nota Fiscal? (Nota 3)</label>
      <div class="col-sm-8">
        <label for="baixar_valor_total_da_nota_fiscal_nota_3" class=' radio-inline'>
          <input type="radio" formControlName='baixar_valor_total_da_nota_fiscal_nota_3' value="Sim" class='radio-btn' (change)="onChangeBaixarValorTotalNota3($event)"
            >&nbsp;Sim
        </label>
        <label for="baixar_valor_total_da_nota_fiscal_nota_3" class='radio-inline'>
          <input type="radio" formControlName='baixar_valor_total_da_nota_fiscal_nota_3' value='Não' class='radio-btn' (change)="onChangeBaixarValorTotalNota3($event)"
            >&nbsp;Não
        </label>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('baixar_valor_total_da_nota_fiscal_nota_3')?.errors?.required && formulario.get('baixar_valor_total_da_nota_fiscal_nota_3')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>



    <div class="form-group row" *ngIf="formulario.getRawValue().baixar_valor_total_da_nota_fiscal_nota_3=='Não'">
      <label for="valor_parcial_a_ser_baixado_nota_3" class="col-sm-4 col-form-label">*Valor Parcial a ser baixado (Nota 3) </label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="valor_parcial_a_ser_baixado_nota_3" mask="separator.2" thousandSeparator="." [validation]="false">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('valor_parcial_a_ser_baixado_nota_3')?.errors?.required && formulario.get('valor_parcial_a_ser_baixado_nota_3')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('valor_parcial_a_ser_baixado_nota_3')?.errors?.maxlength && formulario.get('valor_parcial_a_ser_baixado_nota_3')?.touched"
          mensagem="A quantidade máxima de caracteres permitida é 1000"></app-mensagem>
      </div>
    </div>


    <div class="form-group row">
      <label for="desconto_concedido_nota_3" class="col-sm-4 col-form-label">*Desconto concedido? (Nota 3)</label>
      <div class="col-sm-8">
        <label for="desconto_concedido_nota_3" class=' radio-inline'>
          <input type="radio" formControlName='desconto_concedido_nota_3' value="Sim" class='radio-btn' (change)="onDescontoConcedidoChange3($event)"
            >&nbsp;Sim
        </label>
        <label for="desconto_concedido_nota_3" class='radio-inline'>
          <input type="radio" formControlName='desconto_concedido_nota_3' value='Não' class='radio-btn' (change)="onDescontoConcedidoChange3($event)"
            >&nbsp;Não
        </label>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('desconto_concedido_nota_3')?.errors?.required && formulario.get('desconto_concedido_nota_3')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row" *ngIf="formulario.getRawValue().desconto_concedido_nota_3=='Sim'">
      <label for="valor_do_desconto_nota_3" class="col-sm-4 col-form-label">*Valor do desconto (Nota 3) </label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="valor_do_desconto_nota_3" mask="separator.2" thousandSeparator="." [validation]="false">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('valor_do_desconto_nota_3')?.errors?.required && formulario.get('valor_do_desconto_nota_3')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('valor_do_desconto_nota_3')?.errors?.maxlength && formulario.get('valor_do_desconto_nota_3')?.touched"
          mensagem="A quantidade máxima de caracteres permitida é 1000"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="juros_aplicado_nota_3" class="col-sm-4 col-form-label">*Juros aplicado? (Nota 3)</label>
      <div class="col-sm-8">
        <label for="juros_aplicado_nota_3" class=' radio-inline'>
          <input type="radio" formControlName='juros_aplicado_nota_3' value="Sim" class='radio-btn' (change)="onJurosAplicadoChange3($event)"
            >&nbsp;Sim
        </label>
        <label for="juros_aplicado_nota_3" class='radio-inline'>
          <input type="radio" formControlName='juros_aplicado_nota_3' value='Não' class='radio-btn' (change)="onJurosAplicadoChange3($event)"
            >&nbsp;Não
        </label>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('juros_aplicado_nota_3')?.errors?.required && formulario.get('juros_aplicado_nota_3')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row" *ngIf="formulario.getRawValue().juros_aplicado_nota_3=='Sim'">
      <label for="valor_dos_juros_nota_3" class="col-sm-4 col-form-label">*Valor dos juros (Nota 3) </label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="valor_dos_juros_nota_3" mask="separator.2" thousandSeparator="." [validation]="false">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('valor_dos_juros_nota_3')?.errors?.required && formulario.get('valor_dos_juros_nota_3')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('valor_dos_juros_nota_3')?.errors?.maxlength && formulario.get('valor_dos_juros_nota_3')?.touched"
          mensagem="A quantidade máxima de caracteres permitida é 1000"></app-mensagem>
      </div>
    </div>

  </div>

   <!-- Quantidade = 4 -->

<div *ngIf="formulario.getRawValue().n_mero_de_notas_fiscais_a_serem_baixadas>=4">

  <div class="form-group row">
    <label for="n_mero_da_nota_fiscal_nota_4" class="col-sm-4 col-form-label">*Número da Nota Fiscal (Nota 4)</label>
    <div class="col-sm-8">
      <input type="text" class="form-control" formControlName="n_mero_da_nota_fiscal_nota_4" >
      <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('n_mero_da_nota_fiscal_nota_4')?.errors?.required && formulario.get('n_mero_da_nota_fiscal_nota_4')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('n_mero_da_nota_fiscal_nota_4')?.errors?.maxlength && formulario.get('n_mero_da_nota_fiscal_nota_4')?.touched"
          mensagem="A quantidade máxima de caracteres permitida é 1000"></app-mensagem>

    </div>
  </div>

    <div class="form-group row">
      <label for="baixar_valor_total_da_nota_fiscal_nota_4" class="col-sm-4 col-form-label">*Baixar Valor Total da Nota Fiscal? (Nota 4)</label>
      <div class="col-sm-8">
        <label for="baixar_valor_total_da_nota_fiscal_nota_4" class=' radio-inline'>
          <input type="radio" formControlName='baixar_valor_total_da_nota_fiscal_nota_4' value="Sim" class='radio-btn' (change)="onChangeBaixarValorTotalNota4($event)"
            >&nbsp;Sim
        </label>
        <label for="baixar_valor_total_da_nota_fiscal_nota_4" class='radio-inline'>
          <input type="radio" formControlName='baixar_valor_total_da_nota_fiscal_nota_4' value='Não' class='radio-btn' (change)="onChangeBaixarValorTotalNota4($event)"
            >&nbsp;Não
        </label>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('baixar_valor_total_da_nota_fiscal_nota_4')?.errors?.required && formulario.get('baixar_valor_total_da_nota_fiscal_nota_4')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>



    <div class="form-group row" *ngIf="formulario.getRawValue().baixar_valor_total_da_nota_fiscal_nota_4=='Não'">
      <label for="valor_parcial_a_ser_baixado_nota_4" class="col-sm-4 col-form-label">*Valor Parcial a ser baixado (Nota 4) </label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="valor_parcial_a_ser_baixado_nota_4" mask="separator.2" thousandSeparator="." [validation]="false">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('valor_parcial_a_ser_baixado_nota_4')?.errors?.required && formulario.get('valor_parcial_a_ser_baixado_nota_4')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('valor_parcial_a_ser_baixado_nota_4')?.errors?.maxlength && formulario.get('valor_parcial_a_ser_baixado_nota_4')?.touched"
          mensagem="A quantidade máxima de caracteres permitida é 1000"></app-mensagem>
      </div>
    </div>


    <div class="form-group row">
      <label for="desconto_concedido_nota_4" class="col-sm-4 col-form-label">*Desconto concedido? (Nota 4)</label>
      <div class="col-sm-8">
        <label for="desconto_concedido_nota_4" class=' radio-inline'>
          <input type="radio" formControlName='desconto_concedido_nota_4' value="Sim" class='radio-btn' (change)="onDescontoConcedidoChange4($event)"
            >&nbsp;Sim
        </label>
        <label for="desconto_concedido_nota_4" class='radio-inline'>
          <input type="radio" formControlName='desconto_concedido_nota_4' value='Não' class='radio-btn'(change)="onDescontoConcedidoChange4($event)"
            >&nbsp;Não
        </label>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('desconto_concedido_nota_4')?.errors?.required && formulario.get('desconto_concedido_nota_4')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row" *ngIf="formulario.getRawValue().desconto_concedido_nota_4=='Sim'">
      <label for="valor_do_desconto_nota_4" class="col-sm-4 col-form-label">*Valor do desconto (Nota 4) </label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="valor_do_desconto_nota_4" mask="separator.2" thousandSeparator="." [validation]="false">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('valor_do_desconto_nota_4')?.errors?.required && formulario.get('valor_do_desconto_nota_4')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('valor_do_desconto_nota_4')?.errors?.maxlength && formulario.get('valor_do_desconto_nota_4')?.touched"
          mensagem="A quantidade máxima de caracteres permitida é 1000"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="juros_aplicado_nota_4" class="col-sm-4 col-form-label">*Juros aplicado? (Nota 4)</label>
      <div class="col-sm-8">
        <label for="juros_aplicado_nota_4" class=' radio-inline'>
          <input type="radio" formControlName='juros_aplicado_nota_4' value="Sim" class='radio-btn' (change)="onJurosAplicadoChange4($event)"
            >&nbsp;Sim
        </label>
        <label for="juros_aplicado_nota_4" class='radio-inline'>
          <input type="radio" formControlName='juros_aplicado_nota_4' value='Não' class='radio-btn' (change)="onJurosAplicadoChange4($event)"
            >&nbsp;Não
        </label>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('juros_aplicado_nota_4')?.errors?.required && formulario.get('juros_aplicado_nota_4')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row" *ngIf="formulario.getRawValue().juros_aplicado_nota_4=='Sim'">
      <label for="valor_dos_juros_nota_4" class="col-sm-4 col-form-label">*Valor dos juros (Nota 4) </label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="valor_dos_juros_nota_4" mask="separator.2" thousandSeparator="." [validation]="false">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('valor_dos_juros_nota_4')?.errors?.required && formulario.get('valor_dos_juros_nota_4')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('valor_dos_juros_nota_4')?.errors?.maxlength && formulario.get('valor_dos_juros_nota_4')?.touched"
          mensagem="A quantidade máxima de caracteres permitida é 1000"></app-mensagem>
      </div>
    </div>

  </div>

   <!-- Quantidade = 5 -->

<div *ngIf="formulario.getRawValue().n_mero_de_notas_fiscais_a_serem_baixadas>=5">

  <div class="form-group row">
    <label for="n_mero_da_nota_fiscal_nota_5" class="col-sm-4 col-form-label">*Número da Nota Fiscal (Nota 5)</label>
    <div class="col-sm-8">
      <input type="text" class="form-control" formControlName="n_mero_da_nota_fiscal_nota_5" >
      <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('n_mero_da_nota_fiscal_nota_5')?.errors?.required && formulario.get('n_mero_da_nota_fiscal_nota_5')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('n_mero_da_nota_fiscal_nota_5')?.errors?.maxlength && formulario.get('n_mero_da_nota_fiscal_nota_5')?.touched"
          mensagem="A quantidade máxima de caracteres permitida é 1000"></app-mensagem>
    </div>
  </div>

    <div class="form-group row">
      <label for="baixar_valor_total_da_nota_fiscal_nota_5" class="col-sm-4 col-form-label">*Baixar Valor Total da Nota Fiscal? (Nota 5)</label>
      <div class="col-sm-8">
        <label for="baixar_valor_total_da_nota_fiscal_nota_5" class=' radio-inline'>
          <input type="radio" formControlName='baixar_valor_total_da_nota_fiscal_nota_5' value="Sim" class='radio-btn' (change)="onChangeBaixarValorTotalNota5($event)"
            >&nbsp;Sim
        </label>
        <label for="baixar_valor_total_da_nota_fiscal_nota_5" class='radio-inline'>
          <input type="radio" formControlName='baixar_valor_total_da_nota_fiscal_nota_5' value='Não' class='radio-btn' (change)="onChangeBaixarValorTotalNota5($event)"
            >&nbsp;Não
        </label>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('baixar_valor_total_da_nota_fiscal_nota_5')?.errors?.required && formulario.get('baixar_valor_total_da_nota_fiscal_nota_5')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>



    <div class="form-group row" *ngIf="formulario.getRawValue().baixar_valor_total_da_nota_fiscal_nota_5=='Não'">
      <label for="valor_parcial_a_ser_baixado_nota_5" class="col-sm-4 col-form-label">*Valor Parcial a ser baixado (Nota 5) </label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="valor_parcial_a_ser_baixado_nota_5" mask="separator.2" thousandSeparator="." [validation]="false">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('valor_parcial_a_ser_baixado_nota_5')?.errors?.required && formulario.get('valor_parcial_a_ser_baixado_nota_5')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('valor_parcial_a_ser_baixado_nota_5')?.errors?.maxlength && formulario.get('valor_parcial_a_ser_baixado_nota_5')?.touched"
          mensagem="A quantidade máxima de caracteres permitida é 1000"></app-mensagem>
      </div>
    </div>


    <div class="form-group row">
      <label for="desconto_concedido_nota_5" class="col-sm-4 col-form-label">*Desconto concedido? (Nota 5)</label>
      <div class="col-sm-8">
        <label for="desconto_concedido_nota_5" class=' radio-inline'>
          <input type="radio" formControlName='desconto_concedido_nota_5' value="Sim" class='radio-btn' (change)="onDescontoConcedidoChange5($event)"
            >&nbsp;Sim
        </label>
        <label for="desconto_concedido_nota_5" class='radio-inline'>
          <input type="radio" formControlName='desconto_concedido_nota_5' value='Não' class='radio-btn' (change)="onDescontoConcedidoChange5($event)"
            >&nbsp;Não
        </label>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('desconto_concedido_nota_5')?.errors?.required && formulario.get('desconto_concedido_nota_5')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row" *ngIf="formulario.getRawValue().desconto_concedido_nota_5=='Sim'">
      <label for="valor_do_desconto_nota_5" class="col-sm-4 col-form-label">*Valor do desconto (Nota 5) </label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="valor_do_desconto_nota_5" mask="separator.2" thousandSeparator="." [validation]="false">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('valor_do_desconto_nota_5')?.errors?.required && formulario.get('valor_do_desconto_nota_5')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('valor_do_desconto_nota_5')?.errors?.maxlength && formulario.get('valor_do_desconto_nota_5')?.touched"
          mensagem="A quantidade máxima de caracteres permitida é 1000"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="juros_aplicado_nota_5" class="col-sm-4 col-form-label">*Juros aplicado? (Nota 5)</label>
      <div class="col-sm-8">
        <label for="juros_aplicado_nota_5" class=' radio-inline'>
          <input type="radio" formControlName='juros_aplicado_nota_5' value="Sim" class='radio-btn' (change)="onJurosAplicadoChange5($event)"
            >&nbsp;Sim
        </label>
        <label for="juros_aplicado_nota_5" class='radio-inline'>
          <input type="radio" formControlName='juros_aplicado_nota_5' value='Não' class='radio-btn' (change)="onJurosAplicadoChange5($event)"
            >&nbsp;Não
        </label>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('juros_aplicado_nota_5')?.errors?.required && formulario.get('juros_aplicado_nota_5')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row" *ngIf="formulario.getRawValue().juros_aplicado_nota_5=='Sim'">
      <label for="valor_dos_juros_nota_5" class="col-sm-4 col-form-label">*Valor dos juros (Nota 5) </label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="valor_dos_juros_nota_5" mask="separator.2" thousandSeparator="." [validation]="false">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('valor_dos_juros_nota_5')?.errors?.required && formulario.get('valor_dos_juros_nota_5')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('valor_dos_juros_nota_5')?.errors?.maxlength && formulario.get('valor_dos_juros_nota_5')?.touched"
          mensagem="A quantidade máxima de caracteres permitida é 1000"></app-mensagem>
      </div>
    </div>

  </div>

   <!-- Quantidade = 6 -->

<div *ngIf="formulario.getRawValue().n_mero_de_notas_fiscais_a_serem_baixadas>=6">

  <div class="form-group row">
    <label for="n_mero_da_nota_fiscal_nota_6" class="col-sm-4 col-form-label">*Número da Nota Fiscal (Nota 6)</label>
    <div class="col-sm-8">
      <input type="text" class="form-control" formControlName="n_mero_da_nota_fiscal_nota_6" >
      <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('n_mero_da_nota_fiscal_nota_6')?.errors?.required && formulario.get('n_mero_da_nota_fiscal_nota_6')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('n_mero_da_nota_fiscal_nota_6')?.errors?.maxlength && formulario.get('n_mero_da_nota_fiscal_nota_6')?.touched"
          mensagem="A quantidade máxima de caracteres permitida é 1000"></app-mensagem>
    </div>
  </div>

    <div class="form-group row">
      <label for="baixar_valor_total_da_nota_fiscal_nota_6" class="col-sm-4 col-form-label">*Baixar Valor Total da Nota Fiscal? (Nota 6)</label>
      <div class="col-sm-8">
        <label for="baixar_valor_total_da_nota_fiscal_nota_6" class=' radio-inline'>
          <input type="radio" formControlName='baixar_valor_total_da_nota_fiscal_nota_6' value="Sim" class='radio-btn' (change)="onChangeBaixarValorTotalNota6($event)"
            >&nbsp;Sim
        </label>
        <label for="baixar_valor_total_da_nota_fiscal_nota_6" class='radio-inline'>
          <input type="radio" formControlName='baixar_valor_total_da_nota_fiscal_nota_6' value='Não' class='radio-btn' (change)="onChangeBaixarValorTotalNota6($event)"
            >&nbsp;Não
        </label>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('baixar_valor_total_da_nota_fiscal_nota_6')?.errors?.required && formulario.get('baixar_valor_total_da_nota_fiscal_nota_6')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>



    <div class="form-group row" *ngIf="formulario.getRawValue().baixar_valor_total_da_nota_fiscal_nota_6=='Não'">
      <label for="valor_parcial_a_ser_baixado_nota_6" class="col-sm-4 col-form-label">*Valor Parcial a ser baixado (Nota 6) </label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="valor_parcial_a_ser_baixado_nota_6" mask="separator.2" thousandSeparator="." [validation]="false">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('valor_parcial_a_ser_baixado_nota_6')?.errors?.required && formulario.get('valor_parcial_a_ser_baixado_nota_6')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('valor_parcial_a_ser_baixado_nota_6')?.errors?.maxlength && formulario.get('valor_parcial_a_ser_baixado_nota_6')?.touched"
          mensagem="A quantidade máxima de caracteres permitida é 1000"></app-mensagem>
      </div>
    </div>


    <div class="form-group row">
      <label for="desconto_concedido_nota_6" class="col-sm-4 col-form-label">*Desconto concedido? (Nota 6)</label>
      <div class="col-sm-8">
        <label for="desconto_concedido_nota_6" class=' radio-inline'>
          <input type="radio" formControlName='desconto_concedido_nota_6' value="Sim" class='radio-btn' (change)="onDescontoConcedidoChange6($event)"
            >&nbsp;Sim
        </label>
        <label for="desconto_concedido_nota_6" class='radio-inline'>
          <input type="radio" formControlName='desconto_concedido_nota_6' value='Não' class='radio-btn' (change)="onDescontoConcedidoChange6($event)"
            >&nbsp;Não
        </label>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('desconto_concedido_nota_6')?.errors?.required && formulario.get('desconto_concedido_nota_6')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row" *ngIf="formulario.getRawValue().desconto_concedido_nota_6=='Sim'">
      <label for="valor_do_desconto_nota_6" class="col-sm-4 col-form-label">*Valor do desconto (Nota 6) </label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="valor_do_desconto_nota_6" mask="separator.2" thousandSeparator="." [validation]="false">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('valor_do_desconto_nota_6')?.errors?.required && formulario.get('valor_do_desconto_nota_6')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('valor_do_desconto_nota_6')?.errors?.maxlength && formulario.get('valor_do_desconto_nota_6')?.touched"
          mensagem="A quantidade máxima de caracteres permitida é 1000"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="juros_aplicado_nota_6" class="col-sm-4 col-form-label">*Juros aplicado? (Nota 6)</label>
      <div class="col-sm-8">
        <label for="juros_aplicado_nota_6" class=' radio-inline'>
          <input type="radio" formControlName='juros_aplicado_nota_6' value="Sim" class='radio-btn' (change)="onJurosAplicadoChange6($event)"
            >&nbsp;Sim
        </label>
        <label for="juros_aplicado_nota_6" class='radio-inline'>
          <input type="radio" formControlName='juros_aplicado_nota_6' value='Não' class='radio-btn' (change)="onJurosAplicadoChange6($event)"
            >&nbsp;Não
        </label>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('juros_aplicado_nota_6')?.errors?.required && formulario.get('juros_aplicado_nota_6')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row" *ngIf="formulario.getRawValue().juros_aplicado_nota_6=='Sim'">
      <label for="valor_dos_juros_nota_6" class="col-sm-4 col-form-label">*Valor dos juros (Nota 6) </label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="valor_dos_juros_nota_6" mask="separator.2" thousandSeparator="." [validation]="false">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('valor_dos_juros_nota_6')?.errors?.required && formulario.get('valor_dos_juros_nota_6')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('valor_dos_juros_nota_6')?.errors?.maxlength && formulario.get('valor_dos_juros_nota_6')?.touched"
          mensagem="A quantidade máxima de caracteres permitida é 1000"></app-mensagem>
      </div>
    </div>

  </div>

   <!-- Quantidade = 7 -->

<div *ngIf="formulario.getRawValue().n_mero_de_notas_fiscais_a_serem_baixadas>=7">

  <div class="form-group row">
    <label for="n_mero_da_nota_fiscal_nota_7" class="col-sm-4 col-form-label">*Número da Nota Fiscal (Nota 7)</label>
    <div class="col-sm-8">
      <input type="text" class="form-control" formControlName="n_mero_da_nota_fiscal_nota_7" >
      <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('n_mero_da_nota_fiscal_nota_7')?.errors?.required && formulario.get('n_mero_da_nota_fiscal_nota_7')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('n_mero_da_nota_fiscal_nota_7')?.errors?.maxlength && formulario.get('n_mero_da_nota_fiscal_nota_7')?.touched"
          mensagem="A quantidade máxima de caracteres permitida é 1000"></app-mensagem>
    </div>
  </div>

    <div class="form-group row">
      <label for="baixar_valor_total_da_nota_fiscal_nota_7" class="col-sm-4 col-form-label">*Baixar Valor Total da Nota Fiscal? (Nota 7)</label>
      <div class="col-sm-8">
        <label for="baixar_valor_total_da_nota_fiscal_nota_7" class=' radio-inline'>
          <input type="radio" formControlName='baixar_valor_total_da_nota_fiscal_nota_7' value="Sim" class='radio-btn' (change)="onChangeBaixarValorTotalNota7($event)"
            >&nbsp;Sim
        </label>
        <label for="baixar_valor_total_da_nota_fiscal_nota_7" class='radio-inline'>
          <input type="radio" formControlName='baixar_valor_total_da_nota_fiscal_nota_7' value='Não' class='radio-btn' (change)="onChangeBaixarValorTotalNota7($event)"
            >&nbsp;Não
        </label>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('baixar_valor_total_da_nota_fiscal_nota_7')?.errors?.required && formulario.get('baixar_valor_total_da_nota_fiscal_nota_7')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>



    <div class="form-group row" *ngIf="formulario.getRawValue().baixar_valor_total_da_nota_fiscal_nota_7=='Não'">
      <label for="valor_parcial_a_ser_baixado_nota_7" class="col-sm-4 col-form-label">*Valor Parcial a ser baixado (Nota 7) </label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="valor_parcial_a_ser_baixado_nota_7" mask="separator.2" thousandSeparator="." [validation]="false">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('valor_parcial_a_ser_baixado_nota_7')?.errors?.required && formulario.get('valor_parcial_a_ser_baixado_nota_7')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('valor_parcial_a_ser_baixado_nota_7')?.errors?.maxlength && formulario.get('valor_parcial_a_ser_baixado_nota_7')?.touched"
          mensagem="A quantidade máxima de caracteres permitida é 1000"></app-mensagem>
      </div>
    </div>


    <div class="form-group row">
      <label for="desconto_concedido_nota_7" class="col-sm-4 col-form-label">*Desconto concedido? (Nota 7)</label>
      <div class="col-sm-8">
        <label for="desconto_concedido_nota_7" class=' radio-inline'>
          <input type="radio" formControlName='desconto_concedido_nota_7' value="Sim" class='radio-btn' (change)="onDescontoConcedidoChange7($event)"
            >&nbsp;Sim
        </label>
        <label for="desconto_concedido_nota_7" class='radio-inline'>
          <input type="radio" formControlName='desconto_concedido_nota_7' value='Não' class='radio-btn' (change)="onDescontoConcedidoChange7($event)"
            >&nbsp;Não
        </label>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('desconto_concedido_nota_7')?.errors?.required && formulario.get('desconto_concedido_nota_7')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row" *ngIf="formulario.getRawValue().desconto_concedido_nota_7=='Sim'">
      <label for="valor_do_desconto_nota_7" class="col-sm-4 col-form-label">*Valor do desconto (Nota 7) </label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="valor_do_desconto_nota_7" mask="separator.2" thousandSeparator="." [validation]="false">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('valor_do_desconto_nota_7')?.errors?.required && formulario.get('valor_do_desconto_nota_7')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('valor_do_desconto_nota_7')?.errors?.maxlength && formulario.get('valor_do_desconto_nota_7')?.touched"
          mensagem="A quantidade máxima de caracteres permitida é 1000"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="juros_aplicado_nota_7" class="col-sm-4 col-form-label">*Juros aplicado? (Nota 7)</label>
      <div class="col-sm-8">
        <label for="juros_aplicado_nota_7" class=' radio-inline'>
          <input type="radio" formControlName='juros_aplicado_nota_7' value="Sim" class='radio-btn' (change)="onJurosAplicadoChange7($event)"
            >&nbsp;Sim
        </label>
        <label for="juros_aplicado_nota_7" class='radio-inline'>
          <input type="radio" formControlName='juros_aplicado_nota_7' value='Não' class='radio-btn' (change)="onJurosAplicadoChange7($event)"
            >&nbsp;Não
        </label>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('juros_aplicado_nota_7')?.errors?.required && formulario.get('juros_aplicado_nota_7')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row" *ngIf="formulario.getRawValue().juros_aplicado_nota_7=='Sim'">
      <label for="valor_dos_juros_nota_7" class="col-sm-4 col-form-label">*Valor dos juros (Nota 7) </label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="valor_dos_juros_nota_7" mask="separator.2" thousandSeparator="." [validation]="false">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('valor_dos_juros_nota_7')?.errors?.required && formulario.get('valor_dos_juros_nota_7')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('valor_dos_juros_nota_7')?.errors?.maxlength && formulario.get('valor_dos_juros_nota_7')?.touched"
          mensagem="A quantidade máxima de caracteres permitida é 1000"></app-mensagem>
      </div>
    </div>

  </div>

   <!-- Quantidade = 8 -->

<div *ngIf="formulario.getRawValue().n_mero_de_notas_fiscais_a_serem_baixadas>=8">

  <div class="form-group row">
    <label for="n_mero_da_nota_fiscal_nota_8" class="col-sm-4 col-form-label">*Número da Nota Fiscal (Nota 8)</label>
    <div class="col-sm-8">
      <input type="text" class="form-control" formControlName="n_mero_da_nota_fiscal_nota_8" >
      <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('n_mero_da_nota_fiscal_nota_8')?.errors?.required && formulario.get('n_mero_da_nota_fiscal_nota_8')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('n_mero_da_nota_fiscal_nota_8')?.errors?.maxlength && formulario.get('n_mero_da_nota_fiscal_nota_8')?.touched"
          mensagem="A quantidade máxima de caracteres permitida é 1000"></app-mensagem>
    </div>
  </div>

    <div class="form-group row">
      <label for="baixar_valor_total_da_nota_fiscal_nota_8" class="col-sm-4 col-form-label">*Baixar Valor Total da Nota Fiscal? (Nota 8)</label>
      <div class="col-sm-8">
        <label for="baixar_valor_total_da_nota_fiscal_nota_8" class=' radio-inline'>
          <input type="radio" formControlName='baixar_valor_total_da_nota_fiscal_nota_8' value="Sim" class='radio-btn' (change)="onChangeBaixarValorTotalNota8($event)"
            >&nbsp;Sim
        </label>
        <label for="baixar_valor_total_da_nota_fiscal_nota_8" class='radio-inline'>
          <input type="radio" formControlName='baixar_valor_total_da_nota_fiscal_nota_8' value='Não' class='radio-btn' (change)="onChangeBaixarValorTotalNota8($event)"
            >&nbsp;Não
        </label>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('baixar_valor_total_da_nota_fiscal_nota_8')?.errors?.required && formulario.get('baixar_valor_total_da_nota_fiscal_nota_8')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>



    <div class="form-group row" *ngIf="formulario.getRawValue().baixar_valor_total_da_nota_fiscal_nota_8=='Não'">
      <label for="valor_parcial_a_ser_baixado_nota_8" class="col-sm-4 col-form-label">*Valor Parcial a ser baixado (Nota 8) </label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="valor_parcial_a_ser_baixado_nota_8" mask="separator.2" thousandSeparator="." [validation]="false">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('valor_parcial_a_ser_baixado_nota_8')?.errors?.required && formulario.get('valor_parcial_a_ser_baixado_nota_8')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('valor_parcial_a_ser_baixado_nota_8')?.errors?.maxlength && formulario.get('valor_parcial_a_ser_baixado_nota_8')?.touched"
          mensagem="A quantidade máxima de caracteres permitida é 1000"></app-mensagem>
      </div>
    </div>


    <div class="form-group row">
      <label for="desconto_concedido_nota_8" class="col-sm-4 col-form-label">*Desconto concedido? (Nota 8)</label>
      <div class="col-sm-8">
        <label for="desconto_concedido_nota_8" class=' radio-inline'>
          <input type="radio" formControlName='desconto_concedido_nota_8' value="Sim" class='radio-btn' (change)="onDescontoConcedidoChange8($event)"
            >&nbsp;Sim
        </label>
        <label for="desconto_concedido_nota_8" class='radio-inline'>
          <input type="radio" formControlName='desconto_concedido_nota_8' value='Não' class='radio-btn' (change)="onDescontoConcedidoChange8($event)"
            >&nbsp;Não
        </label>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('desconto_concedido_nota_8')?.errors?.required && formulario.get('desconto_concedido_nota_8')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row" *ngIf="formulario.getRawValue().desconto_concedido_nota_8=='Sim'">
      <label for="valor_do_desconto_nota_8" class="col-sm-4 col-form-label">*Valor do desconto (Nota 8) </label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="valor_do_desconto_nota_8" mask="separator.2" thousandSeparator="." [validation]="false">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('valor_do_desconto_nota_8')?.errors?.required && formulario.get('valor_do_desconto_nota_8')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('valor_do_desconto_nota_8')?.errors?.maxlength && formulario.get('valor_do_desconto_nota_8')?.touched"
          mensagem="A quantidade máxima de caracteres permitida é 1000"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="juros_aplicado_nota_8" class="col-sm-4 col-form-label">*Juros aplicado? (Nota 8)</label>
      <div class="col-sm-8">
        <label for="juros_aplicado_nota_8" class=' radio-inline'>
          <input type="radio" formControlName='juros_aplicado_nota_8' value="Sim" class='radio-btn' (change)="onJurosAplicadoChange8($event)"
            >&nbsp;Sim
        </label>
        <label for="juros_aplicado_nota_8" class='radio-inline'>
          <input type="radio" formControlName='juros_aplicado_nota_8' value='Não' class='radio-btn' (change)="onJurosAplicadoChange8($event)"
            >&nbsp;Não
        </label>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('juros_aplicado_nota_8')?.errors?.required && formulario.get('juros_aplicado_nota_8')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row" *ngIf="formulario.getRawValue().juros_aplicado_nota_8=='Sim'">
      <label for="valor_dos_juros_nota_8" class="col-sm-4 col-form-label">*Valor dos juros (Nota 8) </label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="valor_dos_juros_nota_8" mask="separator.2" thousandSeparator="." [validation]="false">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('valor_dos_juros_nota_8')?.errors?.required && formulario.get('valor_dos_juros_nota_8')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('valor_dos_juros_nota_8')?.errors?.maxlength && formulario.get('valor_dos_juros_nota_8')?.touched"
          mensagem="A quantidade máxima de caracteres permitida é 1000"></app-mensagem>
      </div>
    </div>

  </div>

   <!-- Quantidade = 9 -->

<div *ngIf="formulario.getRawValue().n_mero_de_notas_fiscais_a_serem_baixadas>=9">

  <div class="form-group row">
    <label for="n_mero_da_nota_fiscal_nota_9" class="col-sm-4 col-form-label">*Número da Nota Fiscal (Nota 9)</label>
    <div class="col-sm-8">
      <input type="text" class="form-control" formControlName="n_mero_da_nota_fiscal_nota_9" >
      <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('n_mero_da_nota_fiscal_nota_9')?.errors?.required && formulario.get('n_mero_da_nota_fiscal_nota_9')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('n_mero_da_nota_fiscal_nota_9')?.errors?.maxlength && formulario.get('n_mero_da_nota_fiscal_nota_9')?.touched"
          mensagem="A quantidade máxima de caracteres permitida é 1000"></app-mensagem>
    </div>
  </div>

    <div class="form-group row">
      <label for="baixar_valor_total_da_nota_fiscal_nota_9" class="col-sm-4 col-form-label">*Baixar Valor Total da Nota Fiscal? (Nota 9)</label>
      <div class="col-sm-8">
        <label for="baixar_valor_total_da_nota_fiscal_nota_9" class=' radio-inline'>
          <input type="radio" formControlName='baixar_valor_total_da_nota_fiscal_nota_9' value="Sim" class='radio-btn' (change)="onChangeBaixarValorTotalNota9($event)"
            >&nbsp;Sim
        </label>
        <label for="baixar_valor_total_da_nota_fiscal_nota_9" class='radio-inline'>
          <input type="radio" formControlName='baixar_valor_total_da_nota_fiscal_nota_9' value='Não' class='radio-btn' (change)="onChangeBaixarValorTotalNota9($event)"
            >&nbsp;Não
        </label>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('baixar_valor_total_da_nota_fiscal_nota_9')?.errors?.required && formulario.get('baixar_valor_total_da_nota_fiscal_nota_9')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>



    <div class="form-group row" *ngIf="formulario.getRawValue().baixar_valor_total_da_nota_fiscal_nota_9=='Não'">
      <label for="valor_parcial_a_ser_baixado_nota_9" class="col-sm-4 col-form-label">*Valor Parcial a ser baixado (Nota 9) </label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="valor_parcial_a_ser_baixado_nota_9" mask="separator.2" thousandSeparator="." [validation]="false">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('valor_parcial_a_ser_baixado_nota_9')?.errors?.required && formulario.get('valor_parcial_a_ser_baixado_nota_9')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('valor_parcial_a_ser_baixado_nota_9')?.errors?.maxlength && formulario.get('valor_parcial_a_ser_baixado_nota_9')?.touched"
          mensagem="A quantidade máxima de caracteres permitida é 1000"></app-mensagem>
      </div>
    </div>


    <div class="form-group row">
      <label for="desconto_concedido_nota_9" class="col-sm-4 col-form-label">*Desconto concedido? (Nota 9)</label>
      <div class="col-sm-8">
        <label for="desconto_concedido_nota_9" class=' radio-inline'>
          <input type="radio" formControlName='desconto_concedido_nota_9' value="Sim" class='radio-btn' (change)="onDescontoConcedidoChange9($event)"
            >&nbsp;Sim
        </label>
        <label for="desconto_concedido_nota_9" class='radio-inline'>
          <input type="radio" formControlName='desconto_concedido_nota_9' value='Não' class='radio-btn' (change)="onDescontoConcedidoChange9($event)"
            >&nbsp;Não
        </label>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('desconto_concedido_nota_9')?.errors?.required && formulario.get('desconto_concedido_nota_9')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row" *ngIf="formulario.getRawValue().desconto_concedido_nota_9=='Sim'">
      <label for="valor_do_desconto_nota_9" class="col-sm-4 col-form-label">*Valor do desconto (Nota 9) </label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="valor_do_desconto_nota_9" mask="separator.2" thousandSeparator="." [validation]="false">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('valor_do_desconto_nota_9')?.errors?.required && formulario.get('valor_do_desconto_nota_9')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('valor_do_desconto_nota_9')?.errors?.maxlength && formulario.get('valor_do_desconto_nota_9')?.touched"
          mensagem="A quantidade máxima de caracteres permitida é 1000"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="juros_aplicado_nota_9" class="col-sm-4 col-form-label">*Juros aplicado? (Nota 9)</label>
      <div class="col-sm-8">
        <label for="juros_aplicado_nota_9" class=' radio-inline'>
          <input type="radio" formControlName='juros_aplicado_nota_9' value="Sim" class='radio-btn' (change)="onJurosAplicadoChange9($event)"
            >&nbsp;Sim
        </label>
        <label for="juros_aplicado_nota_9" class='radio-inline'>
          <input type="radio" formControlName='juros_aplicado_nota_9' value='Não' class='radio-btn' (change)="onJurosAplicadoChange9($event)"
            >&nbsp;Não
        </label>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('juros_aplicado_nota_9')?.errors?.required && formulario.get('juros_aplicado_nota_9')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row" *ngIf="formulario.getRawValue().juros_aplicado_nota_9=='Sim'">
      <label for="valor_dos_juros_nota_9" class="col-sm-4 col-form-label">*Valor dos juros (Nota 9) </label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="valor_dos_juros_nota_9" mask="separator.2" thousandSeparator="." [validation]="false">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('valor_dos_juros_nota_9')?.errors?.required && formulario.get('valor_dos_juros_nota_9')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('valor_dos_juros_nota_9')?.errors?.maxlength && formulario.get('valor_dos_juros_nota_9')?.touched"
          mensagem="A quantidade máxima de caracteres permitida é 1000"></app-mensagem>
      </div>
    </div>

  </div>

   <!-- Quantidade = 10 -->

<div *ngIf="formulario.getRawValue().n_mero_de_notas_fiscais_a_serem_baixadas>=10">

  <div class="form-group row">
    <label for="n_mero_da_nota_fiscal_nota_10" class="col-sm-4 col-form-label">*Número da Nota Fiscal (Nota 10)</label>
    <div class="col-sm-8">
      <input type="text" class="form-control" formControlName="n_mero_da_nota_fiscal_nota_10" >
      <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('n_mero_da_nota_fiscal_nota_10')?.errors?.required && formulario.get('n_mero_da_nota_fiscal_nota_10')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('n_mero_da_nota_fiscal_nota_10')?.errors?.maxlength && formulario.get('n_mero_da_nota_fiscal_nota_10')?.touched"
          mensagem="A quantidade máxima de caracteres permitida é 1000"></app-mensagem>
    </div>
  </div>

    <div class="form-group row">
      <label for="baixar_valor_total_da_nota_fiscal_nota_10" class="col-sm-4 col-form-label">*Baixar Valor Total da Nota Fiscal? (Nota 10)</label>
      <div class="col-sm-8">
        <label for="baixar_valor_total_da_nota_fiscal_nota_10" class=' radio-inline'>
          <input type="radio" formControlName='baixar_valor_total_da_nota_fiscal_nota_10' value="Sim" class='radio-btn' (change)="onChangeBaixarValorTotalNota10($event)"
            >&nbsp;Sim
        </label>
        <label for="baixar_valor_total_da_nota_fiscal_nota_10" class='radio-inline'>
          <input type="radio" formControlName='baixar_valor_total_da_nota_fiscal_nota_10' value='Não' class='radio-btn' (change)="onChangeBaixarValorTotalNota10($event)"
            >&nbsp;Não
        </label>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('baixar_valor_total_da_nota_fiscal_nota_10')?.errors?.required && formulario.get('baixar_valor_total_da_nota_fiscal_nota_10')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>



    <div class="form-group row" *ngIf="formulario.getRawValue().baixar_valor_total_da_nota_fiscal_nota_10=='Não'">
      <label for="valor_parcial_a_ser_baixado_nota_10" class="col-sm-4 col-form-label">*Valor Parcial a ser baixado (Nota 10) </label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="valor_parcial_a_ser_baixado_nota_10" mask="separator.2" thousandSeparator="." [validation]="false">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('valor_parcial_a_ser_baixado_nota_10')?.errors?.required && formulario.get('valor_parcial_a_ser_baixado_nota_10')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('valor_parcial_a_ser_baixado_nota_10')?.errors?.maxlength && formulario.get('valor_parcial_a_ser_baixado_nota_10')?.touched"
          mensagem="A quantidade máxima de caracteres permitida é 1000"></app-mensagem>
      </div>
    </div>


    <div class="form-group row">
      <label for="desconto_concedido_nota_10" class="col-sm-4 col-form-label">*Desconto concedido? (Nota 10)</label>
      <div class="col-sm-8">
        <label for="desconto_concedido_nota_10" class=' radio-inline'>
          <input type="radio" formControlName='desconto_concedido_nota_10' value="Sim" class='radio-btn' (change)="onDescontoConcedidoChange10($event)"
            >&nbsp;Sim
        </label>
        <label for="desconto_concedido_nota_10" class='radio-inline'>
          <input type="radio" formControlName='desconto_concedido_nota_10' value='Não' class='radio-btn' (change)="onDescontoConcedidoChange10($event)"
            >&nbsp;Não
        </label>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('desconto_concedido_nota_10')?.errors?.required && formulario.get('desconto_concedido_nota_10')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row" *ngIf="formulario.getRawValue().desconto_concedido_nota_10=='Sim'">
      <label for="valor_do_desconto_nota_10" class="col-sm-4 col-form-label">*Valor do desconto (Nota 10) </label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="valor_do_desconto_nota_10" mask="separator.2" thousandSeparator="." [validation]="false" >
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('valor_do_desconto_nota_10')?.errors?.required && formulario.get('valor_do_desconto_nota_10')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('valor_do_desconto_nota_10')?.errors?.maxlength && formulario.get('valor_do_desconto_nota_10')?.touched"
          mensagem="A quantidade máxima de caracteres permitida é 1000"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="juros_aplicado_nota_10" class="col-sm-4 col-form-label">*Juros aplicado? (Nota 10)</label>
      <div class="col-sm-8">
        <label for="juros_aplicado_nota_10" class=' radio-inline'>
          <input type="radio" formControlName='juros_aplicado_nota_10' value="Sim" class='radio-btn' (change)="onJurosAplicadoChange10($event)"
            >&nbsp;Sim
        </label>
        <label for="juros_aplicado_nota_10" class='radio-inline'>
          <input type="radio" formControlName='juros_aplicado_nota_10' value='Não' class='radio-btn' (change)="onJurosAplicadoChange10($event)"
            >&nbsp;Não
        </label>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('juros_aplicado_nota_10')?.errors?.required && formulario.get('juros_aplicado_nota_10')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row" *ngIf="formulario.getRawValue().juros_aplicado_nota_10=='Sim'">
      <label for="valor_dos_juros_nota_10" class="col-sm-4 col-form-label">*Valor dos juros (Nota 10) </label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="valor_dos_juros_nota_10" mask="separator.2" thousandSeparator="." [validation]="false">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('valor_dos_juros_nota_10')?.errors?.required && formulario.get('valor_dos_juros_nota_10')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('valor_dos_juros_nota_10')?.errors?.maxlength && formulario.get('valor_dos_juros_nota_10')?.touched"
          mensagem="A quantidade máxima de caracteres permitida é 1000"></app-mensagem>
      </div>
    </div>

  </div>





















    <!--  -->

    <div class="form-group row">
      <label for="cidade_loja_xp_nutrien" class="col-sm-4 col-form-label">*Cidade loja/XP Nutrien: </label>
      <div class="col-sm-8">
        <input type="email" class="form-control" formControlName="cidade_loja_xp_nutrien" >
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('cidade_loja_xp_nutrien')?.errors?.required && formulario.get('cidade_loja_xp_nutrien')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('cidade_loja_xp_nutrien')?.errors?.maxlength && formulario.get('cidade_loja_xp_nutrien')?.touched"
          mensagem="A quantidade máxima de caracteres permitida é 1000"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="estado_loja_xp_nutrien" class="col-sm-4 col-form-label">*Estado loja/XP Nutrien: </label>
      <div class="col-sm-8">
        <input type="email" class="form-control" formControlName="estado_loja_xp_nutrien" >
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('estado_loja_xp_nutrien')?.errors?.required && formulario.get('estado_loja_xp_nutrien')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('estado_loja_xp_nutrien')?.errors?.maxlength && formulario.get('estado_loja_xp_nutrien')?.touched"
          mensagem="A quantidade máxima de caracteres permitida é 1000"></app-mensagem>
      </div>
    </div>
    <div class="form-group row">
      <label for="solicitante" class="col-sm-4 col-form-label">*Solicitante: </label>
      <div class="col-sm-8">
        <input type="email" class="form-control" formControlName="solicitante" >
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('solicitante')?.errors?.required && formulario.get('solicitante')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('solicitante')?.errors?.maxlength && formulario.get('solicitante')?.touched"
          mensagem="A quantidade máxima de caracteres permitida é 1000"></app-mensagem>
      </div>
    </div>



    <div class="form-group row">
      <label for="e_mail_solicitante" class="col-sm-4 col-form-label">*E-mail do solicitante: </label>
      <div class="col-sm-8">
        <input type="email" class="form-control" formControlName="e_mail_solicitante" >
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('e_mail_solicitante')?.errors?.required && formulario.get('e_mail_solicitante')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('e_mail_solicitante')?.errors?.email && formulario.get('e_mail_solicitante')?.touched"
          mensagem="Formato de e-mail inválido"></app-mensagem>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('e_mail_solicitante')?.errors?.maxlength && formulario.get('e_mail_solicitante')?.touched"
          mensagem="A quantidade máxima de caracteres permitida é 1000"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="e_mail_secund_rio" class="col-sm-4 col-form-label">E-mail secundário: </label>
      <div class="col-sm-8">
        <input type="email" class="form-control" formControlName="e_mail_secund_rio" >
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('e_mail_secund_rio')?.errors?.required && formulario.get('e_mail_secund_rio')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('e_mail_secund_rio')?.errors?.maxlength && formulario.get('e_mail_secund_rio')?.touched"
          mensagem="A quantidade máxima de caracteres permitida é 1000"></app-mensagem>

      </div>
    </div>


    <div class="form-group row">
      <label for="observa_es" class="col-sm-4 col-form-label">Observações </label>
      <div class="col-sm-8">
        <textarea class="form-control" aria-label="With textarea" formControlName="observa_es"></textarea>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('observa_es')?.errors?.maxlength && formulario.get('observa_es')?.touched"
          mensagem="A quantidade máxima de caracteres permitida é 1000"></app-mensagem>
      </div>
    </div>








    <div class="form-group row">
      <label for="anexo" class="col-sm-4 col-form-label">Anexo
      </label>
      <div class="col-sm-8">
        <input type="file" class="form-control form-anexo" aria-label="With textarea"
          formControlName="anexo" id="formFileMultiple" (change)="save($event)"
          multiple="multiple">
      </div>
    </div>

    <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="anexos.length!=0">
      <div class="card-header">Anexos</div>
      <ul class="list-group list-group-flush" *ngFor="let file of anexos">
        <li class="list-group-item d-flex justify-content-between">
          <span>{{ file.name }}</span>
          <span>
            <i class='fa fa-trash remove-icon removeFile' (click)="removeFile(this.file)"></i>
          </span>
        </li>
      </ul>
    </div>



    <div class="form-group pt-1 col-lg-3 box-botao">
      <button type="submit" class="btn botao" [disabled]="formulario.invalid">Enviar</button>
    </div>
    <!-- <div class="box-dificuldade">
      <p class="text-center">Está com dificuldades para enviar sua solicitação? <span class="link-pipe"
          (click)="abrirPipePublico()">Envie por aqui.</span></p>
    </div> -->

  </form>
</div>


<div class="footer">
  <app-footer link="accouting"></app-footer>
</div>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Aguarde...</p>
</ngx-spinner>
