import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommomServicesService } from 'src/app/services/commom-services.service';

@Component({
  selector: 'app-menu-compras',
  templateUrl: './menu-compras.component.html',
  styleUrls: ['./menu-compras.component.css']
})
export class MenuComprasComponent implements OnInit {

  constructor(private router:Router, private commomS: CommomServicesService) { }

  ngOnInit(): void {
  }


  faqLink() {
    this.commomS.openNewWindow('https://app.pipefy.com/public/form/FZZSofCP');
  }

  goToUrl(url:string){
    this.commomS.openNewWindow(url);
  }

  navegar(){
    this.router.navigate([''])
  }

  faq(){
    this.commomS.openNewWindow('https://app.pipefy.com/public/form/FZZSofCP');
  }

  goHome(){
    this.router.navigate([''])
  }

}
