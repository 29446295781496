import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommomServicesService } from 'src/app/services/commom-services.service';

@Component({
  selector: 'app-box-link-pipe',
  templateUrl: './box-link-pipe.component.html',
  styleUrls: ['./box-link-pipe.component.css']
})
export class BoxLinkPipeComponent implements OnInit {

  @Input() url:string = ''
  @Input() descricao:string = ''
  @Input() titulo: string = ''
  @Input() link: string = ''
  @Input() complemento: string =''
  @Input() subtitulo: string =''
  @Input() subtitulo_2: string =''
  @Input() tipo_de_link:string = ''
  @Input() id_box:string = ''

  constructor(private router: Router, private commomS: CommomServicesService) { }

  ngOnInit(): void {
  }

  clickOnlink(): void {
    if(this.tipo_de_link =='externo'){
      this.commomS.openNewWindow(this.link);
    }else
    this.router.navigate([this.link])
   }

   routerLink() {
    this.router.navigate([this.link])
   }
}
