export const empresas = [
    {
      nome:''
    },
    {
      nome:'Agrichem'
    },
    {
      nome:'Agrosema'
    },
    {
      nome:'Bio Rural'
    },
    {
      nome:'Confiança'
    },
    {
      nome:'Nutrien'
    },
    {
      nome:'TecAgro (FCR)'
    },
    {
      nome:'TecAgro (SGL)'
    },
    {
      nome:'Terra Nova'
    }
]