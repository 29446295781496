export const tipoDeLancamentoContabil =[
    {
        tipo:""
    },
    {
        tipo:"Reclassificação"
    },
    {
        tipo:"Provisão"
    },
    {
        tipo:"Outros"
    },
    {
        tipo:"Lançamento em massa",
    },
    {
        tipo:"Lançamento Manual"
    }
]