import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MsalService } from '@azure/msal-angular';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserInfosService } from 'src/app/autenticacao/usuario/user-infos.service';
import { PipefyService } from 'src/app/services/pipefy.service';
import { TrataInputsService } from 'src/app/services/trata-inputs.service';
import { pipe_fields_incidentes } from 'src/app/servicos-financeiros/formularios/pipe_fields_incidentes';
import Swal from 'sweetalert2';
import { pipe_fields_duvidas_informe_rendimentos } from './pipe_fields_ids';
import { anos_calendario } from './anos-calendario';
import { empresas } from './empresas';
import { tipo_duvida } from './tipo_duvida';

@Component({
  selector: 'app-duvidas-sobre-informe-de-rendimentos',
  templateUrl: './duvidas-sobre-informe-de-rendimentos.component.html',
  styleUrls: ['./duvidas-sobre-informe-de-rendimentos.component.css']
})
export class DuvidasSobreInformeDeRendimentosComponent implements OnInit {

  formulario!: FormGroup;
  documentacao: any[] = [];
  documentacaoUrl: any[] = []

  error_counter: number = 0;
  erro: boolean = false

  empresas = empresas;
  anos_calendario = anos_calendario;
  tipos_de_duvida = tipo_duvida;

  myFiles: any[] = [];
  myFilesUrl: any[] = []


  constructor(
    private formBuilder: FormBuilder,
    private trataInput: TrataInputsService,
    private pipefy: PipefyService,
    private spinner: NgxSpinnerService,
    private msalService: MsalService,
    private userInfo: UserInfosService
  ) { }

  ngOnInit(): void {

    this.formulario = this.formBuilder.group({
      nome: ['', [Validators.required, Validators.maxLength(1000)]],
      email: ['', [Validators.required, Validators.email]],
      empresa: ['', [Validators.required]],
      ano_calendario: ['', [Validators.required]],
      tipo_duvida: ['', [Validators.required]],
      descricao_duvida: ['', [Validators.required, Validators.maxLength(1000)]],
      anexo: ['', []],
    })

    this.getUsernameEmail();

  }

  prepEnviarFormulario() {
    Swal.fire({
      title: 'Atenção',
      text: 'Por favor, ficar atento ao retorno no card, caso o retorno seja o status de Aguardando retorno de pendência, você terá 3 dias para retornar a resposta, caso não tenhamos retorno, o card será fechado automaticamente. ',
      confirmButtonText: 'Ok',
      confirmButtonColor: '#88D600'
    })
    .then(() => {
      this.enviarFormulario();
    }
    )
  }
  enviarFormulario() {
    this.spinner.show()

    const criarBody = async () => {

      await this.getPipesUrl(this.myFiles);
      if (this.myFiles.length > 0) {
        while (this.myFiles.length != this.myFilesUrl.length) {
          // console.log("esperando....")
          await this.delay(100);
          if(this.erro){
            break;
          }
        }
        if(this.erro){
          return
        }
      }

      if (this.myFiles.length > 0) {
        var myFilesUrl2 = ''
        myFilesUrl2 = '['
        for (var i = 0; i < this.myFilesUrl.length; i++) {
          myFilesUrl2 = myFilesUrl2 + '"' + this.myFilesUrl[i] + '",'
        }
        myFilesUrl2 = myFilesUrl2.slice(0, -1)
        myFilesUrl2 = myFilesUrl2 + "]"

      } else {
        myFilesUrl2 = ''
      }

      let nome = this.trataInput.removeCaracters(this.formulario.getRawValue().nome)
      let e_mail = this.trataInput.removeCaracters(this.formulario.getRawValue().email)
      let ano_calend_rio = this.trataInput.removeCaracters(this.formulario.getRawValue().ano_calendario)
      let empresa = this.trataInput.removeCaracters(this.formulario.getRawValue().empresa)
      let tipo_de_d_vida = this.trataInput.removeCaracters(this.formulario.getRawValue().tipo_duvida)
      let descri_o_da_d_vida = this.trataInput.removeCaracters(this.formulario.getRawValue().descricao_duvida)



      let pipe_fields = pipe_fields_duvidas_informe_rendimentos
      let fields_values = [nome, e_mail, ano_calend_rio, empresa, tipo_de_d_vida, descri_o_da_d_vida, myFilesUrl2]
      let pipe_id = "303800189"

      console.log(JSON.stringify(fields_values));

      this.pipefy.createCard(this.pipefy.criaBody(pipe_fields, fields_values, pipe_id)).subscribe((res) => {
        // console.log(res)
        this.spinner.hide()

        if (res.data.result_creation_card.id != undefined) {
          let card_id = res.data.result_creation_card.id
          Swal.fire({
            title: 'Chamado aberto com Sucesso!',
            text: 'O chamado ' + card_id + ' foi aberto com sucesso. Nossa equipe irá tratar e retornar em breve. Obrigado!',
            icon: 'success',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#88D600',
          })
          this.resetform()
        }

        if (res.data.result_creation_card.errors != undefined) {
          var erro = res.data.result_creation_card.errors[0].message
          Swal.fire({
            title: 'Desculpe!',
            html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/FZZSofCP" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
            text: erro,
            icon: 'error',
            confirmButtonColor: '#88D600',
          })
          this.myFilesUrl = []

          let pipe_fields_incidentes_values = ['Incidente', 'Correção', 'Portal NBS', 'HR', 'RH', "Autoatendimento - criação de card -- Dúvidas sobre Informe de Rendimentos", erro, 'Portal NBS Error', 'incidente@portal.com',
            'Sim', 'Outros', 'criação de card via portal', 'Página web com problemas']

          var pipe_fields_incidentes_values2 = []
          for (var i = 0; i < pipe_fields_incidentes_values.length; i++) {
            pipe_fields_incidentes_values2.push(this.trataInput.removeCaractersPipeIncidentes(pipe_fields_incidentes_values[i]))
          }
          // console.log(pipe_fields_incidentes_values2)

          let pipe_incidentes_id = '302487533'

          this.pipefy.createCard(this.pipefy.criaBody(pipe_fields_incidentes, pipe_fields_incidentes_values2, pipe_incidentes_id)).subscribe((res) => {
            // console.log(res)
          })
        }
      }, (error: any) => {
        this.spinner.hide()
        // console.log(error.status)
        this.error_counter = this.error_counter + 1
        if (this.error_counter < 2) {
          if (error.status == 401) {
            // console.log("entrei em error.status")
            Swal.fire({
              title: 'Ops, sua sessão expirou!',
              text: 'Por favor, saia e logue novamente.',
              icon: 'error',
              confirmButtonText: 'Sair',
              confirmButtonColor: '#ff6961',
              allowOutsideClick: false,
              allowEscapeKey: false
            })
              .then(() => {
                this.msalService.logoutRedirect()
              }
              )
          } else {
            Swal.fire({
              title: 'Desculpe!',
              text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
              icon: 'error',
              confirmButtonText: 'Ok',
              confirmButtonColor: '#88D600',
              allowOutsideClick: false,
              allowEscapeKey: false
            })
          }
        }
        else {
          this.spinner.hide()
          Swal.fire({
            title: 'Desculpe!',
            html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/FZZSofCP" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
            icon: 'error',
            confirmButtonColor: '#88D600',
          })
          this.myFilesUrl = []
        }
      })
    }

    criarBody()
  }

  delay(time: any) {
    return new Promise(resolve => setTimeout(resolve, time));
  }

  save(event: any): void {
    var files = event.target.files;
    for (var i = 0; i < files.length; i++) {
      this.myFiles.push(files[i])
    }
  }

  async getPipesUrl(files: any) {
    this.erro = false
    for (var i = 0; i < files.length; i++) {
      var fileContent = files[i]
      let fileName = files[i].name
      const reader = new FileReader();
      reader.readAsDataURL(fileContent);
      reader.onload = () => {
        fileContent = reader.result?.toString()
        var fileContentStart = fileContent.indexOf(",")

        fileContent = fileContent.substring(fileContentStart + 1)
        this.pipefy.getFileUrl(fileContent, fileName).subscribe((res) => {
          let url = res.data.attachments_url_pipefy[0]
          this.myFilesUrl.push(url)
        },
        (error: any) => {
          this.erro = true
          this.spinner.hide()
          this.error_counter = this.error_counter + 1
          if (this.error_counter < 2) {
            if (error.status == 401) {
              // console.log("entrei em error.status")
              Swal.fire({
                title: 'Ops, sua sessão expirou!',
                text: 'Por favor, saia e logue novamente.',
                icon: 'error',
                confirmButtonText: 'Sair',
                confirmButtonColor: '#ff6961',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
                .then(() => {
                  this.msalService.logoutRedirect()
                }
                )
            } else {
              Swal.fire({
                title: 'Desculpe!',
                text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#88D600',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
            }
          }
          else {
            this.spinner.hide()
            Swal.fire({
              title: 'Desculpe!',
              html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/FZZSofCP" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
              icon: 'error',
              confirmButtonColor: '#88D600',
            })
            this.myFilesUrl = []
          }
        })
      }
    }
    return new Promise<void>((resolve) => { resolve(); });
  }

  removeFile(file: File) {
    const index: number = this.myFiles.indexOf(file);
    this.myFiles.splice(index, 1)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500
    })
    if(this.myFiles.length == 0){
      this.formulario.controls['anexo'].markAsTouched()
      this.formulario.get('anexo')?.setValue('')
    }
  }

  getUsernameEmail() {
    let userName = this.userInfo.getName();
    let userEmail = this.userInfo.getUserEmail();

    userName = userName ? userName.replace(",","") : "";

    this.formulario.get('nome')?.setValue(userName);
    this.formulario.get('email')?.setValue(userEmail);
  }

  resetform() {
    for (let field in this.formulario.controls) {
      this.formulario.controls[field].setValue('');
    }
    this.formulario.markAsUntouched();
    this.myFiles = [];
    this.myFilesUrl = [];

    this.getUsernameEmail();
  }

  abrirPipePublico() {
    // w i n d o w . o p e n ('https://app.pipefy.com/public/form/FZZSofCP', '_blank')
  }

}
