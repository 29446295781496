export const filiais_nutrien = [
  {
    nome: ''
  },
  {
    nome: 'Filial-Nutrien-Alfenas-0043-09-Mg'
  },
  {
    nome: 'Matriz-Nutrien-São Paulo-0001-50-Sp'
  },
  {
    nome: 'Matriz-Nutrien-São Paulo-0001-25-Sp'
  },
  {
    nome: 'Matriz-Nutrien-Canadá-0001-26-Ex'
  },
  {
    nome: 'Filial-Nutrien-Angatuba-0014-74-Sp'
  },
  {
    nome: 'Filial-Nutrien-Aracatuba-0029-50-Sp'
  },
  {
    nome: 'Filial-Nutrien-Votuporanga-0059-76-Sp'
  },
  {
    nome: 'Filial-Nutrien-Vargem Grande Do Sul-0017-17-Sp'
  },
  {
    nome: 'Filial-Nutrien-Araxa-0015-55-Mg'
  },
  {
    nome: 'Filial-Nutrien-Araxa-0045-70-Mg'
  },
  {
    nome: 'Filial-Nutrien-Assis-0026-08-Sp'
  },
  {
    nome: 'Filial-Nutrien-Avare-0018-06-Sp'
  },
  {
    nome: 'Filial-Nutrien-Avare-0057-04-Sp'
  },
  {
    nome: 'Filial-Nutrien-Campinas-0060-00-Sp'
  },
  {
    nome: 'Filial-Nutrien-Candido Mota-0046-51-Sp'
  },
  {
    nome: 'Filial-Nutrien-Capão Bonito-0041-47-Sp'
  },
  {
    nome: 'Filial-Nutrien-Coromandel-0033-37-Mg'
  },
  {
    nome: 'Filial-Nutrien-Cristalina-0020-12-Go'
  },
  {
    nome: 'Filial-Nutrien-Elias Fausto-0039-22-Sp'
  },
  {
    nome: 'Filial-Nutrien-Elias Fausto-0042-28-Sp'
  },
  {
    nome: 'Filial-Nutrien-Goiânia-0061-90-Go'
  },
  {
    nome: 'Filial-Nutrien-Ibiá-0025-27-Mg'
  },
  {
    nome: 'Filial-Nutrien-Ibiá-0034-18-Mg'
  },
  {
    nome: 'Filial-Nutrien-Indaiatuba-0056-23-Sp'
  },
  {
    nome: 'Filial-Nutrien-Itapetininga Col Novo-0022-84-Sp'
  },
  {
    nome: 'Filial-Nutrien-Itapetininga-0004-00-Sp'
  },
  {
    nome: 'Filial-Nutrien-Itapetininga-0040-66-Sp'
  },
  {
    nome: 'Filial-Nutrien-Itapeva-0005-83-Sp'
  },
  {
    nome: 'Filial-Nutrien-Itapeva-0048-13-Sp'
  },
  {
    nome: 'Filial-Nutrien-Itapolis-0049-02-Sp'
  },
  {
    nome: 'Filial-Nutrien-Ituiutaba-0035-07-Mg'
  },
  {
    nome: 'Filial-Nutrien-Limeira-0019-89-Sp'
  },
  {
    nome: 'Filial-Nutrien-Limeira-0047-32-Sp'
  },
  {
    nome: 'Filial-Nutrien-Mogi Mirim-0028-70-Sp'
  },
  {
    nome: 'Filial-Nutrien-Mogi-Guaçu-0051-19-Sp'
  },
  {
    nome: 'Filial-Nutrien-Morrinhos-0021-01-Go'
  },
  {
    nome: 'Filial-Nutrien-Paracatu-0027-99-Sp'
  },
  {
    nome: 'Filial-Nutrien-Patos De Minas-0036-80-Mg'
  },
  {
    nome: 'Filial-Nutrien-Patrocinio-0044-90-Mg'
  },
  {
    nome: 'Filial-Nutrien-Paulinia-0024-46-Sp'
  },
  {
    nome: 'Filial-Nutrien-Paulinia-0038-41-Sp'
  },
  {
    nome: 'Filial-Nutrien-Ribeirão Preto-0055-42-Sp'
  },
  {
    nome: 'Filial-Nutrien-Rio Verde-0058-95-Go'
  },
  {
    nome: 'Filial-Nutrien-São Gotardo-0031-75-Mg'
  },
  {
    nome: 'Filial-Nutrien-São Miguel Arcanjo-0052-08-Sp'
  },
  {
    nome: 'Filial-Nutrien-Taquarituba-0050-38-Sp'
  },
  {
    nome: 'Filial-Nutrien-Uberaba-0032-56-Mg'
  },
  {
    nome: 'Filial-Nutrien-Uberaba-0053-80-Mg'
  },
  {
    nome: 'Filial-Nutrien-Uberaba-0054-61-Mg'
  },
  {
    nome: 'Filial-Nutrien-Uberlândia-0037-60-Mg'
  },
  {
    nome: 'Filial-Nutrien-Unaí-0030-94-Mg'
  },
  {
    nome: 'Nutrien Participações Ltda'
  },
  {
    nome: 'Nutrien Soluções Agrícolas Ltda'
  }
]
