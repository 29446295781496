import { Injectable } from '@angular/core';

const KEY = 'token';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  getToken(){
    return null
  }

  setToken(token: string){
    null
  }

  removeToken(){
    null
  }

  hasToken(){
    return !!this.getToken()
  }
}
