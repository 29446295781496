<app-header></app-header>
<app-nbs-banner url="../../../assets/img/backgroundcontabilidade2.png" descricao="teste" titulo="Cancelamento de Solicitação">
</app-nbs-banner>


<div class="container">


  <app-formularios-titulo url="../../../assets/img/auditoria.png" descricao="Payment" titulo="Cancele sua solicitação">
  </app-formularios-titulo>

  <div class="mensagem-topo">
    <h6 class="text-center">Preencha o formulário abaixo para enviar sua solicitação.</h6>
    <p class="text-center">Os campos marcados com (*) são de preenchimento obrigatório.</p>
  </div>

  <form [formGroup]="formulario" (submit)="enviarFormulario()">

    <div class="form-group row">
      <label for="nome_do_solicitante" class="col-sm-4 col-form-label">*Nome do Solicitante</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="nome_do_solicitante">
        <app-mensagem class="col-form-label"
        *ngIf="formulario.get('nome_do_solicitante')?.errors?.required && formulario.get('nome_do_solicitante')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('nome_do_solicitante')?.errors?.maxlength && formulario.get('nome_do_solicitante')?.touched"
          mensagem="A quantidade máxima de caracteres permitida é 1000"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="email" class="col-sm-4 col-form-label">*E-mail Corporativo do Solicitante</label>
      <div class="col-sm-8">
        <input type="email" class="form-control" formControlName="email">
        <app-mensagem class="col-form-label"
          *ngIf="formulario.get('email')?.errors?.required && formulario.get('email')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        <app-mensagem class="col-form-label"
          *ngIf="formulario.get('email')?.errors?.email && formulario.get('email')?.touched"
          mensagem="Formato de E-mail Inválido"></app-mensagem>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('email')?.errors?.maxlength && formulario.get('email')?.touched"
          mensagem="A quantidade máxima de caracteres permitida é 1000"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="numero_do_card_a_ser_cancelado" class="col-sm-4 col-form-label">*Número do Chamado a ser
        Cancelado</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="numero_do_card_a_ser_cancelado">
        <small class="info-message">Número do chamado a ser cancelado</small>
        <app-mensagem class="col-form-label"
          *ngIf="formulario.get('numero_do_card_a_ser_cancelado')?.errors?.required && formulario.get('numero_do_card_a_ser_cancelado')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('numero_do_card_a_ser_cancelado')?.errors?.maxlength && formulario.get('numero_do_card_a_ser_cancelado')?.touched"
          mensagem="A quantidade máxima de caracteres permitida é 1000"></app-mensagem>
      </div>
    </div>


    <div class="form-group row">
      <label for="justificativa" class="col-sm-4 col-form-label">*Justificativa do Cancelamento </label>
      <div class="col-sm-8">
        <textarea class="form-control" aria-label="With textarea" formControlName="justificativa"></textarea>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('justificativa')?.errors?.required && formulario.get('justificativa')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('justificativa')?.errors?.maxlength && formulario.get('justificativa')?.touched"
          mensagem="A quantidade máxima de caracteres permitida é 1000"></app-mensagem>
      </div>
    </div>

    <div class="form-group pt-1 col-lg-3 box-botao">
      <button type="submit" class="btn botao" [disabled]="formulario.invalid">Enviar</button>
    </div>

  </form>
  <!-- <div class="box-dificuldade">
    <p class="text-center">Está com dificuldades para enviar sua solicitação? <span class="link-pipe"
        (click)="abrirPipePublico()">Envie por aqui.</span></p>
  </div> -->
</div>


<div class="footer">
  <app-footer link="menu"></app-footer>
</div>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Aguarde. Sua solicitação está sendo processada.</p>
</ngx-spinner>
