import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MsalService } from '@azure/msal-angular';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserInfosService } from 'src/app/autenticacao/usuario/user-infos.service';
import { PipefyService } from 'src/app/services/pipefy.service';
import { TrataInputsService } from 'src/app/services/trata-inputs.service';
import Swal from 'sweetalert2';
import { empresas } from '../../contabilidade/auditoria/empresas';
import { torres } from '../../contabilidade/auditoria/torres';
import { pipe_fields_incidentes } from '../../pipe_fields_incidentes';
import { pipe_fields_baixa } from './pipe_fields';
import { quantidadeDeNotas } from './quantidade_de_notas';
import { CommomServicesService } from 'src/app/services/commom-services.service';

@Component({
  selector: 'app-baixa-de-titulos',
  templateUrl: './baixa-de-titulos.component.html',
  styleUrls: ['./baixa-de-titulos.component.css']
})
export class BaixaDeTitulosComponent implements OnInit {

  formulario!: FormGroup;
  empresas = empresas;
  torres = torres;
  quantidadeDeNotas = quantidadeDeNotas
  anexos: any[] = [];
  email: string = ''
  anexosUrl: any[] = []
  faltamCamposObrigatorios: boolean = true
  error_counter: number = 0;
  erro: boolean = false

  emailApi: string = ''
  userNameApi: string = ''

  constructor(private formBuilder: FormBuilder,
              private trataInput: TrataInputsService,
              private pipefy: PipefyService,
              private spinner: NgxSpinnerService,
              private msalService: MsalService,
              private userInfo: UserInfosService,
              private commomS: CommomServicesService) { }

  ngOnInit(): void {

    this.formulario = this.formBuilder.group({
      sistema_t_tulo_em_aberto: ['', [Validators.required, Validators.maxLength(1000)]],
      nome_do_cliente: ['', [Validators.required, Validators.maxLength(1000)]],
      c_digo_do_cliente: ['', [Validators.maxLength(1000)]],
      c_digo_do_cliente_sap: ['', [Validators.maxLength(1000)]],
      n_mero_de_notas_fiscais_a_serem_baixadas: ['', [Validators.required, Validators.maxLength(1000)]],

      n_mero_da_nota_fiscal_nota_1: ['', [Validators.maxLength(1000)]],
      baixar_valor_total_da_nota_fiscal_nota_1: ['', [Validators.maxLength(1000)]],
      valor_parcial_a_ser_baixado_nota_1: ['', [Validators.maxLength(1000)]],
      desconto_concedido_nota_1: ['', [Validators.maxLength(1000)]],
      valor_do_desconto_nota_1: ['', [Validators.maxLength(1000)]],
      juros_aplicado_nota_1: ['', [Validators.maxLength(1000)]],
      valor_dos_juros_nota_1: ['', [Validators.maxLength(1000)]],

      n_mero_da_nota_fiscal_nota_2: ['', [Validators.maxLength(1000)]],
      baixar_valor_total_da_nota_fiscal_nota_2: ['', [Validators.maxLength(1000)]],
      valor_parcial_a_ser_baixado_nota_2: ['', [Validators.maxLength(1000)]],
      desconto_concedido_nota_2: ['', [Validators.maxLength(1000)]],
      valor_do_desconto_nota_2: ['', [Validators.maxLength(1000)]],
      juros_aplicado_nota_2: ['', [Validators.maxLength(1000)]],
      valor_dos_juros_nota_2: ['', [Validators.maxLength(1000)]],

      n_mero_da_nota_fiscal_nota_3: ['', [Validators.maxLength(1000)]],
      baixar_valor_total_da_nota_fiscal_nota_3: ['', [Validators.maxLength(1000)]],
      valor_parcial_a_ser_baixado_nota_3: ['', [Validators.maxLength(1000)]],
      desconto_concedido_nota_3: ['', [Validators.maxLength(1000)]],
      valor_do_desconto_nota_3: ['', [Validators.maxLength(1000)]],
      juros_aplicado_nota_3: ['', [Validators.maxLength(1000)]],
      valor_dos_juros_nota_3: ['', [Validators.maxLength(1000)]],

      n_mero_da_nota_fiscal_nota_4: ['', [Validators.maxLength(1000)]],
      baixar_valor_total_da_nota_fiscal_nota_4: ['', [Validators.maxLength(1000)]],
      valor_parcial_a_ser_baixado_nota_4: ['', [Validators.maxLength(1000)]],
      desconto_concedido_nota_4: ['', [Validators.maxLength(1000)]],
      valor_do_desconto_nota_4: ['', [Validators.maxLength(1000)]],
      juros_aplicado_nota_4: ['', [Validators.maxLength(1000)]],
      valor_dos_juros_nota_4: ['', [Validators.maxLength(1000)]],

      n_mero_da_nota_fiscal_nota_5: ['', [Validators.maxLength(1000)]],
      baixar_valor_total_da_nota_fiscal_nota_5: ['', [Validators.maxLength(1000)]],
      valor_parcial_a_ser_baixado_nota_5: ['', [Validators.maxLength(1000)]],
      desconto_concedido_nota_5: ['', [Validators.maxLength(1000)]],
      valor_do_desconto_nota_5: ['', [Validators.maxLength(1000)]],
      juros_aplicado_nota_5: ['', [Validators.maxLength(1000)]],
      valor_dos_juros_nota_5: ['', [Validators.maxLength(1000)]],

      n_mero_da_nota_fiscal_nota_6: ['', [Validators.maxLength(1000)]],
      baixar_valor_total_da_nota_fiscal_nota_6: ['', [Validators.maxLength(1000)]],
      valor_parcial_a_ser_baixado_nota_6: ['', [Validators.maxLength(1000)]],
      desconto_concedido_nota_6: ['', [Validators.maxLength(1000)]],
      valor_do_desconto_nota_6: ['', [Validators.maxLength(1000)]],
      juros_aplicado_nota_6: ['', [Validators.maxLength(1000)]],
      valor_dos_juros_nota_6: ['', [Validators.maxLength(1000)]],

      n_mero_da_nota_fiscal_nota_7: ['', [Validators.maxLength(1000)]],
      baixar_valor_total_da_nota_fiscal_nota_7: ['', [Validators.maxLength(1000)]],
      valor_parcial_a_ser_baixado_nota_7: ['', [Validators.maxLength(1000)]],
      desconto_concedido_nota_7: ['', [Validators.maxLength(1000)]],
      valor_do_desconto_nota_7: ['', [Validators.maxLength(1000)]],
      juros_aplicado_nota_7: ['', [Validators.maxLength(1000)]],
      valor_dos_juros_nota_7: ['', [Validators.maxLength(1000)]],

      n_mero_da_nota_fiscal_nota_8: ['', [Validators.maxLength(1000)]],
      baixar_valor_total_da_nota_fiscal_nota_8: ['', [Validators.maxLength(1000)]],
      valor_parcial_a_ser_baixado_nota_8: ['', [Validators.maxLength(1000)]],
      desconto_concedido_nota_8: ['', [Validators.maxLength(1000)]],
      valor_do_desconto_nota_8: ['', [Validators.maxLength(1000)]],
      juros_aplicado_nota_8: ['', [Validators.maxLength(1000)]],
      valor_dos_juros_nota_8: ['', [Validators.maxLength(1000)]],

      n_mero_da_nota_fiscal_nota_9: ['', [Validators.maxLength(1000)]],
      baixar_valor_total_da_nota_fiscal_nota_9: ['', [Validators.maxLength(1000)]],
      valor_parcial_a_ser_baixado_nota_9: ['', [Validators.maxLength(1000)]],
      desconto_concedido_nota_9: ['', [Validators.maxLength(1000)]],
      valor_do_desconto_nota_9: ['', [Validators.maxLength(1000)]],
      juros_aplicado_nota_9: ['', [Validators.maxLength(1000)]],
      valor_dos_juros_nota_9: ['', [Validators.maxLength(1000)]],

      n_mero_da_nota_fiscal_nota_10: ['', [Validators.maxLength(1000)]],
      baixar_valor_total_da_nota_fiscal_nota_10: ['', [Validators.maxLength(1000)]],
      valor_parcial_a_ser_baixado_nota_10: ['', [Validators.maxLength(1000)]],
      desconto_concedido_nota_10: ['', [Validators.maxLength(1000)]],
      valor_do_desconto_nota_10: ['', [Validators.maxLength(1000)]],
      juros_aplicado_nota_10: ['', [Validators.maxLength(1000)]],
      valor_dos_juros_nota_10: ['', [Validators.maxLength(1000)]],

      cidade_loja_xp_nutrien: ['', [Validators.required, Validators.maxLength(1000)]],
      estado_loja_xp_nutrien: ['', [Validators.required, Validators.maxLength(1000)]],
      solicitante: ['', [Validators.required, Validators.maxLength(1000)]],
      e_mail_solicitante: ['', [Validators.required, Validators.email, Validators.maxLength(1000)]],
      e_mail_secund_rio: ['', [Validators.maxLength(1000)]],
      observa_es: ['', [Validators.maxLength(1000)]],
      anexo: ['', []],
    })

    this.emailApi = this.userInfo.getUserEmail()
    // this.userNameApi = this.userInfo.getName()

    // this.formulario.get('email_do_solicitante')?.setValue(this.emailApi)

    // if(this.formulario.getRawValue().email_do_solicitante !=''){
    //   this.formulario.controls['email_do_solicitante'].disable()
    // }
    // if(this.formulario.getRawValue().email_do_solicitante == undefined){
    //   this.formulario.controls['email_do_solicitante'].enable()
    // }

  }





  enviarFormulario() {
    this.spinner.show()

    const criarBody = async () => {

      await this.getPipesUrl(this.anexos);
      if (this.anexos.length > 0) {
        while (this.anexos.length != this.anexosUrl.length) {
          // console.log("esperando....")
          await this.delay(100);
          if(this.erro){
            break;
          }
        }
        if(this.erro){
          return
        }
      }

      if (this.anexos.length > 0) {
        var anexosUrl2 = ''
        anexosUrl2 = '['
        for (var i = 0; i < this.anexosUrl.length; i++) {
          anexosUrl2 = anexosUrl2 + '"' + this.anexosUrl[i] + '",'
        }
        anexosUrl2 = anexosUrl2.slice(0, -1)
        anexosUrl2 = anexosUrl2 + "]"

      } else {
        anexosUrl2 = ''
      }

      let sistema_t_tulo_em_aberto= this.trataInput.removeCaracters(this.formulario.getRawValue().sistema_t_tulo_em_aberto)
      let nome_do_cliente= this.trataInput.removeCaracters(this.formulario.getRawValue().nome_do_cliente)
      let c_digo_do_cliente= this.trataInput.removeCaracters(this.formulario.getRawValue().c_digo_do_cliente)
      let c_digo_do_cliente_sap= this.trataInput.removeCaracters(this.formulario.getRawValue().c_digo_do_cliente_sap)
      let n_mero_de_notas_fiscais_a_serem_baixadas= this.trataInput.removeCaracters(this.formulario.getRawValue().n_mero_de_notas_fiscais_a_serem_baixadas)

      let n_mero_da_nota_fiscal_nota_1= this.trataInput.removeCaracters(this.formulario.getRawValue().n_mero_da_nota_fiscal_nota_1)
      let baixar_valor_total_da_nota_fiscal_nota_1= this.trataInput.removeCaracters(this.formulario.getRawValue().baixar_valor_total_da_nota_fiscal_nota_1)
      let valor_parcial_a_ser_baixado_nota_1= this.trataInput.removeCaracters(this.formulario.getRawValue().valor_parcial_a_ser_baixado_nota_1)
      let desconto_concedido_nota_1= this.trataInput.removeCaracters(this.formulario.getRawValue().desconto_concedido_nota_1)
      let valor_do_desconto_nota_1= this.trataInput.removeCaracters(this.formulario.getRawValue().valor_do_desconto_nota_1)
      let juros_aplicado_nota_1= this.trataInput.removeCaracters(this.formulario.getRawValue().juros_aplicado_nota_1)
      let valor_dos_juros_nota_1= this.trataInput.removeCaracters(this.formulario.getRawValue().valor_dos_juros_nota_1)

      let n_mero_da_nota_fiscal_nota_2= this.trataInput.removeCaracters(this.formulario.getRawValue().n_mero_da_nota_fiscal_nota_2)
      let baixar_valor_total_da_nota_fiscal_nota_2= this.trataInput.removeCaracters(this.formulario.getRawValue().baixar_valor_total_da_nota_fiscal_nota_2)
      let valor_parcial_a_ser_baixado_nota_2= this.trataInput.removeCaracters(this.formulario.getRawValue().valor_parcial_a_ser_baixado_nota_2)
      let desconto_concedido_nota_2= this.trataInput.removeCaracters(this.formulario.getRawValue().desconto_concedido_nota_2)
      let valor_do_desconto_nota_2= this.trataInput.removeCaracters(this.formulario.getRawValue().valor_do_desconto_nota_2)
      let juros_aplicado_nota_2= this.trataInput.removeCaracters(this.formulario.getRawValue().juros_aplicado_nota_2)
      let valor_dos_juros_nota_2= this.trataInput.removeCaracters(this.formulario.getRawValue().valor_dos_juros_nota_2)

      let n_mero_da_nota_fiscal_nota_3= this.trataInput.removeCaracters(this.formulario.getRawValue().n_mero_da_nota_fiscal_nota_3)
      let baixar_valor_total_da_nota_fiscal_nota_3= this.trataInput.removeCaracters(this.formulario.getRawValue().baixar_valor_total_da_nota_fiscal_nota_3)
      let valor_parcial_a_ser_baixado_nota_3= this.trataInput.removeCaracters(this.formulario.getRawValue().valor_parcial_a_ser_baixado_nota_3)
      let desconto_concedido_nota_3= this.trataInput.removeCaracters(this.formulario.getRawValue().desconto_concedido_nota_3)
      let valor_do_desconto_nota_3= this.trataInput.removeCaracters(this.formulario.getRawValue().valor_do_desconto_nota_3)
      let juros_aplicado_nota_3= this.trataInput.removeCaracters(this.formulario.getRawValue().juros_aplicado_nota_3)
      let valor_dos_juros_nota_3= this.trataInput.removeCaracters(this.formulario.getRawValue().valor_dos_juros_nota_3)

      let n_mero_da_nota_fiscal_nota_4= this.trataInput.removeCaracters(this.formulario.getRawValue().n_mero_da_nota_fiscal_nota_4)
      let baixar_valor_total_da_nota_fiscal_nota_4= this.trataInput.removeCaracters(this.formulario.getRawValue().baixar_valor_total_da_nota_fiscal_nota_4)
      let valor_parcial_a_ser_baixado_nota_4= this.trataInput.removeCaracters(this.formulario.getRawValue().valor_parcial_a_ser_baixado_nota_4)
      let desconto_concedido_nota_4= this.trataInput.removeCaracters(this.formulario.getRawValue().desconto_concedido_nota_4)
      let valor_do_desconto_nota_4= this.trataInput.removeCaracters(this.formulario.getRawValue().valor_do_desconto_nota_4)
      let juros_aplicado_nota_4= this.trataInput.removeCaracters(this.formulario.getRawValue().juros_aplicado_nota_4)
      let valor_dos_juros_nota_4= this.trataInput.removeCaracters(this.formulario.getRawValue().valor_dos_juros_nota_4)

      let n_mero_da_nota_fiscal_nota_5= this.trataInput.removeCaracters(this.formulario.getRawValue().n_mero_da_nota_fiscal_nota_5)
      let baixar_valor_total_da_nota_fiscal_nota_5= this.trataInput.removeCaracters(this.formulario.getRawValue().baixar_valor_total_da_nota_fiscal_nota_5)
      let valor_parcial_a_ser_baixado_nota_5= this.trataInput.removeCaracters(this.formulario.getRawValue().valor_parcial_a_ser_baixado_nota_5)
      let desconto_concedido_nota_5= this.trataInput.removeCaracters(this.formulario.getRawValue().desconto_concedido_nota_5)
      let valor_do_desconto_nota_5= this.trataInput.removeCaracters(this.formulario.getRawValue().valor_do_desconto_nota_5)
      let juros_aplicado_nota_5= this.trataInput.removeCaracters(this.formulario.getRawValue().juros_aplicado_nota_5)
      let valor_dos_juros_nota_5= this.trataInput.removeCaracters(this.formulario.getRawValue().valor_dos_juros_nota_5)

      let n_mero_da_nota_fiscal_nota_6= this.trataInput.removeCaracters(this.formulario.getRawValue().n_mero_da_nota_fiscal_nota_6)
      let baixar_valor_total_da_nota_fiscal_nota_6= this.trataInput.removeCaracters(this.formulario.getRawValue().baixar_valor_total_da_nota_fiscal_nota_6)
      let valor_parcial_a_ser_baixado_nota_6= this.trataInput.removeCaracters(this.formulario.getRawValue().valor_parcial_a_ser_baixado_nota_6)
      let desconto_concedido_nota_6= this.trataInput.removeCaracters(this.formulario.getRawValue().desconto_concedido_nota_6)
      let valor_do_desconto_nota_6= this.trataInput.removeCaracters(this.formulario.getRawValue().valor_do_desconto_nota_6)
      let juros_aplicado_nota_6= this.trataInput.removeCaracters(this.formulario.getRawValue().juros_aplicado_nota_6)
      let valor_dos_juros_nota_6= this.trataInput.removeCaracters(this.formulario.getRawValue().valor_dos_juros_nota_6)

      let n_mero_da_nota_fiscal_nota_7= this.trataInput.removeCaracters(this.formulario.getRawValue().n_mero_da_nota_fiscal_nota_7)
      let baixar_valor_total_da_nota_fiscal_nota_7= this.trataInput.removeCaracters(this.formulario.getRawValue().baixar_valor_total_da_nota_fiscal_nota_7)
      let valor_parcial_a_ser_baixado_nota_7= this.trataInput.removeCaracters(this.formulario.getRawValue().valor_parcial_a_ser_baixado_nota_7)
      let desconto_concedido_nota_7= this.trataInput.removeCaracters(this.formulario.getRawValue().desconto_concedido_nota_7)
      let valor_do_desconto_nota_7= this.trataInput.removeCaracters(this.formulario.getRawValue().valor_do_desconto_nota_7)
      let juros_aplicado_nota_7= this.trataInput.removeCaracters(this.formulario.getRawValue().juros_aplicado_nota_7)
      let valor_dos_juros_nota_7= this.trataInput.removeCaracters(this.formulario.getRawValue().valor_dos_juros_nota_7)

      let n_mero_da_nota_fiscal_nota_8= this.trataInput.removeCaracters(this.formulario.getRawValue().n_mero_da_nota_fiscal_nota_8)
      let baixar_valor_total_da_nota_fiscal_nota_8= this.trataInput.removeCaracters(this.formulario.getRawValue().baixar_valor_total_da_nota_fiscal_nota_8)
      let valor_parcial_a_ser_baixado_nota_8= this.trataInput.removeCaracters(this.formulario.getRawValue().valor_parcial_a_ser_baixado_nota_8)
      let desconto_concedido_nota_8= this.trataInput.removeCaracters(this.formulario.getRawValue().desconto_concedido_nota_8)
      let valor_do_desconto_nota_8= this.trataInput.removeCaracters(this.formulario.getRawValue().valor_do_desconto_nota_8)
      let juros_aplicado_nota_8= this.trataInput.removeCaracters(this.formulario.getRawValue().juros_aplicado_nota_8)
      let valor_dos_juros_nota_8= this.trataInput.removeCaracters(this.formulario.getRawValue().valor_dos_juros_nota_8)

      let n_mero_da_nota_fiscal_nota_9= this.trataInput.removeCaracters(this.formulario.getRawValue().n_mero_da_nota_fiscal_nota_9)
      let baixar_valor_total_da_nota_fiscal_nota_9= this.trataInput.removeCaracters(this.formulario.getRawValue().baixar_valor_total_da_nota_fiscal_nota_9)
      let valor_parcial_a_ser_baixado_nota_9= this.trataInput.removeCaracters(this.formulario.getRawValue().valor_parcial_a_ser_baixado_nota_9)
      let desconto_concedido_nota_9= this.trataInput.removeCaracters(this.formulario.getRawValue().desconto_concedido_nota_9)
      let valor_do_desconto_nota_9= this.trataInput.removeCaracters(this.formulario.getRawValue().valor_do_desconto_nota_9)
      let juros_aplicado_nota_9= this.trataInput.removeCaracters(this.formulario.getRawValue().juros_aplicado_nota_9)
      let valor_dos_juros_nota_9= this.trataInput.removeCaracters(this.formulario.getRawValue().valor_dos_juros_nota_9)

      let n_mero_da_nota_fiscal_nota_10= this.trataInput.removeCaracters(this.formulario.getRawValue().n_mero_da_nota_fiscal_nota_10)
      let baixar_valor_total_da_nota_fiscal_nota_10= this.trataInput.removeCaracters(this.formulario.getRawValue().baixar_valor_total_da_nota_fiscal_nota_10)
      let valor_parcial_a_ser_baixado_nota_10= this.trataInput.removeCaracters(this.formulario.getRawValue().valor_parcial_a_ser_baixado_nota_10)
      let desconto_concedido_nota_10= this.trataInput.removeCaracters(this.formulario.getRawValue().desconto_concedido_nota_10)
      let valor_do_desconto_nota_10= this.trataInput.removeCaracters(this.formulario.getRawValue().valor_do_desconto_nota_10)
      let juros_aplicado_nota_10= this.trataInput.removeCaracters(this.formulario.getRawValue().juros_aplicado_nota_10)
      let valor_dos_juros_nota_10= this.trataInput.removeCaracters(this.formulario.getRawValue().valor_dos_juros_nota_10)

      let cidade_loja_xp_nutrien= this.trataInput.removeCaracters(this.formulario.getRawValue().cidade_loja_xp_nutrien)
      let estado_loja_xp_nutrien= this.trataInput.removeCaracters(this.formulario.getRawValue().estado_loja_xp_nutrien)
      let solicitante= this.trataInput.removeCaracters(this.formulario.getRawValue().solicitante)
      let e_mail_solicitante= this.trataInput.removeCaracters(this.formulario.getRawValue().e_mail_solicitante)
      let e_mail_secund_rio= this.trataInput.removeCaracters(this.formulario.getRawValue().e_mail_secund_rio)
      let observa_es= this.trataInput.removeCaracters(this.formulario.getRawValue().observa_es)
      let anexo= this.trataInput.removeCaracters(this.formulario.getRawValue().anexo)


      let pipe_fields = pipe_fields_baixa
      let fields_values = [ sistema_t_tulo_em_aberto,
      nome_do_cliente,
      c_digo_do_cliente,
      c_digo_do_cliente_sap,
      n_mero_de_notas_fiscais_a_serem_baixadas,
      n_mero_da_nota_fiscal_nota_1,
      baixar_valor_total_da_nota_fiscal_nota_1,
      valor_parcial_a_ser_baixado_nota_1,
      desconto_concedido_nota_1,
      valor_do_desconto_nota_1,
      juros_aplicado_nota_1,
      valor_dos_juros_nota_1,
      n_mero_da_nota_fiscal_nota_2,
      baixar_valor_total_da_nota_fiscal_nota_2,
      valor_parcial_a_ser_baixado_nota_2,
      desconto_concedido_nota_2,
      valor_do_desconto_nota_2,
      juros_aplicado_nota_2,
      valor_dos_juros_nota_2,
      n_mero_da_nota_fiscal_nota_3,
      baixar_valor_total_da_nota_fiscal_nota_3,
      valor_parcial_a_ser_baixado_nota_3,
      desconto_concedido_nota_3,
      valor_do_desconto_nota_3,
      juros_aplicado_nota_3,
      valor_dos_juros_nota_3,
      n_mero_da_nota_fiscal_nota_4,
      baixar_valor_total_da_nota_fiscal_nota_4,
      valor_parcial_a_ser_baixado_nota_4,
      desconto_concedido_nota_4,
      valor_do_desconto_nota_4,
      juros_aplicado_nota_4,
      valor_dos_juros_nota_4,
      n_mero_da_nota_fiscal_nota_5,
      baixar_valor_total_da_nota_fiscal_nota_5,
      valor_parcial_a_ser_baixado_nota_5,
      desconto_concedido_nota_5,
      valor_do_desconto_nota_5,
      juros_aplicado_nota_5,
      valor_dos_juros_nota_5,
      n_mero_da_nota_fiscal_nota_6,
      baixar_valor_total_da_nota_fiscal_nota_6,
      valor_parcial_a_ser_baixado_nota_6,
      desconto_concedido_nota_6,
      valor_do_desconto_nota_6,
      juros_aplicado_nota_6,
      valor_dos_juros_nota_6,
      n_mero_da_nota_fiscal_nota_7,
      baixar_valor_total_da_nota_fiscal_nota_7,
      valor_parcial_a_ser_baixado_nota_7,
      desconto_concedido_nota_7,
      valor_do_desconto_nota_7,
      juros_aplicado_nota_7,
      valor_dos_juros_nota_7,
      n_mero_da_nota_fiscal_nota_8,
      baixar_valor_total_da_nota_fiscal_nota_8,
      valor_parcial_a_ser_baixado_nota_8,
      desconto_concedido_nota_8,
      valor_do_desconto_nota_8,
      juros_aplicado_nota_8,
      valor_dos_juros_nota_8,
      n_mero_da_nota_fiscal_nota_9,
      baixar_valor_total_da_nota_fiscal_nota_9,
      valor_parcial_a_ser_baixado_nota_9,
      desconto_concedido_nota_9,
      valor_do_desconto_nota_9,
      juros_aplicado_nota_9,
      valor_dos_juros_nota_9,
      n_mero_da_nota_fiscal_nota_10,
      baixar_valor_total_da_nota_fiscal_nota_10,
      valor_parcial_a_ser_baixado_nota_10,
      desconto_concedido_nota_10,
      valor_do_desconto_nota_10,
      juros_aplicado_nota_10,
      valor_dos_juros_nota_10,
      cidade_loja_xp_nutrien,
      estado_loja_xp_nutrien,
      solicitante,
      e_mail_solicitante,
      e_mail_secund_rio,
      observa_es,
      anexosUrl2]
      let pipe_id = "303800183"

      this.pipefy.createCard(this.pipefy.criaBody(pipe_fields, fields_values, pipe_id)).subscribe((res) => {
        // console.log(res)
        this.spinner.hide()
        // console.log(res)

        if(res.code == 504){
          Swal.fire({
            title: 'Desculpe!',
            text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
            icon: 'error',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#88D600',
            allowOutsideClick: false,
            allowEscapeKey: false
          })
        }

        if (res.data.result_creation_card.id != undefined) {
          let card_id = res.data.result_creation_card.id
          Swal.fire({
            title: 'Chamado aberto com Sucesso!',
            text: 'O chamado ' + card_id + ' foi aberto com sucesso. Nossa equipe irá tratar e retornar em breve. Obrigado!',
            icon: 'success',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#88D600',
          })
          this.resetform()
        }

        if (res.data.result_creation_card.errors != undefined) {
          var erro = res.data.result_creation_card.errors[0].message
          Swal.fire({
            title: 'Desculpe!',
            html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/FZZSofCP" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
            text: erro,
            icon: 'error',
            confirmButtonColor: '#88D600',
          })
          this.anexosUrl = []

          let pipe_fields_incidentes_values = ['Incidente', 'Correção', 'Portal NBS', 'F&A', 'Contabilidade', "Contabilidade - criação de card -- Auditoria - Documentos Suporte", erro, 'Portal NBS Error', 'incidente@portal.com',
            'Sim', 'Outros', 'criação de card via portal', 'Página web com problemas',this.emailApi]

          var pipe_fields_incidentes_values2 = []
          for (var i = 0; i < pipe_fields_incidentes_values.length; i++) {
            pipe_fields_incidentes_values2.push(this.trataInput.removeCaractersPipeIncidentes(pipe_fields_incidentes_values[i]))
          }
          // console.log(pipe_fields_incidentes_values2)

          let pipe_incidentes_id = '302487533'

          this.pipefy.createCard(this.pipefy.criaBody(pipe_fields_incidentes, pipe_fields_incidentes_values2, pipe_incidentes_id)).subscribe((res) => {
            // console.log(res)
          })
        }
      }, (error: any) => {
        this.spinner.hide()
        // console.log(error.status)
        this.error_counter = this.error_counter + 1
        if (this.error_counter < 2) {
          if (error.status == 401) {
            // console.log("entrei em error.status")
            Swal.fire({
              title: 'Ops, sua sessão expirou!',
              text: 'Por favor, saia e logue novamente.',
              icon: 'error',
              confirmButtonText: 'Sair',
              confirmButtonColor: '#ff6961',
              allowOutsideClick: false,
              allowEscapeKey: false
            })
              .then(() => {
                this.msalService.logoutRedirect()
              }
              )
          } else {
            Swal.fire({
              title: 'Desculpe!',
              text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
              icon: 'error',
              confirmButtonText: 'Ok',
              confirmButtonColor: '#88D600',
              allowOutsideClick: false,
              allowEscapeKey: false
            })
          }
        }
        else {
          this.spinner.hide()
          Swal.fire({
            title: 'Desculpe!',
            html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/FZZSofCP" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
            icon: 'error',
            confirmButtonColor: '#88D600',
          })
          this.anexosUrl = []
        }
      })
    }

    criarBody()
  }

  delay(time: any) {
    return new Promise(resolve => setTimeout(resolve, time));
  }

  save(event: any): void {
    var files = event.target.files;
    console.log(files)
    var extPermitidas = ['xlsx','csv','doc','pdf'];


    for (var i = 0; i < files.length; i++) {
      var extArquivo = files[i].name.split('.').pop();
      var extensionParts = files[i].name.split('.')
      console.log(extArquivo)
      if(typeof extPermitidas.find(function(ext){ return extArquivo == ext; }) == 'undefined' || extensionParts.length > 2) {
        console.log("entrei em extensao nao permitida")
        alert(files[i].name + " - Extensão Não permitida");
        this.formulario.get('anexo')?.setValue("")
      } else {
        this.anexos.push(files[i])
      }
    }
  }

  async getPipesUrl(files: any) {
    this.erro = false
    for (var i = 0; i < files.length; i++) {
      var fileContent = files[i]
      let fileName = files[i].name
      const reader = new FileReader();
      reader.readAsDataURL(fileContent);
      reader.onload = () => {
        fileContent = reader.result?.toString()
        var fileContentStart = fileContent.indexOf(",")

        fileContent = fileContent.substring(fileContentStart + 1)
        this.pipefy.getFileUrl(fileContent, fileName).subscribe((res) => {
          let url = res.data.attachments_url_pipefy[0]
          this.anexosUrl.push(url)
        },
        (error: any) => {
          this.erro = true
          this.spinner.hide()
          this.error_counter = this.error_counter + 1
          if (this.error_counter < 2) {
            if (error.status == 401) {
              // console.log("entrei em error.status")
              Swal.fire({
                title: 'Ops, sua sessão expirou!',
                text: 'Por favor, saia e logue novamente.',
                icon: 'error',
                confirmButtonText: 'Sair',
                confirmButtonColor: '#ff6961',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
                .then(() => {
                  this.msalService.logoutRedirect()
                }
                )
            } else {
              Swal.fire({
                title: 'Desculpe!',
                text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#88D600',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
            }
          }
          else {
            this.spinner.hide()
            Swal.fire({
              title: 'Desculpe!',
              html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/FZZSofCP" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
              icon: 'error',
              confirmButtonColor: '#88D600',
            })
            this.anexosUrl = []
          }
        })
      }
    }
    return new Promise<void>((resolve) => { resolve(); });
  }

  removeFile(file: File) {
    const index: number = this.anexos.indexOf(file);
    this.anexos.splice(index, 1)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500
    })
    if(this.anexos.length == 0){
      this.formulario.controls['anexar_solicitacao_de_auditoria'].markAsTouched()
      this.formulario.get('anexar_solicitacao_de_auditoria')?.setValue('')
    }
  }

  resetform() {

    for (let field in this.formulario.controls) {
      this.formulario.controls[field].setValue('');
    }
    this.formulario.markAsUntouched()
    this.anexos = []
    this.anexosUrl = []
  }

  abrirPipePublico() {
    this.commomS.openNewWindow('https://app.pipefy.com/public/form/FZZSofCP');
  }


  onNumeroDeNotasChange(event: any){
    let numeroDeNotas = event.target.value
    // console.log(numeroDeNotas)
    numeroDeNotas = parseInt(numeroDeNotas)


    if(numeroDeNotas >= 1){

      this.formulario.get('n_mero_da_nota_fiscal_nota_1')?.setValidators([Validators.required])
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_1')?.setValidators([Validators.required])
      this.formulario.get('desconto_concedido_nota_1')?.setValidators([Validators.required])
      this.formulario.get('juros_aplicado_nota_1')?.setValidators([Validators.required])

      this.formulario.get('n_mero_da_nota_fiscal_nota_1')?.updateValueAndValidity()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_1')?.updateValueAndValidity()
      this.formulario.get('desconto_concedido_nota_1')?.updateValueAndValidity()
      this.formulario.get('juros_aplicado_nota_1')?.updateValueAndValidity()

      this.formulario.get('n_mero_da_nota_fiscal_nota_2')?.clearValidators()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_2')?.clearValidators()
      this.formulario.get('desconto_concedido_nota_2')?.clearValidators()
      this.formulario.get('juros_aplicado_nota_2')?.clearValidators()

      this.formulario.get('n_mero_da_nota_fiscal_nota_2')?.updateValueAndValidity()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_2')?.updateValueAndValidity()
      this.formulario.get('desconto_concedido_nota_2')?.updateValueAndValidity()
      this.formulario.get('juros_aplicado_nota_2')?.updateValueAndValidity()

      this.formulario.get('n_mero_da_nota_fiscal_nota_3')?.clearValidators()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_3')?.clearValidators()
      this.formulario.get('desconto_concedido_nota_3')?.clearValidators()
      this.formulario.get('juros_aplicado_nota_3')?.clearValidators()

      this.formulario.get('n_mero_da_nota_fiscal_nota_3')?.updateValueAndValidity()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_3')?.updateValueAndValidity()
      this.formulario.get('desconto_concedido_nota_3')?.updateValueAndValidity()
      this.formulario.get('juros_aplicado_nota_3')?.updateValueAndValidity()


      this.formulario.get('n_mero_da_nota_fiscal_nota_4')?.clearValidators()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_4')?.clearValidators()
      this.formulario.get('desconto_concedido_nota_4')?.clearValidators()
      this.formulario.get('juros_aplicado_nota_4')?.clearValidators()

      this.formulario.get('n_mero_da_nota_fiscal_nota_4')?.updateValueAndValidity()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_4')?.updateValueAndValidity()
      this.formulario.get('desconto_concedido_nota_4')?.updateValueAndValidity()
      this.formulario.get('juros_aplicado_nota_4')?.updateValueAndValidity()


      this.formulario.get('n_mero_da_nota_fiscal_nota_5')?.clearValidators()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_5')?.clearValidators()
      this.formulario.get('desconto_concedido_nota_5')?.clearValidators()
      this.formulario.get('juros_aplicado_nota_5')?.clearValidators()

      this.formulario.get('n_mero_da_nota_fiscal_nota_5')?.updateValueAndValidity()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_5')?.updateValueAndValidity()
      this.formulario.get('desconto_concedido_nota_5')?.updateValueAndValidity()
      this.formulario.get('juros_aplicado_nota_5')?.updateValueAndValidity()


      this.formulario.get('n_mero_da_nota_fiscal_nota_6')?.clearValidators()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_6')?.clearValidators()
      this.formulario.get('desconto_concedido_nota_6')?.clearValidators()
      this.formulario.get('juros_aplicado_nota_6')?.clearValidators()

      this.formulario.get('n_mero_da_nota_fiscal_nota_6')?.updateValueAndValidity()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_6')?.updateValueAndValidity()
      this.formulario.get('desconto_concedido_nota_6')?.updateValueAndValidity()
      this.formulario.get('juros_aplicado_nota_6')?.updateValueAndValidity()


      this.formulario.get('n_mero_da_nota_fiscal_nota_7')?.clearValidators()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_7')?.clearValidators()
      this.formulario.get('desconto_concedido_nota_7')?.clearValidators()
      this.formulario.get('juros_aplicado_nota_7')?.clearValidators()

      this.formulario.get('n_mero_da_nota_fiscal_nota_7')?.updateValueAndValidity()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_7')?.updateValueAndValidity()
      this.formulario.get('desconto_concedido_nota_7')?.updateValueAndValidity()
      this.formulario.get('juros_aplicado_nota_7')?.updateValueAndValidity()


      this.formulario.get('n_mero_da_nota_fiscal_nota_8')?.clearValidators()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_8')?.clearValidators()
      this.formulario.get('desconto_concedido_nota_8')?.clearValidators()
      this.formulario.get('juros_aplicado_nota_8')?.clearValidators()

      this.formulario.get('n_mero_da_nota_fiscal_nota_8')?.updateValueAndValidity()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_8')?.updateValueAndValidity()
      this.formulario.get('desconto_concedido_nota_8')?.updateValueAndValidity()
      this.formulario.get('juros_aplicado_nota_8')?.updateValueAndValidity()


      this.formulario.get('n_mero_da_nota_fiscal_nota_9')?.clearValidators()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_9')?.clearValidators()
      this.formulario.get('desconto_concedido_nota_9')?.clearValidators()
      this.formulario.get('juros_aplicado_nota_9')?.clearValidators()

      this.formulario.get('n_mero_da_nota_fiscal_nota_9')?.updateValueAndValidity()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_9')?.updateValueAndValidity()
      this.formulario.get('desconto_concedido_nota_9')?.updateValueAndValidity()
      this.formulario.get('juros_aplicado_nota_9')?.updateValueAndValidity()


      this.formulario.get('n_mero_da_nota_fiscal_nota_10')?.clearValidators()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_10')?.clearValidators()
      this.formulario.get('desconto_concedido_nota_10')?.clearValidators()
      this.formulario.get('juros_aplicado_nota_10')?.clearValidators()

      this.formulario.get('n_mero_da_nota_fiscal_nota_10')?.updateValueAndValidity()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_10')?.updateValueAndValidity()
      this.formulario.get('desconto_concedido_nota_10')?.updateValueAndValidity()
      this.formulario.get('juros_aplicado_nota_10')?.updateValueAndValidity()

      this.formulario.get('valor_parcial_a_ser_baixado_nota_2')?.clearValidators()
      this.formulario.get('valor_parcial_a_ser_baixado_nota_2')?.updateValueAndValidity()
      this.formulario.get('valor_do_desconto_nota_2')?.clearValidators()
      this.formulario.get('valor_do_desconto_nota_2')?.updateValueAndValidity()
      this.formulario.get('valor_dos_juros_nota_2')?.clearValidators()
      this.formulario.get('valor_dos_juros_nota_2')?.updateValueAndValidity()

      this.formulario.get('valor_parcial_a_ser_baixado_nota_3')?.clearValidators()
      this.formulario.get('valor_parcial_a_ser_baixado_nota_3')?.updateValueAndValidity()
      this.formulario.get('valor_do_desconto_nota_3')?.clearValidators()
      this.formulario.get('valor_do_desconto_nota_3')?.updateValueAndValidity()
      this.formulario.get('valor_dos_juros_nota_3')?.clearValidators()
      this.formulario.get('valor_dos_juros_nota_3')?.updateValueAndValidity()

      this.formulario.get('valor_parcial_a_ser_baixado_nota_4')?.clearValidators()
      this.formulario.get('valor_parcial_a_ser_baixado_nota_4')?.updateValueAndValidity()
      this.formulario.get('valor_do_desconto_nota_4')?.clearValidators()
      this.formulario.get('valor_do_desconto_nota_4')?.updateValueAndValidity()
      this.formulario.get('valor_dos_juros_nota_4')?.clearValidators()
      this.formulario.get('valor_dos_juros_nota_4')?.updateValueAndValidity()

      this.formulario.get('valor_parcial_a_ser_baixado_nota_5')?.clearValidators()
      this.formulario.get('valor_parcial_a_ser_baixado_nota_5')?.updateValueAndValidity()
      this.formulario.get('valor_do_desconto_nota_5')?.clearValidators()
      this.formulario.get('valor_do_desconto_nota_5')?.updateValueAndValidity()
      this.formulario.get('valor_dos_juros_nota_5')?.clearValidators()
      this.formulario.get('valor_dos_juros_nota_5')?.updateValueAndValidity()

      this.formulario.get('valor_parcial_a_ser_baixado_nota_6')?.clearValidators()
      this.formulario.get('valor_parcial_a_ser_baixado_nota_6')?.updateValueAndValidity()
      this.formulario.get('valor_do_desconto_nota_6')?.clearValidators()
      this.formulario.get('valor_do_desconto_nota_6')?.updateValueAndValidity()
      this.formulario.get('valor_dos_juros_nota_6')?.clearValidators()
      this.formulario.get('valor_dos_juros_nota_6')?.updateValueAndValidity()

      this.formulario.get('valor_parcial_a_ser_baixado_nota_7')?.clearValidators()
      this.formulario.get('valor_parcial_a_ser_baixado_nota_7')?.updateValueAndValidity()
      this.formulario.get('valor_do_desconto_nota_7')?.clearValidators()
      this.formulario.get('valor_do_desconto_nota_7')?.updateValueAndValidity()
      this.formulario.get('valor_dos_juros_nota_7')?.clearValidators()
      this.formulario.get('valor_dos_juros_nota_7')?.updateValueAndValidity()

      this.formulario.get('valor_parcial_a_ser_baixado_nota_8')?.clearValidators()
      this.formulario.get('valor_parcial_a_ser_baixado_nota_8')?.updateValueAndValidity()
      this.formulario.get('valor_do_desconto_nota_8')?.clearValidators()
      this.formulario.get('valor_do_desconto_nota_8')?.updateValueAndValidity()
      this.formulario.get('valor_dos_juros_nota_8')?.clearValidators()
      this.formulario.get('valor_dos_juros_nota_8')?.updateValueAndValidity()

      this.formulario.get('valor_parcial_a_ser_baixado_nota_9')?.clearValidators()
      this.formulario.get('valor_parcial_a_ser_baixado_nota_9')?.updateValueAndValidity()
      this.formulario.get('valor_do_desconto_nota_9')?.clearValidators()
      this.formulario.get('valor_do_desconto_nota_9')?.updateValueAndValidity()
      this.formulario.get('valor_dos_juros_nota_9')?.clearValidators()
      this.formulario.get('valor_dos_juros_nota_9')?.updateValueAndValidity()

      this.formulario.get('valor_parcial_a_ser_baixado_nota_10')?.clearValidators()
      this.formulario.get('valor_parcial_a_ser_baixado_nota_10')?.updateValueAndValidity()
      this.formulario.get('valor_do_desconto_nota_10')?.clearValidators()
      this.formulario.get('valor_do_desconto_nota_10')?.updateValueAndValidity()
      this.formulario.get('valor_dos_juros_nota_10')?.clearValidators()
      this.formulario.get('valor_dos_juros_nota_10')?.updateValueAndValidity()


    }

    if(numeroDeNotas >= 2){

      this.formulario.get('n_mero_da_nota_fiscal_nota_2')?.setValidators([Validators.required])
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_2')?.setValidators([Validators.required])
      this.formulario.get('desconto_concedido_nota_2')?.setValidators([Validators.required])
      this.formulario.get('juros_aplicado_nota_2')?.setValidators([Validators.required])

      this.formulario.get('n_mero_da_nota_fiscal_nota_2')?.updateValueAndValidity()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_2')?.updateValueAndValidity()
      this.formulario.get('desconto_concedido_nota_2')?.updateValueAndValidity()
      this.formulario.get('juros_aplicado_nota_2')?.updateValueAndValidity()


      this.formulario.get('n_mero_da_nota_fiscal_nota_3')?.clearValidators()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_3')?.clearValidators()
      this.formulario.get('desconto_concedido_nota_3')?.clearValidators()
      this.formulario.get('juros_aplicado_nota_3')?.clearValidators()

      this.formulario.get('n_mero_da_nota_fiscal_nota_3')?.updateValueAndValidity()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_3')?.updateValueAndValidity()
      this.formulario.get('desconto_concedido_nota_3')?.updateValueAndValidity()
      this.formulario.get('juros_aplicado_nota_3')?.updateValueAndValidity()


      this.formulario.get('n_mero_da_nota_fiscal_nota_4')?.clearValidators()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_4')?.clearValidators()
      this.formulario.get('desconto_concedido_nota_4')?.clearValidators()
      this.formulario.get('juros_aplicado_nota_4')?.clearValidators()

      this.formulario.get('n_mero_da_nota_fiscal_nota_4')?.updateValueAndValidity()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_4')?.updateValueAndValidity()
      this.formulario.get('desconto_concedido_nota_4')?.updateValueAndValidity()
      this.formulario.get('juros_aplicado_nota_4')?.updateValueAndValidity()


      this.formulario.get('n_mero_da_nota_fiscal_nota_5')?.clearValidators()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_5')?.clearValidators()
      this.formulario.get('desconto_concedido_nota_5')?.clearValidators()
      this.formulario.get('juros_aplicado_nota_5')?.clearValidators()

      this.formulario.get('n_mero_da_nota_fiscal_nota_5')?.updateValueAndValidity()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_5')?.updateValueAndValidity()
      this.formulario.get('desconto_concedido_nota_5')?.updateValueAndValidity()
      this.formulario.get('juros_aplicado_nota_5')?.updateValueAndValidity()


      this.formulario.get('n_mero_da_nota_fiscal_nota_6')?.clearValidators()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_6')?.clearValidators()
      this.formulario.get('desconto_concedido_nota_6')?.clearValidators()
      this.formulario.get('juros_aplicado_nota_6')?.clearValidators()

      this.formulario.get('n_mero_da_nota_fiscal_nota_6')?.updateValueAndValidity()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_6')?.updateValueAndValidity()
      this.formulario.get('desconto_concedido_nota_6')?.updateValueAndValidity()
      this.formulario.get('juros_aplicado_nota_6')?.updateValueAndValidity()


      this.formulario.get('n_mero_da_nota_fiscal_nota_7')?.clearValidators()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_7')?.clearValidators()
      this.formulario.get('desconto_concedido_nota_7')?.clearValidators()
      this.formulario.get('juros_aplicado_nota_7')?.clearValidators()

      this.formulario.get('n_mero_da_nota_fiscal_nota_7')?.updateValueAndValidity()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_7')?.updateValueAndValidity()
      this.formulario.get('desconto_concedido_nota_7')?.updateValueAndValidity()
      this.formulario.get('juros_aplicado_nota_7')?.updateValueAndValidity()


      this.formulario.get('n_mero_da_nota_fiscal_nota_8')?.clearValidators()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_8')?.clearValidators()
      this.formulario.get('desconto_concedido_nota_8')?.clearValidators()
      this.formulario.get('juros_aplicado_nota_8')?.clearValidators()

      this.formulario.get('n_mero_da_nota_fiscal_nota_8')?.updateValueAndValidity()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_8')?.updateValueAndValidity()
      this.formulario.get('desconto_concedido_nota_8')?.updateValueAndValidity()
      this.formulario.get('juros_aplicado_nota_8')?.updateValueAndValidity()


      this.formulario.get('n_mero_da_nota_fiscal_nota_9')?.clearValidators()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_9')?.clearValidators()
      this.formulario.get('desconto_concedido_nota_9')?.clearValidators()
      this.formulario.get('juros_aplicado_nota_9')?.clearValidators()

      this.formulario.get('n_mero_da_nota_fiscal_nota_9')?.updateValueAndValidity()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_9')?.updateValueAndValidity()
      this.formulario.get('desconto_concedido_nota_9')?.updateValueAndValidity()
      this.formulario.get('juros_aplicado_nota_9')?.updateValueAndValidity()


      this.formulario.get('n_mero_da_nota_fiscal_nota_10')?.clearValidators()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_10')?.clearValidators()
      this.formulario.get('desconto_concedido_nota_10')?.clearValidators()
      this.formulario.get('juros_aplicado_nota_10')?.clearValidators()

      this.formulario.get('n_mero_da_nota_fiscal_nota_10')?.updateValueAndValidity()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_10')?.updateValueAndValidity()
      this.formulario.get('desconto_concedido_nota_10')?.updateValueAndValidity()
      this.formulario.get('juros_aplicado_nota_10')?.updateValueAndValidity()

      this.formulario.get('valor_parcial_a_ser_baixado_nota_3')?.clearValidators()
      this.formulario.get('valor_parcial_a_ser_baixado_nota_3')?.updateValueAndValidity()
      this.formulario.get('valor_do_desconto_nota_3')?.clearValidators()
      this.formulario.get('valor_do_desconto_nota_3')?.updateValueAndValidity()
      this.formulario.get('valor_dos_juros_nota_3')?.clearValidators()
      this.formulario.get('valor_dos_juros_nota_3')?.updateValueAndValidity()

      this.formulario.get('valor_parcial_a_ser_baixado_nota_4')?.clearValidators()
      this.formulario.get('valor_parcial_a_ser_baixado_nota_4')?.updateValueAndValidity()
      this.formulario.get('valor_do_desconto_nota_4')?.clearValidators()
      this.formulario.get('valor_do_desconto_nota_4')?.updateValueAndValidity()
      this.formulario.get('valor_dos_juros_nota_4')?.clearValidators()
      this.formulario.get('valor_dos_juros_nota_4')?.updateValueAndValidity()

      this.formulario.get('valor_parcial_a_ser_baixado_nota_5')?.clearValidators()
      this.formulario.get('valor_parcial_a_ser_baixado_nota_5')?.updateValueAndValidity()
      this.formulario.get('valor_do_desconto_nota_5')?.clearValidators()
      this.formulario.get('valor_do_desconto_nota_5')?.updateValueAndValidity()
      this.formulario.get('valor_dos_juros_nota_5')?.clearValidators()
      this.formulario.get('valor_dos_juros_nota_5')?.updateValueAndValidity()

      this.formulario.get('valor_parcial_a_ser_baixado_nota_6')?.clearValidators()
      this.formulario.get('valor_parcial_a_ser_baixado_nota_6')?.updateValueAndValidity()
      this.formulario.get('valor_do_desconto_nota_6')?.clearValidators()
      this.formulario.get('valor_do_desconto_nota_6')?.updateValueAndValidity()
      this.formulario.get('valor_dos_juros_nota_6')?.clearValidators()
      this.formulario.get('valor_dos_juros_nota_6')?.updateValueAndValidity()

      this.formulario.get('valor_parcial_a_ser_baixado_nota_7')?.clearValidators()
      this.formulario.get('valor_parcial_a_ser_baixado_nota_7')?.updateValueAndValidity()
      this.formulario.get('valor_do_desconto_nota_7')?.clearValidators()
      this.formulario.get('valor_do_desconto_nota_7')?.updateValueAndValidity()
      this.formulario.get('valor_dos_juros_nota_7')?.clearValidators()
      this.formulario.get('valor_dos_juros_nota_7')?.updateValueAndValidity()

      this.formulario.get('valor_parcial_a_ser_baixado_nota_8')?.clearValidators()
      this.formulario.get('valor_parcial_a_ser_baixado_nota_8')?.updateValueAndValidity()
      this.formulario.get('valor_do_desconto_nota_8')?.clearValidators()
      this.formulario.get('valor_do_desconto_nota_8')?.updateValueAndValidity()
      this.formulario.get('valor_dos_juros_nota_8')?.clearValidators()
      this.formulario.get('valor_dos_juros_nota_8')?.updateValueAndValidity()

      this.formulario.get('valor_parcial_a_ser_baixado_nota_9')?.clearValidators()
      this.formulario.get('valor_parcial_a_ser_baixado_nota_9')?.updateValueAndValidity()
      this.formulario.get('valor_do_desconto_nota_9')?.clearValidators()
      this.formulario.get('valor_do_desconto_nota_9')?.updateValueAndValidity()
      this.formulario.get('valor_dos_juros_nota_9')?.clearValidators()
      this.formulario.get('valor_dos_juros_nota_9')?.updateValueAndValidity()

      this.formulario.get('valor_parcial_a_ser_baixado_nota_10')?.clearValidators()
      this.formulario.get('valor_parcial_a_ser_baixado_nota_10')?.updateValueAndValidity()
      this.formulario.get('valor_do_desconto_nota_10')?.clearValidators()
      this.formulario.get('valor_do_desconto_nota_10')?.updateValueAndValidity()
      this.formulario.get('valor_dos_juros_nota_10')?.clearValidators()
      this.formulario.get('valor_dos_juros_nota_10')?.updateValueAndValidity()



    }

    if(numeroDeNotas >= 3){

      this.formulario.get('n_mero_da_nota_fiscal_nota_3')?.setValidators([Validators.required])
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_3')?.setValidators([Validators.required])
      this.formulario.get('desconto_concedido_nota_3')?.setValidators([Validators.required])
      this.formulario.get('juros_aplicado_nota_3')?.setValidators([Validators.required])

      this.formulario.get('n_mero_da_nota_fiscal_nota_3')?.updateValueAndValidity()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_3')?.updateValueAndValidity()
      this.formulario.get('desconto_concedido_nota_3')?.updateValueAndValidity()
      this.formulario.get('juros_aplicado_nota_3')?.updateValueAndValidity()

      this.formulario.get('n_mero_da_nota_fiscal_nota_4')?.clearValidators()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_4')?.clearValidators()
      this.formulario.get('desconto_concedido_nota_4')?.clearValidators()
      this.formulario.get('juros_aplicado_nota_4')?.clearValidators()

      this.formulario.get('n_mero_da_nota_fiscal_nota_4')?.updateValueAndValidity()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_4')?.updateValueAndValidity()
      this.formulario.get('desconto_concedido_nota_4')?.updateValueAndValidity()
      this.formulario.get('juros_aplicado_nota_4')?.updateValueAndValidity()


      this.formulario.get('n_mero_da_nota_fiscal_nota_5')?.clearValidators()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_5')?.clearValidators()
      this.formulario.get('desconto_concedido_nota_5')?.clearValidators()
      this.formulario.get('juros_aplicado_nota_5')?.clearValidators()

      this.formulario.get('n_mero_da_nota_fiscal_nota_5')?.updateValueAndValidity()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_5')?.updateValueAndValidity()
      this.formulario.get('desconto_concedido_nota_5')?.updateValueAndValidity()
      this.formulario.get('juros_aplicado_nota_5')?.updateValueAndValidity()


      this.formulario.get('n_mero_da_nota_fiscal_nota_6')?.clearValidators()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_6')?.clearValidators()
      this.formulario.get('desconto_concedido_nota_6')?.clearValidators()
      this.formulario.get('juros_aplicado_nota_6')?.clearValidators()

      this.formulario.get('n_mero_da_nota_fiscal_nota_6')?.updateValueAndValidity()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_6')?.updateValueAndValidity()
      this.formulario.get('desconto_concedido_nota_6')?.updateValueAndValidity()
      this.formulario.get('juros_aplicado_nota_6')?.updateValueAndValidity()


      this.formulario.get('n_mero_da_nota_fiscal_nota_7')?.clearValidators()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_7')?.clearValidators()
      this.formulario.get('desconto_concedido_nota_7')?.clearValidators()
      this.formulario.get('juros_aplicado_nota_7')?.clearValidators()

      this.formulario.get('n_mero_da_nota_fiscal_nota_7')?.updateValueAndValidity()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_7')?.updateValueAndValidity()
      this.formulario.get('desconto_concedido_nota_7')?.updateValueAndValidity()
      this.formulario.get('juros_aplicado_nota_7')?.updateValueAndValidity()


      this.formulario.get('n_mero_da_nota_fiscal_nota_8')?.clearValidators()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_8')?.clearValidators()
      this.formulario.get('desconto_concedido_nota_8')?.clearValidators()
      this.formulario.get('juros_aplicado_nota_8')?.clearValidators()

      this.formulario.get('n_mero_da_nota_fiscal_nota_8')?.updateValueAndValidity()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_8')?.updateValueAndValidity()
      this.formulario.get('desconto_concedido_nota_8')?.updateValueAndValidity()
      this.formulario.get('juros_aplicado_nota_8')?.updateValueAndValidity()


      this.formulario.get('n_mero_da_nota_fiscal_nota_9')?.clearValidators()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_9')?.clearValidators()
      this.formulario.get('desconto_concedido_nota_9')?.clearValidators()
      this.formulario.get('juros_aplicado_nota_9')?.clearValidators()

      this.formulario.get('n_mero_da_nota_fiscal_nota_9')?.updateValueAndValidity()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_9')?.updateValueAndValidity()
      this.formulario.get('desconto_concedido_nota_9')?.updateValueAndValidity()
      this.formulario.get('juros_aplicado_nota_9')?.updateValueAndValidity()


      this.formulario.get('n_mero_da_nota_fiscal_nota_10')?.clearValidators()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_10')?.clearValidators()
      this.formulario.get('desconto_concedido_nota_10')?.clearValidators()
      this.formulario.get('juros_aplicado_nota_10')?.clearValidators()

      this.formulario.get('n_mero_da_nota_fiscal_nota_10')?.updateValueAndValidity()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_10')?.updateValueAndValidity()
      this.formulario.get('desconto_concedido_nota_10')?.updateValueAndValidity()
      this.formulario.get('juros_aplicado_nota_10')?.updateValueAndValidity()

      this.formulario.get('valor_parcial_a_ser_baixado_nota_4')?.clearValidators()
      this.formulario.get('valor_parcial_a_ser_baixado_nota_4')?.updateValueAndValidity()
      this.formulario.get('valor_do_desconto_nota_4')?.clearValidators()
      this.formulario.get('valor_do_desconto_nota_4')?.updateValueAndValidity()
      this.formulario.get('valor_dos_juros_nota_4')?.clearValidators()
      this.formulario.get('valor_dos_juros_nota_4')?.updateValueAndValidity()

      this.formulario.get('valor_parcial_a_ser_baixado_nota_5')?.clearValidators()
      this.formulario.get('valor_parcial_a_ser_baixado_nota_5')?.updateValueAndValidity()
      this.formulario.get('valor_do_desconto_nota_5')?.clearValidators()
      this.formulario.get('valor_do_desconto_nota_5')?.updateValueAndValidity()
      this.formulario.get('valor_dos_juros_nota_5')?.clearValidators()
      this.formulario.get('valor_dos_juros_nota_5')?.updateValueAndValidity()

      this.formulario.get('valor_parcial_a_ser_baixado_nota_6')?.clearValidators()
      this.formulario.get('valor_parcial_a_ser_baixado_nota_6')?.updateValueAndValidity()
      this.formulario.get('valor_do_desconto_nota_6')?.clearValidators()
      this.formulario.get('valor_do_desconto_nota_6')?.updateValueAndValidity()
      this.formulario.get('valor_dos_juros_nota_6')?.clearValidators()
      this.formulario.get('valor_dos_juros_nota_6')?.updateValueAndValidity()

      this.formulario.get('valor_parcial_a_ser_baixado_nota_7')?.clearValidators()
      this.formulario.get('valor_parcial_a_ser_baixado_nota_7')?.updateValueAndValidity()
      this.formulario.get('valor_do_desconto_nota_7')?.clearValidators()
      this.formulario.get('valor_do_desconto_nota_7')?.updateValueAndValidity()
      this.formulario.get('valor_dos_juros_nota_7')?.clearValidators()
      this.formulario.get('valor_dos_juros_nota_7')?.updateValueAndValidity()

      this.formulario.get('valor_parcial_a_ser_baixado_nota_8')?.clearValidators()
      this.formulario.get('valor_parcial_a_ser_baixado_nota_8')?.updateValueAndValidity()
      this.formulario.get('valor_do_desconto_nota_8')?.clearValidators()
      this.formulario.get('valor_do_desconto_nota_8')?.updateValueAndValidity()
      this.formulario.get('valor_dos_juros_nota_8')?.clearValidators()
      this.formulario.get('valor_dos_juros_nota_8')?.updateValueAndValidity()

      this.formulario.get('valor_parcial_a_ser_baixado_nota_9')?.clearValidators()
      this.formulario.get('valor_parcial_a_ser_baixado_nota_9')?.updateValueAndValidity()
      this.formulario.get('valor_do_desconto_nota_9')?.clearValidators()
      this.formulario.get('valor_do_desconto_nota_9')?.updateValueAndValidity()
      this.formulario.get('valor_dos_juros_nota_9')?.clearValidators()
      this.formulario.get('valor_dos_juros_nota_9')?.updateValueAndValidity()

      this.formulario.get('valor_parcial_a_ser_baixado_nota_10')?.clearValidators()
      this.formulario.get('valor_parcial_a_ser_baixado_nota_10')?.updateValueAndValidity()
      this.formulario.get('valor_do_desconto_nota_10')?.clearValidators()
      this.formulario.get('valor_do_desconto_nota_10')?.updateValueAndValidity()
      this.formulario.get('valor_dos_juros_nota_10')?.clearValidators()
      this.formulario.get('valor_dos_juros_nota_10')?.updateValueAndValidity()


    }

    if(numeroDeNotas >= 4){

      this.formulario.get('n_mero_da_nota_fiscal_nota_4')?.setValidators([Validators.required])
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_4')?.setValidators([Validators.required])
      this.formulario.get('desconto_concedido_nota_4')?.setValidators([Validators.required])
      this.formulario.get('juros_aplicado_nota_4')?.setValidators([Validators.required])

      this.formulario.get('n_mero_da_nota_fiscal_nota_4')?.updateValueAndValidity()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_4')?.updateValueAndValidity()
      this.formulario.get('desconto_concedido_nota_4')?.updateValueAndValidity()
      this.formulario.get('juros_aplicado_nota_4')?.updateValueAndValidity()

      this.formulario.get('n_mero_da_nota_fiscal_nota_5')?.clearValidators()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_5')?.clearValidators()
      this.formulario.get('desconto_concedido_nota_5')?.clearValidators()
      this.formulario.get('juros_aplicado_nota_5')?.clearValidators()

      this.formulario.get('n_mero_da_nota_fiscal_nota_5')?.updateValueAndValidity()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_5')?.updateValueAndValidity()
      this.formulario.get('desconto_concedido_nota_5')?.updateValueAndValidity()
      this.formulario.get('juros_aplicado_nota_5')?.updateValueAndValidity()


      this.formulario.get('n_mero_da_nota_fiscal_nota_6')?.clearValidators()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_6')?.clearValidators()
      this.formulario.get('desconto_concedido_nota_6')?.clearValidators()
      this.formulario.get('juros_aplicado_nota_6')?.clearValidators()

      this.formulario.get('n_mero_da_nota_fiscal_nota_6')?.updateValueAndValidity()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_6')?.updateValueAndValidity()
      this.formulario.get('desconto_concedido_nota_6')?.updateValueAndValidity()
      this.formulario.get('juros_aplicado_nota_6')?.updateValueAndValidity()


      this.formulario.get('n_mero_da_nota_fiscal_nota_7')?.clearValidators()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_7')?.clearValidators()
      this.formulario.get('desconto_concedido_nota_7')?.clearValidators()
      this.formulario.get('juros_aplicado_nota_7')?.clearValidators()

      this.formulario.get('n_mero_da_nota_fiscal_nota_7')?.updateValueAndValidity()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_7')?.updateValueAndValidity()
      this.formulario.get('desconto_concedido_nota_7')?.updateValueAndValidity()
      this.formulario.get('juros_aplicado_nota_7')?.updateValueAndValidity()


      this.formulario.get('n_mero_da_nota_fiscal_nota_8')?.clearValidators()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_8')?.clearValidators()
      this.formulario.get('desconto_concedido_nota_8')?.clearValidators()
      this.formulario.get('juros_aplicado_nota_8')?.clearValidators()

      this.formulario.get('n_mero_da_nota_fiscal_nota_8')?.updateValueAndValidity()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_8')?.updateValueAndValidity()
      this.formulario.get('desconto_concedido_nota_8')?.updateValueAndValidity()
      this.formulario.get('juros_aplicado_nota_8')?.updateValueAndValidity()


      this.formulario.get('n_mero_da_nota_fiscal_nota_9')?.clearValidators()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_9')?.clearValidators()
      this.formulario.get('desconto_concedido_nota_9')?.clearValidators()
      this.formulario.get('juros_aplicado_nota_9')?.clearValidators()

      this.formulario.get('n_mero_da_nota_fiscal_nota_9')?.updateValueAndValidity()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_9')?.updateValueAndValidity()
      this.formulario.get('desconto_concedido_nota_9')?.updateValueAndValidity()
      this.formulario.get('juros_aplicado_nota_9')?.updateValueAndValidity()


      this.formulario.get('n_mero_da_nota_fiscal_nota_10')?.clearValidators()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_10')?.clearValidators()
      this.formulario.get('desconto_concedido_nota_10')?.clearValidators()
      this.formulario.get('juros_aplicado_nota_10')?.clearValidators()

      this.formulario.get('n_mero_da_nota_fiscal_nota_10')?.updateValueAndValidity()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_10')?.updateValueAndValidity()
      this.formulario.get('desconto_concedido_nota_10')?.updateValueAndValidity()
      this.formulario.get('juros_aplicado_nota_10')?.updateValueAndValidity()

      this.formulario.get('valor_parcial_a_ser_baixado_nota_5')?.clearValidators()
      this.formulario.get('valor_parcial_a_ser_baixado_nota_5')?.updateValueAndValidity()
      this.formulario.get('valor_do_desconto_nota_5')?.clearValidators()
      this.formulario.get('valor_do_desconto_nota_5')?.updateValueAndValidity()
      this.formulario.get('valor_dos_juros_nota_5')?.clearValidators()
      this.formulario.get('valor_dos_juros_nota_5')?.updateValueAndValidity()

      this.formulario.get('valor_parcial_a_ser_baixado_nota_6')?.clearValidators()
      this.formulario.get('valor_parcial_a_ser_baixado_nota_6')?.updateValueAndValidity()
      this.formulario.get('valor_do_desconto_nota_6')?.clearValidators()
      this.formulario.get('valor_do_desconto_nota_6')?.updateValueAndValidity()
      this.formulario.get('valor_dos_juros_nota_6')?.clearValidators()
      this.formulario.get('valor_dos_juros_nota_6')?.updateValueAndValidity()

      this.formulario.get('valor_parcial_a_ser_baixado_nota_7')?.clearValidators()
      this.formulario.get('valor_parcial_a_ser_baixado_nota_7')?.updateValueAndValidity()
      this.formulario.get('valor_do_desconto_nota_7')?.clearValidators()
      this.formulario.get('valor_do_desconto_nota_7')?.updateValueAndValidity()
      this.formulario.get('valor_dos_juros_nota_7')?.clearValidators()
      this.formulario.get('valor_dos_juros_nota_7')?.updateValueAndValidity()

      this.formulario.get('valor_parcial_a_ser_baixado_nota_8')?.clearValidators()
      this.formulario.get('valor_parcial_a_ser_baixado_nota_8')?.updateValueAndValidity()
      this.formulario.get('valor_do_desconto_nota_8')?.clearValidators()
      this.formulario.get('valor_do_desconto_nota_8')?.updateValueAndValidity()
      this.formulario.get('valor_dos_juros_nota_8')?.clearValidators()
      this.formulario.get('valor_dos_juros_nota_8')?.updateValueAndValidity()

      this.formulario.get('valor_parcial_a_ser_baixado_nota_9')?.clearValidators()
      this.formulario.get('valor_parcial_a_ser_baixado_nota_9')?.updateValueAndValidity()
      this.formulario.get('valor_do_desconto_nota_9')?.clearValidators()
      this.formulario.get('valor_do_desconto_nota_9')?.updateValueAndValidity()
      this.formulario.get('valor_dos_juros_nota_9')?.clearValidators()
      this.formulario.get('valor_dos_juros_nota_9')?.updateValueAndValidity()

      this.formulario.get('valor_parcial_a_ser_baixado_nota_10')?.clearValidators()
      this.formulario.get('valor_parcial_a_ser_baixado_nota_10')?.updateValueAndValidity()
      this.formulario.get('valor_do_desconto_nota_10')?.clearValidators()
      this.formulario.get('valor_do_desconto_nota_10')?.updateValueAndValidity()
      this.formulario.get('valor_dos_juros_nota_10')?.clearValidators()
      this.formulario.get('valor_dos_juros_nota_10')?.updateValueAndValidity()


    }

    if(numeroDeNotas >= 5){

      this.formulario.get('n_mero_da_nota_fiscal_nota_5')?.setValidators([Validators.required])
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_5')?.setValidators([Validators.required])
      this.formulario.get('desconto_concedido_nota_5')?.setValidators([Validators.required])
      this.formulario.get('juros_aplicado_nota_5')?.setValidators([Validators.required])

      this.formulario.get('n_mero_da_nota_fiscal_nota_5')?.updateValueAndValidity()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_5')?.updateValueAndValidity()
      this.formulario.get('desconto_concedido_nota_5')?.updateValueAndValidity()
      this.formulario.get('juros_aplicado_nota_5')?.updateValueAndValidity()

      this.formulario.get('n_mero_da_nota_fiscal_nota_6')?.clearValidators()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_6')?.clearValidators()
      this.formulario.get('desconto_concedido_nota_6')?.clearValidators()
      this.formulario.get('juros_aplicado_nota_6')?.clearValidators()

      this.formulario.get('n_mero_da_nota_fiscal_nota_6')?.updateValueAndValidity()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_6')?.updateValueAndValidity()
      this.formulario.get('desconto_concedido_nota_6')?.updateValueAndValidity()
      this.formulario.get('juros_aplicado_nota_6')?.updateValueAndValidity()


      this.formulario.get('n_mero_da_nota_fiscal_nota_7')?.clearValidators()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_7')?.clearValidators()
      this.formulario.get('desconto_concedido_nota_7')?.clearValidators()
      this.formulario.get('juros_aplicado_nota_7')?.clearValidators()

      this.formulario.get('n_mero_da_nota_fiscal_nota_7')?.updateValueAndValidity()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_7')?.updateValueAndValidity()
      this.formulario.get('desconto_concedido_nota_7')?.updateValueAndValidity()
      this.formulario.get('juros_aplicado_nota_7')?.updateValueAndValidity()


      this.formulario.get('n_mero_da_nota_fiscal_nota_8')?.clearValidators()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_8')?.clearValidators()
      this.formulario.get('desconto_concedido_nota_8')?.clearValidators()
      this.formulario.get('juros_aplicado_nota_8')?.clearValidators()

      this.formulario.get('n_mero_da_nota_fiscal_nota_8')?.updateValueAndValidity()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_8')?.updateValueAndValidity()
      this.formulario.get('desconto_concedido_nota_8')?.updateValueAndValidity()
      this.formulario.get('juros_aplicado_nota_8')?.updateValueAndValidity()


      this.formulario.get('n_mero_da_nota_fiscal_nota_9')?.clearValidators()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_9')?.clearValidators()
      this.formulario.get('desconto_concedido_nota_9')?.clearValidators()
      this.formulario.get('juros_aplicado_nota_9')?.clearValidators()

      this.formulario.get('n_mero_da_nota_fiscal_nota_9')?.updateValueAndValidity()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_9')?.updateValueAndValidity()
      this.formulario.get('desconto_concedido_nota_9')?.updateValueAndValidity()
      this.formulario.get('juros_aplicado_nota_9')?.updateValueAndValidity()


      this.formulario.get('n_mero_da_nota_fiscal_nota_10')?.clearValidators()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_10')?.clearValidators()
      this.formulario.get('desconto_concedido_nota_10')?.clearValidators()
      this.formulario.get('juros_aplicado_nota_10')?.clearValidators()

      this.formulario.get('n_mero_da_nota_fiscal_nota_10')?.updateValueAndValidity()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_10')?.updateValueAndValidity()
      this.formulario.get('desconto_concedido_nota_10')?.updateValueAndValidity()
      this.formulario.get('juros_aplicado_nota_10')?.updateValueAndValidity()

      this.formulario.get('valor_parcial_a_ser_baixado_nota_6')?.clearValidators()
      this.formulario.get('valor_parcial_a_ser_baixado_nota_6')?.updateValueAndValidity()
      this.formulario.get('valor_do_desconto_nota_6')?.clearValidators()
      this.formulario.get('valor_do_desconto_nota_6')?.updateValueAndValidity()
      this.formulario.get('valor_dos_juros_nota_6')?.clearValidators()
      this.formulario.get('valor_dos_juros_nota_6')?.updateValueAndValidity()

      this.formulario.get('valor_parcial_a_ser_baixado_nota_7')?.clearValidators()
      this.formulario.get('valor_parcial_a_ser_baixado_nota_7')?.updateValueAndValidity()
      this.formulario.get('valor_do_desconto_nota_7')?.clearValidators()
      this.formulario.get('valor_do_desconto_nota_7')?.updateValueAndValidity()
      this.formulario.get('valor_dos_juros_nota_7')?.clearValidators()
      this.formulario.get('valor_dos_juros_nota_7')?.updateValueAndValidity()

      this.formulario.get('valor_parcial_a_ser_baixado_nota_8')?.clearValidators()
      this.formulario.get('valor_parcial_a_ser_baixado_nota_8')?.updateValueAndValidity()
      this.formulario.get('valor_do_desconto_nota_8')?.clearValidators()
      this.formulario.get('valor_do_desconto_nota_8')?.updateValueAndValidity()
      this.formulario.get('valor_dos_juros_nota_8')?.clearValidators()
      this.formulario.get('valor_dos_juros_nota_8')?.updateValueAndValidity()

      this.formulario.get('valor_parcial_a_ser_baixado_nota_9')?.clearValidators()
      this.formulario.get('valor_parcial_a_ser_baixado_nota_9')?.updateValueAndValidity()
      this.formulario.get('valor_do_desconto_nota_9')?.clearValidators()
      this.formulario.get('valor_do_desconto_nota_9')?.updateValueAndValidity()
      this.formulario.get('valor_dos_juros_nota_9')?.clearValidators()
      this.formulario.get('valor_dos_juros_nota_9')?.updateValueAndValidity()

      this.formulario.get('valor_parcial_a_ser_baixado_nota_10')?.clearValidators()
      this.formulario.get('valor_parcial_a_ser_baixado_nota_10')?.updateValueAndValidity()
      this.formulario.get('valor_do_desconto_nota_10')?.clearValidators()
      this.formulario.get('valor_do_desconto_nota_10')?.updateValueAndValidity()
      this.formulario.get('valor_dos_juros_nota_10')?.clearValidators()
      this.formulario.get('valor_dos_juros_nota_10')?.updateValueAndValidity()


    }

    if(numeroDeNotas >= 6){

      this.formulario.get('n_mero_da_nota_fiscal_nota_6')?.setValidators([Validators.required])
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_6')?.setValidators([Validators.required])
      this.formulario.get('desconto_concedido_nota_6')?.setValidators([Validators.required])
      this.formulario.get('juros_aplicado_nota_6')?.setValidators([Validators.required])

      this.formulario.get('n_mero_da_nota_fiscal_nota_6')?.updateValueAndValidity()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_6')?.updateValueAndValidity()
      this.formulario.get('desconto_concedido_nota_6')?.updateValueAndValidity()
      this.formulario.get('juros_aplicado_nota_6')?.updateValueAndValidity()

      this.formulario.get('n_mero_da_nota_fiscal_nota_7')?.clearValidators()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_7')?.clearValidators()
      this.formulario.get('desconto_concedido_nota_7')?.clearValidators()
      this.formulario.get('juros_aplicado_nota_7')?.clearValidators()

      this.formulario.get('n_mero_da_nota_fiscal_nota_7')?.updateValueAndValidity()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_7')?.updateValueAndValidity()
      this.formulario.get('desconto_concedido_nota_7')?.updateValueAndValidity()
      this.formulario.get('juros_aplicado_nota_7')?.updateValueAndValidity()


      this.formulario.get('n_mero_da_nota_fiscal_nota_8')?.clearValidators()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_8')?.clearValidators()
      this.formulario.get('desconto_concedido_nota_8')?.clearValidators()
      this.formulario.get('juros_aplicado_nota_8')?.clearValidators()

      this.formulario.get('n_mero_da_nota_fiscal_nota_8')?.updateValueAndValidity()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_8')?.updateValueAndValidity()
      this.formulario.get('desconto_concedido_nota_8')?.updateValueAndValidity()
      this.formulario.get('juros_aplicado_nota_8')?.updateValueAndValidity()


      this.formulario.get('n_mero_da_nota_fiscal_nota_9')?.clearValidators()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_9')?.clearValidators()
      this.formulario.get('desconto_concedido_nota_9')?.clearValidators()
      this.formulario.get('juros_aplicado_nota_9')?.clearValidators()

      this.formulario.get('n_mero_da_nota_fiscal_nota_9')?.updateValueAndValidity()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_9')?.updateValueAndValidity()
      this.formulario.get('desconto_concedido_nota_9')?.updateValueAndValidity()
      this.formulario.get('juros_aplicado_nota_9')?.updateValueAndValidity()


      this.formulario.get('n_mero_da_nota_fiscal_nota_10')?.clearValidators()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_10')?.clearValidators()
      this.formulario.get('desconto_concedido_nota_10')?.clearValidators()
      this.formulario.get('juros_aplicado_nota_10')?.clearValidators()

      this.formulario.get('n_mero_da_nota_fiscal_nota_10')?.updateValueAndValidity()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_10')?.updateValueAndValidity()
      this.formulario.get('desconto_concedido_nota_10')?.updateValueAndValidity()
      this.formulario.get('juros_aplicado_nota_10')?.updateValueAndValidity()

      this.formulario.get('valor_parcial_a_ser_baixado_nota_7')?.clearValidators()
      this.formulario.get('valor_parcial_a_ser_baixado_nota_7')?.updateValueAndValidity()
      this.formulario.get('valor_do_desconto_nota_7')?.clearValidators()
      this.formulario.get('valor_do_desconto_nota_7')?.updateValueAndValidity()
      this.formulario.get('valor_dos_juros_nota_7')?.clearValidators()
      this.formulario.get('valor_dos_juros_nota_7')?.updateValueAndValidity()

      this.formulario.get('valor_parcial_a_ser_baixado_nota_8')?.clearValidators()
      this.formulario.get('valor_parcial_a_ser_baixado_nota_8')?.updateValueAndValidity()
      this.formulario.get('valor_do_desconto_nota_8')?.clearValidators()
      this.formulario.get('valor_do_desconto_nota_8')?.updateValueAndValidity()
      this.formulario.get('valor_dos_juros_nota_8')?.clearValidators()
      this.formulario.get('valor_dos_juros_nota_8')?.updateValueAndValidity()

      this.formulario.get('valor_parcial_a_ser_baixado_nota_9')?.clearValidators()
      this.formulario.get('valor_parcial_a_ser_baixado_nota_9')?.updateValueAndValidity()
      this.formulario.get('valor_do_desconto_nota_9')?.clearValidators()
      this.formulario.get('valor_do_desconto_nota_9')?.updateValueAndValidity()
      this.formulario.get('valor_dos_juros_nota_9')?.clearValidators()
      this.formulario.get('valor_dos_juros_nota_9')?.updateValueAndValidity()

      this.formulario.get('valor_parcial_a_ser_baixado_nota_10')?.clearValidators()
      this.formulario.get('valor_parcial_a_ser_baixado_nota_10')?.updateValueAndValidity()
      this.formulario.get('valor_do_desconto_nota_10')?.clearValidators()
      this.formulario.get('valor_do_desconto_nota_10')?.updateValueAndValidity()
      this.formulario.get('valor_dos_juros_nota_10')?.clearValidators()
      this.formulario.get('valor_dos_juros_nota_10')?.updateValueAndValidity()

    }

    if(numeroDeNotas >= 7){

      this.formulario.get('n_mero_da_nota_fiscal_nota_7')?.setValidators([Validators.required])
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_7')?.setValidators([Validators.required])
      this.formulario.get('desconto_concedido_nota_7')?.setValidators([Validators.required])
      this.formulario.get('juros_aplicado_nota_7')?.setValidators([Validators.required])

      this.formulario.get('n_mero_da_nota_fiscal_nota_7')?.updateValueAndValidity()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_7')?.updateValueAndValidity()
      this.formulario.get('desconto_concedido_nota_7')?.updateValueAndValidity()
      this.formulario.get('juros_aplicado_nota_7')?.updateValueAndValidity()

      this.formulario.get('n_mero_da_nota_fiscal_nota_8')?.clearValidators()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_8')?.clearValidators()
      this.formulario.get('desconto_concedido_nota_8')?.clearValidators()
      this.formulario.get('juros_aplicado_nota_8')?.clearValidators()

      this.formulario.get('n_mero_da_nota_fiscal_nota_8')?.updateValueAndValidity()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_8')?.updateValueAndValidity()
      this.formulario.get('desconto_concedido_nota_8')?.updateValueAndValidity()
      this.formulario.get('juros_aplicado_nota_8')?.updateValueAndValidity()


      this.formulario.get('n_mero_da_nota_fiscal_nota_9')?.clearValidators()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_9')?.clearValidators()
      this.formulario.get('desconto_concedido_nota_9')?.clearValidators()
      this.formulario.get('juros_aplicado_nota_9')?.clearValidators()

      this.formulario.get('n_mero_da_nota_fiscal_nota_9')?.updateValueAndValidity()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_9')?.updateValueAndValidity()
      this.formulario.get('desconto_concedido_nota_9')?.updateValueAndValidity()
      this.formulario.get('juros_aplicado_nota_9')?.updateValueAndValidity()


      this.formulario.get('n_mero_da_nota_fiscal_nota_10')?.clearValidators()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_10')?.clearValidators()
      this.formulario.get('desconto_concedido_nota_10')?.clearValidators()
      this.formulario.get('juros_aplicado_nota_10')?.clearValidators()

      this.formulario.get('n_mero_da_nota_fiscal_nota_10')?.updateValueAndValidity()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_10')?.updateValueAndValidity()
      this.formulario.get('desconto_concedido_nota_10')?.updateValueAndValidity()
      this.formulario.get('juros_aplicado_nota_10')?.updateValueAndValidity()

      this.formulario.get('valor_parcial_a_ser_baixado_nota_8')?.clearValidators()
      this.formulario.get('valor_parcial_a_ser_baixado_nota_8')?.updateValueAndValidity()
      this.formulario.get('valor_do_desconto_nota_8')?.clearValidators()
      this.formulario.get('valor_do_desconto_nota_8')?.updateValueAndValidity()
      this.formulario.get('valor_dos_juros_nota_8')?.clearValidators()
      this.formulario.get('valor_dos_juros_nota_8')?.updateValueAndValidity()

      this.formulario.get('valor_parcial_a_ser_baixado_nota_9')?.clearValidators()
      this.formulario.get('valor_parcial_a_ser_baixado_nota_9')?.updateValueAndValidity()
      this.formulario.get('valor_do_desconto_nota_9')?.clearValidators()
      this.formulario.get('valor_do_desconto_nota_9')?.updateValueAndValidity()
      this.formulario.get('valor_dos_juros_nota_9')?.clearValidators()
      this.formulario.get('valor_dos_juros_nota_9')?.updateValueAndValidity()

      this.formulario.get('valor_parcial_a_ser_baixado_nota_10')?.clearValidators()
      this.formulario.get('valor_parcial_a_ser_baixado_nota_10')?.updateValueAndValidity()
      this.formulario.get('valor_do_desconto_nota_10')?.clearValidators()
      this.formulario.get('valor_do_desconto_nota_10')?.updateValueAndValidity()
      this.formulario.get('valor_dos_juros_nota_10')?.clearValidators()
      this.formulario.get('valor_dos_juros_nota_10')?.updateValueAndValidity()


    }

    if(numeroDeNotas >= 8){

      this.formulario.get('n_mero_da_nota_fiscal_nota_8')?.setValidators([Validators.required])
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_8')?.setValidators([Validators.required])
      this.formulario.get('desconto_concedido_nota_8')?.setValidators([Validators.required])
      this.formulario.get('juros_aplicado_nota_8')?.setValidators([Validators.required])

      this.formulario.get('n_mero_da_nota_fiscal_nota_8')?.updateValueAndValidity()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_8')?.updateValueAndValidity()
      this.formulario.get('desconto_concedido_nota_8')?.updateValueAndValidity()
      this.formulario.get('juros_aplicado_nota_8')?.updateValueAndValidity()

      this.formulario.get('n_mero_da_nota_fiscal_nota_9')?.clearValidators()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_9')?.clearValidators()
      this.formulario.get('desconto_concedido_nota_9')?.clearValidators()
      this.formulario.get('juros_aplicado_nota_9')?.clearValidators()

      this.formulario.get('n_mero_da_nota_fiscal_nota_9')?.updateValueAndValidity()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_9')?.updateValueAndValidity()
      this.formulario.get('desconto_concedido_nota_9')?.updateValueAndValidity()
      this.formulario.get('juros_aplicado_nota_9')?.updateValueAndValidity()


      this.formulario.get('n_mero_da_nota_fiscal_nota_10')?.clearValidators()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_10')?.clearValidators()
      this.formulario.get('desconto_concedido_nota_10')?.clearValidators()
      this.formulario.get('juros_aplicado_nota_10')?.clearValidators()

      this.formulario.get('n_mero_da_nota_fiscal_nota_10')?.updateValueAndValidity()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_10')?.updateValueAndValidity()
      this.formulario.get('desconto_concedido_nota_10')?.updateValueAndValidity()
      this.formulario.get('juros_aplicado_nota_10')?.updateValueAndValidity()

      this.formulario.get('valor_parcial_a_ser_baixado_nota_9')?.clearValidators()
      this.formulario.get('valor_parcial_a_ser_baixado_nota_9')?.updateValueAndValidity()
      this.formulario.get('valor_do_desconto_nota_9')?.clearValidators()
      this.formulario.get('valor_do_desconto_nota_9')?.updateValueAndValidity()
      this.formulario.get('valor_dos_juros_nota_9')?.clearValidators()
      this.formulario.get('valor_dos_juros_nota_9')?.updateValueAndValidity()

      this.formulario.get('valor_parcial_a_ser_baixado_nota_10')?.clearValidators()
      this.formulario.get('valor_parcial_a_ser_baixado_nota_10')?.updateValueAndValidity()
      this.formulario.get('valor_do_desconto_nota_10')?.clearValidators()
      this.formulario.get('valor_do_desconto_nota_10')?.updateValueAndValidity()
      this.formulario.get('valor_dos_juros_nota_10')?.clearValidators()
      this.formulario.get('valor_dos_juros_nota_10')?.updateValueAndValidity()

    }

    if(numeroDeNotas >= 9){

      this.formulario.get('n_mero_da_nota_fiscal_nota_9')?.setValidators([Validators.required])
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_9')?.setValidators([Validators.required])
      this.formulario.get('desconto_concedido_nota_9')?.setValidators([Validators.required])
      this.formulario.get('juros_aplicado_nota_9')?.setValidators([Validators.required])

      this.formulario.get('n_mero_da_nota_fiscal_nota_9')?.updateValueAndValidity()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_9')?.updateValueAndValidity()
      this.formulario.get('desconto_concedido_nota_9')?.updateValueAndValidity()
      this.formulario.get('juros_aplicado_nota_9')?.updateValueAndValidity()

      this.formulario.get('n_mero_da_nota_fiscal_nota_10')?.clearValidators()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_10')?.clearValidators()
      this.formulario.get('desconto_concedido_nota_10')?.clearValidators()
      this.formulario.get('juros_aplicado_nota_10')?.clearValidators()

      this.formulario.get('n_mero_da_nota_fiscal_nota_10')?.updateValueAndValidity()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_10')?.updateValueAndValidity()
      this.formulario.get('desconto_concedido_nota_10')?.updateValueAndValidity()
      this.formulario.get('juros_aplicado_nota_10')?.updateValueAndValidity()

      this.formulario.get('valor_parcial_a_ser_baixado_nota_10')?.clearValidators()
      this.formulario.get('valor_parcial_a_ser_baixado_nota_10')?.updateValueAndValidity()
      this.formulario.get('valor_do_desconto_nota_10')?.clearValidators()
      this.formulario.get('valor_do_desconto_nota_10')?.updateValueAndValidity()
      this.formulario.get('valor_dos_juros_nota_10')?.clearValidators()
      this.formulario.get('valor_dos_juros_nota_10')?.updateValueAndValidity()

    }

    if(numeroDeNotas >= 10){

      this.formulario.get('n_mero_da_nota_fiscal_nota_10')?.setValidators([Validators.required])
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_10')?.setValidators([Validators.required])
      this.formulario.get('desconto_concedido_nota_10')?.setValidators([Validators.required])
      this.formulario.get('juros_aplicado_nota_10')?.setValidators([Validators.required])

      this.formulario.get('n_mero_da_nota_fiscal_nota_10')?.updateValueAndValidity()
      this.formulario.get('baixar_valor_total_da_nota_fiscal_nota_10')?.updateValueAndValidity()
      this.formulario.get('desconto_concedido_nota_10')?.updateValueAndValidity()
      this.formulario.get('juros_aplicado_nota_10')?.updateValueAndValidity()

    }
  }

  onChangeBaixarValorTotalNota1(event:any){
    let valor = event.target.value

    if(valor == 'Sim'){
      // console.log("entrei no sim")
      this.formulario.get('valor_parcial_a_ser_baixado_nota_1')?.clearValidators()
      this.formulario.get('valor_parcial_a_ser_baixado_nota_1')?.updateValueAndValidity()
    }

    if(valor == 'Não'){
      // console.log("entrei no Não")
      this.formulario.get('valor_parcial_a_ser_baixado_nota_1')?.setValidators([Validators.required])
      this.formulario.get('valor_parcial_a_ser_baixado_nota_1')?.updateValueAndValidity()
    }
  }

  onChangeBaixarValorTotalNota2(event:any){
    let valor = event.target.value

    if(valor == 'Sim'){
      // console.log("entrei no sim")
      this.formulario.get('valor_parcial_a_ser_baixado_nota_2')?.clearValidators()
      this.formulario.get('valor_parcial_a_ser_baixado_nota_2')?.updateValueAndValidity()
    }

    if(valor == 'Não'){
      // console.log("entrei no Não")
      this.formulario.get('valor_parcial_a_ser_baixado_nota_2')?.setValidators([Validators.required])
      this.formulario.get('valor_parcial_a_ser_baixado_nota_2')?.updateValueAndValidity()
    }
  }

  onChangeBaixarValorTotalNota3(event:any){
    let valor = event.target.value

    if(valor == 'Sim'){
      // console.log("entrei no sim")
      this.formulario.get('valor_parcial_a_ser_baixado_nota_3')?.clearValidators()
      this.formulario.get('valor_parcial_a_ser_baixado_nota_3')?.updateValueAndValidity()
    }

    if(valor == 'Não'){
      // console.log("entrei no Não")
      this.formulario.get('valor_parcial_a_ser_baixado_nota_3')?.setValidators([Validators.required])
      this.formulario.get('valor_parcial_a_ser_baixado_nota_3')?.updateValueAndValidity()
    }
  }

  onChangeBaixarValorTotalNota4(event:any){
    let valor = event.target.value

    if(valor == 'Sim'){
      // console.log("entrei no sim")
      this.formulario.get('valor_parcial_a_ser_baixado_nota_4')?.clearValidators()
      this.formulario.get('valor_parcial_a_ser_baixado_nota_4')?.updateValueAndValidity()
    }

    if(valor == 'Não'){
      // console.log("entrei no Não")
      this.formulario.get('valor_parcial_a_ser_baixado_nota_4')?.setValidators([Validators.required])
      this.formulario.get('valor_parcial_a_ser_baixado_nota_4')?.updateValueAndValidity()
    }
  }

  onChangeBaixarValorTotalNota5(event:any){
    let valor = event.target.value

    if(valor == 'Sim'){
      // console.log("entrei no sim")
      this.formulario.get('valor_parcial_a_ser_baixado_nota_5')?.clearValidators()
      this.formulario.get('valor_parcial_a_ser_baixado_nota_5')?.updateValueAndValidity()
    }

    if(valor == 'Não'){
      // console.log("entrei no Não")
      this.formulario.get('valor_parcial_a_ser_baixado_nota_5')?.setValidators([Validators.required])
      this.formulario.get('valor_parcial_a_ser_baixado_nota_5')?.updateValueAndValidity()
    }
  }

  onChangeBaixarValorTotalNota6(event:any){
    let valor = event.target.value

    if(valor == 'Sim'){
      // console.log("entrei no sim")
      this.formulario.get('valor_parcial_a_ser_baixado_nota_6')?.clearValidators()
      this.formulario.get('valor_parcial_a_ser_baixado_nota_6')?.updateValueAndValidity()
    }

    if(valor == 'Não'){
      // console.log("entrei no Não")
      this.formulario.get('valor_parcial_a_ser_baixado_nota_6')?.setValidators([Validators.required])
      this.formulario.get('valor_parcial_a_ser_baixado_nota_6')?.updateValueAndValidity()
    }
  }

  onChangeBaixarValorTotalNota7(event:any){
    let valor = event.target.value

    if(valor == 'Sim'){
      // console.log("entrei no sim")
      this.formulario.get('valor_parcial_a_ser_baixado_nota_7')?.clearValidators()
      this.formulario.get('valor_parcial_a_ser_baixado_nota_7')?.updateValueAndValidity()
    }

    if(valor == 'Não'){
      // console.log("entrei no Não")
      this.formulario.get('valor_parcial_a_ser_baixado_nota_7')?.setValidators([Validators.required])
      this.formulario.get('valor_parcial_a_ser_baixado_nota_7')?.updateValueAndValidity()
    }
  }

  onChangeBaixarValorTotalNota8(event:any){
    let valor = event.target.value

    if(valor == 'Sim'){
      // console.log("entrei no sim")
      this.formulario.get('valor_parcial_a_ser_baixado_nota_8')?.clearValidators()
      this.formulario.get('valor_parcial_a_ser_baixado_nota_8')?.updateValueAndValidity()
    }

    if(valor == 'Não'){
      // console.log("entrei no Não")
      this.formulario.get('valor_parcial_a_ser_baixado_nota_8')?.setValidators([Validators.required])
      this.formulario.get('valor_parcial_a_ser_baixado_nota_8')?.updateValueAndValidity()
    }
  }

  onChangeBaixarValorTotalNota9(event:any){
    let valor = event.target.value

    if(valor == 'Sim'){
      // console.log("entrei no sim")
      this.formulario.get('valor_parcial_a_ser_baixado_nota_9')?.clearValidators()
      this.formulario.get('valor_parcial_a_ser_baixado_nota_9')?.updateValueAndValidity()
    }

    if(valor == 'Não'){
      // console.log("entrei no Não")
      this.formulario.get('valor_parcial_a_ser_baixado_nota_9')?.setValidators([Validators.required])
      this.formulario.get('valor_parcial_a_ser_baixado_nota_9')?.updateValueAndValidity()
    }
  }

  onChangeBaixarValorTotalNota10(event:any){
    let valor = event.target.value

    if(valor == 'Sim'){
      // console.log("entrei no sim")
      this.formulario.get('valor_parcial_a_ser_baixado_nota_10')?.clearValidators()
      this.formulario.get('valor_parcial_a_ser_baixado_nota_10')?.updateValueAndValidity()
    }

    if(valor == 'Não'){
      // console.log("entrei no Não")
      this.formulario.get('valor_parcial_a_ser_baixado_nota_10')?.setValidators([Validators.required])
      this.formulario.get('valor_parcial_a_ser_baixado_nota_10')?.updateValueAndValidity()
    }
  }


  onDescontoConcedidoChange1(event:any){
    let valor = event.target.value

    if(valor == 'Não'){
      // console.log("entrei no sim")
      this.formulario.get('valor_do_desconto_nota_1')?.clearValidators()
      this.formulario.get('valor_do_desconto_nota_1')?.updateValueAndValidity()
    }

    if(valor == 'Sim'){
      // console.log("entrei no Não")
      this.formulario.get('valor_do_desconto_nota_1')?.setValidators([Validators.required])
      this.formulario.get('valor_do_desconto_nota_1')?.updateValueAndValidity()
    }
  }

  onDescontoConcedidoChange2(event:any){
    let valor = event.target.value

    if(valor == 'Não'){
      // console.log("entrei no sim")
      this.formulario.get('valor_do_desconto_nota_2')?.clearValidators()
      this.formulario.get('valor_do_desconto_nota_2')?.updateValueAndValidity()
    }

    if(valor == 'Sim'){
      // console.log("entrei no Não")
      this.formulario.get('valor_do_desconto_nota_2')?.setValidators([Validators.required])
      this.formulario.get('valor_do_desconto_nota_2')?.updateValueAndValidity()
    }
  }

  onDescontoConcedidoChange3(event:any){
    let valor = event.target.value

    if(valor == 'Não'){
      // console.log("entrei no sim")
      this.formulario.get('valor_do_desconto_nota_3')?.clearValidators()
      this.formulario.get('valor_do_desconto_nota_3')?.updateValueAndValidity()
    }

    if(valor == 'Sim'){
      // console.log("entrei no Não")
      this.formulario.get('valor_do_desconto_nota_3')?.setValidators([Validators.required])
      this.formulario.get('valor_do_desconto_nota_3')?.updateValueAndValidity()
    }
  }

  onDescontoConcedidoChange4(event:any){
    let valor = event.target.value

    if(valor == 'Não'){
      // console.log("entrei no sim")
      this.formulario.get('valor_do_desconto_nota_4')?.clearValidators()
      this.formulario.get('valor_do_desconto_nota_4')?.updateValueAndValidity()
    }

    if(valor == 'Sim'){
      // console.log("entrei no Não")
      this.formulario.get('valor_do_desconto_nota_4')?.setValidators([Validators.required])
      this.formulario.get('valor_do_desconto_nota_4')?.updateValueAndValidity()
    }
  }

  onDescontoConcedidoChange5(event:any){
    let valor = event.target.value

    if(valor == 'Não'){
      // console.log("entrei no sim")
      this.formulario.get('valor_do_desconto_nota_5')?.clearValidators()
      this.formulario.get('valor_do_desconto_nota_5')?.updateValueAndValidity()
    }

    if(valor == 'Sim'){
      // console.log("entrei no Não")
      this.formulario.get('valor_do_desconto_nota_5')?.setValidators([Validators.required])
      this.formulario.get('valor_do_desconto_nota_5')?.updateValueAndValidity()
    }
  }

  onDescontoConcedidoChange6(event:any){
    let valor = event.target.value

    if(valor == 'Não'){
      // console.log("entrei no sim")
      this.formulario.get('valor_do_desconto_nota_6')?.clearValidators()
      this.formulario.get('valor_do_desconto_nota_6')?.updateValueAndValidity()
    }

    if(valor == 'Sim'){
      // console.log("entrei no Não")
      this.formulario.get('valor_do_desconto_nota_6')?.setValidators([Validators.required])
      this.formulario.get('valor_do_desconto_nota_6')?.updateValueAndValidity()
    }
  }

  onDescontoConcedidoChange7(event:any){
    let valor = event.target.value

    if(valor == 'Não'){
      // console.log("entrei no sim")
      this.formulario.get('valor_do_desconto_nota_7')?.clearValidators()
      this.formulario.get('valor_do_desconto_nota_7')?.updateValueAndValidity()
    }

    if(valor == 'Sim'){
      // console.log("entrei no Não")
      this.formulario.get('valor_do_desconto_nota_7')?.setValidators([Validators.required])
      this.formulario.get('valor_do_desconto_nota_7')?.updateValueAndValidity()
    }
  }

  onDescontoConcedidoChange8(event:any){
    let valor = event.target.value

    if(valor == 'Não'){
      // console.log("entrei no sim")
      this.formulario.get('valor_do_desconto_nota_8')?.clearValidators()
      this.formulario.get('valor_do_desconto_nota_8')?.updateValueAndValidity()
    }

    if(valor == 'Sim'){
      // console.log("entrei no Não")
      this.formulario.get('valor_do_desconto_nota_8')?.setValidators([Validators.required])
      this.formulario.get('valor_do_desconto_nota_8')?.updateValueAndValidity()
    }
  }

  onDescontoConcedidoChange9(event:any){
    let valor = event.target.value

    if(valor == 'Não'){
      // console.log("entrei no sim")
      this.formulario.get('valor_do_desconto_nota_9')?.clearValidators()
      this.formulario.get('valor_do_desconto_nota_9')?.updateValueAndValidity()
    }

    if(valor == 'Sim'){
      // console.log("entrei no Não")
      this.formulario.get('valor_do_desconto_nota_9')?.setValidators([Validators.required])
      this.formulario.get('valor_do_desconto_nota_9')?.updateValueAndValidity()
    }
  }

  onDescontoConcedidoChange10(event:any){
    let valor = event.target.value

    if(valor == 'Não'){
      // console.log("entrei no sim")
      this.formulario.get('valor_do_desconto_nota_10')?.clearValidators()
      this.formulario.get('valor_do_desconto_nota_10')?.updateValueAndValidity()
    }

    if(valor == 'Sim'){
      // console.log("entrei no Não")
      this.formulario.get('valor_do_desconto_nota_10')?.setValidators([Validators.required])
      this.formulario.get('valor_do_desconto_nota_10')?.updateValueAndValidity()
    }
  }

  onJurosAplicadoChange1(event:any){
    let valor = event.target.value
    if(valor == 'Não'){
      // console.log("entrei no sim")
      this.formulario.get('valor_dos_juros_nota_1')?.clearValidators()
      this.formulario.get('valor_dos_juros_nota_1')?.updateValueAndValidity()
    }

    if(valor == 'Sim'){
      // console.log("entrei no Não")
      this.formulario.get('valor_dos_juros_nota_1')?.setValidators([Validators.required])
      this.formulario.get('valor_dos_juros_nota_1')?.updateValueAndValidity()
    }
  }

  onJurosAplicadoChange2(event:any){
    let valor = event.target.value
    if(valor == 'Não'){
      // console.log("entrei no sim")
      this.formulario.get('valor_dos_juros_nota_2')?.clearValidators()
      this.formulario.get('valor_dos_juros_nota_2')?.updateValueAndValidity()
    }

    if(valor == 'Sim'){
      // console.log("entrei no Não")
      this.formulario.get('valor_dos_juros_nota_2')?.setValidators([Validators.required])
      this.formulario.get('valor_dos_juros_nota_2')?.updateValueAndValidity()
    }
  }

  onJurosAplicadoChange3(event:any){
    let valor = event.target.value
    if(valor == 'Não'){
      // console.log("entrei no sim")
      this.formulario.get('valor_dos_juros_nota_3')?.clearValidators()
      this.formulario.get('valor_dos_juros_nota_3')?.updateValueAndValidity()
    }

    if(valor == 'Sim'){
      // console.log("entrei no Não")
      this.formulario.get('valor_dos_juros_nota_3')?.setValidators([Validators.required])
      this.formulario.get('valor_dos_juros_nota_3')?.updateValueAndValidity()
    }
  }

  onJurosAplicadoChange4(event:any){
    let valor = event.target.value
    if(valor == 'Não'){
      // console.log("entrei no sim")
      this.formulario.get('valor_dos_juros_nota_4')?.clearValidators()
      this.formulario.get('valor_dos_juros_nota_4')?.updateValueAndValidity()
    }

    if(valor == 'Sim'){
      // console.log("entrei no Não")
      this.formulario.get('valor_dos_juros_nota_4')?.setValidators([Validators.required])
      this.formulario.get('valor_dos_juros_nota_4')?.updateValueAndValidity()
    }
  }

  onJurosAplicadoChange5(event:any){
    let valor = event.target.value
    if(valor == 'Não'){
      // console.log("entrei no sim")
      this.formulario.get('valor_dos_juros_nota_5')?.clearValidators()
      this.formulario.get('valor_dos_juros_nota_5')?.updateValueAndValidity()
    }

    if(valor == 'Sim'){
      // console.log("entrei no Não")
      this.formulario.get('valor_dos_juros_nota_5')?.setValidators([Validators.required])
      this.formulario.get('valor_dos_juros_nota_5')?.updateValueAndValidity()
    }
  }

  onJurosAplicadoChange6(event:any){
    let valor = event.target.value
    if(valor == 'Não'){
      // console.log("entrei no sim")
      this.formulario.get('valor_dos_juros_nota_6')?.clearValidators()
      this.formulario.get('valor_dos_juros_nota_6')?.updateValueAndValidity()
    }

    if(valor == 'Sim'){
      // console.log("entrei no Não")
      this.formulario.get('valor_dos_juros_nota_6')?.setValidators([Validators.required])
      this.formulario.get('valor_dos_juros_nota_6')?.updateValueAndValidity()
    }
  }

  onJurosAplicadoChange7(event:any){
    let valor = event.target.value
    if(valor == 'Não'){
      // console.log("entrei no sim")
      this.formulario.get('valor_dos_juros_nota_7')?.clearValidators()
      this.formulario.get('valor_dos_juros_nota_7')?.updateValueAndValidity()
    }

    if(valor == 'Sim'){
      // console.log("entrei no Não")
      this.formulario.get('valor_dos_juros_nota_7')?.setValidators([Validators.required])
      this.formulario.get('valor_dos_juros_nota_7')?.updateValueAndValidity()
    }
  }

  onJurosAplicadoChange8(event:any){
    let valor = event.target.value
    if(valor == 'Não'){
      // console.log("entrei no sim")
      this.formulario.get('valor_dos_juros_nota_8')?.clearValidators()
      this.formulario.get('valor_dos_juros_nota_8')?.updateValueAndValidity()
    }

    if(valor == 'Sim'){
      // console.log("entrei no Não")
      this.formulario.get('valor_dos_juros_nota_8')?.setValidators([Validators.required])
      this.formulario.get('valor_dos_juros_nota_8')?.updateValueAndValidity()
    }
  }

  onJurosAplicadoChange9(event:any){
    let valor = event.target.value
    if(valor == 'Não'){
      // console.log("entrei no sim")
      this.formulario.get('valor_dos_juros_nota_9')?.clearValidators()
      this.formulario.get('valor_dos_juros_nota_9')?.updateValueAndValidity()
    }

    if(valor == 'Sim'){
      // console.log("entrei no Não")
      this.formulario.get('valor_dos_juros_nota_9')?.setValidators([Validators.required])
      this.formulario.get('valor_dos_juros_nota_9')?.updateValueAndValidity()
    }
  }

  onJurosAplicadoChange10(event:any){
    let valor = event.target.value
    if(valor == 'Não'){
      // console.log("entrei no sim")
      this.formulario.get('valor_dos_juros_nota_10')?.clearValidators()
      this.formulario.get('valor_dos_juros_nota_10')?.updateValueAndValidity()
    }

    if(valor == 'Sim'){
      // console.log("entrei no Não")
      this.formulario.get('valor_dos_juros_nota_10')?.setValidators([Validators.required])
      this.formulario.get('valor_dos_juros_nota_10')?.updateValueAndValidity()
    }
  }

  onSistemaTituloChange(event:any){
    let valor = event.target.value
    // console.log(valor)

    if(valor != "SAP"){
      this.formulario.get('c_digo_do_cliente_sap')?.clearValidators()
      this.formulario.get('c_digo_do_cliente_sap')?.updateValueAndValidity()
    }

    if(valor == "SAP"){
      this.formulario.get('c_digo_do_cliente_sap')?.setValidators([Validators.required])
      this.formulario.get('c_digo_do_cliente_sap')?.updateValueAndValidity()
    }
  }
}
