<app-header></app-header>
<div class="container">
  <div class="logo"><img class="logo_nbs" src="../../../assets/img/logo_nbs.png"></div>

  <!-- <h4 class="titulo">Para qual área deseja abrir uma solicitação?</h4> -->
  <div class="nbs_services">
    <div class="nbs_services-item">
      <app-nbs-banner url="../../../assets/img/home/background.png" descricao="Acessar Serviços Financeiros" titulo="Serviços Financeiros"
        routerLink="/menu"></app-nbs-banner>
    </div>

    <!-- <app-nbs-banner url="../../../assets/img/background_compras.png" descricao="Acessar Compras"
      titulo="Compras de Serviços e Materiais Indiretos" routerLink="/compras"></app-nbs-banner> -->
    <div class="nbs_services-item">
      <app-nbs-banner url="../../../assets/img/background_compras.png" descricao="Acessar Recursos Humanos"
      titulo="Recursos Humanos" routerLink="/recursos-humanos/home"></app-nbs-banner>
    </div>

    <!-- <div class="nbs_services-item">
      <a (click)="openForm()">
        <app-nbs-banner url="../../../assets/img/home/background-sala-suporte.png" descricao="Sala de Suporte"
        titulo="Sala de Suporte"></app-nbs-banner>
      </a>
    </div> -->

  </div>
</div>

<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Aguarde...</p>
</ngx-spinner>


