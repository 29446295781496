<app-header></app-header>
<app-nbs-banner url="../../../assets/img/background_pagamentos.png" descricao="teste" titulo="Finanças">
</app-nbs-banner>
<div class="container">
  <h6 class="title">Escolha a opção desejada para realizar sua solicitação.</h6>



  <div class="itens">
    <div class="row menu-itens">
      <div class="col-lg-6">
        <app-box-link-pipe descricao="Serviços para Cartão de Crédito Corporativo"
          url="../../../assets/img/fa-menus/financas/cartao_de_credito_corporativo.png"
          titulo="Cartão de Crédito Corporativo" subtitulo="Serviços"
          link="/payment/forms/servicos-para-cartao-de-credito-corporativo"></app-box-link-pipe>
      </div>
      <div class="col-lg-6"> <!--*ngIf="!podeAcessarIpe"-->
        <app-box-link-pipe descricao="Lançamento/Pagamento de Notas/Impostos e outras cobrança"
          url="../../../assets/img/fa-menus/financas/lancamento_pagamento.png" titulo="Lançamento/Pagamento"
          subtitulo="Notas/Impostos e outras cobranças"
          link="/payment/forms/solicitacao-de-lancamento-de-notas"></app-box-link-pipe>
      </div>
    </div>
    <div class="row menu-itens">
      <div class="col-lg-6">
        <app-box-link-pipe descricao="Solicitação de Reembolso de Despesas"
          url="../../../assets/img/fa-menus/financas/solicitacao_de_reembolso.png" titulo="Solicitação de reembolso"
          subtitulo="Despesas / Viagens" link="https://selfbooking.lemontech.com.br/nutrien"
          tipo_de_link="externo"></app-box-link-pipe>
      </div>

      <div class="col-lg-6">
        <app-box-link-pipe descricao="Service Desk Payments" url="../../../assets/img/background-sala-suporte.png"
          titulo="Service Desk Payments" subtitulo="" [link]="urlSalaSuporte" tipo_de_link="externo"
          id_box="helpDesk"></app-box-link-pipe>
      </div>
    </div>

  </div>


</div>
<div class="footer">

  <app-footer link="menu"></app-footer>
</div>