export const pipe_fields_ids =[
  'nome_do_solicitante', // nome
  'e_mail_do_solicitante', // e_mail
  // 'risco_sacado',
  'tipo_de_nota',
  'qual_a_quantidade_de_cte',
  'conta_cont_bil',
  'documento_do_fornecedor',
  'cpf_fornecedor', //cpf_do_fornecedor
  'cnpj_fornecedor',
  'nome_do_fornecedor',
  'cnpj_da_empresa_nutrien',
  'nome_empresa_nutrien',
  'nro_nota',
  'data_de_envio_de_nf',
  'valor_total_da_nf', // valor_total_da_nf_1
  'nota_com_rateio_de_valores',
  'arquivo_de_rateio', //anexo
  'data_de_vencimento_da_nota',
  'departamento',
  'n_mero_de_pedido', // numero_po
  'n_mero_do_item_da_po_1', // n_mero_de_itens_da_po
  'n_mero_do_item_da_po',
  'lista_de_itens_da_po', //anexo
  'centro_de_custo',
  'anexo_nota', //anexo  anexo_nota_ou_boleto
  'certeza_que_incluiu_o_arquivo_conforme_orienta_o',
  'anexo_documenta_o_suporte',//anexo
  'nota_para_pagamento_fora_da_pol_tica',
  'nota_para_pagamento_urgente',
  'motivo_da_exce_o',
  'observa_o_sobre_o_documento',
  'informa_es_complementares_da_po',
  'quantidade_de_pedidos',
  'n_mero_da_linha_do_pedido_1',
  'n_mero_da_linha_do_pedido_2',
  'n_mero_da_linha_do_pedido_3',
  'n_mero_da_linha_do_pedido_4',
  'n_mero_da_linha_do_pedido_5',
  'n_mero_da_linha_do_pedido_6',
  'n_mero_da_linha_do_pedido_7',
  'n_mero_da_linha_do_pedido_8',
  'n_mero_da_linha_do_pedido_9',
  'n_mero_da_linha_do_pedido_10',
  'lista_de_pedidos',

  'anexo_boleto', //anexo
  'anexo_xml', //anexo
  'nf_do_produto_original', //anexo
  'tipo_de_custo_planejado',
  'n_mero_de_cte',
  'opex_e_capex',
  'tipo_de_importa_o',
  'tipo_de_pagamento',
  'n_mero_afe',
  
  // 'arquivo_rateio_long_text',
  // 'lista_de_itens_da_po_long_text',
  // 'anexo_nota_long_text',
  // 'anexo_documenta_o_suporte_long_text',
  // 'anexo_xml_long_text',
  // 'anexo_boleto_long_text',
  // 'nf_do_produto_original_long_text',


  'id_card_di_ipe',
  'categoria',
  'subcategoria_po',
  'tipo_de_opera_o',
  'migo_1',
  'anexo_npo_modelo_excel', // anexo
  'origem_da_cria_o_do_card',
  'json_most',
  'tomador_ipe',
  'fornecedor_ipe',

  'estado_de_origem', 
  'estado_de_destino',
  'grc', 
  'parcela_do_aluguel', 
  'm_s_do_aluguel',
  'ano_do_aluguel',
  'n_mero_do_pedido_1_1',
  'n_mero_do_pedido_2_1',
  'n_mero_do_pedido_3',
  'n_mero_do_pedido_4',
  'n_mero_do_pedido_5',
  'n_mero_do_pedido_6',
  'n_mero_do_pedido_7',
  'n_mero_do_pedido_8',
  'n_mero_do_pedido_9',
  'n_mero_do_pedido_10',
  'tempo_ocr',
  'hora_inicio_chamada_most',
  'hora_fim_chamada_most',
  'baixa_de_adiantamento'

]
