<app-header></app-header>
<app-nbs-banner url="../../../assets/img/background_lojas.png" descricao="teste" titulo="Lojas"
  subtitulo="Centros de Experiência"></app-nbs-banner>

<div class="container">


  <app-formularios-titulo url="../../../assets/img/Impostos/Impostos18.png" descricao="costumer-service"
    titulo="Cadastro e Renovação de Clientes - Agrometrika"></app-formularios-titulo>

  <div class="mensagem-topo">
    <h6 class="text-center">
      Preencha o formulário abaixo para enviar sua solicitação.
    </h6>
    <p class="text-center">
      Os campos marcados com (*) são de preenchimento obrigatório.
    </p>
    <hr>
  </div>
  <app-form-mat class="col-md-4 mx-auto text-size"  [fields]="fields" urlPipefy="https://app.pipefy.com/public/form/FZZSofCP" (changeForm)="enviarFormulario($event)" pipeId="303800184"></app-form-mat>

<div class="footer">
  <app-footer link="menu"></app-footer>
</div>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">
    Aguarde. Sua solicitação está sendo processada.
  </p>
</ngx-spinner>
