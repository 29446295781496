export const pipe_fields_id = [
  'empresa',
  'categoria',
  'tipo_venda',
  'tipo_de_cliente',
  'raz_o_social',
  'cnpj',
  'nome_cliente',
  'cpf',
  'inscri_o_estadual',
  'nome_da_propriedade',
  'cliente_novo',
  'cultura',
  'necess_rio_emitir_receitu_rio_agron_mico',
  'i_praga',
  'ii_m_todo_de_aplica_o',
  'iii_per_odo_de_aplica_o',
  'tipo_de_pedido',
  'campanha',
  'n_mero_de_produtos_no_pedido',
  '1_c_digo_produto',
  '1_descri_o_produto',
  // '1_fam_lia_produto',
  '1_quantidade',
  '1_valor_unit_rio',
  '1_embalagem',
  '1_data_de_entrega_solicitada',
  '1_planta_de_expedi_o',
  // '1_centro_de_expedi_o',
  '2_c_digo_produto',
  '2_descri_o_produto',
  // '2_fam_lia_produto',
  '2_quantidade',
  '2_valor_unit_rio',
  '2_embalagem',
  '2_data_de_entrega_solicitada',
  '2_planta_de_expedi_o',
  // '2_centro_de_expedi_o',
  '3_c_digo_produto',
  '3_descri_o_produto',
  // '3_fam_lia_produto',
  '3_quantidade',
  '3_valor_unit_rio',
  '3_embalagem',
  '3_data_de_entrega_solicitada',
  '3_planta_de_expedi_o',
  // '3_centro_de_expedi_o',
  '4_c_digo_produto',
  '4_descri_o_produto',
  // '4_fam_lia_produto',
  '4_quantidade',
  '4_valor_unit_rio',
  '4_embalagem',
  '4_data_de_entrega_solicitada',
  '4_planta_de_expedi_o',
  // '4_centro_de_expedi_o',
  '5_c_digo_produto',
  '5_descri_o_produto',
  // '5_fam_lia_produto',
  '5_quantidade',
  '5_valor_unit_rio',
  '5_embalagem',
  '5_data_de_entrega_solicitada',
  '5_planta_de_expedi_o',
  // '5_centro_de_expedi_o',
  '6_c_digo_produto',
  '6_descri_o_produto',
  // '6_fam_lia_produto',
  '6_quantidade',
  '6_valor_unit_rio',
  '6_embalagem',
  '6_data_de_entrega_solicitada',
  '6_planta_de_expedi_o',
  // '6_centro_de_expedi_o',
  '7_c_digo_produto',
  '7_descri_o_produto',
  // '7_fam_lia_produto',
  '7_quantidade',
  '7_valor_unit_rio',
  '7_embalagem',
  '7_data_de_entrega_solicitada',
  '7_planta_de_expedi_o',
  // '7_centro_de_expedi_o',
  '8_c_digo_produto',
  '8_descri_o_produto',
  // '8_fam_lia_produto',
  '8_quantidade',
  '8_valor_unit_rio',
  '8_embalagem',
  '8_data_de_entrega_solicitada',
  '8_planta_de_expedi_o',
  // '8_centro_de_expedi_o',
  '9_c_digo_produto',
  '9_descri_o_produto',
  // '9_fam_lia_produto',
  '9_quantidade',
  '9_valor_unit_rio',
  '9_embalagem',
  '9_data_de_entrega_solicitada',
  '9_planta_de_expedi_o',
  // '9_centro_de_expedi_o',
  '10_c_digo_produto',
  '10_descri_o_produto',
  // '10_fam_lia_produto',
  '10_quantidade',
  '10_valor_unit_rio',
  '10_embalagem',
  '10_data_de_entrega_solicitada',
  '10_planta_de_expedi_o',
  // '10_centro_de_expedi_o',
  '11_c_digo_produto',
  '11_descri_o_produto',
  // '11_fam_lia_produto',
  '11_quantidade',
  '11_valor_unit_rio',
  '11_embalagem',
  '11_data_de_entrega_solicitada',
  '11_planta_de_expedi_o',
  // '11_centro_de_expedi_o',
  '12_c_digo_produto',
  '12_descri_o_produto',
  // '12_fam_lia_produto',
  '12_quantidade',
  '12_valor_unit_rio',
  '12_embalagem',
  '12_data_de_entrega_solicitada',
  '12_planta_de_expedi_o',
  // '12_centro_de_expedi_o',
  '13_c_digo_produto',
  '13_descri_o_produto',
  // '13_fam_lia_produto',
  '13_quantidade',
  '13_valor_unit_rio',
  '13_embalagem',
  '13_data_de_entrega_solicitada',
  '13_planta_de_expedi_o',
  // '13_centro_de_expedi_o',
  '14_c_digo_produto',
  '14_descri_o_produto',
  // '14_fam_lia_produto',
  '14_quantidade',
  '14_valor_unit_rio',
  '14_embalagem',
  '14_data_de_entrega_solicitada',
  '14_planta_de_expedi_o',
  // '14_centro_de_expedi_o',
  '15_c_digo_produto',
  '15_descri_o_produto',
  // '15_fam_lia_produto',
  '15_quantidade',
  '15_valor_unit_rio',
  '15_embalagem',
  '15_data_de_entrega_solicitada',
  '15_planta_de_expedi_o',
  // '15_centro_de_expedi_o',
  '16_c_digo_produto',
  '16_descri_o_produto',
  // '16_fam_lia_produto',
  '16_quantidade',
  '16_valor_unit_rio',
  '16_embalagem',
  '16_data_de_entrega_solicitada',
  '16_planta_de_expedi_o',
  // '16_centro_de_expedi_o',
  '17_c_digo_produto',
  '17_descri_o_produto',
  // '17_fam_lia_produto',
  '17_quantidade',
  '17_valor_unit_rio',
  '17_embalagem',
  '17_data_de_entrega_solicitada',
  '17_planta_de_expedi_o',
  // '17_centro_de_expedi_o',
  '18_c_digo_produto',
  '18_descri_o_produto',
  // '18_fam_lia_produto',
  '18_quantidade',
  '18_valor_unit_rio',
  '18_embalagem',
  '18_data_de_entrega_solicitada',
  '18_planta_de_expedi_o',
  // '18_centro_de_expedi_o',
  '19_c_digo_produto',
  '19_descri_o_produto',
  // '19_fam_lia_produto',
  '19_quantidade',
  '19_valor_unit_rio',
  '19_embalagem',
  '19_data_de_entrega_solicitada',
  '19_planta_de_expedi_o',
  // '19_centro_de_expedi_o',
  '20_c_digo_produto',
  '20_descri_o_produto',
  // '20_fam_lia_produto',
  '20_quantidade',
  '20_valor_unit_rio',
  '20_embalagem',
  '20_data_de_entrega_solicitada',
  '20_planta_de_expedi_o',
  // '20_centro_de_expedi_o',
  'frete',
  'cif_valor_do_frete',
  // 't_tulo',
  // 'pedido_cont_m_defensivo_qu_mico',
  'valor_total_do_pedido',
  // 'valor_total_do_pedido',
  'forma_de_pagamento',
  'condi_es_de_parcelamento',
  'vencimento_condi_o_de_pagamento',
  'consultor_t_cnico_de_vendas',
  'e_mail_do_solicitante',
  'e_mail_secund_rio',
  'data_do_pedido',
  'demanda_urgente',
  'realizar_faturamento_imediato_pelo_nbs',
  // 'descreva_a_urg_ncia',
  'munic_pio_loja_nutrien',
  'estado_loja_nutrien',
  'observa_o',
  'anexo'
]
