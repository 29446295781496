export const pipe_fields_ids =[
  'per_odo',
  'empresa',
  'filiais_tec_agro',
  'filiais_nutrien',
  'filiais_agrosema',
  'filiais_agrichem',
  'descri_o_da_solicita_o',
  'n_mero_da_di',
  'pedido_de_compra',
  'prazo_para_pagamento',
  'quantidade_de_notas_a_serem_emitidas',
  'produto_perigoso',
  'declara_o_de_produto_perigoso_e_onu',
  'declara_o_de_importa_o',
  'coa_invoice',
  'exonera_es_isen_es_guia_icms_taxas_e_tela_d_bito',
  'transportadora',
  'planilha_de_lotes',
  'nome_do_solicitante',
  'e_mail_do_solicitante',
  'torre_rea_departamento',
  'observa_es_1',
  'demais_anexos'

]
