import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { PipefyService } from 'src/app/services/pipefy.service';
import { TrataInputsService } from 'src/app/services/trata-inputs.service';
import Swal from 'sweetalert2';
import { pipe_fields_incidentes } from '../../pipe_fields_incidentes';
import { empresas } from './empresas';
import { motivos } from './motivos_do_contato';
import { pipe_fields_id } from './pipe_fields_id';

@Component({
  selector: 'app-suporte-para-compras',
  templateUrl: './suporte-para-compras.component.html',
  styleUrls: ['./suporte-para-compras.component.css']
})
export class SuporteParaComprasComponent implements OnInit {

  formulario!: FormGroup;
  empresas = empresas
  motivos = motivos
  anexos: any[] = []

  anexosUrl: any[]=[]

  constructor(private formBuilder: FormBuilder, private trataInput: TrataInputsService, private pipefy: PipefyService, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {

    this.formulario = this.formBuilder.group({
      sua_empresa : ['',[Validators.required, Validators.maxLength(1000)]],
      seu_nome : ['',[Validators.required, Validators.maxLength(1000)]],
      seu_email: ['',[Validators.required, Validators.email]],
      motivo_do_contato : ['',[Validators.required, Validators.maxLength(1000)]],
      descreva_sua_solicitacao: ['',[Validators.required, Validators.maxLength(1000)]],
      mais_detalhes: ['',[Validators.required, Validators.maxLength(1000)]],
      anexos:['',[Validators.required]]
    })
  }

  enviarFormulario() {
    this.spinner.show()



    const criarBody = async () => {

      await this.getPipesUrl(this.anexos);
      if(this.anexos.length >0){
        while (this.anexos.length != this.anexosUrl.length) {
          // console.log("esperando....")
          await this.delay(100);
        }
      }

      if(this.anexos.length>0){
        var anexosUrl2 = ''
        anexosUrl2 = '['
        for(var i = 0; i < this.anexosUrl.length ; i++){
          anexosUrl2 = anexosUrl2+'"'+this.anexosUrl[i]+'",'
        }
        anexosUrl2 = anexosUrl2.slice(0, -1)
        anexosUrl2 = anexosUrl2+"]"

      }else{
        anexosUrl2 =''
      }

      let sua_empresa = this.trataInput.removeCaracters(this.formulario.getRawValue().sua_empresa)
      let seu_nome = this.trataInput.removeCaracters(this.formulario.getRawValue().seu_nome)
      let seu_email = this.trataInput.removeCaracters(this.formulario.getRawValue().seu_email)
      let motivo_do_contato = this.trataInput.removeCaracters(this.formulario.getRawValue().motivo_do_contato)
      let descreva_sua_solicitacao = this.trataInput.removeCaracters(this.formulario.getRawValue().descreva_sua_solicitacao)
      let mais_detalhes = this.trataInput.removeCaracters(this.formulario.getRawValue().mais_detalhes)


      let pipe_fields = pipe_fields_id
      let fields_values = [sua_empresa, seu_nome, seu_email, motivo_do_contato, descreva_sua_solicitacao, anexosUrl2, mais_detalhes]
      let pipe_id = "303797828"

      this.pipefy.createCard(this.pipefy.criaBody(pipe_fields, fields_values, pipe_id)).subscribe((res) => {
        // console.log(res)
        this.spinner.hide()

        if (res.data.result_creation_card.id != undefined) {
          let card_id = res.data.result_creation_card.id
          Swal.fire({
            title: 'Chamado aberto com Sucesso!',
            text: 'O chamado ' + card_id + ' foi aberto com sucesso. Nossa equipe irá tratar e retornar em breve. Obrigado!',
            icon: 'success',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#88D600',
          })
          this.resetform()
        }

        if (res.data.result_creation_card.errors != undefined) {
          var erro = res.data.result_creation_card.errors[0].message
          Swal.fire({
            title: 'Desculpe!',
            html:'<p>Ocorreu um erro.</p>'+'<a href="https://app.pipefy.com/public/form/FZZSofCP" target="_blank">Clique aqui </a>'+'<span>para fazer sua solicitação</span>',
            text: erro,
            icon: 'error',
            confirmButtonColor: '#88D600',
          })
          this.anexosUrl = []

          let pipe_fields_incidentes_values = ['Incidente', 'Correção', 'Portal NBS', 'F&A', 'Contabilidade', "Contabilidade - criação de card -- Auditoria - Documentos Suporte", erro, 'Portal NBS Error', 'incidente@portal.com',
            'Sim', 'Outros', 'criação de card via portal', 'Página web com problemas']

          var pipe_fields_incidentes_values2 = []
          for (var i = 0; i<pipe_fields_incidentes_values.length; i++ ){
            pipe_fields_incidentes_values2.push(this.trataInput.removeCaractersPipeIncidentes(pipe_fields_incidentes_values[i]))
          }
          // console.log(pipe_fields_incidentes_values2)

          let pipe_incidentes_id = '302487533'

          this.pipefy.createCard(this.pipefy.criaBody(pipe_fields_incidentes, pipe_fields_incidentes_values2, pipe_incidentes_id)).subscribe((res) => {
            // console.log(res)
          })
        }
      },(error: HttpErrorResponse)=>{
        this.spinner.hide()
        console.error(error)
        Swal.fire({
          title: 'Desculpe!',
          text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
          icon: 'error',
          confirmButtonColor: '#88D600',
        })
      })
    }

    criarBody()
  }

  delay(time: any) {
    return new Promise(resolve => setTimeout(resolve, time));
  }

  save(event: any): void {
    var files = event.target.files;
    for (var i = 0; i < files.length; i++) {
      this.anexos.push(files[i])
    }
  }

  async getPipesUrl(files: any) {

    for (var i = 0; i < files.length; i++) {
      var fileContent = files[i]
      let fileName = files[i].name
      const reader = new FileReader();
      reader.readAsDataURL(fileContent);
      reader.onload = () => {
        fileContent = reader.result?.toString()
        var fileContentStart = fileContent.indexOf(",")

        fileContent = fileContent.substring(fileContentStart + 1)
        this.pipefy.getFileUrl(fileContent, fileName).subscribe((res) => {
          let url = res.data.attachments_url_pipefy[0]
          this.anexosUrl.push(url)
        },
          (error: HttpErrorResponse) => {
            console.error(error)
            console.error("mensagem de erro:", error.error)
            console.error("mensagem de erro status:", error.error.status.code)
            console.error("mensagem de erro mensagem:", error.error.status.message)
          })
      }
    }
    return new Promise<void>((resolve) => {resolve();});
  }

  removeFile(file: File) {
    const index: number = this.anexos.indexOf(file);
    this.anexos.splice(index, 1)
    alert("Arquivo Removido")
  }

  resetform() {

    for (let field in this.formulario.controls) {
      this.formulario.controls[field].setValue('');
    }
    this.formulario.markAsUntouched()
    this.anexos = []
    this.anexosUrl = []
  }

}
