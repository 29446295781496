export const tipos_de_notas =
[
    {
        tipo:''
    },
    {
        tipo:"Aluguel"
    },
    {
        tipo:"Ativo fixo (AFE)"
    },
    {
        tipo:"Benefícios"
    },
    {
        tipo:"Cartório"
    },
    {
        tipo:"Contas de consumo (Luz, água, telefone, internet, gás)"
    },
    {
        tipo:"Correio"
    },
    {
        tipo:"Devolução de Cliente"
    },
    {
        tipo:"Escrituração - Não gera pagamento"
    },
    {
        tipo:"Frete/CTE"
    },
    {
        tipo:"Guias/Imposto/Taxas"
    },
    {
        tipo:"Importação"
    },
    {
        tipo:"Incentivo - Doação"
    },
    {
        tipo:"Industrialização"
    },
    {
        tipo:"Jurídico"
    },
    {
        tipo:"Material (DANFE)"
    },
    {
        tipo:"Nota de Crédito"
    },
    {
        tipo:"Outras NFs sem PO"
    },
    {
        tipo:"Sem valor fiscal"
    },
    {
        tipo:"Serviço"
    }
]