import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, tap, timeout } from 'rxjs/operators'
import { JsonPipe } from '@angular/common';
import { ReturnSecrets, TokenServiceService } from './token-service.service';
import { UserInfosService } from '../autenticacao/usuario/user-infos.service';
import { environment } from 'src/environments/environment';



export interface EmailRecoverRequest {
  data: {
    email: string,
    filtros: {
      numero_chamado?: string,
      tipo_solicitacao?: string,
      data_inicio?: string,
      data_fim?: string,
      status?: string
    },
    pagination: {
      endCursor?: string,
      hasNextPage?: any
    }
  }
}

export interface EmailRecoverChamados {
  aging: string,
  aging_de_conclus_o: string,
  atualizar_t_tulo: string,
  data_abertura: string,
  data_conclusao: string,
  data_de_vencimento: string,
  data_de_vencimento_vazio_n_o_apagar: string,
  email_do_solicitante: string,
  link_de_pend_ncia: string,
  nota_fiscal: string,
  nr_pedido: string,
  numero_chamado: string,
  pipe_id: string,
  rea: string,
  solicitacao: string,
  status: string,
  titulo: string,
  torre: string
}
export interface EmailRecoverResponse {
  pagination: {
          endCursor: string,
          hasNextPage: boolean,
          totalCards: number
        },
        chamados: EmailRecoverChamados[],
  status: {
    code: number,
    message: string
  }
}
@Injectable({
  providedIn: 'root'
})
export class PipefyService {

  constructor(private httpClient: HttpClient, private tokenService: TokenServiceService, private userInfo: UserInfosService) { }

  criaBody(pipe_fields: any, fields_values: any, pipe_id: string, arrayFieldDetect?:string[] ) {

    var query = `{"data":{"pipe_id":"${pipe_id}","field_values":[`;
    for (var i = 0; i < fields_values.length; i++) {
      if (fields_values[i] != '') {
        // todos os campos dentro do if abaixo são campos do tipo anexo.
        // o if abaixo serve para enviar o array de urls dos anexos sem enviar o array entre aspas.

        if
          (arrayFieldDetect?.includes(pipe_fields[i])
          || pipe_fields[i] == "anexar_solicita_o_da_auditoria"
          || pipe_fields[i] == "anexar_suporte_de_documentos"
          || pipe_fields[i] == "formul_rio_assinado"
          || pipe_fields[i] == "anexar_o_template_de_lan_amento"
          || pipe_fields[i] == "apura_o"
          || pipe_fields[i] == "contrato"
          || pipe_fields[i] == "anexar_invoice"
          || pipe_fields[i] == "anexar_informa_es_de_contrato_ou_gross_up"
          || pipe_fields[i] == "anexar_documento"
          || pipe_fields[i] == "documentos_suporte"
          || pipe_fields[i] == "arquivo_de_rateio"
          || pipe_fields[i] == "lista_de_itens_da_po"
          || pipe_fields[i] == "anexo_nota_ou_boleto"
          || pipe_fields[i] == "anexo_documenta_o_suporte"
          || pipe_fields[i] == "declara_o_de_produto_perigoso_e_onu"
          || pipe_fields[i] == "declara_o_de_importa_o"
          || pipe_fields[i] == "coa_invoice"
          || pipe_fields[i] == "exonera_es_isen_es_guia_icms_taxas_e_tela_d_bito"
          || pipe_fields[i] == "planilha_de_lotes"
          || pipe_fields[i] == "anexo"
          || pipe_fields[i] == "documenta_o"
          || pipe_fields[i] == "anexos"
          || pipe_fields[i] == "servi_os_a_delegar"
          || pipe_fields[i] == "informa_es_complementares_da_po"
          || pipe_fields[i] == "anexo_boleto"
          || pipe_fields[i] == "anexo_xml"
          || pipe_fields[i] == "anexo_nota"
          || pipe_fields[i] == "planilha_de_cards"
          || pipe_fields[i] == "anexar_a_autoriza_o_do_respons_vel_da_unidade_solicitante"
          || pipe_fields[i] == "anexar_a_autoriza_o_do_respons_vel_do_time_de_cr_dito_e_cobran_a"
          || pipe_fields[i] == "nf_do_produto_original"
          || pipe_fields[i] == "comprovantes"
          || pipe_fields[i] == "documenta_o"
          || pipe_fields[i] == "tipo"
          || pipe_fields[i] == "anexo_documenta_o"
          || pipe_fields[i] == "anexo_ipva"
          || pipe_fields[i] == "anexo_seguro"
          || pipe_fields[i] == "anexo_nota_fiscal_ou_boleto"
          || pipe_fields[i] == "anexo_xml"
          || pipe_fields[i] == "anexo_nota_fiscal_documento_e_boleto_se_houver"
          || pipe_fields[i] == "anexo_npo_modelo_excel"
          || pipe_fields[i] == "caracteristicas"
          || pipe_fields[i] == "carta_oferta"
          || pipe_fields[i] == "cpf_do_dependente"
          || pipe_fields[i] == "carta_de_demiss_o"
          || pipe_fields[i] == "cart_o_sus_do_dependente"
          || pipe_fields[i] == "certid_o_de_casamento_nascimento"
          || pipe_fields[i] == "template_do_formul_rio_de_solicita_o_vt"
          || pipe_fields[i] == "lista_de_novos_benef_cios"
          || pipe_fields[i] == "anexo_de_aprova_o_de_nova_data"
          || pipe_fields[i] == "formul_rio_de_altera_o_de_benef_cio"
          || pipe_fields[i] == "documenta_o_e_ou_anexo"
          || pipe_fields[i] == "carta_de_demiss_o"
          || pipe_fields[i] == "anexar_autoriza_o_gt_ou_gr"
          || pipe_fields[i] == "evid_ncia"
          || pipe_fields[i] ==  "ficha_cadastral"
          || pipe_fields[i] == "documentos_obrigat_rios_cliente"
          || pipe_fields[i] == "anexo_de_documento"
          || pipe_fields[i] == "incluir_anexos"
          || pipe_fields[i] == "demais_anexos"
          || pipe_fields[i] == 'anexo_para_pagamento_de_aut_nomo'
          || pipe_fields[i] == 'anexar_registro_do_pagamento_de_aut_nomo'
          || pipe_fields[i] == 'anexar_nota_emitida_na_prefeitura'
          // || pipe_fields[i] == "json_most"

        ) {
          query = query + `{"field_id":"${pipe_fields[i]}", "field_value":${fields_values[i]}},`
        } else {
          query = query + `{"field_id":"${pipe_fields[i]}", "field_value":"${fields_values[i]}"},`

        }
      }
    }
    query = query.slice(0, -1)
    query = query + "]}}"
    // console.log(query)
    return query
  }


  getFileUrl(fileContent: string, fileName: string, organization_id = 129719, field_id = '"anexo_variavel"'): Observable<any> {

    let fileNameTratado = fileName.replace(" ", "_").replace(/\)/g, "_").replace(/\(/g, "_").replace(/\%/g, "_").replace(/\-/g, "_").replace(/\_/g, "_").toLowerCase();

    let body = { "data": { "organization_id": organization_id, "card_id": "999999", "field_id": field_id, "attachment": [{ "data": fileContent, "filename": fileNameTratado }] } }

    var token = this.tokenService.getToken()

    // console.log(body)

    let header: HttpHeaders;
    header = new HttpHeaders({
      'ContentType': 'application/json',
      // 'Access-Control-Allow-Origin': '*',
      //'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
      'Access-Control-Allow-Methods': 'POST',
      'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization, X-CSRF-Token',
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Max-Age': '86400',
      'Accept': '*/*',
      'Authorization': token
    })

    return this.httpClient.post<any>(environment.generate_pipe_url_digibee, body
    ).pipe(
      tap(res => {
        JSON.stringify(res);
        return res;
      }))
  }
  async mutation(idCard: any, idCampo: any, valor: any) {
    const bearer = (await this.tokenService.getSecrets()).tokens.pipefy
    let header: HttpHeaders; header = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': bearer
    })
    valor = valor.replace(/\\"/g, '"')
    var graphql = JSON.stringify({
      query: `mutation{updateCardField(input:{card_id:"${idCard}",field_id:"${idCampo}",new_value:${valor}}){clientMutationId,success}}`
    })
    return this.httpClient.post<any>(environment.pipefy_queries,
      graphql, { headers: header, observe: 'response' }).pipe(tap(res => {
        // console.log(res)
      }))

  }

  async mutation2(idCard: any, idCampo: any, valor: any){
    const bearer = (await this.tokenService.getSecrets()).tokens.pipefy
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", bearer);
    myHeaders.append("Cookie", environment.pipefy_queries_anexo_cookie);
    // console.log(valor)
    var raw = JSON.stringify({
      "query": `mutation{updateCardField(input:{card_id:"${idCard}",field_id:"${idCampo}",new_value:"${valor}"}){clientMutationId,success}}`
    });
    // console.log(raw)
    fetch(environment.pipefy_queries_anexo, {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    })
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
  }

  getFileUrl2(fileContent: any, fileName: string, organization_id = 129719, field_id = '"anexo_variavel"'): Observable<any> {
    // console.log("Entrei aqui")
    // let fileNameTratado = fileName.replace(/ /g, "_").replace(/\)/g, "_").replace(/\(/g, "_").replace(/\%/g, "_").replace(/\-/g, "_").replace(/\_/g, "_").toLowerCase();
    let fileNameTratado = fileName.replace(/[^a-zA-Z0-9.]/g, "");
    let response_fields = 'url'
    let query = JSON.stringify({
      query: `mutation{ createPresignedUrl( input: { organizationId: 129719, fileName: "${fileNameTratado}"}){ downloadUrl, url } }`
    })

    console.log("passo 3" + query)
    let header: HttpHeaders; header = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.tokenService.secrets.tokens.pipefy
    })
    return this.httpClient.post<any>(environment.pipefy_queries,
      query, { headers: header, observe: 'response' }).pipe(tap(res => {
        console.log("passo 4" + res)
        let url = res.body.data.createPresignedUrl.url
        var fileUrlTemp = "";
        var startIndex = 0;
        if (url.indexOf("orgs") > 0) {
          startIndex = url.indexOf("orgs/");
        } else {
          startIndex = url.indexOf("uploads/");
        }
        var endIndex = url.indexOf("?");
        fileUrlTemp = url.substring(startIndex, endIndex);
        this.uploadFileToAws(url, fileContent)
      }))
  }

  //  async getFileUrl3(fileContent: any, fileName: string, organization_id = 129719, field_id = '"anexo_variavel"') {
  //    // console.log("Entrei aqui")
  //    console.log("Token Pipefy" + (await this.tokenService.getSecrets()).tokens.pipefy);
  //    // let fileNameTratado = fileName.replace(/ /g, "_").replace(/\)/g, "_").replace(/\(/g, "_").replace(/\%/g, "_").replace(/\-/g, "_").replace(/\_/g, "_").toLowerCase();
  //    let fileNameTratado = fileName.replace(/[^a-zA-Z0-9.]/g, "");
  //    let response_fields = 'url'
  //    let query = JSON.stringify({
  //      query: `mutation{ createPresignedUrl( input: { organizationId: 129719, fileName: "${fileNameTratado}"}){ downloadUrl, url } }`
  //    })

  //    console.log("passo 1" + query)
  //    let header: HttpHeaders; header = new HttpHeaders({
  //      'Content-Type': 'application/json',
  //      'Authorization': (await this.tokenService.getSecrets()).tokens.pipefy
  //    })
  //   const retorno:any = await this.httpClient.post<any>(environment.pipefy_queries,
  //      query, { headers: header, observe: 'response' }).toPromise().then(async res => {
  //        console.log("passo 2" + res)
  //        let url = res!.body.data.createPresignedUrl.url
  //        console.log("url: ", url)
  //        console.log(res)
  //        const put = await this.uploadFileToAws(url, fileContent)
  //       return url
  //      })
  //    return retorno
  //  }
  async getFileUrl3(fileContent: any, fileName: string, organization_id = 129719, field_id = '"anexo_variavel"') {
    try {
        const token = (await this.tokenService.getSecrets()).tokens.pipefy;
        console.log("Token Pipefy: " + token);

        // Tratamento do nome do arquivo
        let fileNameTratado = fileName.replace(/[^a-zA-Z0-9.]/g, "");

        // Definindo a query GraphQL como um objeto
        const query = {
            query: `mutation {
                createPresignedUrl(input: { organizationId: ${organization_id}, fileName: "${fileNameTratado}" }) {
                    downloadUrl,
                    url
                }
            }`
        };

        // Cabeçalhos da requisição
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': token
        });

        // Fazendo a requisição POST
        const res = await this.httpClient.post<any>(environment.pipefy_queries, query, { headers: headers, observe: 'response' }).toPromise();

        if (res && res.body && res.body.data && res.body.data.createPresignedUrl && res.body.data.createPresignedUrl.url) {
            const url = res.body.data.createPresignedUrl.url;
            console.log("URL do arquivo: ", url);

            // Subindo o arquivo para o AWS usando a URL pre-assinada
            const put = await this.uploadFileToAws(url, fileContent);

            return url;
        } else {
            console.error("Resposta GraphQL inválida: ", res);
            throw new Error("Resposta GraphQL inválida");
        }
    } catch (error) {
        console.error("Erro ao obter URL do arquivo:", error);
        throw error;
    }
  }

  async uploadFileToAws(url: any, data: any) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/octet-stream");
    var file = data;
    const retorno = fetch(url, {
      method: 'PUT',
      headers: myHeaders,
      body: file,
      redirect: 'follow'
    }
    )
      .then(response => {
        console.log("response: ",response)
        response.text()
        return response
      })
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
      return retorno
  }

  createCard(body: any) {

    var token = this.tokenService.getToken()

    let header: HttpHeaders;
    header = new HttpHeaders({
      'ContentType': 'application/json',
      // 'Access-Control-Allow-Origin': '*',
      //'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
      'Access-Control-Allow-Methods': 'POST',
      'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization, X-CSRF-Token',
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Max-Age': '86400',
      'Accept': '*/*',
      'Authorization': token
    })

    var fields = JSON.parse(body)
    console.log(fields)
    return this.httpClient.post<any>(environment.generate_card_digibee, fields).
      pipe(
        tap(res => {
          JSON.stringify(res)
          return res
        }))
  }

  createCard2(body: any) {
    var token = this.tokenService.getToken()
    let header: HttpHeaders;
    header = new HttpHeaders({
     'ContentType': 'application/json',
    //  'Access-Control-Allow-Origin': '*',
     //'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
     'Access-Control-Allow-Methods': 'POST',
     'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization, X-CSRF-Token',
     'Access-Control-Allow-Credentials': 'true',
     'Access-Control-Max-Age': '86400',
     'Accept': '*/*',
     'Authorization': token
    })

    var fields = JSON.parse(body)
    console.log(fields)

    return this.httpClient.post<any>(environment.generate_card_anexo_digibee, fields).
      pipe(
        tap(res => {
          JSON.stringify(res)
          return res
        }))
  }

  getTicketStatus(endCursor: string, hasNextPage: boolean|"", numero_chamado?: string, tipo_solicitacao?: string, data_inicio?: string, data_fim?: string, status?: string, userEmailBusca?: any) {

    var token = this.tokenService.getToken()
    var userEmail = ''
    // console.log(this.userInfo.getUserEmail())
    if (userEmailBusca == undefined || userEmailBusca == '') {
      userEmail = this.userInfo.getUserEmail()
    } else {
      userEmail = userEmailBusca
    }


    // console.log('userEmailConsulta:', userEmail)

    let header: HttpHeaders;
    header = new HttpHeaders({
      'ContentType': 'application/json',
      'contenttype': 'application/json',
      // 'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'POST',
      //'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
      'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization, X-CSRF-Token',
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Max-Age': '86400',
      'Accept': '*/*',
      'Authorization': 'Bearer',
      'Ambiente': 'teste',
      'Email': userEmail
    })

    // userEmail = "maria.mira@nutrien.com"

    let body: EmailRecoverRequest = {
      "data": {
        "email": userEmail,
        "filtros": {
          "numero_chamado": numero_chamado,
          "tipo_solicitacao": tipo_solicitacao,
          "data_inicio": data_inicio,
          "data_fim": data_fim,
          "status": status
        },
        "pagination": {
          "endCursor": endCursor,
          "hasNextPage": hasNextPage
        }
      }
    }

    return new Promise<EmailRecoverResponse>( (resolve, reject) =>{

      this.httpClient.post<any>(environment.recover_status_digibee, body, { headers: header, observe: 'response' })
      .toPromise()
      .then(v=> {
        let res: EmailRecoverResponse = {
          pagination: { ...v!.body.data.email_recover_status[0].pagination},
          chamados: v!.body.data.email_recover_status[1],
          status: {...v!.body.status}
        };
        resolve(res);
      })
      .catch( e => reject(e));

    })


  }

  async cancelarDesligamento(cpf: string, nome: string, justificativa: string, codigo_processo: string) {

    let data_hora_solicitacao = new Date()

    let data_formatada = new Date(data_hora_solicitacao.getTime());
    var userEmail = this.userInfo.getUserEmail()


    let cpfFormatado = cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")
    // console.log(cpfFormatado)

    console.log("Date: " + data_formatada.getDate() +
      "/" + (data_formatada.getMonth() + 1) +
      "/" + data_formatada.getFullYear() +
      " " + data_formatada.getHours() +
      ":" + data_formatada.getMinutes())


    let header: HttpHeaders;
    header = new HttpHeaders({
      'ContentType': 'application/json',
      // 'Access-Control-Allow-Origin': '*',
      //'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
      'Access-Control-Allow-Methods': 'POST',
      'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization, X-CSRF-Token',
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Max-Age': '86400',
      'Accept': '*/*',
    })

    var body = {
      "data": {
        "cpf": cpfFormatado,
        "nome": nome,
        "email": userEmail,
        "justificativa": justificativa,
        "codigo_processo": codigo_processo,
        "data_hora_solicitacao": data_formatada
      }
    }

      return this.httpClient.post(environment.cancelar_desligamento_digibee, body).
      pipe(
        tap(res => {
          JSON.stringify(res)
          return res
        },
        ))

  }


  async getReembolso() {
    const gcpToken = ((await this.tokenService.getSecrets()).apikeys.gcp);
    var token = this.tokenService.getToken()
    var userEmail = this.userInfo.getUserEmail()

    let header: HttpHeaders;
    header = new HttpHeaders({
      'ContentType': 'application/json',
      // 'Access-Control-Allow-Origin': '*',
      //'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
      'Access-Control-Allow-Methods': 'POST',
      'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization, X-CSRF-Token',
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Max-Age': '86400',
      'Accept': '*/*',
      'Authorization': token,
      'Ambiente': 'teste'
    })

    var body = {
      "data": {
        "email": userEmail
      }
    }


    return await this.httpClient.post(environment.reembolso_digibee, body, { headers: header, observe: 'response' }).toPromise()
  }

  getProdutosPedVendaFaturamento() {

    var token = this.tokenService.getToken()

    let header: HttpHeaders;
    header = new HttpHeaders({
      'ContentType': 'application/json',
      // 'Access-Control-Allow-Origin': '*',
      //'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
      'Access-Control-Allow-Methods': 'POST',
      'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization, X-CSRF-Token',
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Max-Age': '86400',
      'Accept': '*/*',
      'Authorization': token,
      'Ambiente': 'teste'
    })

    var body = {
      "data": {
        "material": ""
      }
    }


    return this.httpClient.post(environment.ped_venda_fat_produtos_digibee, body, { headers: header, observe: 'response' }).toPromise();
  }

  buscaInformeRendimentos() {
    var token = this.tokenService.getToken()
    var userEmail = this.userInfo.getUserEmail()

    let header: HttpHeaders;
    header = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'Authorization': token
    })
    var body = {
      "data": {
        "email": userEmail
      }
    }
    return this.httpClient.post<any>(environment.informe_rendimentos_digibee, body, { headers: header }).toPromise();
  }

  async buscaTabelasMasterdata(key: string, filtro: any) {
    var token = this.tokenService.getToken()
    let header: HttpHeaders;
    header = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'Authorization': token
    })
    var body = {
      "data": {
        "processo": key,
        "filtro": filtro
      }
    }
    return await this.httpClient.post<any>(environment.tabela_masterdata, body, { headers: header }).toPromise();
  }

  async buscaCardsPipesToDropDown(processo: string) {
    var token = this.tokenService.getToken()
    var userEmail = this.userInfo.getUserEmail()
    let header: HttpHeaders;
    header = new HttpHeaders({
      'ContentType': 'application/json'
    })
    var body = {
      "data": {
        "processo": processo
      }
    }
    return this.httpClient.post<any>(environment.tabela_masterdata, body).toPromise();
  }

  testeMost(file:any): Observable<any> {
    let header: HttpHeaders;
    header = new HttpHeaders({
      'Authorization': 'Token ' + this.tokenService.secrets.tokens.mostqi,
      'Content-Type': 'application/json'
    })

    var body = {
      "fileBase64": file,
      "fileUrl": "",
      "returnImage": "False",
      "type": "",
      "tag": ""
  }

    return this.httpClient.post<any>(environment.most_qi_ocr, body, { headers: header, observe: "response" }).pipe(
      tap(res=>{
        // console.log(res)
      })
    )

  }


  getUserInfos(token: string): Observable<any> {
    // console.log("entrei aqui")
    // console.log("token: ", token)
    let header: HttpHeaders;
    header = new HttpHeaders({
      "ContentType": "application/json",
      "Authorization": "Bearer " + token
    })

    return this.httpClient.get<any>(environment.graph_microsoft).pipe(
      tap(res => {
        // console.log(res)
        JSON.stringify(res);
        return res
      })
    )
  }



}
