import { Component, OnInit } from '@angular/core';
import { CommomServicesService } from '../services/commom-services.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {

 isHidden: boolean = true;

  constructor(private commomS: CommomServicesService) { }

  ngOnInit(): void {
  }

  faq(){
    this.commomS.openNewWindow('https://app.pipefy.com/public/form/FZZSofCP');
  }

}
