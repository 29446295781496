export const filiais_tec_agro = [
  {
    nome: ''
  },
  {
    nome: "Apms Participações Societárias Ltda"
  },
  {
    nome: "Filial-Sementes Goiás - Tocantins-0004-90-To"
  },
  {
    nome: "Filial-Sementes Goiás - Unidade 02-0003-00-Go"
  },
  {
    nome: "Filial-Sementes Goiás -0002-29-Go"
  },
  {
    nome: "Filial-Sementes Goiás-0005-71-Go"
  },
  {
    nome: "Filial-Tec agro - Aparecida De Goiânia (Centro De Distribuição) -0022-98-Go"
  },
  {
    nome: "Filial-Tec agro - Araxá-0010-54-Mg"
  },
  {
    nome: "Filial-Tec agro - Catalão-0006-78-Go"
  },
  {
    nome: "Filial-Tec agro - Catalão-0034-21-Go"
  },
  {
    nome: "Filial-Tec agro - Chapadão Do Ceu-0023-79-Go"
  },
  {
    nome: "Filial-Tec agro - Cristalina-0030-06-Go"
  },
  {
    nome: "Filial-Tec agro - Formosa-0012-16-Go"
  },
  {
    nome: "Filial-Tec agro - Goiânia-0008-30-Go"
  },
  {
    nome: "Filial-Tec agro - Goiânia-0031-89-Go"
  },
  {
    nome: "Filial-Tec agro - Goiatuba-0007-59-Go"
  },
  {
    nome: "Filial-Tec agro - Goiatuba-0029-64-Go"
  },
  {
    nome: "Filial-Tec agro - Guaraí-0020-26-To"
  },
  {
    nome: "Filial-Tec agro - Gurupí-0014-88-To"
  },
  {
    nome: "Filial-Tec agro - Itapetininga-0009-10-Sp"
  },
  {
    nome: "Filial-Tec agro - Jataí-0005-97-Go"
  },
  {
    nome: "Filial-Tec agro - Jataí-0024-50-Go"
  },
  {
    nome: "Filial-Tec agro - Jussara-0021-07-Go"
  },
  {
    nome: "Filial-Tec agro - Lagoa Da Confusão-0032-60-To"
  },
  {
    nome: "Filial-Tec agro - Mineiros-0015-69-Go"
  },
  {
    nome: "Filial-Tec agro - Montividiu-0002-44-Go"
  },
  {
    nome: "Filial-Tec agro - Morrinhos-0026-11-Go"
  },
  {
    nome: "Filial-Tec agro - Palmas (Centro De Distribuição) -0019-92-To"
  },
  {
    nome: "Filial-Tec agro - Palmas (Operador Logístico Verde log) -0018-01-To"
  },
  {
    nome: "Filial-Tec agro - Paraíso Do Tocantins-0033-40-To"
  },
  {
    nome: "Filial-Tec agro - Paraúna-0003-25-Go"
  },
  {
    nome: "Filial-Tec agro - Porto Nacional-0013-05-To"
  },
  {
    nome: "Filial-Tec agro - Rio Verde-0025-30-Go"
  },
  {
    nome: "Filial-Tec agro - Rio Verde-0027-00-Go"
  },
  {
    nome: "Filial-Tec agro - Rio Verde-0028-83-Go"
  },
  {
    nome: "Filial-Tec agro - Shego-0004-06-Go"
  },
  {
    nome: "Filial-Tec agro - Silviania-0017-20-Go"
  },
  {
    nome: "Filial-Tec agro - Uruaçú-0011-35-Go"
  },
  {
    nome: "Filial-Tec agro - Vianópolis-0016-40-Go"
  },
  {
    nome: "Filial-Tec agro Armazéns Gerais - Paraúna-0002-29-Go"
  },
  {
    nome: "Filial-Tec Agro Armazéns Gerais - Shego-0002-10-Go"
  },
  {
    nome: "Filial-Tec agro Armazéns Gerais - Shego-0005-71-Go"
  },
  {
    nome: "Fronteira Armazéns Gerais Ltda"
  },
  {
    nome: "Fronteira Comércio Ltda"
  },
  {
    nome: "Matriz -Fronteira Armazéns Gerais-0001-30-Go"
  },
  {
    nome: "Matriz -Holding Apms-0001-27-Go"
  },
  {
    nome: "Matriz -Sementes Goiás-0001-48-Go"
  },
  {
    nome: "Matriz -Tec agro - Rio Verde-0001-63-Go"
  },
  {
    nome: "Sementes Goiás Ltda"
  }
]
