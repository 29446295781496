import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UserInfosService } from 'src/app/autenticacao/usuario/user-infos.service';
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from "src/environments/environment";
import { MsalService } from "@azure/msal-angular";
import { TokenServiceService } from "src/app/services/token-service.service";

@Injectable()

export class GroupsResolver implements Resolve<any> {

  isUserLoggedIn: boolean = false;


  constructor(private http: HttpClient, private userInfo: UserInfosService, private spinner: NgxSpinnerService, private msalService: MsalService, private tokenService: TokenServiceService ) { }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    if (!!environment.localhost) {
      return;
    }
    await this.getAccounts();
  
    this.spinner.show();  
    try {
      const groups = await this.userInfo.getUserGroupsFromAzure().toPromise();
      this.spinner.hide();
      this.userInfo.setUserGroups(groups as any);
    } catch (error) {
      console.error(error);
    }
  }
  async getAccounts(): Promise<Boolean> {
    return await new Promise(resolve => {
      if (!!environment.localhost) {
        resolve(true);
        return;
      }
      const checkValue = () => {
        if (!!this.msalService.instance.getAllAccounts()[0]?.name && !!this.msalService.instance.getAllAccounts()[0]?.username) {
          this.userInfo.setName(this.msalService.instance.getAllAccounts()[0]?.name);
          this.userInfo.setUserEmail(this.msalService.instance.getAllAccounts()[0]?.username);

          resolve(true);
        } else {
          setTimeout(checkValue, 50); // Espera 100ms
        }
      };
      checkValue();
    });
  }
  

}
