import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormMatComponent } from './form.component';
import {  FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { SelectSearchComponent } from './select-search/select-search.component';
import { SelectFileComponent } from './select-file/select-file.component';

@NgModule({
  declarations: [
    FormMatComponent,
    SelectSearchComponent,
    SelectFileComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskDirective, NgxMaskPipe
  ],
  exports: [
    FormMatComponent
  ],
  providers: [
    provideNgxMask()
  ]
})
export class FormMatModule { }
