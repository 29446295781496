import { Component, Input, OnChanges, Output, EventEmitter, SimpleChanges } from '@angular/core';

import { FormGroup, FormBuilder, ValidatorFn, Validators } from '@angular/forms';
import { MsalService } from '@azure/msal-angular';
import { NgxSpinnerService } from 'ngx-spinner';
import { PipefyService } from 'src/app/services/pipefy.service';
import { TrataInputsService } from 'src/app/services/trata-inputs.service';
import Swal from 'sweetalert2';
import { pipe_fields_incidentes } from '../../servicos-financeiros/formularios/pipe_fields_incidentes';
import { UserInfosService } from 'src/app/autenticacao/usuario/user-infos.service';
import { Validadores } from './Validatores';
import { AdsPerfil } from "./form.enum";
import { FormField, ListPipefyConnector, FormOptions } from './form.interface';

@Component({
  selector: 'app-form-mat',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})

export class FormMatComponent implements OnChanges {
  @Input() urlPipefy: string = ""; // ex: https://app.pipefy.com/public/form/MerUV_4C
  @Input() pipeId: string = "";
  @Input() fields: FormField[] = [];
  @Output() changeForm = new EventEmitter<any>();
  urlsFile: { [key: string]: string } = {} as any;
  urlsFileList: { [key: string]: any | any[] } = {} as any;
  dataFieldPersistence: { [key: string]: any } = {} as any;
  form: FormGroup = this.fb.group([]);
  _fields: FormField[] = [];
  __fields = new Map<any, FormField>();
  countFields: number = 0;
  erro: boolean = false;
  error_counter: number = 0;
  preLoaderList: boolean[] = [];
  arrayFieldDetect: string[] = [];

  constructor(private fb: FormBuilder, private pipefy: PipefyService, private spinner: NgxSpinnerService, private trataInput: TrataInputsService,
    private msalService: MsalService, private userInfo: UserInfosService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.resetForm();
  }

  verificarAdPerfil(ads?: AdsPerfil[]): boolean {
    // if (this.userInfo.getUserEmail() == "carlos.santos3@nutrien.com" || this.userInfo.getUserEmail() == "carlos.v.santos@accenture.com") {
    //   return true;
    // }

    if (ads == undefined) {
      return true;
    }
    if (document.location.host.search('localhost') >= 0 && this.userInfo.getUserGroups().length == 0) {
      // console.log("Não é possível verificar o perfil em localhost", ads);
      return true;
    }
    let acesso: boolean[] = [];
    ads.forEach(ad => {
      acesso.push(this.userInfo.getUserGroups().includes(ad) ? true : false);
    });
    return !acesso.includes(false);
  }


  private preLoader(nameProperty: any, bo: boolean) {
    let teste = false;
    this.preLoaderList[nameProperty] = bo;
    Object.getOwnPropertyNames(this.preLoaderList).forEach(name => {
      teste = !!this.preLoaderList[name as any] ? true : teste;
    })
    if (teste) {
      this.spinner.show();
    } else {
      this.spinner.hide();
    }
  }

  private _getConnector(data: FormField) {
    if (!!this.dataFieldPersistence[data.name]) {
      return;
    }
    if (!!!data.connector) {
      console.error("Connector não apresentado!");
      return;
    }
    let response: ListPipefyConnector[] = [];
    this.preLoader(data.name, true);
    this.pipefy.buscaCardsPipesToDropDown(data.connector).then((res: any) => {
      let retorno = res.data.masterdata;
      if (retorno) {
        for (let index = 0; index < retorno.length; index++) {
          response.push({
            name: retorno[index]?.card_id,
            value: retorno[index]?.label
          });
        }
        this.dataFieldPersistence[data.name] = { ...data, select: data.connector == "Data Admissão" ? this.ordenarDatas(response) : response };
        this.form.get(data.name)?.setValue({ ...data, select: data.connector == "Data Admissão" ? this.ordenarDatas(response) : response });
      } else {
        Swal.fire({
          title: 'Desculpe!',
          text: `Ocorreu algum erro durante a Listagem do campo ${data.label}. Por favor, tente novamente.`,
          icon: 'error',
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false
        });
      }
      this.preLoader(data.name, false);
    },
      () => {
        Swal.fire({
          title: 'Desculpe!',
          text: `Erro durante a Listagem do campo ${data.label}. Por favor, tente novamente.`,
          icon: 'error',
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false
        });
        this.preLoader(data.name, false);
      });
  }

  private _pushSelectOption(v: FormOptions | undefined, selectName: string) {
    if (typeof v?.fields == 'undefined') {
      return;
    }
    v.fields.forEach(vv => {
      const disabled = vv.type == 'updateField' ? (!!vv._disabled ? true : false) : true;
      this._pushFields({ ...vv, _disabled: disabled, _optionName: v.name, _selectName: selectName });
    });
  }

  private _pushFields(v: FormField): void {
    this.countFields++;
    v._name = v.name + this.countFields;
    if (v.type != "option" && v.type != "updateField" && this.verificarAdPerfil(v.ad)) {
      this.__fields.set((v.name + v?._optionName + v?._selectName), v);
    }
    v.hide = !!v.hide || !this.verificarAdPerfil(v.ad);
    v.select?.forEach((vv, ii) => {
      this._pushFields(vv);
      this._pushSelectOption(vv, v._name!);
    });
  }

  private _criarCampos() {
    this._fields = [];
    this.__fields.clear();
    this.fields.forEach((v, i) => {
      this._pushFields(v);
    });
    this._fields = Array.from(this.__fields.values());
    this._fields.forEach((v, i) => {
      if (!Array.isArray(v.validators)) {
        this._fields[i].validators = v.validators != undefined ? [v.validators] : [] as any;
      }
      switch (v.type) {
        case 'cpf':
          let cpf = this._fields[i].validators as any[];
          cpf.push(Validadores.cpf);
          this._fields[i].validators = cpf;
          break;
        case 'cnpj':
          let cnpj = this._fields[i].validators as any[];
          cnpj.push(Validadores.cnpj);
          this._fields[i].validators = cnpj;
          break;
        case 'email':
          let email = this._fields[i].validators as any[];
          email.push(Validators.email);
          this._fields[i].validators = email;
          break;
        case 'email-solicitante':
          let emailSolicitante = this._fields[i].validators as any[];
          emailSolicitante.push(Validators.email);
          this._fields[i].validators = emailSolicitante;
          break;

        default:
          break;
      }
      if (v.type == 'mensagem1') {
        this.form.setControl(v.name, this.fb.control(undefined));
        this.form.get(v.name)?.disable();
      }

      if (v.type != "buttonSubmit" && v.type != "select" && v.type != 'radioHorizontal' && v.type != "radioVertical"
        && v.type != 'connector' && v.type != 'selectSearch' && v.type != 'attachment' && v.type != 'mensagem1' && v.type != "updateField") {
        this.form.setControl(v.name, this.fb.control(v.value, v.validators));
      }
      if (v.type == "select") {
        let value = !!v.value ? v.select?.filter(vv => { return vv.value == v.value })[0] : undefined;
        this.form.setControl(v.name, this.fb.control({ disabled: !!v.disabled, value: value }, v.validators));
      }
      if (v.type == "radioHorizontal" || v.type == "radioVertical") {
        let value = !!v.value ? v.select?.filter(vv => { return vv.value == v.value })[0] : undefined;
        this.form.setControl(v.name, this.fb.control({ disabled: !!v.disabled, value: value }, v.validators));
      }
      if (v.type == "checklistHorizontal" || v.type == "checklistVertical") { // melhorias para iniciar form com valores padrão
        let value = !!v.value ? v.select?.filter(vv => { return vv.value == v.value })[0] : undefined;
        this.form.setControl(v.name, this.fb.control(undefined, v.validators));
      }
      if (v.type == 'connector') {
        this.form.setControl(v.name, this.fb.control(undefined, v.validators));
        this._getConnector(v)
      }
      if (v.type == "selectSearch") {
        this.form.setControl(v.name, this.fb.control({ ...v }, v.validators));
      }
      if (v.type == "attachment") {
       
        this.form.setControl(v.name, this.fb.control({ ...v }, v.validators));
        this.form.get(v.name)?.valueChanges.subscribe(field => {
         if (!!!field) {
            this.urlsFileList[v.name] = [];
          }
          if (!!field && typeof field != "string") {
            this.getPipesUrlAttachment(v.name, field);
          }
        });
      }
    });
    this._fields.forEach((v) => {
      if (v.type == "checklistHorizontal" || v.type == "checklistVertical") {
        {
          document.getElementsByName(v?.name).forEach(doc => {
            (document.getElementById(doc.id) as HTMLInputElement).checked = false;
          });
        }
        if (!!v?.select) {
          this.desabilitarEmMassa(v.select as any);
        }
      }
    });
    if (this.pipeId == "302443614") { // suporte-fiscal
      {
        this.form.get("e_mail_nutrien_2")?.setValue("");
        this._fields.forEach((vv, ii) => {
          if (vv.name == "e_mail_nutrien_2") {
            this._fields[ii]._disabled = true;
          }
        });
      }
      {
        this.form.get("e_mail_nutrien_3")?.setValue("");
        this.form.get("e_mail_nutrien_3")?.disable();
        this._fields.forEach((vv, ii) => {
          if (vv.name == "e_mail_nutrien_3") {
            this._fields[ii]._disabled = true;
          }
        });
      }
      this.form.get("e_mail")?.valueChanges.subscribe(v => {
        if (!!v && this.form.get("e_mail")?.errors == null) {
          this.form.get("e_mail_nutrien_2")?.enable();
          this._fields.forEach((vv, ii) => {
            if (vv.name == "e_mail_nutrien_2") {
              this._fields[ii]._disabled = false;
            }
          });
        } else {
          this.form.get("e_mail_nutrien_3")?.disable();
          this._fields.forEach((vv, ii) => {
            if (vv.name == "e_mail_nutrien_2") {
              this._fields[ii]._disabled = true;
            }
          });
          this._fields.forEach((vv, ii) => {
            if (vv.name == "e_mail_nutrien_3") {
              this._fields[ii]._disabled = true;
              // this._fields[ii].hide == true;
            }
          });
        }
      });
      this.form.get("e_mail_nutrien_2")?.valueChanges.subscribe(v => {
        if (this.form.value["e_mail_nutrien_2"]?.length > 0 && !!v && this.form.get("e_mail_nutrien_2")?.errors == null) {
          this.form.get("e_mail_nutrien_3")?.setValue("");
          this.form.get("e_mail_nutrien_3")?.enable();
          this._fields.forEach((vv, ii) => {
            if (vv.name == "e_mail_nutrien_3") {
              this._fields[ii]._disabled = false;
            }
          });
        } else {
          // this.form.get("e_mail_nutrien_3")?.setValue("");
          this.form.get("e_mail_nutrien_3")?.disable();
          this._fields.forEach((vv, ii) => {
            if (vv.name == "e_mail_nutrien_3") {
              this._fields[ii]._disabled = true;
              // this._fields[ii].hide == true;
            }
          });
        }
      });
    }
    if (this.pipeId == "302443335") { // Retificação obrigações acessórias e apurações

      {
        this.form.get("e_mail_2")?.setValue("");
        this._fields.forEach((vv, ii) => {
          if (vv.name == "e_mail_2") {
            this._fields[ii]._disabled = true;
          }
        });
      }
      {
        this.form.get("e_mail_3")?.setValue("");
        this.form.get("e_mail_3")?.disable();
        this._fields.forEach((vv, ii) => {
          if (vv.name == "e_mail_3") {
            this._fields[ii]._disabled = true;
          }
        });
      }
      {
        this.form.get("e_mail_4")?.setValue("");
        this.form.get("e_mail_4")?.disable();
        this._fields.forEach((vv, ii) => {
          if (vv.name == "e_mail_4") {
            this._fields[ii]._disabled = true;
          }
        });
      }
      this.form.get("e_mail_do_solicitante")?.valueChanges.subscribe(v => {
        if (!!v && this.form.get("e_mail_do_solicitante")?.errors == null) {
          this.form.get("e_mail_2")?.enable();
          this._fields.forEach((vv, ii) => {
            if (vv.name == "e_mail_2") {
              this._fields[ii]._disabled = false;
            }
          });
        } else {
          this.form.get("e_mail_3")?.disable();
          this._fields.forEach((vv, ii) => {
            if (vv.name == "e_mail_2") {
              this._fields[ii]._disabled = true;
            }
          });
          this._fields.forEach((vv, ii) => {
            if (vv.name == "e_mail_3") {
              this._fields[ii]._disabled = true;
            }
          });
        }
      });
      this.form.get("e_mail_2")?.valueChanges.subscribe(v => {
        if (this.form.value["e_mail_2"]?.length > 0 && !!v && this.form.get("e_mail_2")?.errors == null) {
          this.form.get("e_mail_3")?.setValue("");
          this.form.get("e_mail_3")?.enable();
          this._fields.forEach((vv, ii) => {
            if (vv.name == "e_mail_3") {
              this._fields[ii]._disabled = false;
            }
          });
        } else {
          this.form.get("e_mail_3")?.disable();
          this._fields.forEach((vv, ii) => {
            if (vv.name == "e_mail_3") {
              this._fields[ii]._disabled = true;
              // this._fields[ii].hide == true;
            }
          });
        }
      });
      this.form.get("e_mail_3")?.valueChanges.subscribe(v => {
        if (this.form.value["e_mail_3"]?.length > 0 && !!v && this.form.get("e_mail_3")?.errors == null) {
          this.form.get("e_mail_4")?.setValue("");
          this.form.get("e_mail_4")?.enable();
          this._fields.forEach((vv, ii) => {
            if (vv.name == "e_mail_4") {
              this._fields[ii]._disabled = false;
            }
          });
        } else {
          this.form.get("e_mail_4")?.disable();
          this._fields.forEach((vv, ii) => {
            if (vv.name == "e_mail_4") {
              this._fields[ii]._disabled = true;
              // this._fields[ii].hide == true;
            }
          });
        }
      });
      {
        this.form.get("e_mail_nbs_2")?.setValue("");
        this._fields.forEach((vv, ii) => {
          if (vv.name == "e_mail_nbs_2") {
            this._fields[ii]._disabled = true;
          }
        });
      }
      {
        this.form.get("e_mail_nbs_3")?.setValue("");
        this.form.get("e_mail_nbs_3")?.disable();
        this._fields.forEach((vv, ii) => {
          if (vv.name == "e_mail_nbs_3") {
            this._fields[ii]._disabled = true;
          }
        });
      }
      this.form.get("e_mail_nbs_2")?.valueChanges.subscribe(v => {
        if (this.form.value["e_mail_nbs_2"]?.length > 0 && !!v && this.form.get("e_mail_nbs_2")?.errors == null) {
          this.form.get("e_mail_nbs_3")?.setValue("");
          this.form.get("e_mail_nbs_3")?.enable();
          this._fields.forEach((vv, ii) => {
            if (vv.name == "e_mail_nbs_3") {
              this._fields[ii]._disabled = false;
            }
          });
        } else {
          // this.form.get("e_mail_nutrien_3")?.setValue("");
          this.form.get("e_mail_nbs_3")?.disable();
          this._fields.forEach((vv, ii) => {
            if (vv.name == "e_mail_nbs_3") {
              this._fields[ii]._disabled = true;
              // this._fields[ii].hide == true;
            }
          });
        }
      });
    }
    this.valoresEstaticos();
  }
  valoresEstaticos(resetForm = true) {

    this._fields.forEach((v, i) => {
      switch (v.type) {
        case "nome-solicitante":
          this.form.get(v.name)?.setValue(document.location.host.search('localhost') >= 0 ? "Sobrenome, Nome" : this.userInfo.getName())
          this._fields[i].disabled = document.location.host.search('localhost') >= 0 || !!this.userInfo.getName() ? true : false;
          break;
        case "email-solicitante":
          this.form.get(v.name)?.setValue(document.location.host.search('localhost') >= 0 ? "nome.sobrenome@nutrien.com" : this.userInfo.getUserEmail())
          this._fields[i].disabled = document.location.host.search('localhost') >= 0 || !!this.userInfo.getUserEmail() ? true : false;
          break;
      }
      switch (v.name) {
        case "nome_do_solicitante":
          this.form.get(v.name)?.setValue(document.location.host.search('localhost') >= 0 ? "Sobrenome, Nome" : this.userInfo.getName())
          this._fields[i].disabled = document.location.host.search('localhost') >= 0 || !!this.userInfo.getName() ? true : false;
          break;
        case "nome_solicitante":
          this.form.get(v.name)?.setValue(document.location.host.search('localhost') >= 0 ? "Sobrenome, Nome" : this.userInfo.getName())
          this._fields[i].disabled = document.location.host.search('localhost') >= 0 || !!this.userInfo.getName() ? true : false;
          break;
        case "e_mail_do_solicitante":
          this.form.get(v.name)?.setValue(document.location.host.search('localhost') >= 0 ? "nome.sobrenome@nutrien.com" : this.userInfo.getUserEmail())
          this._fields[i].disabled = document.location.host.search('localhost') >= 0 || !!this.userInfo.getUserEmail() ? true : false;
          break;
        case "e_mail_solicitante":
          this.form.get(v.name)?.setValue(document.location.host.search('localhost') >= 0 ? "nome.sobrenome@nutrien.com" : this.userInfo.getUserEmail())
          this._fields[i].disabled = document.location.host.search('localhost') >= 0 || !!this.userInfo.getUserEmail() ? true : false;
          break;
        default:
          break;
      }
    });
    if (resetForm) {
      this._fields.forEach((v) => {
        v.select?.forEach(vv => {
          if (!Array.isArray(v.value) && v.value == vv.value) {
            this._changeSelectOption(vv, v._name!);
          }
        });
      });
    }
  }
  desabilitarEmMassa(data: FormOptions & FormField): FormOptions & FormField {
    if (Array.isArray(data)) {
      data = data.map(v => {
        v = this.desabilitarEmMassa(v);
        return v;
      }) as any;
    } else {
      if (data.type != "option" && data.type != "submit") {
        data._disabled = data.type == 'updateField' ? (!!data._disabled ? true : false) : true;
        if (data.type != 'updateField' && data.type != "buttonSubmit") {
          this.form.controls[data.name].disable();
        }
        this._fields.forEach((d, i) => {
          if (d.name == data.name && d.type == data.type) {
            this._fields[i]._disabled = true;
          };
        })
        if (data.type == "checklistVertical" || data.type == "checklistHorizontal") {
          document.getElementsByName(data?.name).forEach(doc => {
            (document.getElementById(doc.id) as HTMLInputElement).checked = false;
          })
        }
      }
      data.select = !!data.select ? this.desabilitarEmMassa(data.select as any) : undefined as any;
      data.fields = !!data.fields ? this.desabilitarEmMassa(data.fields as any) : undefined as any;
    }
    return data;
  }
  updateField(field: FormField) {
    this._fields.forEach((v, i) => {
      if (field.name == v.name && v.type != "updateField" && !!field._name ? (field._name == v._name) : true) {
        let value: FormOptions[] = [];
        if (Array.isArray(field.value)) {
          field.value.forEach(vv => {
            value = this._fields[i].select!.filter(f => { return f.value == vv });
          });
        }
        this._fields[i] = {
          ...v,
          label: !!field.label ? field.label : v.label,
          typeMensagem1: !!field.typeMensagem1 ? field.typeMensagem1 : v.typeMensagem1,
          _disabled: !!field._disabled ? true : false,
          descricao: !!field.descricao ? field.descricao : v.descricao,
          disabled: !!field.disabled ? true : false,
          value: !!field.value ? value : v.value,
          files: !!field.files ? field.files : v.files,
          placeholder: !!field.placeholder ? field.placeholder : v.placeholder,
          typeTextArea: !!field.typeTextArea ? field.typeTextArea : v.typeTextArea,
          validators: !!field.validators ? field.validators : v.validators,
        }
        this.form.get(field.name)?.setValue(!!field.value ? field.value : v.value);
        this._changeSelectOption(this._fields[i].value, v._name!);
      };
    });
  }
  habilitar(event: FormOptions, selectName: string) {
    if (!!event?.fields)
      event.fields.forEach((v, i) => {
        this._fields.forEach((vv, ii) => {
          if (this._fields[ii]._selectName == selectName && this._fields[ii]._optionName == event.name) {
            this._fields[ii]._disabled = vv.type == 'updateField' ? (!!vv._disabled ? true : false) : false;
            if (vv.type != "buttonSubmit") {
              // this._fields[ii].value = undefined;
              this.form.controls[vv.name].markAsUntouched();
              if (vv.type != "select" && vv.type != "checklistHorizontal" && vv.type != "checklistVertical" // fix fazer a implementação para valores estáticos para radio e checklist pois precisa carregar os campos dentro do option selecionado
                && vv.type != "radioHorizontal" && vv.type != "radioVertical") {
                this.form.controls[vv.name].setValue(this._fields[ii].value);
              } else {
                this.form.controls[vv.name].setValue(undefined);
                this._fields[ii].value = undefined;
              }
            }
            if (vv.type != 'mensagem1' && vv.type != "buttonSubmit") {
              this.form.controls[vv.name].enable();
            }
            if (vv.type == 'connector') {
              this.form.get(vv.name)?.setValue(this.dataFieldPersistence[vv.name]);
            }
          }
          this.valoresEstaticos(false);
        });
        if (v.type == 'updateField') {
          this.updateField(v);
        }
      })
  }

  _changeSelectOption(event: FormOptions[] | FormOptions, selectName: string) {
    if (!event) {
    }
    this._fields.forEach((v, i) => {
      if (v._name == selectName) {
        this._fields[i].select?.forEach((vv, ii) => {
          if (typeof event != "undefined" && Array.isArray(event) && event.length > 0) {
            let eventNames = [] as string[];
            event.forEach(e => {
              eventNames.push(e.name);
              if (!eventNames.includes(this._fields[i].select![ii].name)) {
                this.desabilitarEmMassa(this._fields[i].select![ii] as any);
              }
              this.habilitar(e, selectName!);
            })
          }
          if (typeof event != "undefined" && !Array.isArray(event)) {
            if (this._fields[i].select![ii]?.name != event?.name) {
              this.desabilitarEmMassa(this._fields[i].select![ii] as any);

            }
            this.habilitar(event, selectName!);
          }
          if (Array.isArray(event) && event.length == 0) {
            this.desabilitarEmMassa(this._fields[i].select![ii] as any);
          }

        });
      }
    });

  }
  _changeSelectChecklist(event: any, selectName: string) {
    if (!event) {
      return;
    }
    let fieldIndex = 0;
    let field = {} as FormField;
    const valueCheck = event?.target?.ngValue ? event?.target?.ngValue : event;
    this._fields.forEach((v, i) => {
      if (v._name == selectName) {
        field = v;
        fieldIndex = i;
      }
    });
    if (field.type == "checklistHorizontal" || field.type == "checklistVertical") {
      let valueForm = (this.form.get(field.name)?.value as string[])?.length > 0 ? this.form.get(field.name)?.value as FormOptions[] : [];
      if (!!event.target.checked) {
        valueForm = valueForm.filter(v => {
          (document.getElementById(field.name + valueCheck.value) as HTMLInputElement).checked = true;
          return v !== valueCheck;
        });
        valueForm.push(valueCheck);
      }
      if (!!!event.target.checked) {
        valueForm = valueForm.filter(v => {
          (document.getElementById(field.name + valueCheck.value) as HTMLInputElement).checked = false;
          return v !== valueCheck;
        });
      }
      valueForm = valueForm.length > 0 ? valueForm : [];
      this.form.get(field.name)?.setValue(valueForm);
      this.form.get(field.name)?.markAsTouched();
      // this._fields[fieldIndex].value = valueForm;

      this._changeSelectOption(valueForm, selectName);
    } else {
      this._changeSelectOption(event, selectName);
    }

  }
  converterArrayEmString(controlName: string, values: string[]): string {
    if (!values.length) {
      return values as any;
    }
    const _values = values;

    let response = "";
    response = '[';
    for (var i = 0; i < _values.length; i++) {
      response = response + '"' + _values[i] + '",';
    }
    response = response.slice(0, -2);
    response = response + '"]';
    this.arrayFieldDetect.push(controlName);
    return response;
  }

  _submit() {
    let response: any = this.form.value;
    Object.getOwnPropertyNames(response).forEach(async v => {
      if (!!response[v]) {
        if (typeof response[v] == "object" && response[v]?.type == 'option' && !Array.isArray(response[v])) {
          response[v] = response[v]?.value;
        }
        if (Array.isArray(response[v])) {
          if (response[v]?.length > 0) {
            const res = response[v] as FormOptions[];
            response[v] = res.map(v => {
              return !!v?.value ? v.value : v;
            });
            response[v] = this.converterArrayEmString(v, response[v]);
          } else {
            response[v] = undefined;
          }
        }
      }
    });



    if (this.form.invalid) { // para debug e impedir de submit do form com botão desabilitado
      this.abrirModalObrigatoriedade();
      // console.log(response);
      return;
    }

    const criarBody = async () => {
      this.preLoader("submit", true);

      let pipe_fields: string[] = [];
      let pipe_values: any[] = [];
      Object.getOwnPropertyNames(response).forEach(name => {
        if (response[name] != undefined) {
          pipe_fields.push(name);
          pipe_values.push(response[name]?.replace(/\n/g, "\\n")); // corrige o problema de quebra de linha do JSON.parse()
        }
      });
      this.pipefy
        .createCard(this.pipefy.criaBody(pipe_fields, pipe_values, this.pipeId, this.arrayFieldDetect))
        .subscribe(
          (res) => {
            this.preLoader("submit", false);
            if (res.data.result_creation_card.id != undefined) {
              let card_id = res.data.result_creation_card.id;
              Swal.fire({
                title: 'Chamado aberto com Sucesso!',
                text:
                  'A solicitação ' +
                  card_id +
                  ' foi enviada, para uma nova clique em Ok.',
                icon: 'success',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#88D600',
              });
              this.resetForm();
              this.changeForm.emit(response);
            }

            if (res.data.result_creation_card.errors != undefined) {
              var erro = res.data.result_creation_card.errors[0].message;
              Swal.fire({
                title: 'Desculpe!',
                html:
                  '<p>Ocorreu um erro.</p>' +
                  '<a href="https://app.pipefy.com/public/form/TmOnbl5o" target="_blank">Clique aqui </a>' +
                  '<span>para fazer sua solicitação</span>',
                text: erro,
                icon: 'error',
                confirmButtonColor: '#88D600',
              });

              let pipe_fields_incidentes_values = [
                'Incidente',
                'Correção',
                'Portal NBS',
                'F&RH',
                'RU',
                'RH - criação de card -- Movimentação de Benefícios',
                erro,
                'Portal NBS Error',
                'incidente@portal.com',
                'Sim',
                'Movimentação de Benefícios',
                'criação de card via portal',
                'Página web com problemas',
              ];

              var pipe_fields_incidentes_values2 = [];
              for (var i = 0; i < pipe_fields_incidentes_values.length; i++) {
                pipe_fields_incidentes_values2.push(
                  this.trataInput.removeCaractersPipeIncidentes(pipe_fields_incidentes_values[i]));
              }

              let pipe_incidentes_id = '302487533';

              this.pipefy
                .createCard(
                  this.pipefy.criaBody(
                    pipe_fields_incidentes,
                    pipe_fields_incidentes_values2,
                    pipe_incidentes_id
                  )
                )
                .subscribe((res) => {
                  // console.log(res)
                });
            }
          },
          (error: any) => {
            this.preLoader("submit", false);
            this.error_counter = this.error_counter + 1;
            if (this.error_counter < 2) {
              if (error.status == 401) {
                Swal.fire({
                  title: 'Ops, sua sessão expirou!',
                  text: 'Por favor, saia e logue novamente.',
                  icon: 'error',
                  confirmButtonText: 'Sair',
                  confirmButtonColor: '#ff6961',
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                }).then(() => {
                  this.msalService.logoutRedirect();
                });
              } else {
                Swal.fire({
                  title: 'Desculpe!',
                  text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                  icon: 'error',
                  confirmButtonText: 'Ok',
                  confirmButtonColor: '#88D600',
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                });
              }
            } else {
              Swal.fire({
                title: 'Desculpe!',
                html:
                  '<p>Ocorreu um erro.</p>' +
                  '<a href="https://app.pipefy.com/public/form/TmOnbl5o" target="_blank">Clique aqui </a>' +
                  '<span>para fazer sua solicitação</span>',
                icon: 'error',
                confirmButtonColor: '#88D600',
              });
            }
          }
        );

    }
    criarBody();

  }

  resetForm() {
    this.form.reset();
    this.urlsFile = [] as any;
    this.urlsFileList = [];
    this._fields.forEach(v => {
      this.form.removeControl(v.name);
    });
    this.form = undefined as any;
    this.form = this.fb.group([]);
    this._criarCampos();
  }

  public focusInput(controlName: string, value: any) {
    this.form.get(controlName)?.setValue(!!value ? value : undefined);
  }

  public blurInput(controlName: string, value: any) {
    // this.form.get(controlName)?.markAsTouched();
    // this.form.get(controlName)?.setValue(!!value ? value : undefined);
  }

  public formataValorDecimal(controlName: string, input: any) {
    input = input.replace(/[^0-9]/g, ''); // remove all the characters except the numeric values
    if (input.length == 0) input = "0.00"; // set the default value
    else if (input.length == 1) input = "0.0" + input;
    else if (input.length == 2) input = "0." + input;
    else input = input.substring(0, input.length - 2) + '.' + input.substring(input.length - 2, input.length);
    input = new Number(input); // set the precision
    input = input.toFixed(2);    // only works with the "."
    input = input.replace(/\./g, ','); // change the splitter to ","
    let x = input.split(','); // format the amount
    let valor = x[0];
    let decimal = x.length > 1 ? ',' + x[1] : '';
    let rgx = /(\d+)(\d{3})/;
    while (rgx.test(valor)) {
      valor = valor.replace(rgx, '$1' + '.' + '$2');
    }
    this.form.get(controlName)?.setValue(valor + decimal);
  }

  delay(time: any) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  async getPipesUrlAttachment(name: string, files: any[]) {
    this.erro = false;
    this.urlsFileList[name] = [];
    for (var i = 0; i < files.length; i++) {
      if (typeof files[i] == "string") {
        // return;
      }
      var fileContent = files[i];
      let fileName = files[i].name;
      const reader = new FileReader();
      this.preLoader(fileName + name, true);
      reader.readAsDataURL(fileContent as any);
      reader.onload = () => {
        fileContent = reader.result?.toString();
        var fileContentStart = fileContent.indexOf(',');

        fileContent = fileContent.substring(fileContentStart + 1);

        var binaryString = window.atob(fileContent);
        var byteArray = new Uint8Array(binaryString.length);
        for (var i = 0; i < binaryString.length; i++) {
          byteArray[i] += binaryString.charCodeAt(i);
        }/*from w  ww .  j a v a 2  s  .c  o  m*/

        this.pipefy.getFileUrl3(byteArray, fileName).then((urlResposta) => {

          var fileUrlTemp = "";
          var startIndex = 0;

          if (urlResposta.indexOf("orgs") > 0) {
            startIndex = urlResposta.indexOf("orgs/");
          } else {
            startIndex = urlResposta.indexOf("uploads/");
          }

          var endIndex = urlResposta.indexOf("?");
          fileUrlTemp = urlResposta.substring(startIndex, endIndex);

          let url = fileUrlTemp;
          this.urlsFileList[name].push(url);
          this.form.get(name)?.setValue(this.converterArrayEmString(name, this.urlsFileList[name]));
          this.preLoader(fileName + name, false);
        },
          (error: any) => {
            this.erro = true;
            this.preLoader(fileName + name, false);
            this.error_counter = this.error_counter + 1;
            if (this.error_counter < 2) {
              if (error.status == 401) {
                Swal.fire({
                  title: 'Ops, sua sessão expirou!',
                  text: 'Por favor, saia e logue novamente.',
                  icon: 'error',
                  confirmButtonText: 'Sair',
                  confirmButtonColor: '#ff6961',
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                }).then(() => {
                  this.msalService.logoutRedirect();
                });
              } else {
                Swal.fire({
                  title: 'Desculpe!',
                  text: 'Erro ao enviar o anexo. Tente novamente',
                  icon: 'error',
                  confirmButtonText: 'Ok',
                  confirmButtonColor: '#88D600',
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                });
              }
            } else {
              Swal.fire({
                title: 'Desculpe!',
                html:
                  '<p>Ocorreu um erro.</p>' +
                  '<a href="https://app.pipefy.com/public/form/ujpJTjaj" target="_blank">Clique aqui </a>' +
                  '<span>para fazer sua solicitação</span>',
                icon: 'error',
                confirmButtonColor: '#88D600',
              });
              this.urlsFileList[name] = [];
            }
            this.form.get(name)?.reset();
            this.urlsFile[name] = undefined as any;
            this.urlsFileList[name] = undefined as any;
          }
        );
      };
    }
    return new Promise<void>((resolve) => {
      resolve();
    });
  }

  ordenarDatas(date: ListPipefyConnector[]): ListPipefyConnector[] {
    let response: ListPipefyConnector[] = date.map(v => {
      let values = v.value.split("/");
      values[0] = (parseInt(values[0]) + 1).toString();
      return { ...v, value: values[2] + "-" + values[1] + "-" + values[0] }
    });
    response = response.sort((a, b) => new Date(a.value).getTime() - new Date(b.value).getTime());
    return response.map(v => {
      let value = v.value as any;
      value = value.split("-");
      value[2] = (parseInt(value[2]) - 1).toString().length == 1 ? "0" + (parseInt(value[2]) - 1).toString() : (parseInt(value[2]) - 1).toString();
      return { ...v, value: value[2] + "/" + value[1] + "/" + value[0] }

    });
  }

  checkValidatorRequired(field: FormField): boolean {
    const valid = field.validators as any[];
    return valid[0] == Validators.required;
  }

  abrirModalObrigatoriedade() {
    let campos = "";
    Object.getOwnPropertyNames(this.form.getRawValue()).forEach(v => {
      const form = this.form.get(v);
      const formField = this._fields.filter(field => { return field.name == v })[0];
      if (form?.errors?.required && (formField?.label != undefined) && !formField.hide) {
        this.form.get(v)?.markAsTouched();
        campos += !!campos ? ', ' + formField?.label! : formField?.label!;
      }
    });
    campos = campos.split("").reverse().join("").replace(',', 'e ').split("").reverse().join("");
    Swal.fire({
      title: 'Alguns campos são obrigatórios',
      text:
        'Verifique o(s) campo(s): ' + campos,
      icon: 'info',
      confirmButtonText: 'Ok',
      confirmButtonColor: '#88D600',
    });

  }

}
export { FormField, FormOptions, ListPipefyConnector };

