import { Component, OnInit } from '@angular/core';
import { CommomServicesService } from 'src/app/services/commom-services.service';

@Component({
  selector: 'app-taxes',
  templateUrl: './taxes.component.html',
  styleUrls: ['./taxes.component.css']
})
export class TaxesComponent implements OnInit {

  constructor(private commomS: CommomServicesService) { }

  ngOnInit(): void {
  }


  faqLink() {
    this.commomS.openNewWindow('https://app.pipefy.com/public/form/FZZSofCP');
  }

}
