import { Component, OnInit } from '@angular/core';
import { operacoes } from './operacao';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TrataInputsService } from './../../../../services/trata-inputs.service';
import { empresas } from './empresas';
import { filiais_agrichem } from './filiais_agrichem';
import { filiais_nutrien } from './filiais_nutrien';
import { filiais_agrosema } from './filiais_agrosema';
import { filiais_tec_agro } from './filiais_tec_agro';
import { finalidade } from './finalidade';
import { pipe_fields_id } from './pipe_fields_id';
import { HttpErrorResponse } from '@angular/common/http';
import { PipefyService } from 'src/app/services/pipefy.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { pipe_fields_incidentes } from '../../pipe_fields_incidentes';
import { MsalService } from '@azure/msal-angular';
import { UserInfosService } from 'src/app/autenticacao/usuario/user-infos.service';
import { emailNbs } from './email-nbs';
import { CommomServicesService } from 'src/app/services/commom-services.service';

@Component({
  selector: 'app-cancelamento-nf',
  templateUrl: './cancelamento-nf.component.html',
  styleUrls: ['./cancelamento-nf.component.css'],
})
export class CancelamentoNfComponent implements OnInit {
  formulario!: FormGroup;
  operacoes = operacoes;
  empresas = empresas;
  documentos: any[] = [];
  filiais_agrichem = filiais_agrichem;
  filiais_nutrien = filiais_nutrien;
  filiais_agrosema = filiais_agrosema;
  filiais_tec_agro = filiais_tec_agro;
  finalidade = finalidade;
  emailNbs = emailNbs;

  anexoLote: any[] = [];
  anexoVencimento: any[] = [];

  incluirAnexosParm: any[] = [];
  anexoDocumentoParm: any[] = [];

  documentosUrl: any[] = [];
  anexoLoteUrl: any[] = [];
  anexoVencimentoUrl: any[] = [];
  incluirAnexosUrl: any[] = [];
  anexoDocumentoUrl: any[] = [];

  error_counter: number = 0;
  erro: boolean = false;

  emailApi: string = '';
  userNameApi: string = '';

  constructor(
    private formBuilder: FormBuilder,
    private trataInput: TrataInputsService,
    private pipefy: PipefyService,
    private spinner: NgxSpinnerService,
    private msalService: MsalService,
    private userInfo: UserInfosService,
    private commomS: CommomServicesService
  ) {}

  ngOnInit(): void {
    this.formulario = this.formBuilder.group({
      informacoes_da_prioridade: ['', [Validators.required, Validators.maxLength(1000)]],
      motivo_da_urgencia: ['', [Validators.required, Validators.maxLength(1000)]],
      anexoDocumento1: ['', []],
      operacao: ['', [Validators.required, Validators.maxLength(1000)]],
      form_solicitao_emisso_nota_fiscal_anexado_e_preenchido: ['', [Validators.required, Validators.maxLength(1000)]],
      incluirAnexos: ['', [Validators.required, Validators.maxLength(1000)]],
      form_autorizao_contabilidade_emisso_nota_fiscal_em_anexo_assinado_pela_gesto: ['', [Validators.required, Validators.maxLength(1000)]],
      pdf_nota_fiscal_de_origem_em_anexo: ['', [Validators.required, Validators.maxLength(1000)]],
      os_doc_atrelados_nota_fiscal_conhecimento_transporte_cte_foi_cancel_transportadora: ['', [Validators.required, Validators.maxLength(1000)]],
      motivo_do_cancelamento: ['', [Validators.required, Validators.maxLength(1000)]],
      autoriza_o_do_fiscal_nutrien: ['', [Validators.required, Validators.maxLength(1000)]],
      motivo_da_recusa: ['', [Validators.required, Validators.maxLength(1000)]],
      n_mero_da_nota_fiscal_e_s_rie: ['', [Validators.required, Validators.maxLength(1000)]],
      n_mero_do_documento_sap:['', [Validators.maxLength(1000)]],
      n_mero_da_migo: ['', [Validators.required, Validators.maxLength(1000)]],
      empresa: ['', [Validators.required, Validators.maxLength(1000)]],
      filial_agrichem: ['', [Validators.maxLength(1000)]],
      filial_agrosema: ['', [Validators.maxLength(1000)]],
      filial_nutrien: ['', [Validators.maxLength(1000)]],
      filial_tec_agro: ['', [Validators.maxLength(1000)]],
      requisitante_e_e_mail: ['', [Validators.required, Validators.maxLength(1000)]],
      e_mail_do_solicitante_principal_nutrien: ['', [Validators.required, Validators.email]],
      copia_email_nutrien2: ['', [Validators.email, Validators.maxLength(1000)]],
      copia_email_nutrien3: ['', [Validators.email, Validators.maxLength(1000)]],
      copia_email_nutrien4: ['', [Validators.email, Validators.maxLength(1000)]],
      copia_email_nutrien5: ['', [Validators.email, Validators.maxLength(1000)]],
      numero_de_nota_fiscal: ['', [Validators.maxLength(1000)]],
      finalidade: ['', [Validators.maxLength(1000)]],
      desc_produto: ['', [Validators.maxLength(1000)]],
      desc_correta_produto: ['', [Validators.maxLength(1000)]],
      anexo_lote: ['', []],
      peso_transporte: ['', [Validators.maxLength(1000)]],
      peso_correto: ['', [Validators.maxLength(1000)]],
      dados_transporte: ['', [Validators.maxLength(1000)]],
      dados_transportador: ['', [Validators.maxLength(1000)]],
      dados_transportador_peso: ['', [Validators.maxLength(1000)]],
      dados_transportador_qtd: ['', [Validators.maxLength(1000)]],
      dados_transportador_volume: ['', [Validators.maxLength(1000)]],
      dados_transportador_tipo_carga: ['', [Validators.maxLength(1000)]],
      vencimento: ['', []],
      anexo_vencimento: ['', []],
      descricao_da_solicitacao_e_operacao: ['', [Validators.required, Validators.maxLength(1000)]],
      e_mail_nbs: ['', [Validators.maxLength(1000)],],
      n_mero_de_documentos: ['',[]],
      observacao: ['', [Validators.maxLength(1000)]],
      data_de_emiss_o_da_nota_fiscal: ['', []],
      hor_rio_de_emiss_o_da_nota_fiscal: ['', []]
    });

    this.emailApi = this.userInfo.getUserEmail();
    // this.userNameApi = this.userInfo.getName()

    // this.formulario.get('requisitante_e_email')?.setValue(this.emailApi)

    // if(this.formulario.getRawValue().requisitante_e_email !=''){
    //   this.formulario.controls['requisitante_e_email'].disable()
    // }
    // if(this.formulario.getRawValue().requisitante_e_email == undefined){
    //   this.formulario.controls['requisitante_e_email'].enable()
    // }
  }

  onOperacaoChange(event: any){
    this.resetValidators();
    this.resetValues();
    var operacao = event.target.value

    if (operacao === '01-Emissão de nota fiscal de mercadoria' ||
        operacao === '06-Emissão de nota fiscal de serviços-prestados') {
      this.formulario.get('informacoes_da_prioridade')?.setValidators([Validators.required])
      this.formulario.get('informacoes_da_prioridade')?.updateValueAndValidity();

      this.formulario.get('operacao')?.setValidators([Validators.required])
      this.formulario.get('operacao')?.updateValueAndValidity();

      if(this.formulario.get('informacoes_da_prioridade')?.value == 'Urgente'){
        this.formulario.get('motivo_da_urgencia')?.setValidators([Validators.required])
        this.formulario.get('motivo_da_urgencia')?.updateValueAndValidity();
      }

      this.formulario.get('form_solicitao_emisso_nota_fiscal_anexado_e_preenchido')?.setValidators([Validators.required])
      this.formulario.get('form_solicitao_emisso_nota_fiscal_anexado_e_preenchido')?.updateValueAndValidity();

      this.formulario.get('incluirAnexos')?.setValidators([Validators.required])
      this.formulario.get('incluirAnexos')?.updateValueAndValidity();

    }

    if (operacao === '02-Emissão de nota fiscal de ativo imobilizado') {
      this.formulario.get('informacoes_da_prioridade')?.setValidators([Validators.required])
      this.formulario.get('informacoes_da_prioridade')?.updateValueAndValidity();

      this.formulario.get('operacao')?.setValidators([Validators.required])
      this.formulario.get('operacao')?.updateValueAndValidity();

      if(this.formulario.get('informacoes_da_prioridade')?.value == 'Urgente'){
        this.formulario.get('motivo_da_urgencia')?.setValidators([Validators.required])
        this.formulario.get('motivo_da_urgencia')?.updateValueAndValidity();
      }

      this.formulario.get('form_solicitao_emisso_nota_fiscal_anexado_e_preenchido')?.setValidators([Validators.required])
      this.formulario.get('form_solicitao_emisso_nota_fiscal_anexado_e_preenchido')?.updateValueAndValidity();

      this.formulario.get('form_autorizao_contabilidade_emisso_nota_fiscal_em_anexo_assinado_pela_gesto')?.setValidators([Validators.required])
      this.formulario.get('form_autorizao_contabilidade_emisso_nota_fiscal_em_anexo_assinado_pela_gesto')?.updateValueAndValidity();

      this.formulario.get('pdf_nota_fiscal_de_origem_em_anexo')?.setValidators([Validators.required])
      this.formulario.get('pdf_nota_fiscal_de_origem_em_anexo')?.updateValueAndValidity();

      this.formulario.get('incluirAnexos')?.setValidators([Validators.required])
      this.formulario.get('incluirAnexos')?.updateValueAndValidity();
    }

    if (operacao === '03-Emissão de nota fiscal complementar' ||
        operacao === '04-Emissão de nota fiscal de remessa' ||
        operacao === '05-Emissão de nota fiscal devoluções de fornecedores') {
      this.formulario.get('informacoes_da_prioridade')?.setValidators([Validators.required])
      this.formulario.get('informacoes_da_prioridade')?.updateValueAndValidity();

      this.formulario.get('operacao')?.setValidators([Validators.required])
      this.formulario.get('operacao')?.updateValueAndValidity();

      if(this.formulario.get('informacoes_da_prioridade')?.value == 'Urgente'){
        this.formulario.get('motivo_da_urgencia')?.setValidators([Validators.required])
        this.formulario.get('motivo_da_urgencia')?.updateValueAndValidity();
      }

      this.formulario.get('form_solicitao_emisso_nota_fiscal_anexado_e_preenchido')?.setValidators([Validators.required])
      this.formulario.get('form_solicitao_emisso_nota_fiscal_anexado_e_preenchido')?.updateValueAndValidity();

      this.formulario.get('pdf_nota_fiscal_de_origem_em_anexo')?.setValidators([Validators.required])
      this.formulario.get('pdf_nota_fiscal_de_origem_em_anexo')?.updateValueAndValidity();

      this.formulario.get('incluirAnexos')?.setValidators([Validators.required])
      this.formulario.get('incluirAnexos')?.updateValueAndValidity();
    }

    if(operacao == "07-Cancelamento para demais estados (24h)"){

      Swal.fire({
        // title: 'Atenção',
        width: 600,
        html:
              '<p style="font-size:15px;text-align:justify"><span>&#8226;&nbsp;&nbsp;</span>O Prazo Regulamentar para Cancelamento da nota fiscal é de 24 Horas. Ao realizar a abertura do chamado, se atentar ao expediente do time fiscal, que é de: Segunda-feira a Sexta-feira, em horário comercial. Caso sua solicitação seja realizada fora do horário mencionado, o cancelamento NÃO será realizado.</p>'+
              '<p style="font-size:15px;color:#F44336;text-align:justify"><span>&#8226;&nbsp;&nbsp;</span>ATENÇÃO: O prazo máximo para solicitar o cancelamento da nota fiscal é de 3h úteis antes do vencimento do prazo regulamentar.</p><br>'+
              '<p style="font-size:15px;text-align:justify"><span>&#8226;&nbsp;&nbsp;</span>Deseja continuar com a solicitação de cancelamento da NFe?</p>',
        icon: 'info',
        showDenyButton: true,
        denyButtonText: 'Não',
        confirmButtonText: 'Sim, estou ciente',
        confirmButtonColor: '#88D600',
        padding:'10px 30px 30px',
        iconColor:'#88D600'
      }
      ).then((result) => {
        if (result.isConfirmed) {

        } else if (result.isDenied) {
          this.resetform();
        }
      })

      this.formulario.get('informacoes_da_prioridade')?.setValidators([Validators.required])
      this.formulario.get('informacoes_da_prioridade')?.updateValueAndValidity();

      this.formulario.get('operacao')?.setValidators([Validators.required])
      this.formulario.get('operacao')?.updateValueAndValidity();

      if(this.formulario.get('informacoes_da_prioridade')?.value == 'Urgente'){
        this.formulario.get('motivo_da_urgencia')?.setValidators([Validators.required])
        this.formulario.get('motivo_da_urgencia')?.updateValueAndValidity();
      }

      this.formulario.get('data_de_emiss_o_da_nota_fiscal')?.setValidators([Validators.required])
      this.formulario.get('data_de_emiss_o_da_nota_fiscal')?.updateValueAndValidity();

      this.formulario.get('hor_rio_de_emiss_o_da_nota_fiscal')?.setValidators([Validators.required])
      this.formulario.get('hor_rio_de_emiss_o_da_nota_fiscal')?.updateValueAndValidity();

      this.formulario.get('os_doc_atrelados_nota_fiscal_conhecimento_transporte_cte_foi_cancel_transportadora')?.setValidators([Validators.required])
      this.formulario.get('os_doc_atrelados_nota_fiscal_conhecimento_transporte_cte_foi_cancel_transportadora')?.updateValueAndValidity();

      this.formulario.get('pdf_nota_fiscal_de_origem_em_anexo')?.setValidators([Validators.required])
      this.formulario.get('pdf_nota_fiscal_de_origem_em_anexo')?.updateValueAndValidity();

      this.formulario.get('motivo_do_cancelamento')?.setValidators([Validators.required])
      this.formulario.get('motivo_do_cancelamento')?.updateValueAndValidity();

      this.formulario.get('incluirAnexos')?.setValidators([Validators.required])
      this.formulario.get('incluirAnexos')?.updateValueAndValidity();
    }

    if(operacao == "08-Cancelamento específico para Mato Grosso (3h)"){
      this.formulario.get('informacoes_da_prioridade')?.setValidators([Validators.required])
      this.formulario.get('informacoes_da_prioridade')?.updateValueAndValidity();

      this.formulario.get('operacao')?.setValidators([Validators.required])
      this.formulario.get('operacao')?.updateValueAndValidity();

      if(this.formulario.get('informacoes_da_prioridade')?.value == 'Urgente'){
        this.formulario.get('motivo_da_urgencia')?.setValidators([Validators.required])
        this.formulario.get('motivo_da_urgencia')?.updateValueAndValidity();
      }

      this.formulario.get('data_de_emiss_o_da_nota_fiscal')?.setValidators([Validators.required])
      this.formulario.get('data_de_emiss_o_da_nota_fiscal')?.updateValueAndValidity();

      this.formulario.get('hor_rio_de_emiss_o_da_nota_fiscal')?.setValidators([Validators.required])
      this.formulario.get('hor_rio_de_emiss_o_da_nota_fiscal')?.updateValueAndValidity();

      this.formulario.get('os_doc_atrelados_nota_fiscal_conhecimento_transporte_cte_foi_cancel_transportadora')?.setValidators([Validators.required])
      this.formulario.get('os_doc_atrelados_nota_fiscal_conhecimento_transporte_cte_foi_cancel_transportadora')?.updateValueAndValidity();

      this.formulario.get('pdf_nota_fiscal_de_origem_em_anexo')?.setValidators([Validators.required])
      this.formulario.get('pdf_nota_fiscal_de_origem_em_anexo')?.updateValueAndValidity();

      this.formulario.get('motivo_do_cancelamento')?.setValidators([Validators.required])
      this.formulario.get('motivo_do_cancelamento')?.updateValueAndValidity();

      this.formulario.get('incluirAnexos')?.setValidators([Validators.required])
      this.formulario.get('incluirAnexos')?.updateValueAndValidity();

    }

    if (operacao === '09-Carta de correção') {
      this.formulario.get('informacoes_da_prioridade')?.setValidators([Validators.required])
      this.formulario.get('informacoes_da_prioridade')?.updateValueAndValidity();

      this.formulario.get('operacao')?.setValidators([Validators.required])
      this.formulario.get('operacao')?.updateValueAndValidity();

      if(this.formulario.get('informacoes_da_prioridade')?.value == 'Urgente'){
        this.formulario.get('motivo_da_urgencia')?.setValidators([Validators.required])
        this.formulario.get('motivo_da_urgencia')?.updateValueAndValidity();
      }

      this.formulario.get('pdf_nota_fiscal_de_origem_em_anexo')?.setValidators([Validators.required])
      this.formulario.get('pdf_nota_fiscal_de_origem_em_anexo')?.updateValueAndValidity();

      this.formulario.get('incluirAnexos')?.setValidators([Validators.required])
      this.formulario.get('incluirAnexos')?.updateValueAndValidity();
    }

    if (operacao === '10-Cancelamento com a emissão de nota fiscal de entrada') {
      this.formulario.get('informacoes_da_prioridade')?.setValidators([Validators.required])
      this.formulario.get('informacoes_da_prioridade')?.updateValueAndValidity();

      this.formulario.get('operacao')?.setValidators([Validators.required])
      this.formulario.get('operacao')?.updateValueAndValidity();

      if(this.formulario.get('informacoes_da_prioridade')?.value == 'Urgente'){
        this.formulario.get('motivo_da_urgencia')?.setValidators([Validators.required])
        this.formulario.get('motivo_da_urgencia')?.updateValueAndValidity();
      }

      this.formulario.get('form_solicitao_emisso_nota_fiscal_anexado_e_preenchido')?.setValidators([Validators.required])
      this.formulario.get('form_solicitao_emisso_nota_fiscal_anexado_e_preenchido')?.updateValueAndValidity();

      this.formulario.get('pdf_nota_fiscal_de_origem_em_anexo')?.setValidators([Validators.required])
      this.formulario.get('pdf_nota_fiscal_de_origem_em_anexo')?.updateValueAndValidity();

      this.formulario.get('incluirAnexos')?.setValidators([Validators.required])
      this.formulario.get('incluirAnexos')?.updateValueAndValidity();
    }

    if (operacao === '11-Cancelamento extemporâneo') {
      this.formulario.get('informacoes_da_prioridade')?.setValidators([Validators.required])
      this.formulario.get('informacoes_da_prioridade')?.updateValueAndValidity();

      this.formulario.get('operacao')?.setValidators([Validators.required])
      this.formulario.get('operacao')?.updateValueAndValidity();

      if(this.formulario.get('informacoes_da_prioridade')?.value == 'Urgente'){
        this.formulario.get('motivo_da_urgencia')?.setValidators([Validators.required])
        this.formulario.get('motivo_da_urgencia')?.updateValueAndValidity();
      }

      this.formulario.get('autoriza_o_do_fiscal_nutrien')?.setValidators([Validators.required])
      this.formulario.get('autoriza_o_do_fiscal_nutrien')?.updateValueAndValidity();

      this.formulario.get('pdf_nota_fiscal_de_origem_em_anexo')?.setValidators([Validators.required])
      this.formulario.get('pdf_nota_fiscal_de_origem_em_anexo')?.updateValueAndValidity();

      this.formulario.get('motivo_do_cancelamento')?.setValidators([Validators.required])
      this.formulario.get('motivo_do_cancelamento')?.updateValueAndValidity();

      this.formulario.get('incluirAnexos')?.setValidators([Validators.required])
      this.formulario.get('incluirAnexos')?.updateValueAndValidity();
    }

    if (operacao === '12-Recusas') {
      this.formulario.get('informacoes_da_prioridade')?.setValidators([Validators.required])
      this.formulario.get('informacoes_da_prioridade')?.updateValueAndValidity();

      this.formulario.get('operacao')?.setValidators([Validators.required])
      this.formulario.get('operacao')?.updateValueAndValidity();

      if(this.formulario.get('informacoes_da_prioridade')?.value == 'Urgente'){
        this.formulario.get('motivo_da_urgencia')?.setValidators([Validators.required])
        this.formulario.get('motivo_da_urgencia')?.updateValueAndValidity();
      }

      this.formulario.get('pdf_nota_fiscal_de_origem_em_anexo')?.setValidators([Validators.required])
      this.formulario.get('pdf_nota_fiscal_de_origem_em_anexo')?.updateValueAndValidity();

      this.formulario.get('motivo_da_recusa')?.setValidators([Validators.required])
      this.formulario.get('motivo_da_recusa')?.updateValueAndValidity();

      this.formulario.get('incluirAnexos')?.setValidators([Validators.required])
      this.formulario.get('incluirAnexos')?.updateValueAndValidity();
    }

    if (operacao === '13-Suporte ao monitor de nota fiscal (GRC)') {
      this.formulario.get('informacoes_da_prioridade')?.setValidators([Validators.required])
      this.formulario.get('informacoes_da_prioridade')?.updateValueAndValidity();

      this.formulario.get('operacao')?.setValidators([Validators.required])
      this.formulario.get('operacao')?.updateValueAndValidity();

      if(this.formulario.get('informacoes_da_prioridade')?.value == 'Urgente'){
        this.formulario.get('motivo_da_urgencia')?.setValidators([Validators.required])
        this.formulario.get('motivo_da_urgencia')?.updateValueAndValidity();
      }

      this.formulario.get('n_mero_da_nota_fiscal_e_s_rie')?.setValidators([Validators.required])
      this.formulario.get('n_mero_da_nota_fiscal_e_s_rie')?.updateValueAndValidity();

    }

    if (operacao === '14-Emissão de contra nota fiscal de energia elétrica' ||
        operacao === '15-Emissão de contra nota fiscal de produtor rural') {
      this.formulario.get('informacoes_da_prioridade')?.setValidators([Validators.required])
      this.formulario.get('informacoes_da_prioridade')?.updateValueAndValidity();

      this.formulario.get('operacao')?.setValidators([Validators.required])
      this.formulario.get('operacao')?.updateValueAndValidity();

      if(this.formulario.get('informacoes_da_prioridade')?.value == 'Urgente'){
        this.formulario.get('motivo_da_urgencia')?.setValidators([Validators.required])
        this.formulario.get('motivo_da_urgencia')?.updateValueAndValidity();
      }

      this.formulario.get('n_mero_da_migo')?.setValidators([Validators.required])
      this.formulario.get('n_mero_da_migo')?.updateValueAndValidity();

      this.formulario.get('pdf_nota_fiscal_de_origem_em_anexo')?.setValidators([Validators.required])
      this.formulario.get('pdf_nota_fiscal_de_origem_em_anexo')?.updateValueAndValidity();

      this.formulario.get('form_solicitao_emisso_nota_fiscal_anexado_e_preenchido')?.setValidators([Validators.required])
      this.formulario.get('form_solicitao_emisso_nota_fiscal_anexado_e_preenchido')?.updateValueAndValidity();

      this.formulario.get('incluirAnexos')?.setValidators([Validators.required])
      this.formulario.get('incluirAnexos')?.updateValueAndValidity();
    }

      this.formulario.get('empresa')?.setValidators([Validators.required])
      this.formulario.get('empresa')?.updateValueAndValidity();

      if(this.formulario.get('empresa')?.value == 'Agrichem'){
        this.formulario.get('filial_agrichem')?.setValidators([Validators.required])
        this.formulario.get('filial_agrichem')?.updateValueAndValidity();
      }

      if(this.formulario.get('empresa')?.value == 'Agrosema'){
        this.formulario.get('filial_agrosema')?.setValidators([Validators.required])
        this.formulario.get('filial_agrosema')?.updateValueAndValidity();
      }

      if(this.formulario.get('empresa')?.value == 'Nutrien'){
        this.formulario.get('filial_nutrien')?.setValidators([Validators.required])
        this.formulario.get('filial_nutrien')?.updateValueAndValidity();
      }

      if(this.formulario.get('empresa')?.value == 'Tec Agro'){
        this.formulario.get('filial_tec_agro')?.setValidators([Validators.required])
        this.formulario.get('filial_tec_agro')?.updateValueAndValidity();
      }

      this.formulario.get('requisitante_e_e_mail')?.setValidators([Validators.required])
      this.formulario.get('requisitante_e_e_mail')?.updateValueAndValidity();

      this.formulario.get('e_mail_do_solicitante_principal_nutrien')?.setValidators([Validators.required, Validators.email])
      this.formulario.get('e_mail_do_solicitante_principal_nutrien')?.updateValueAndValidity();

      this.formulario.get('descricao_da_solicitacao_e_operacao')?.setValidators([Validators.required])
      this.formulario.get('descricao_da_solicitacao_e_operacao')?.updateValueAndValidity();
  }

  enviarFormulario() {
    this.spinner.show();
    const criarBody = async () => {
      await this.getPipesUrlDocumentos(this.documentos);
      if (this.documentos.length > 0) {
        while (this.documentos.length != this.documentosUrl.length) {
          // console.log("esperando....")
          await this.delay(100);
          if (this.erro) {
            break;
          }
        }
        if (this.erro) {
          return;
        }
      }

      if (this.documentos.length > 0) {
        var documentosUrl2 = '';
        documentosUrl2 = '[';
        for (var i = 0; i < this.documentosUrl.length; i++) {
          documentosUrl2 = documentosUrl2 + '"' + this.documentosUrl[i] + '",';
        }
        documentosUrl2 = documentosUrl2.slice(0, -1);
        documentosUrl2 = documentosUrl2 + ']';
      } else {
        documentosUrl2 = '';
      }


      await this.getPipesUrlIncluirAnexo(this.incluirAnexosParm);
      if (this.incluirAnexosParm.length > 0) {
        while (this.incluirAnexosParm.length != this.incluirAnexosUrl.length) {
          // console.log("esperando....")
          await this.delay(100);
          if (this.erro) {
            break;
          }
        }
        if (this.erro) {
          return;
        }
      }

      if (this.incluirAnexosParm.length > 0) {
        var incluirAnexosUrl2 = '';
        incluirAnexosUrl2 = '[';
        for (var i = 0; i < this.incluirAnexosUrl.length; i++) {
          incluirAnexosUrl2 = incluirAnexosUrl2 + '"' + this.incluirAnexosUrl[i] + '",';
        }
        incluirAnexosUrl2 = incluirAnexosUrl2.slice(0, -1);
        incluirAnexosUrl2 = incluirAnexosUrl2 + ']';
      } else {
        incluirAnexosUrl2 = '';
      }

      await this.getPipesUrlanexoDocumento1(this.anexoDocumentoParm);
      if (this.anexoDocumentoParm.length > 0) {
        while (this.anexoDocumentoParm.length != this.anexoDocumentoUrl.length) {
          // console.log("esperando....")
          await this.delay(100);
          if (this.erro) {
            break;
          }
        }
        if (this.erro) {
          return;
        }
      }

      if (this.anexoDocumentoParm.length > 0) {
        var anexoDocumentoUrl2 = '';
        anexoDocumentoUrl2 = '[';
        for (var i = 0; i < this.anexoDocumentoUrl.length; i++) {
          anexoDocumentoUrl2 = anexoDocumentoUrl2 + '"' + this.anexoDocumentoUrl[i] + '",';
        }
        anexoDocumentoUrl2 = anexoDocumentoUrl2.slice(0, -1);
        anexoDocumentoUrl2 = anexoDocumentoUrl2 + ']';
      } else {
        anexoDocumentoUrl2 = '';
      }

      await this.getPipesUrlAnexoLote(this.anexoLote);
      if (this.anexoLote.length > 0) {
        while (this.anexoLote.length != this.anexoLoteUrl.length) {
          // console.log("esperando....")
          await this.delay(100);
          if (this.erro) {
            break;
          }
        }
        if (this.erro) {
          return;
        }
      }

      if (this.anexoLote.length > 0) {
        var anexoLoteUrl2 = '';
        anexoLoteUrl2 = '[';
        for (var i = 0; i < this.anexoLoteUrl.length; i++) {
          anexoLoteUrl2 = anexoLoteUrl2 + '"' + this.anexoLoteUrl[i] + '",';
        }
        anexoLoteUrl2 = anexoLoteUrl2.slice(0, -1);
        anexoLoteUrl2 = anexoLoteUrl2 + ']';
      } else {
        anexoLoteUrl2 = '';
      }

      await this.getPipesUrlAnexoVencimento(this.anexoVencimento);
      if (this.anexoVencimento.length > 0) {
        while (this.anexoVencimento.length != this.anexoVencimentoUrl.length) {
          // console.log("esperando....")
          await this.delay(100);
          if (this.erro) {
            break;
          }
        }
        if (this.erro) {
          return;
        }
      }

      if (this.anexoVencimento.length > 0) {
        var anexoVencimentoUrl2 = '';
        anexoVencimentoUrl2 = '[';
        for (var i = 0; i < this.anexoVencimentoUrl.length; i++) {
          anexoVencimentoUrl2 =
            anexoVencimentoUrl2 + '"' + this.anexoVencimentoUrl[i] + '",';
        }
        anexoVencimentoUrl2 = anexoVencimentoUrl2.slice(0, -1);
        anexoVencimentoUrl2 = anexoVencimentoUrl2 + ']';
      } else {
        anexoVencimentoUrl2 = '';
      }

      let informacoes_da_prioridade = this.trataInput.removeCaracters(
        this.formulario.getRawValue().informacoes_da_prioridade
      );
      let motivo_da_urgencia = this.trataInput.removeCaracters(
        this.formulario.getRawValue().motivo_da_urgencia
      );
      let operacao = this.formulario.getRawValue().operacao;

      let form_solicitao_emisso_nota_fiscal_anexado_e_preenchido = this.trataInput.removeCaracters(
        this.formulario.getRawValue().form_solicitao_emisso_nota_fiscal_anexado_e_preenchido
      );
      let form_autorizao_contabilidade_emisso_nota_fiscal_em_anexo_assinado_pela_gesto = this.trataInput.removeCaracters(
        this.formulario.getRawValue().form_autorizao_contabilidade_emisso_nota_fiscal_em_anexo_assinado_pela_gesto
      );
      let pdf_nota_fiscal_de_origem_em_anexo = this.trataInput.removeCaracters(
        this.formulario.getRawValue().pdf_nota_fiscal_de_origem_em_anexo
      );
      let os_doc_atrelados_nota_fiscal_conhecimento_transporte_cte_foi_cancel_transportadora = this.trataInput.removeCaracters(
        this.formulario.getRawValue().os_doc_atrelados_nota_fiscal_conhecimento_transporte_cte_foi_cancel_transportadora
      );
      let motivo_do_cancelamento = this.trataInput.removeCaracters(
        this.formulario.getRawValue().motivo_do_cancelamento
      );
      let autoriza_o_do_fiscal_nutrien = this.trataInput.removeCaracters(
        this.formulario.getRawValue().autoriza_o_do_fiscal_nutrien
      );
      let motivo_da_recusa = this.trataInput.removeCaracters(
        this.formulario.getRawValue().motivo_da_recusa
      );
      let n_mero_da_nota_fiscal_e_s_rie = this.trataInput.removeCaracters(
        this.formulario.getRawValue().n_mero_da_nota_fiscal_e_s_rie
      );
      let n_mero_do_documento_sap = this.trataInput.removeCaracters(
        this.formulario.getRawValue().n_mero_do_documento_sap
      );
      let n_mero_da_migo = this.trataInput.removeCaracters(
        this.formulario.getRawValue().n_mero_da_migo
      );
      let empresa = this.trataInput.removeCaracters(
        this.formulario.getRawValue().empresa
      );
      let filial_tec_agro = this.trataInput.removeCaracters(
        this.formulario.getRawValue().filial_tec_agro
      );
      let filial_nutrien = this.trataInput.removeCaracters(
        this.formulario.getRawValue().filial_nutrien
      );
      let filial_agrichem = this.trataInput.removeCaracters(
        this.formulario.getRawValue().filial_agrichem
      );
      let filial_agrosema = this.trataInput.removeCaracters(
        this.formulario.getRawValue().filial_agrosema
      );
      let requisitante_e_e_mail = this.trataInput.removeCaracters(
        this.formulario.getRawValue().requisitante_e_e_mail
      );
      let e_mail_do_solicitante_principal_nutrien = this.trataInput.removeCaracters(
        this.formulario.getRawValue().e_mail_do_solicitante_principal_nutrien
      );
      let copia_email_nutrien2 = this.trataInput.removeCaracters(
        this.formulario.getRawValue().copia_email_nutrien2
      );
      let copia_email_nutrien3 = this.trataInput.removeCaracters(
        this.formulario.getRawValue().copia_email_nutrien3
      );
      let copia_email_nutrien4 = this.trataInput.removeCaracters(
        this.formulario.getRawValue().copia_email_nutrien4
      );
      let copia_email_nutrien5 = this.trataInput.removeCaracters(
        this.formulario.getRawValue().copia_email_nutrien5
      );
      let numero_de_nota_fiscal = this.trataInput.removeCaracters(
        this.formulario.getRawValue().numero_de_nota_fiscal
      );
      let finalidade = this.trataInput.removeCaracters(
        this.formulario.getRawValue().finalidade
      );
      let desc_produto = this.trataInput.removeCaracters(
        this.formulario.getRawValue().desc_produto
      );

      // let dados_transporte = this.trataInput.removeCaracters(
      //   this.formulario.getRawValue().dados_transporte
      // );
      // let peso_transporte = this.trataInput.removeCaracters(
      //   this.formulario.getRawValue().peso_transporte
      // );

      // let desc_correta_produto = this.trataInput.removeCaracters(
      //   this.formulario.getRawValue().desc_correta_produto
      // );
      let peso_correto = this.trataInput.removeCaracters(
        this.formulario.getRawValue().peso_correto
      );
      let dados_transportador = this.trataInput.removeCaracters(
        this.formulario.getRawValue().dados_transportador
      );
      let dados_transportador_peso = this.trataInput.removeCaracters(
        this.formulario.getRawValue().dados_transportador_peso
      );
      let dados_transportador_qtd = this.trataInput.removeCaracters(
        this.formulario.getRawValue().dados_transportador_qtd
      );
      let dados_transportador_volume = this.trataInput.removeCaracters(
        this.formulario.getRawValue().dados_transportador_volume
      );
      let dados_transportador_tipo_carga = this.trataInput.removeCaracters(
        this.formulario.getRawValue().dados_transportador_tipo_carga
      );
      let descricao_da_solicitacao_e_operacao = this.trataInput.removeCaracters(
        this.formulario.getRawValue().descricao_da_solicitacao_e_operacao
      );
      let e_mail_nbs = this.trataInput.removeCaracters(
        this.formulario.getRawValue().e_mail_nbs
      );
      let n_mero_de_documentos = this.trataInput.removeCaracters(
        this.formulario.getRawValue().n_mero_de_documentos
      );
      let observacao = this.trataInput.removeCaracters(
        this.formulario.getRawValue().observacao
      );
      let data_de_emiss_o_da_nota_fiscal = this.trataInput.removeCaracters(
        this.formulario.getRawValue().data_de_emiss_o_da_nota_fiscal
      );
      let hor_rio_de_emiss_o_da_nota_fiscal = this.trataInput.removeCaracters(
        this.formulario.getRawValue().hor_rio_de_emiss_o_da_nota_fiscal
      );
      let pipe_fields = pipe_fields_id;
      let fields_values = [
        informacoes_da_prioridade,
        motivo_da_urgencia,
        anexoDocumentoUrl2,
        operacao,
        form_solicitao_emisso_nota_fiscal_anexado_e_preenchido,
        incluirAnexosUrl2,
        form_autorizao_contabilidade_emisso_nota_fiscal_em_anexo_assinado_pela_gesto,
        pdf_nota_fiscal_de_origem_em_anexo,
        os_doc_atrelados_nota_fiscal_conhecimento_transporte_cte_foi_cancel_transportadora,
        motivo_do_cancelamento,
        autoriza_o_do_fiscal_nutrien,
        motivo_da_recusa,
        n_mero_da_nota_fiscal_e_s_rie,
        n_mero_do_documento_sap,
        n_mero_da_migo,
        empresa,
        filial_tec_agro,
        filial_nutrien,
        filial_agrichem,
        filial_agrosema,
        requisitante_e_e_mail,
        e_mail_do_solicitante_principal_nutrien,
        copia_email_nutrien2,
        copia_email_nutrien3,
        copia_email_nutrien4,
        copia_email_nutrien5,
        numero_de_nota_fiscal,
        finalidade,
        desc_produto,
        anexoLoteUrl2,
        peso_correto,
        dados_transportador,
        dados_transportador_peso,
        dados_transportador_qtd,
        dados_transportador_volume,
        dados_transportador_tipo_carga,
        anexoVencimentoUrl2,
        descricao_da_solicitacao_e_operacao,
        e_mail_nbs,
        documentosUrl2,
        n_mero_de_documentos,
        observacao,
        data_de_emiss_o_da_nota_fiscal,
        hor_rio_de_emiss_o_da_nota_fiscal
      ];

      let pipe_id = '303797752';

      this.pipefy
        .createCard(this.pipefy.criaBody(pipe_fields, fields_values, pipe_id))
        .subscribe(
          (res) => {
            // console.log(res)
            this.spinner.hide();

            if (res.data.result_creation_card.id != undefined) {
              let card_id = res.data.result_creation_card.id;
              Swal.fire({
                title: 'Chamado aberto com Sucesso!',
                text:
                  'O chamado ' +
                  card_id +
                  ' foi aberto com sucesso. Nossa equipe irá tratar e retornar em breve. Obrigado!',
                icon: 'success',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#88D600',
              });
              this.resetform();
            }

            if (res.data.result_creation_card.errors != undefined) {
              var erro = res.data.result_creation_card.errors[0].message;
              Swal.fire({
                title: 'Desculpe!',
                html:
                  '<p>Ocorreu um erro.</p>' +
                  '<a href="https://app.pipefy.com/public/form/FZZSofCP" target="_blank">Clique aqui </a>' +
                  '<span>para fazer sua solicitação</span>',
                text: erro,
                icon: 'error',
                confirmButtonColor: '#88D600',
              });
              this.documentosUrl = [];

              let pipe_fields_incidentes_values = [
                'Incidente',
                'Correção',
                'Portal NBS',
                'F&A',
                'Fiscal',
                'Fiscal - criação de card -- Cancelamento de Nota Fiscal',
                erro,
                'Portal NBS Error',
                'incidente@portal.com',
                'Sim',
                'Cancelamento, Recusa, Emissão Manual - Notas Fiscais',
                'criação de card via portal',
                'Página web com problemas',
              ];

              var pipe_fields_incidentes_values2 = [];
              for (var i = 0; i < pipe_fields_incidentes_values.length; i++) {
                pipe_fields_incidentes_values2.push(
                  this.trataInput.removeCaractersPipeIncidentes(
                    pipe_fields_incidentes_values[i]
                  )
                );
              }
              // console.log(pipe_fields_incidentes_values2)

              let pipe_incidentes_id = '302487533';

              this.pipefy
                .createCard(
                  this.pipefy.criaBody(
                    pipe_fields_incidentes,
                    pipe_fields_incidentes_values2,
                    pipe_incidentes_id
                  )
                )
                .subscribe((res) => {
                  // console.log(res)
                });
            }
          },
          (error: any) => {
            this.spinner.hide();
            this.error_counter = this.error_counter + 1;
            if (this.error_counter < 2) {
              if (error.status == 401) {
                // console.log("entrei em error.status")
                Swal.fire({
                  title: 'Ops, sua sessão expirou!',
                  text: 'Por favor, saia e logue novamente.',
                  icon: 'error',
                  confirmButtonText: 'Sair',
                  confirmButtonColor: '#ff6961',
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                }).then(() => {
                  this.msalService.logoutRedirect();
                });
              } else {
                Swal.fire({
                  title: 'Desculpe!',
                  text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                  icon: 'error',
                  confirmButtonText: 'Ok',
                  confirmButtonColor: '#88D600',
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                });
              }
            } else {
              this.spinner.hide();
              Swal.fire({
                title: 'Desculpe!',
                html:
                  '<p>Ocorreu um erro.</p>' +
                  '<a href="https://app.pipefy.com/public/form/FZZSofCP" target="_blank">Clique aqui </a>' +
                  '<span>para fazer sua solicitação</span>',
                icon: 'error',
                confirmButtonColor: '#88D600',
              });
              this.documentosUrl = [];
            }
          }
        );
    };
    criarBody();
  }

  delay(time: any) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  saveAnexoLote(event: any): void {
    var files = event.target.files;

    var extPermitidas = ['xlsx','csv','doc','pdf'];


    for (var i = 0; i < files.length; i++) {
      var extArquivo = files[i].name.split('.').pop();
      var extensionParts = files[i].name.split('.')
      console.log(extArquivo)
      if(typeof extPermitidas.find(function(ext){ return extArquivo == ext; }) == 'undefined' || extensionParts.length > 2) {
        console.log("entrei em extensao nao permitida")
        alert(files[i].name + " - Extensão Não permitida");
        this.formulario.get('anexo_lote')?.setValue('')

      } else {
        this.anexoLote.push(files[i])
      }
    }
  }

  removeFileAnexoLote(file: File) {
    const index: number = this.anexoLote.indexOf(file);
    this.anexoLote.splice(index, 1);
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500,
    });
    if (this.anexoLote.length == 0) {
      this.formulario.controls['anexo_lote'].markAsTouched();
      this.formulario.get('anexo_lote')?.setValue('');
    }
  }

  async getPipesUrlAnexoLote(files: any) {
    this.erro = false
    for (var i = 0; i < files.length; i++) {
      var fileContent = files[i]
      let fileName = files[i].name
      const reader = new FileReader();
      reader.readAsDataURL(fileContent);
      reader.onload = async () => {
        fileContent = reader.result?.toString()
        var fileContentStart = fileContent.indexOf(",")

        fileContent = fileContent.substring(fileContentStart + 1)
        fileContent = fileContent.toString()

        var binaryString = window.atob(fileContent);
        var byteArray = new Uint8Array(binaryString.length);
        for (var i = 0; i < binaryString.length; i++) {
          byteArray[i] += binaryString.charCodeAt(i);
        }/*from w  ww .  j a v a 2  s  .c  o  m*/

        let urlResposta: any = await this.pipefy.getFileUrl3(byteArray, fileName)


        var fileUrlTemp = "";
        var startIndex = 0;

        if (urlResposta.indexOf("orgs") > 0) {
          startIndex = urlResposta.indexOf("orgs/");
        } else {
          startIndex = urlResposta.indexOf("uploads/");
        }

        var endIndex = urlResposta.indexOf("?");
        fileUrlTemp = urlResposta.substring(startIndex, endIndex);

        this.anexoLoteUrl.push(fileUrlTemp)
      },
        (error: any) => {
          this.erro = true
          this.spinner.hide()
          // console.log(error.status)
          this.error_counter = this.error_counter + 1
          if (this.error_counter < 2) {
            if (error.status == 401) {
              // console.log("entrei em error.status")
              Swal.fire({
                title: 'Ops, sua sessão expirou!',
                text: 'Por favor, saia e logue novamente.',
                icon: 'error',
                confirmButtonText: 'Sair',
                confirmButtonColor: '#ff6961',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
                .then(() => {
                  this.msalService.logoutRedirect()
                }
                )
            } else {
              Swal.fire({
                title: 'Desculpe!',
                text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#88D600',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
            }
          }
          else {
            this.spinner.hide()
            Swal.fire({
              title: 'Desculpe!',
              html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/FZZSofCP" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
              icon: 'error',
              confirmButtonColor: '#88D600',
            })
            this.anexoLoteUrl = []
          }
        }
    }
    return new Promise<void>((resolve) => { resolve(); });
  }

  saveAnexoVencimento(event: any): void {
    var files = event.target.files;

    var extPermitidas = ['xlsx','csv','doc','pdf'];


    for (var i = 0; i < files.length; i++) {
      var extArquivo = files[i].name.split('.').pop();
      var extensionParts = files[i].name.split('.')
      console.log(extArquivo)
      if(typeof extPermitidas.find(function(ext){ return extArquivo == ext; }) == 'undefined' || extensionParts.length > 2) {
        console.log("entrei em extensao nao permitida")
        alert(files[i].name + " - Extensão Não permitida");
        this.formulario.get('anexo_vencimento')?.setValue('')

      } else {
        this.anexoVencimento.push(files[i])
      }
    }
  }

  removeFileAnexoVencimento(file: File) {
    const index: number = this.anexoVencimento.indexOf(file);
    this.anexoVencimento.splice(index, 1);
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500,
    });
    if (this.anexoVencimento.length == 0) {
      this.formulario.controls['anexo_lote'].markAsTouched();
      this.formulario.get('anexo_lote')?.setValue('');
    }
  }

  async getPipesUrlAnexoVencimento(files: any) {
    this.erro = false
    for (var i = 0; i < files.length; i++) {
      var fileContent = files[i]
      let fileName = files[i].name
      const reader = new FileReader();
      reader.readAsDataURL(fileContent);
      reader.onload = async () => {
        fileContent = reader.result?.toString()
        var fileContentStart = fileContent.indexOf(",")

        fileContent = fileContent.substring(fileContentStart + 1)
        fileContent = fileContent.toString()

        var binaryString = window.atob(fileContent);
        var byteArray = new Uint8Array(binaryString.length);
        for (var i = 0; i < binaryString.length; i++) {
          byteArray[i] += binaryString.charCodeAt(i);
        }/*from w  ww .  j a v a 2  s  .c  o  m*/

        let urlResposta: any = await this.pipefy.getFileUrl3(byteArray, fileName)


        var fileUrlTemp = "";
        var startIndex = 0;

        if (urlResposta.indexOf("orgs") > 0) {
          startIndex = urlResposta.indexOf("orgs/");
        } else {
          startIndex = urlResposta.indexOf("uploads/");
        }

        var endIndex = urlResposta.indexOf("?");
        fileUrlTemp = urlResposta.substring(startIndex, endIndex);

        this.anexoVencimentoUrl.push(fileUrlTemp)
      },
        (error: any) => {
          this.erro = true
          this.spinner.hide()
          // console.log(error.status)
          this.error_counter = this.error_counter + 1
          if (this.error_counter < 2) {
            if (error.status == 401) {
              // console.log("entrei em error.status")
              Swal.fire({
                title: 'Ops, sua sessão expirou!',
                text: 'Por favor, saia e logue novamente.',
                icon: 'error',
                confirmButtonText: 'Sair',
                confirmButtonColor: '#ff6961',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
                .then(() => {
                  this.msalService.logoutRedirect()
                }
                )
            } else {
              Swal.fire({
                title: 'Desculpe!',
                text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#88D600',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
            }
          }
          else {
            this.spinner.hide()
            Swal.fire({
              title: 'Desculpe!',
              html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/FZZSofCP" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
              icon: 'error',
              confirmButtonColor: '#88D600',
            })
            this.anexoVencimentoUrl = []
          }
        }
    }
    return new Promise<void>((resolve) => { resolve(); });
  }

  //Salva o anexo do input Anexar Documentos

  saveDocumentos(event: any): void {
    var files = event.target.files;

    var extPermitidas = ['xlsx','csv','doc','pdf'];


    for (var i = 0; i < files.length; i++) {
      var extArquivo = files[i].name.split('.').pop();
      var extensionParts = files[i].name.split('.')
      console.log(extArquivo)
      if(typeof extPermitidas.find(function(ext){ return extArquivo == ext; }) == 'undefined' || extensionParts.length > 2) {
        console.log("entrei em extensao nao permitida")
        alert(files[i].name + " - Extensão Não permitida");
        this.formulario.get('anexoDocumento1')?.setValue('')

      } else {
        this.documentos.push(files[i])
      }
    }
  }

  // Remove o anexo do input Anexar Documentos

  removeFileDocumentos(file: File) {
    const index: number = this.documentos.indexOf(file);
    this.documentos.splice(index, 1);
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500,
    });
    if (this.documentos.length == 0) {
      this.formulario.controls['anexar_documento'].markAsTouched();
      this.formulario.get('anexar_documento')?.setValue('');
    }
  }

  // Insere no card

  async getPipesUrlDocumentos(files: any) {
    this.erro = false
    for (var i = 0; i < files.length; i++) {
      var fileContent = files[i]
      let fileName = files[i].name
      const reader = new FileReader();
      reader.readAsDataURL(fileContent);
      reader.onload = async () => {
        fileContent = reader.result?.toString()
        var fileContentStart = fileContent.indexOf(",")

        fileContent = fileContent.substring(fileContentStart + 1)
        fileContent = fileContent.toString()

        var binaryString = window.atob(fileContent);
        var byteArray = new Uint8Array(binaryString.length);
        for (var i = 0; i < binaryString.length; i++) {
          byteArray[i] += binaryString.charCodeAt(i);
        }/*from w  ww .  j a v a 2  s  .c  o  m*/

        let urlResposta: any = await this.pipefy.getFileUrl3(byteArray, fileName)


        var fileUrlTemp = "";
        var startIndex = 0;

        if (urlResposta.indexOf("orgs") > 0) {
          startIndex = urlResposta.indexOf("orgs/");
        } else {
          startIndex = urlResposta.indexOf("uploads/");
        }

        var endIndex = urlResposta.indexOf("?");
        fileUrlTemp = urlResposta.substring(startIndex, endIndex);

        this.documentosUrl.push(fileUrlTemp)
      },
        (error: any) => {
          this.erro = true
          this.spinner.hide()
          // console.log(error.status)
          this.error_counter = this.error_counter + 1
          if (this.error_counter < 2) {
            if (error.status == 401) {
              // console.log("entrei em error.status")
              Swal.fire({
                title: 'Ops, sua sessão expirou!',
                text: 'Por favor, saia e logue novamente.',
                icon: 'error',
                confirmButtonText: 'Sair',
                confirmButtonColor: '#ff6961',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
                .then(() => {
                  this.msalService.logoutRedirect()
                }
                )
            } else {
              Swal.fire({
                title: 'Desculpe!',
                text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#88D600',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
            }
          }
          else {
            this.spinner.hide()
            Swal.fire({
              title: 'Desculpe!',
              html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/FZZSofCP" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
              icon: 'error',
              confirmButtonColor: '#88D600',
            })
            this.documentosUrl = []
          }
        }
    }
    return new Promise<void>((resolve) => { resolve(); });
  }

  saveIncluirAnexo(event: any): void {
    var files = event.target.files;

    var extPermitidas = ['xlsx','csv','doc','pdf'];


    for (var i = 0; i < files.length; i++) {
      var extArquivo = files[i].name.split('.').pop();
      var extensionParts = files[i].name.split('.')
      console.log(extArquivo)
      if(typeof extPermitidas.find(function(ext){ return extArquivo == ext; }) == 'undefined' || extensionParts.length > 2) {
        console.log("entrei em extensao nao permitida")
        alert(files[i].name + " - Extensão Não permitida");
        this.formulario.get('incluirAnexos')?.setValue('')

      } else {
        this.incluirAnexosParm.push(files[i])
      }
    }

  }

  removeFileIncluirAnexo(file: File) {
    const index: number = this.incluirAnexosParm.indexOf(file);
    this.incluirAnexosParm.splice(index, 1)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500
    })
    if (this.incluirAnexosParm.length == 0) {
      this.formulario.controls['incluirAnexos'].markAsTouched()
      this.formulario.get('incluirAnexos')?.setValue('')
    }
  }

  async getPipesUrlIncluirAnexo(files: any) {
    this.erro = false
    for (var i = 0; i < files.length; i++) {
      var fileContent = files[i]
      let fileName = files[i].name
      const reader = new FileReader();
      reader.readAsDataURL(fileContent);
      reader.onload = async () => {
        fileContent = reader.result?.toString()
        var fileContentStart = fileContent.indexOf(",")

        fileContent = fileContent.substring(fileContentStart + 1)
        fileContent = fileContent.toString()

        var binaryString = window.atob(fileContent);
        var byteArray = new Uint8Array(binaryString.length);
        for (var i = 0; i < binaryString.length; i++) {
          byteArray[i] += binaryString.charCodeAt(i);
        }/*from w  ww .  j a v a 2  s  .c  o  m*/

        let urlResposta: any = await this.pipefy.getFileUrl3(byteArray, fileName)


        var fileUrlTemp = "";
        var startIndex = 0;

        if (urlResposta.indexOf("orgs") > 0) {
          startIndex = urlResposta.indexOf("orgs/");
        } else {
          startIndex = urlResposta.indexOf("uploads/");
        }

        var endIndex = urlResposta.indexOf("?");
        fileUrlTemp = urlResposta.substring(startIndex, endIndex);

        this.incluirAnexosUrl.push(fileUrlTemp)
      },
        (error: any) => {
          this.erro = true
          this.spinner.hide()
          // console.log(error.status)
          this.error_counter = this.error_counter + 1
          if (this.error_counter < 2) {
            if (error.status == 401) {
              // console.log("entrei em error.status")
              Swal.fire({
                title: 'Ops, sua sessão expirou!',
                text: 'Por favor, saia e logue novamente.',
                icon: 'error',
                confirmButtonText: 'Sair',
                confirmButtonColor: '#ff6961',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
                .then(() => {
                  this.msalService.logoutRedirect()
                }
                )
            } else {
              Swal.fire({
                title: 'Desculpe!',
                text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#88D600',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
            }
          }
          else {
            this.spinner.hide()
            Swal.fire({
              title: 'Desculpe!',
              html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/FZZSofCP" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
              icon: 'error',
              confirmButtonColor: '#88D600',
            })
            this.incluirAnexosUrl = []
          }
        }
    }
    return new Promise<void>((resolve) => { resolve(); });
  }

  saveanexoDocumento1(event: any): void {
    var files = event.target.files;

    var extPermitidas = ['xlsx','csv','doc','pdf'];


    for (var i = 0; i < files.length; i++) {
      var extArquivo = files[i].name.split('.').pop();
      var extensionParts = files[i].name.split('.')
      console.log(extArquivo)
      if(typeof extPermitidas.find(function(ext){ return extArquivo == ext; }) == 'undefined' || extensionParts.length > 2) {
        console.log("entrei em extensao nao permitida")
        alert(files[i].name + " - Extensão Não permitida");
        this.formulario.get('anexoDocumento1')?.setValue('')

      } else {
        this.anexoDocumentoParm.push(files[i])
      }
    }

  }

  removeFileanexoDocumento1(file: File) {
    const index: number = this.anexoDocumentoParm.indexOf(file);
    this.anexoDocumentoParm.splice(index, 1)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500
    })
    if (this.anexoDocumentoParm.length == 0) {
      this.formulario.controls['anexoDocumento1'].markAsTouched()
      this.formulario.get('anexoDocumento1')?.setValue('')
    }
  }

  async getPipesUrlanexoDocumento1(files: any) {
    this.erro = false
    for (var i = 0; i < files.length; i++) {
      var fileContent = files[i]
      let fileName = files[i].name
      const reader = new FileReader();
      reader.readAsDataURL(fileContent);
      reader.onload = async () => {
        fileContent = reader.result?.toString()
        var fileContentStart = fileContent.indexOf(",")

        fileContent = fileContent.substring(fileContentStart + 1)
        fileContent = fileContent.toString()

        var binaryString = window.atob(fileContent);
        var byteArray = new Uint8Array(binaryString.length);
        for (var i = 0; i < binaryString.length; i++) {
          byteArray[i] += binaryString.charCodeAt(i);
        }/*from w  ww .  j a v a 2  s  .c  o  m*/

        let urlResposta: any = await this.pipefy.getFileUrl3(byteArray, fileName)


        var fileUrlTemp = "";
        var startIndex = 0;

        if (urlResposta.indexOf("orgs") > 0) {
          startIndex = urlResposta.indexOf("orgs/");
        } else {
          startIndex = urlResposta.indexOf("uploads/");
        }

        var endIndex = urlResposta.indexOf("?");
        fileUrlTemp = urlResposta.substring(startIndex, endIndex);

        this.anexoDocumentoUrl.push(fileUrlTemp)
      },
        (error: any) => {
          this.erro = true
          this.spinner.hide()
          // console.log(error.status)
          this.error_counter = this.error_counter + 1
          if (this.error_counter < 2) {
            if (error.status == 401) {
              // console.log("entrei em error.status")
              Swal.fire({
                title: 'Ops, sua sessão expirou!',
                text: 'Por favor, saia e logue novamente.',
                icon: 'error',
                confirmButtonText: 'Sair',
                confirmButtonColor: '#ff6961',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
                .then(() => {
                  this.msalService.logoutRedirect()
                }
                )
            } else {
              Swal.fire({
                title: 'Desculpe!',
                text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#88D600',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
            }
          }
          else {
            this.spinner.hide()
            Swal.fire({
              title: 'Desculpe!',
              html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/FZZSofCP" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
              icon: 'error',
              confirmButtonColor: '#88D600',
            })
            this.anexoDocumentoUrl = []
          }
        }
    }
    return new Promise<void>((resolve) => { resolve(); });
  }


  onEmpresaChange(event: any) {
    let empresa = event.target.value;

    if (empresa === 'Tec Agro') {
      this.formulario.get('filial_tec_agro')?.setValidators([Validators.required]);
      this.formulario.get('filial_tec_agro')?.updateValueAndValidity();

      this.formulario.get('filial_agrichem')?.clearValidators();
      this.formulario.get('filial_agrichem')?.updateValueAndValidity();

      this.formulario.get('filial_nutrien')?.clearValidators();
      this.formulario.get('filial_nutrien')?.updateValueAndValidity();

      this.formulario.get('filial_agrosema')?.clearValidators();
      this.formulario.get('filial_agrosema')?.updateValueAndValidity();

      this.formulario.get("e_mail_nbs")?.setValue("tecagro.fiscal@accenture.com");
    }

    if (empresa === 'Nutrien') {
      this.formulario.get('filial_tec_agro')?.clearValidators();
      this.formulario.get('filial_tec_agro')?.updateValueAndValidity();

      this.formulario.get('filial_agrichem')?.clearValidators();
      this.formulario.get('filial_agrichem')?.updateValueAndValidity();

      this.formulario
        .get('filial_nutrien')
        ?.setValidators([Validators.required]);
      this.formulario.get('filial_nutrien')?.updateValueAndValidity();

      this.formulario.get('filial_agrosema')?.clearValidators();
      this.formulario.get('filial_agrosema')?.updateValueAndValidity();

      this.formulario.get("e_mail_nbs")?.setValue("nutrien.fiscal@accenture.com");
    }

    if (empresa === 'Agrichem') {
      this.formulario.get('filial_tec_agro')?.clearValidators();
      this.formulario.get('filial_tec_agro')?.updateValueAndValidity();

      this.formulario
        .get('filial_agrichem')
        ?.setValidators([Validators.required]);
      this.formulario.get('filial_agrichem')?.updateValueAndValidity();

      this.formulario.get('filial_nutrien')?.clearValidators();
      this.formulario.get('filial_nutrien')?.updateValueAndValidity();

      this.formulario.get('filial_agrosema')?.clearValidators();
      this.formulario.get('filial_agrosema')?.updateValueAndValidity();

      this.formulario.get("e_mail_nbs")?.setValue("agrichem.fiscal@accenture.com");
    }

    if (empresa === 'Agrosema') {
      this.formulario.get('filial_tec_agro')?.clearValidators();
      this.formulario.get('filial_tec_agro')?.updateValueAndValidity();

      this.formulario.get('filial_agrichem')?.clearValidators();
      this.formulario.get('filial_agrichem')?.updateValueAndValidity();

      this.formulario.get('filial_nutrien')?.clearValidators();
      this.formulario.get('filial_nutrien')?.updateValueAndValidity();

      this.formulario
        .get('filial_agrosema')
        ?.setValidators([Validators.required]);
      this.formulario.get('filial_agrosema')?.updateValueAndValidity();

      this.formulario.get("e_mail_nbs")?.setValue("agrosema.fiscal@accenture.com");
    }
  }

  onFinalidadeChange(event: any) {
    let finalidade = event.target.value;
    // console.log(finalidade);

    if (finalidade === 'Descrição do Produto') {

      this.formulario
        .get('desc_produto')
        ?.setValidators([Validators.required]);
      this.formulario.get('desc_produto')?.updateValueAndValidity();
      this.formulario.get('desc_produto')?.setValidators([Validators.required]);
      this.formulario.get('desc_produto')?.updateValueAndValidity();

      this.formulario.get('anexo_lote')?.clearValidators();
      this.formulario.get('anexo_lote')?.updateValueAndValidity();

      this.formulario.get('peso_transporte')?.clearValidators();
      this.formulario.get('peso_transporte')?.updateValueAndValidity();

      this.formulario.get('dados_transporte')?.clearValidators();
      this.formulario.get('dados_transporte')?.updateValueAndValidity();

      this.formulario.get('anexo_vencimento')?.clearValidators();
      this.formulario.get('anexo_vencimento')?.updateValueAndValidity();
    }

    if (finalidade === 'Lote') {
      this.formulario
        .get('anexo_lote')
        ?.setValidators([Validators.required]);
      this.formulario.get('anexo_lote')?.updateValueAndValidity();
      this.formulario.get('anexo_lote')?.setValidators([Validators.required]);
      this.formulario.get('anexo_lote')?.updateValueAndValidity();

      this.formulario.get('desc_produto')?.clearValidators();
      this.formulario.get('desc_produto')?.updateValueAndValidity();

      this.formulario.get('peso_transporte')?.clearValidators();
      this.formulario.get('peso_transporte')?.updateValueAndValidity();

      this.formulario.get('dados_transporte')?.clearValidators();
      this.formulario.get('dados_transporte')?.updateValueAndValidity();

      this.formulario.get('anexo_vencimento')?.clearValidators();
      this.formulario.get('anexo_vencimento')?.updateValueAndValidity();
    }

    if (finalidade === 'Peso para transporte') {
      this.formulario
        .get('peso_transporte')
        // ?.setValidators([Validators.required]);
      this.formulario.get('peso_transporte')?.updateValueAndValidity();
// console.log('entrei aqui no peso pra transporte')
      this.formulario.get('peso_transporte');
      // ?.setValidators([Validators.required]);
      this.formulario.get('peso_transporte')?.updateValueAndValidity();

      this.formulario.get('anexo_lote')?.clearValidators();
      this.formulario.get('anexo_lote')?.updateValueAndValidity();

      this.formulario.get('desc_produto')?.clearValidators();
      this.formulario.get('desc_produto')?.updateValueAndValidity();

      this.formulario.get('dados_transporte')?.clearValidators();
      this.formulario.get('dados_transporte')?.updateValueAndValidity();

      this.formulario.get('anexo_vencimento')?.clearValidators();
      this.formulario.get('anexo_vencimento')?.updateValueAndValidity();
    }

    if (finalidade === 'Dados do transporte') {
      this.formulario
        .get('dados_transporte')
        // ?.setValidators([Validators.required]);
      this.formulario.get('dados_transporte')?.updateValueAndValidity();
// console.log('entrei aqui no Dados transporte')
      this.formulario.get('dados_transporte');
      // ?.setValidators([Validators.required]);
      this.formulario.get('dados_transporte')?.updateValueAndValidity();

      this.formulario.get('anexo_lote')?.clearValidators();
      this.formulario.get('anexo_lote')?.updateValueAndValidity();

      this.formulario.get('peso_transporte')?.clearValidators();
      this.formulario.get('peso_transporte')?.updateValueAndValidity();

      this.formulario.get('desc_produto')?.clearValidators();
      this.formulario.get('desc_produto')?.updateValueAndValidity();

      this.formulario.get('anexo_vencimento')?.clearValidators();
      this.formulario.get('anexo_vencimento')?.updateValueAndValidity();
    }

    if (finalidade === 'Vencimento') {
      this.formulario
        .get('anexo_vencimento')
        // ?.setValidators([Validators.required]);
      this.formulario.get('anexo_vencimento')?.updateValueAndValidity();
// console.log('entrei aqui no vencimento')
      this.formulario.get('anexo_lote')?.setValidators([Validators.required]);
      this.formulario.get('anexo_lote')?.updateValueAndValidity();

      this.formulario.get('desc_produto')?.clearValidators();
      this.formulario.get('desc_produto')?.updateValueAndValidity();

      this.formulario.get('peso_transporte')?.clearValidators();
      this.formulario.get('peso_transporte')?.updateValueAndValidity();

      this.formulario.get('dados_transporte')?.clearValidators();
      this.formulario.get('dados_transporte')?.updateValueAndValidity();

      this.formulario.get('anexo_lote')?.clearValidators();
      this.formulario.get('anexo_lote')?.updateValueAndValidity();

    }

  }

  mostraValor(event: any) {
    const valor = event?.target.value
    //console.log(valor)
  }

  resetValidators(){
    Object.keys(this.formulario.controls).forEach((key) => {
      const control = this.formulario.controls[key];
      control.setValidators([])
      control.updateValueAndValidity()
      control.markAsPristine();
      control.markAsUntouched();
      if(key=='operacao'){
        control.setValidators([Validators.required])
        control.updateValueAndValidity()
      }
  });
  }

  resetValues(){

    this.documentos = [];
    this.documentosUrl = [];

    this.anexoLote = [];
    this.anexoLoteUrl = [];

    this.anexoVencimento = [];
    this.anexoVencimentoUrl = [];

    // this.anexoDocumentoParm = [];
    this.anexoDocumentoUrl = [];

    this.incluirAnexosParm = [];
    this.incluirAnexosUrl = [];

    this.formulario.get('form_solicitao_emisso_nota_fiscal_anexado_e_preenchido')?.setValue('');
    this.formulario.get('form_autorizao_contabilidade_emisso_nota_fiscal_em_anexo_assinado_pela_gesto')?.setValue('');
    this.formulario.get('pdf_nota_fiscal_de_origem_em_anexo')?.setValue('');
    this.formulario.get('os_doc_atrelados_nota_fiscal_conhecimento_transporte_cte_foi_cancel_transportadora')?.setValue('');
    this.formulario.get('incluirAnexos')?.setValue('');
    this.formulario.get('n_mero_da_migo')?.setValue('');
    this.formulario.get('n_mero_da_nota_fiscal_e_s_rie')?.setValue('');
    this.formulario.get('n_mero_do_documento_sap')?.setValue('');
    this.formulario.get('data_de_emiss_o_da_nota_fiscal')?.setValue('');
    this.formulario.get('hor_rio_de_emiss_o_da_nota_fiscal')?.setValue('');
    this.formulario.get('motivo_da_recusa')?.setValue('');
    this.formulario.get('motivo_do_cancelamento')?.setValue('');

    this.formulario.get('filial_tec_agro')?.clearValidators();
    this.formulario.get('filial_tec_agro')?.updateValueAndValidity();

    this.formulario.get('filial_agrichem')?.clearValidators();
    this.formulario.get('filial_agrichem')?.updateValueAndValidity();

    this.formulario.get('filial_nutrien')?.clearValidators();
    this.formulario.get('filial_nutrien')?.updateValueAndValidity();

    this.formulario.get('filial_agrosema')?.clearValidators();
    this.formulario.get('filial_agrosema')?.updateValueAndValidity();

  }


  resetform() {
    for (let field in this.formulario.controls) {
      this.formulario.controls[field].setValue('');
    }

    this.documentos = [];
    this.documentosUrl = [];

    this.anexoLote = [];
    this.anexoLoteUrl = [];

    this.anexoVencimento = [];
    this.anexoVencimentoUrl = [];

    this.anexoDocumentoParm = [];
    this.anexoDocumentoUrl = [];

    this.incluirAnexosParm = [];
    this.incluirAnexosUrl = [];

    this.formulario.get('filial_tec_agro')?.clearValidators();
    this.formulario.get('filial_tec_agro')?.updateValueAndValidity();

    this.formulario.get('filial_agrichem')?.clearValidators();
    this.formulario.get('filial_agrichem')?.updateValueAndValidity();

    this.formulario.get('filial_nutrien')?.clearValidators();
    this.formulario.get('filial_nutrien')?.updateValueAndValidity();

    this.formulario.get('filial_agrosema')?.clearValidators();
    this.formulario.get('filial_agrosema')?.updateValueAndValidity();
    this.formulario.get('desc_produto')?.clearValidators();
    this.formulario.get('desc_produto')?.updateValueAndValidity();


    this.formulario.get('lote')?.clearValidators();
    this.formulario.get('lote')?.updateValueAndValidity();

    this.formulario.get('dados_transporte')?.clearValidators();
    this.formulario.get('dados_transporte')?.updateValueAndValidity();

    this.formulario.get('peso_transporte')?.clearValidators();
    this.formulario.get('peso_transporte')?.updateValueAndValidity();

    this.formulario.get('anexo_vencimento')?.clearValidators();
    this.formulario.get('anexo_vencimento')?.updateValueAndValidity();

    this.formulario.markAsUntouched();
  }

  abrirPipePublico() {
    this.commomS.openNewWindow('https://app.pipefy.com/public/form/FZZSofCP');
  }
}
