export const environment = {
  "acessar_ipe": "https://api.nutrien.nbs.hml.accenture.com/nutrien-gcpfn-portal-nbs-per-ipe-lanc-hml",
  "cancelar_desligamento_digibee": "https://api.nutrien.nbs.hml.accenture.com/nutrien-gcpfn-portal-nbs-solicitar-canc-demissao-hml",
  "controle_de_versao": "https://api.nutrien.nbs.hml.accenture.com/nutrien-gcpfn-portal-nbs-historico-versao-acesso-hml",
  "dev": true,
  "digibee_key": "",
  "email_versao": "hml@versao.com",
  "generate_card_anexo_digibee": "https://api.nutrien.nbs.hml.accenture.com/nutrien-gcpfn-portal-nbs-generate-pipefy-cards-anexos-hml",
  "generate_card_digibee": "https://api.nutrien.nbs.hml.accenture.com/nutrien-gcpfn-portal-nbs-generate-pipefy-card-hml",
  "generate_card_gcp": "https://api.nutrien.accenture.com/nutrien-gcpfn-portal-nbs-generate-pipefy-card",
  "generate_pipe_url_digibee": "https://api.nutrien.nbs.hml.accenture.com/nutrien-gcpfn-portal-nbs-generate-pipefy-url-hml", // não usamos mais
  "generate_pipe_url_gcp": "https://api.nutrien.accenture.com/nutrien-gcpfn-portal-nbs-generate-pipefy-url",
  "gerar_jwt": "https://api.nutrien.nbs.dev.accenture.com/nutrien-gcpfn-portal-nbs-generate-jwt-token",
  "get_pipe_url": "https://api.nutrien.accenture.com/nutrien-gcpfn-portal-nbs-generate-pipefy-url",
  "get_secrets": "https://api.nutrien.nbs.hml.accenture.com/nutrien-gcpfn-authenticate-portal-hml",
  "graph_groups_microsoft": "https://graph.microsoft.com/v1.0/me/transitiveMemberOf/microsoft.graph.group?$count=true",
  "graph_microsoft": "https://graph.microsoft.com/v1.0/me",
  "homolog": false,
  "informe_rendimentos_digibee": "https://api.nutrien.nbs.hml.accenture.com/nutrien-gcpfn-portal-nbs-informe-rendimento-hml",
  "informe_tabelas_masterdata": "https://api.nutrien.nbs.hml.accenture.com/nutrien-gcpfn-portal-nbs-tabelas-masterdata-hml",
  "localhost": false,
  "most_qi_ocr": "https://mostqiapi.com/process-image/content-extraction",
  "most_qi_ocr_authorization": "",
  "ped_venda_fat_produtos_digibee": "https://api.nutrien.nbs.hml.accenture.com/nutrien-gcpfn-portal-nbs-carga-lista-excel-materiais-hml",
  "pipefy_key": "",
  "pipefy_queries": "https://app.pipefy.com/queries",
  "pipefy_queries_anexo": "https://app.pipefy.com/queries",
  "pipefy_queries_anexo_cookie": "__cfruid=659827f285967f7b7d9c656b82225a8766a75d78-1680286746",
  "pipefy_queries_autorization": "",
  "gcp_authorization":"",
  "production": false,
  "recover_status_digibee": "https://api.nutrien.nbs.hml.accenture.com/nutrien-gcpfn-portal-nbs-email-recover-status-hml",
  "recover_status_gcp": "https://api.nutrien.accenture.com/nutrien-gcpfn-portal-nbs-email-recover-status",
  "reembolso_digibee": "https://api.nutrien.nbs.hml.accenture.com/nutrien-gcpfn-portal-nbs-envio-email-reembolso-hml",
  "sala_de_suporte": "https://app.pipefy.com/public/form/FZZSofCP",
  "SSOAccentureauthority": "https://login.microsoftonline.com/e0793d39-0939-496d-b129-198edd916feb",
  "SSOAccentureclientId": "3a4675de-d68b-4f37-b732-3bc0cfb1e49c",
  "SSOAccenturepostLogoutRedirectUri": "https://nbs.hml.accenture.com/",
  "SSOAccentureredirectUri": "https://nbs.hml.accenture.com/",
  "SSONutrienauthority": "https://login.microsoftonline.com/e0793d39-0939-496d-b129-198edd916feb",
  "SSONutrienclientId": "3a4675de-d68b-4f37-b732-3bc0cfb1e49c",
  "SSONutrienpostLogoutRedirectUri": "https://nbs.hml.accenture.com/",
  "SSONutrienredirectUri": "https://nbs.hml.accenture.com/",
  "tabela_masterdata": "https://api.nutrien.nbs.hml.accenture.com/nutrien-gcpfn-portal-nbs-tabelas-masterdata-hml",
  "verificar_atualizacao_minutos": 10,
  "versao": "13.0.60",
  "versaoPopup": "13.0.60",
  "versaoData": "18/06/2024",
  "versaoHora": "11:25",
  "zenviaChat": "",
  "userNameMock": "",
  "userEmailMock": ""
};
