import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, tap } from 'rxjs/operators'
import { Observable } from 'rxjs';
import { TokenServiceService } from './token-service.service';


@Injectable({
  providedIn: 'root'
})
export class CommomServicesService {

  constructor(private httpClient : HttpClient, private tokenService: TokenServiceService) { }

  openNewWindow(url: string): void {
    url = url.includes('http') ? url : 'https://' + url;
    const link = document.createElement('a');
    link.target = '_blank';
    link.href = url;
    link.click();
    link.remove();
  }

  testeApi(url:any, body: any, authorization?:any):Observable<any> {

    var token = this.tokenService.getToken()

    let header: HttpHeaders;
    header = new HttpHeaders({
      'Content-Type': 'application/json',
      // 'Access-Control-Allow-Origin': '*',
      // 'Access-Control-Allow-Methods': '*',
      // 'Access-Control-Allow-Headers': '*',
      // 'Access-Control-Allow-Credentials': 'true',
      // 'Access-Control-Max-Age': '86400',
      // 'Accept': '*/*',
      // 'Authorization': authorization
    })

    var bodyJson = JSON.parse(body)
    return this.httpClient.post<any>(url, bodyJson, { headers: header, observe:'response' }).
      pipe(
        tap((res: any) => {
          JSON.stringify('resposta',res)
          return res
        })
      )
  }
}
