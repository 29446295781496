import { Injectable } from '@angular/core';
import { UserInfosService } from '../autenticacao/usuario/user-infos.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {  TokenServiceService } from './token-service.service';
import Swal from 'sweetalert2';


export interface VerificarAtualizacao {
  "data": {
    "dados": {
      "e_mail": string,
      "status": "ok" | "não ok" | "não existe o email no pipe!" | "versão do card foi atualizada.",
      "versão": string
    }
  },
}
@Injectable({
  providedIn: 'root'
})
export class ControleDeVersoesService {

  constructor(private userInfo: UserInfosService,
    private httpClient: HttpClient, private token: TokenServiceService) { }

  acessaIpe: boolean = false

  getVersao() {
    return environment.versao
  }

  async consultaVersaoPopUp() { // fix me: mudar para pegar token do usuário depois
    if(environment.dev || environment.localhost) {
      this._showPopUp();
      return ;
    }
    const token = (await (this.token.getSecrets())).apikeys.gcp;
    var userEmail = ''
    userEmail = this.userInfo.getUserEmail()

    console.log("")

    let header: HttpHeaders;
    header = new HttpHeaders({
      "Authorization": "Bearer"+  this.token.jwt
    })


    // if(userEmail =='' || userEmail == undefined){
    //   userEmail = "diego.campos@nutrien.com"
    // }



    let body = { "data": { "processo": "Consultar", "email": userEmail, "versao": environment.versaoPopup } }

    const response = (await this.httpClient.post<any>(environment.controle_de_versao, body,{headers:header}).toPromise()).data.dados.status;

    if (response == "não existe o email no pipe!" || response == "não ok") {
      this._showPopUp();
    }
  }

  async _atualizarVersaoPopUp() {
    if(environment.localhost) {
      return ;
    }
    const token = (await (this.token.getSecrets())).apikeys.gcp;
    var userEmail = ''
    userEmail = this.userInfo.getUserEmail()

    // if(userEmail =='' || userEmail == undefined){
    //   userEmail = "diego.campos@nutrien.com"
    // }

    let body = { "data": { "processo": "Atualizar", "email": userEmail, "versao": environment.versaoPopup } }

    return await this.httpClient.post<any>(environment.controle_de_versao, body).toPromise();
  }

  async podeAcessarIpe() {
    const token = (await (this.token.getSecrets())).apikeys.gcp;
    var userEmail = ''
    userEmail = this.userInfo.getUserEmail()

    if (userEmail == '' || userEmail == undefined) {
      // userEmail = "diego.alves.d.campos@ds.dev.accenture.com"
      userEmail = "silvio.g.moreira@ds.dev.accenture.com"

    }

    let body = { "data": { "email": userEmail } }

    return await this.httpClient.post<any>(environment.acessar_ipe, body
    ).pipe(
      tap(async res => {
        this.acessaIpe = res.data.dados.status
        // console.log(this.acessaIpe)
        JSON.stringify(res);
        return await res;
      }))
  }

  getAcessaIpe() {
    return this.acessaIpe
  }

  procurarAtualizacaoPortal() {
    if(environment.localhost) {
      return;
    }
    console.log(environment.versao + " - " + environment.versaoData + " - " + environment.versaoHora);
    console.log("Procurando atualização.");

    const _atualizarPortal = async () => {
      try {
        const token = (await (this.token.getSecrets())).apikeys.gcp;
        let body = { "data": { "processo": "Consultar", "email": environment.email_versao, "versao": environment.versao } }

        const res = (await this.httpClient.post<VerificarAtualizacao>(environment.controle_de_versao, body).toPromise())?.data?.dados;
        if (!!res?.versão && parseInt(res.versão.replace(".", "").replace(".", "").replace(".", "")) > parseInt(environment.versao.replace(".", "").replace(".", "").replace(".", ""))) {
            window.location.reload();
        }
        if (!!res?.versão && parseInt(res.versão.replace(".", "").replace(".", "").replace(".", "")) < parseInt(environment.versao.replace(".", "").replace(".", "").replace(".", ""))) {
          (await this.httpClient.post<VerificarAtualizacao>(environment.controle_de_versao,
          { "data": { "processo": "Atualizar", "email": environment.email_versao, "versao": environment.versao} }
          ).toPromise());
        }

        setInterval(async () => {
          const res = (await this.httpClient.post<VerificarAtualizacao>(environment.controle_de_versao, body).toPromise())!.data.dados;
          if (!!res?.versão && parseInt(res.versão.replace(".", "").replace(".", "").replace(".", "")) > parseInt(environment.versao.replace(".", "").replace(".", "").replace(".", ""))) {
            console.log("Atualizando portal...");
            setTimeout(() => {
              window.location.reload();
            }, 100);
          }
        }, environment.verificar_atualizacao_minutos * 60000);
      } catch ( err ) {
        console.log("Erro na atualização do portal. Nova tentativa em breve! ", err);
      }
    }

    setInterval( () => {
      _atualizarPortal();
    }, environment.verificar_atualizacao_minutos * 60000)
    _atualizarPortal();
  }

  formatarVersao(valor: string) {
    const partes = valor.split('.');
    partes[partes.length - 1] = '0';
    return partes.join('.');
  }

  _showPopUp() {
    Swal.fire({
      title: 'Comunicação de Melhoria',
      html: '<div style="text-align:justify; padding:20px">' +
      '<h4 style="text-align:center">Versão ' + environment.versao + ' - ' + environment.versaoData + '</h4>' +
        '<div>' +
        '<span style="color:#88D600">Atualizações automáticas a partir da versão 13.0.0</span><br>' +
        '<h6>Não se preocupe! Todas as atualizações deverão ocorrer em segundo plano de forma automática.</h6>' +
        '<span style="color:#88D600">Está com o portal desatualizado? Siga o passo a passo:</span></div>' +
      '<img class="nbs_banner-img" src="../../../assets/img/limpar-cache.png" style= "width: 100%" >' +
      '<h4 style="text-align:center">Outras atualizações</h4>' +
        'Contabilidade' +
        '<div> <b style="font-weight: bold;">ALTERAÇÃO: </b>' +
        '<span style="color:#88D600">Inclusão de descrição informativa para preenchimento do formulário.</span><br>' +
        '<b style="font-weight: bold;">MENU: </b>' +
        '<span>Serviços Financeiros > Contabilidade > Imobilizado </span><br><br>' +
        '</div><br>' +
        '</div>',
      iconColor: '#88D600',
      confirmButtonColor: '#88D600',
      customClass: {
        container: "comunicadoMelhoria",
      },
      didClose: () => {
        this._atualizarVersaoPopUp();
      },
    })

  }



}

