export const filiais_agrosema = [
  {
    nome: ''
  },
  {
    nome: 'Agrosema'
  },
  {
    nome: 'Filial-Agrosema -Alfenas-0031-31-Mg'
  },
  {
    nome: 'Filial-Agrosema -Araxá-0029-17-Mg'
  },
  {
    nome: 'Filial-Agrosema -Capão Bonito-0005-40-Sp'
  },
  {
    nome: 'Filial-Agrosema -Coromandel-0026-74-Mg'
  },
  {
    nome: 'Filial-Agrosema -Elias Fausto-0012-79-Sp'
  },
  {
    nome: 'Filial-Agrosema -Ibiá-0018-64-Mg'
  },
  {
    nome: 'Filial-Agrosema -Ibiá-0028-36-Mg'
  },
  {
    nome: 'Filial-Agrosema -Indaiatuba-0011-98-Sp'
  },
  {
    nome: 'Filial-Agrosema -Itapetininga-0008-92-Sp'
  },
  {
    nome: 'Filial-Agrosema -Ituiutaba-0032-12-Mg'
  },
  {
    nome: 'Filial-Agrosema -Mogi-Guaçu-0007-01-Sp'
  },
  {
    nome: 'Filial-Agrosema -Patos De Minas-0015-11-Mg'
  },
  {
    nome: 'Filial-Agrosema -Patrocínio-0027-55-Mg'
  },
  {
    nome: 'Filial-Agrosema -São Gotardo-0030-50-Mg'
  },
  {
    nome: 'Filial-Agrosema -São Miguel Arcanjo-0006-20-Sp'
  },
  {
    nome: 'Filial-Agrosema -Taquarituba-0013-50-Sp'
  },
  {
    nome: 'Filial-Agrosema -Uberaba-0023-21-Mg'
  },
  {
    nome: 'Filial-Agrosema -Uberaba-0024-02-Mg'
  },
  {
    nome: 'Filial-Agrosema -Uberaba-0025-93-Mg'
  },
  {
    nome: 'Filial-Agrosema -Uberlândia-0022-40-Mg'
  },
  {
    nome: 'Filial-Agrosema -Vargem Grande Do Sul-0016-00-Sp'
  },
  {
    nome: 'Matriz-Agrosema -Elias Fausto-0001-16-Sp'
  }
]
