import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MsalBroadcastService, MsalGuardConfiguration, MSAL_GUARD_CONFIG, MsalService } from '@azure/msal-angular';
import { InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AzureService } from './autenticacao/azure.service';
import { UserInfosService } from 'src/app/autenticacao/usuario/user-infos.service';
import { environment } from 'src/environments/environment';
import { ControleDeVersoesService } from './services/controle-de-versoes.service';
import { ActivatedRoute } from '@angular/router';
import { TokenServiceService } from './services/token-service.service';
import { CommomServicesService } from './services/commom-services.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'portal-front';
  isUserLoggedIn: boolean = false;
  cont: number = 0;
  groups: any;

  private readonly _destroy = new Subject<void>();

  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalBroadcastService: MsalBroadcastService,
    private authService: MsalService,
    private azureService: AzureService,
    private controleVersao: ControleDeVersoesService,
    private userInfo: UserInfosService,
    private route: ActivatedRoute,
    private tokenS: TokenServiceService,
    private commomS: CommomServicesService
  ) { }

  ngOnDestroy(): void {
    this._destroy.next(undefined);
    this._destroy.complete()
  }
  ngOnInit() {
    this.tokenS.getAccounts()
      .then(() => {
        this.tokenS.getSecrets();
      });

    this.msalBroadcastService.inProgress$.pipe
      (filter((interactionStatus: InteractionStatus) =>
        interactionStatus == InteractionStatus.None),
        takeUntil(this._destroy))
      .subscribe(x => {
        this.isUserLoggedIn = this.authService.instance.getAllAccounts().length > 0;
        this.azureService.isUserLoggedIn.next(this.isUserLoggedIn);
      });


    this.groups = this.route.data.subscribe(
      (groups) => {
        this.groups = groups.groups;
      }
    )



  }



  acessaIpe() {
    this.controleVersao.podeAcessarIpe().then((res) => {
      // console.log(res);
    })
  }

  isLoggedIn(): boolean {
    return this.authService.instance.getActiveAccount() != null
  }

  logout(): void {
    this.authService.logoutRedirect()
  }



  // getInfos(): string | undefined {
  //   return this.authService.instance.getActiveAccount()?.name
  // }


  verificaPerfil(group: any) {
    var perfil = this.userInfo.getUserGroups().includes(group);
    return perfil;
  }

  getUserProfile() {
    this.userInfo.getUserGroupsFromAzure().subscribe((res: any) => {
      this.userInfo.setUserGroups(res);
    });
  }

  openForm() {
    let userEmail = this.userInfo.getUserEmail()
    let userName = this.userInfo.getName()

    userName = userName.replace(" ", "_").replace(",", "")

    const url = environment.sala_de_suporte + "?nome_do_solicitante=" + userName + "&e_mail_do_solicitante=" + userEmail
    this.commomS.openNewWindow(url);
  }
  // async getAccounts(): Promise<any> {
  //   return await new Promise(resolve => {
  //     const checkValue = () => {
  //       if (!!this.msalService.instance.getAllAccounts()[0]?.name && !!this.msalService.instance.getAllAccounts()[0]?.username) {
  //         this.userInfo.setName(this.msalService.instance.getAllAccounts()[0]?.name);
  //         this.userInfo.setUserEmail(this.msalService.instance.getAllAccounts()[0]?.username);
  //         resolve(true);
  //       } else {
  //         setTimeout(checkValue, 50); // Espera 100ms
  //       }
  //     };
  //     checkValue();
  //   });
  // }

}
