<app-header></app-header>
<app-nbs-banner url="../../../assets/img/backgroundcontabilidade2.png" descricao="teste" titulo="Contabilidade">
</app-nbs-banner>
<div class="container content">

  <h6 class="title">Escolha a opção desejada para realizar sua solicitação.</h6>

  
  <div class="itens">
    <div class="row menu-itens">
      <div class="col-lg-6">
        <app-box-link-pipe descricao="Contabilidade" url="../../../assets/img/fa-menus/contabilidade/auditoria.png" titulo="Documentos" subtitulo="Suporte e Relatórios"  link="/accouting/forms/auditoria"></app-box-link-pipe>
      </div>
      <div class="col-lg-6">
        <app-box-link-pipe descricao="Compras" url="../../../assets/img/fa-menus/contabilidade/baixa_imobilizado.png" titulo="Imobilizado" link="/accouting/forms/baixa-imobilizado"></app-box-link-pipe>
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-lg-6">
        <app-box-link-pipe descricao="Compras" url="../../../assets/img/fa-menus/contabilidade/lancamento_contabil.png" titulo="Lançamento Contábil" link="/accouting/forms/lancamento-contabil"></app-box-link-pipe>
      </div>
    </div> -->
  </div>


</div>


<div class="footer">


  <app-footer link="menu"></app-footer>
</div>

