import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  @Input() link:string = ""

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  navegar(){
    this.router.navigate([this.link])
  }

  faq(){
    this.router.navigate(['/forms/ajuda'])
    // w i n d o w . o p e n ('https://teams.microsoft.com/l/channel/19%3aCLyOXydI8jXEU778giMgw_CdE7NNTjG39KT2CE6Gaw01%40thread.tacv2/General?groupId=33affcdb-311a-4c29-bda9-f80dfcf5e27d&tenantId=5e0b361b-59ed-466e-8759-030448046197',"_blank")
  }

  termo() {
    Swal.fire({
      title: 'Termo de Compromisso',
      html: '<div style="text-align:justify; padding:20px">' +
      '<h4 style="text-align:center">Versão ' + environment.versao + ' - ' + environment.versaoData + '</h4>' +
      //   '<div>' +
      //   '<span style="color:#88D600">Atualizações automáticas a partir da versão 13.0.0</span><br>' +
      //   '<h6>Não se preocupe! Todas as atualizações deverão ocorrer em segundo plano de forma automática.</h6>' +
      //   '<span style="color:#88D600">Está com o portal desatualizado? Siga o passo a passo:</span></div>' +
      //'<img class="nbs_banner-img" src="../../../assets/img/melhoria.png" style= "width: 100%" >' +
       //'<h4 style="text-align:center">Outras atualizações</h4>' +
        //'Fiscal</br></br>'+
        '<p>Bem-vindo à nutrien. Este Site é de propriedade da <strong>Nutrien Soluções Agrícolas Ltda.</strong>  ("Nutrien", "nós", "nos", "nosso"). Estes Termos de Uso ("Termos de Uso") regem as condições do seu uso deste Site.</p>' +
				'<h3>Escopo destes Termos de Uso</h3> ' +
				'<p>Este Site inclui áreas abertas a todos os visitantes, bem como algumas áreas protegidas por senha, como o acesso a determinados serviços (o "Serviço"), que podem ser acessadas apenas por clientes da Nutrien que possuem uma conta com as credenciais de acesso e outros usuários autorizados (coletivamente, "Usuários Autorizados"). Estes Termos de Uso aplicam-se tanto às áreas públicas deste Site quanto às áreas protegidas por senha deste Site.</p>' +
				'<h3>Sua aceitação</h3>' +
				'<p>POR FAVOR, REVISE ESTES TERMOS DE USO CUIDADOSAMENTE. AO ACESSAR OU USAR ESTE SITE, VOCÊ CONCORDA EM CUMPRIR E ESTAR VINCULADO A ESTES TERMOS DE USO. SE VOCÊ NÃO CONCORDAR COM ESTES TERMOS DE USO, VOCÊ NÃO PODE USAR ESTE SITE.</p>' +
				'<h3>Alterações a estes Termos de Uso</h3> ' +
				'<p>À medida que adicionamos novos recursos e funcionalidades a este Site, talvez precisemos atualizar ou revisar estes Termos de Uso. Portanto, reservamo-nos o direito de atualizar ou revisar estes Termos de Uso, a qualquer momento e sem aviso prévio, publicando a versão revisada neste Site. Essas alterações entrarão em vigor a partir da data em que publicarmos a versão revisada neste Site. Seu uso deste Site após qualquer alteração constitui sua concordância com os termos dos Termos de Uso atualizados. Por esta razão, é importante rever estes Termos de Uso regularmente.</p>' +
				'<p>Se modificarmos estes Termos de Uso, forneceremos um aviso na parte superior desta página por pelo menos 30 dias após a nova data de vigência. Você pode acessar a versão atual destes Termos de Uso a qualquer momento, clicando no link marcado "Termos de Uso" na parte inferior de cada página deste Site. Para sua conveniência, listamos a data de vigência destes Termos de Uso na parte superior desta página, para que você possa ver rapidamente se nossos Termos de Uso foram alterados desde sua última visita.</p>' +
				'<h3>Nossa Política de Privacidade Online</h3>' +
				'<p>Nossa Declaração de Privacidade &amp; Cookies online descreve como protegemos sua privacidade quando você usa este Site. Para consultar a nossa Declaração de Privacidade &amp; Cookies online, <a href="https://nutrien.com.br/declaracao-de-privacidade-e-cookies-da-nutrien">clique aqui</a>. Nossa Declaração de Privacidade &amp; Cookies online faz parte e está incorporada a estes Termos de Uso.</p>' +
				'<h3>Inscrição</h3>' +
				'<p>Podemos exigir que você siga regras, diretrizes ou condições adicionais para usar vários recursos de nosso Site, para participar de certas promoções ou atividades em nosso Site, ou por outros motivos. Nesses casos, você pode ser solicitado a concordar expressamente com esses termos adicionais, por exemplo, marcando uma caixa ou clicando em um botão marcado "Concordo" ou você pode simplesmente ser apresentado aos termos adicionais em certas áreas do nosso Site. Esses contratos e outros termos adicionais são chamados de "Termos Adicionais". Se qualquer um dos Termos Adicionais for diferente destes Termos de Uso, esses Termos Adicionais complementarão ou alterarão estes Termos de Uso, mas apenas com relação aos assuntos regidos por esses Termos Adicionais.</p>' +
				'<h3>Seu Feedback</h3>' +
				'<p>Agradecemos seus comentários, feedbacks, sugestões e outras comunicações sobre este Site e as informações e serviços que disponibilizamos por meio deste Site (coletivamente, "Feedback"). O Feedback que você nos fornece por meio deste Site será e continuará sendo nossa propriedade exclusiva. Seu envio de Comentários constituirá uma cessão para nós de todos os direitos, títulos e interesses em caráter universal, incluindo todos os direitos autorais e outros direitos de propriedade intelectual decorrentes de seu Feedback. Teremos o direito de usar sem restrições, explorar, divulgar, exibir ou executar publicamente, qualquer Feedback que você enviar para qualquer finalidade e sem compensá-lo de qualquer forma. Por esta razão, pedimos que você não nos envie nenhum Feedback que não deseje nos atribuir estes direitos.</p>' +
				'<h3>Propriedade do Site e do Conteúdo</h3>' +
				'<p>Exceto conforme expressamente estabelecido neste documento ou em quaisquer Termos Adicionais, todos os direitos, títulos e interesses neste Site, incluindo integralmente o software e código que compõem e operam este Site, e todos os textos, fotografias, imagens, ilustrações, gráficos, clipes de áudio, vídeo e áudio-vídeo e outros materiais fornecidos através deste Site, e todos os dados que você pode inserir ou transmitir de outra forma para o Site (coletivamente,  "Conteúdo") são de propriedade da Nutrien ou de terceiros que licenciaram seu Conteúdo para nós. Este Site está protegido por marcas registradas, marcas de serviço, imagem comercial, direitos autorais, patentes, segredos comerciais e outras Leis de Propriedade Intelectual. Além disso, todo o Conteúdo deste Site é um trabalho coletivo sob as Leis e tratados de direitos autorais internacionais, inclusive dos EUA, e a Nutrien detém os direitos autorais derivados da seleção, coordenação, organização e aprimoramento do Conteúdo deste Site.</p>' +
        '<p>Pelo presente, concedemos a você uma licença limitada e revogável para baixar e imprimir cópias de qualquer parte do Conteúdo deste Site ao qual você tenha obtido acesso apropriado, mas apenas para seu uso comercial interno ou para seu próprio uso pessoal e não comercial, e somente se você não remover, modificar ou ocultar qualquer direito autoral, marca registrada, ou outros avisos de propriedade do Conteúdo baixado. A licença acima está sujeita a estes Termos de Uso e não inclui o direito de usar qualquer mineração de dados, robôs ou métodos semelhantes de coleta ou extração de dados. Esta licença é revogável a qualquer momento sem aviso prévio e com ou sem causa. Você não pode e nem pode permitir que outros copiem, distribuam, executem ou exibam publicamente, preparem trabalhos derivados com base em, difundam, explorem ou usem qualquer parte do Conteúdo deste Site, exceto conforme expressamente previsto nestes Termos de Uso sem nossa permissão prévia por escrito. Nada nestes Termos de Uso deve ser interpretado como transferência de qualquer direito, título ou interesse neste Site ou seu Conteúdo para você ou qualquer outra pessoa, exceto a licença limitada para usar este Site e seu Conteúdo, nos termos expressamente estabelecidos aqui.</p>' +
				'<h3>Conteúdo de terceiros</h3>' +
				'<p>Algumas das informações, artigos e outros materiais disponíveis através deste Site (incluindo, sem limitação, informações como notícias, informações meteorológicas, referências de pragas e informações de tratamento, formulários governamentais, rótulo e informações da Ficha de Dados de Segurança do Material (“FDS” ou antiga "FISPQ") fornecidos à Nutrien por terceiros, são fornecidos apenas em seu interesse e conveniência. A Nutrien não endossa materiais ou os fornecedores que os fornecem para nós, nem a Nutrien garante ou declara que esses materiais são atuais, precisos, completos ou confiáveis.</p>' +
				'<h3>Serviços de Terceiros</h3>' +
				'<p>Certos recursos e funcionalidades oferecidos em nosso Site, incluindo, sem limitação, o Serviço, são fornecidos por terceiros não relacionados à Nutrien (coletivamente, "Serviços de Terceiros"). A Nutrien não é responsável de forma alguma por tais Serviços de Terceiros. O acesso e o uso de Serviços de Terceiros podem exigir a sua concordância com os Termos Adicionais.</p>' +
				'<h3>Marcas comerciais</h3> ' +
				'<p>As marcas comerciais e marcas de serviço usadas ou exibidas neste Site ("Marcas comerciais") são marcas registradas e não registradas da Nutrien, nossas entidades relacionadas ou terceiros. Você não pode usar quaisquer Marcas Comerciais exibidas neste Site, sem a permissão prévia e expressa por escrito da Nutrien ou do proprietário da marca registrada.</p>' +
				'<h3>Links para sites de terceiros</h3>' +
				'<p>Este Site contém links para outros sites de propriedade e operados por terceiros não relacionados à Nutrien. Não somos responsáveis por examinar ou avaliar, e não garantimos os produtos ou ofertas de qualquer um desses terceiros, ou a precisão do conteúdo de seus sites. Nós não assumimos qualquer responsabilidade ou obrigação pelas ações, produtos e conteúdo de tais sites. Antes de usar qualquer site de terceiros, você deve revisar os termos de uso e políticas aplicáveis a esses sites. A inclusão de um link neste Site não implica nosso endosso de tal site de terceiros. Se você decidir acessar qualquer um desses sites de terceiros vinculados, você o faz por sua conta e risco.</p>' +
				'<h3>Links para sites relacionados à Nutrien </h3>' +
				'<p>Este Site pode conter links para outros sites de propriedade da Nutrien, nossa empresa matriz ou outras entidades relacionadas à Nutrien. Sites diferentes são usados para diferentes finalidades e, portanto, podem ter termos de uso e políticas de privacidade diferentes. Estes Termos de Uso (e a Declaração de Privacidade &amp; Cookies online mencionada acima) aplicam-se apenas a Nutrien. Antes de usar qualquer outro site, você deve revisar os termos de uso e as políticas aplicáveis para esse site.</p>' +
				'<h3>Isenção de Garantias</h3>' +
				'<p>O USO DESTE SITE E DE TODO O CONTEÚDO, PRODUTOS E SERVIÇOS DISPONIBILIZADOS ATRAVÉS DESTE SITE, É POR SUA CONTA E RISCO. ESTE SITE, E TODO O CONTEÚDO, PRODUTOS E SERVIÇOS DISPONIBILIZADOS POR MEIO DESTE SITE, É FORNECIDO "NO ESTADO EM QUE SE ENCONTRA" E "CONFORME DISPONÍVEL", SEM REPRESENTAÇÕES OU GARANTIAS DE QUALQUER TIPO. NA MÁXIMA EXTENSÃO PERMITIDA POR LEI, A NUTRIEN, SUA CONTROLADORA, AFILIADAS, SUBSIDIÁRIAS E SEUS RESPECTIVOS DIRETORES, EXECUTIVOS, FUNCIONÁRIOS, AGENTES, LICENCIADORES E PRESTADORES DE SERVIÇOS (COLETIVAMENTE, AS "PARTES DA NUTRIEN"), RENUNCIAM EXPRESSAMENTE A TODAS AS REPRESENTAÇÕES E GARANTIAS DE QUALQUER TIPO, SEJAM EXPRESSAS, IMPLÍCITAS OU ESTATUTÁRIAS, COM RELAÇÃO A ESTE SITE; OS CONTEÚDOS, PRODUTOS E SERVIÇOS DISPONIBILIZADOS ATRAVÉS DESTE SITE; SEU USO DESTE SITE; OU QUAISQUER SITES AOS QUAIS ESTE SITE ESTEJA VINCULADO. SEM LIMITAR A GENERALIDADE DO ACIMA EXPOSTO, AS PARTES DA NUTRIEN RENUNCIAM A TODAS AS DECLARAÇÕES E GARANTIAS (A) DE COMERCIALIZAÇÃO, ADEQUAÇÃO A UMA FINALIDADE ESPECÍFICA, TÍTULO E NÃO VIOLAÇÃO; B) DECORRENTES DO CURSO DE NEGOCIAÇÃO OU DE EXECUÇÃO.</p>' +
        //  'Pagamentos</br></br>' +
        //  '<div> <b style="font-weight: bold;">ALTERAÇÃO: </b>' +
        //  '<span style="color:#88D600">Inclusão da opção “Agrichem” no campo Empresa.</span><br>' +
        //  '<b style="font-weight: bold;">MENU: </b>' +
        //  '<span>Serviços Financeiros / Finanças / Cartão de Crédito Corporativo - Serviços</span><br><br>' +
        //  '<div> <b style="font-weight: bold;">ALTERAÇÃO: </b>' +
        //  '<span style="color:#88D600">Para o Tipo de Nota de Crédito e Empresa Nutrien ou Agrichem, exibe o campo Conta Contábil.</span><br>' +
        //  '<b style="font-weight: bold;">MENU: </b>' +
        //  '<span>Serviços Financeiros > Finanças > Lançamento/Pagamento - Notas/Impostos e outras cobranças</span><br><br>' +
         //'</br>'+


        '</div>',
      iconColor: '#88D600',
      confirmButtonColor: '#88D600',
      customClass: {
        container: "comunicadoMelhoria",
      }

    })

  }

  goHome(){
    this.router.navigate([''])
  }

}
