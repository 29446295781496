<app-header></app-header>
<app-nbs-banner url="../../../assets/img/backgroundimpostos.png" descricao="teste"
  titulo="Fiscal">
</app-nbs-banner>


<div class="container">


  <app-formularios-titulo url="../../../assets/img/auditoria.png" descricao="Payment" titulo="Retificação de obrigações acessórias e apurações (Uso exclusivo do fiscal)"></app-formularios-titulo>

  <div class="mensagem-topo">
    <h6 class="text-center">Preencha o formulário abaixo para enviar sua solicitação.</h6>
    <p class="text-center">Os campos marcados com (*) são de preenchimento obrigatório.</p>
  </div>

  <app-form-mat [fields]="fields" urlPipefy="https://app.pipefy.com/public/form/FZZSofCP" pipeId="303797722"></app-form-mat>


<div class="footer">
  <app-footer link="taxes"></app-footer>
</div>
