import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { UserInfosService } from 'src/app/autenticacao/usuario/user-infos.service';
import { TokenServiceService } from './../../services/token-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { PipefyService } from 'src/app/services/pipefy.service';
import { Groups } from './../../autenticacao/usuario/groups';
import Swal from 'sweetalert2';
import { CommomServicesService } from 'src/app/services/commom-services.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  isUserLoggedIn: boolean = false;
  email : string = ''
  groups: any


  constructor(private router:Router,private msalService: MsalService, 
    private userInfo: UserInfosService, private tokenService: TokenServiceService, 
    private spinner: NgxSpinnerService, private pipefyService: PipefyService, private route: ActivatedRoute,
    private commomS: CommomServicesService) { }

  ngOnInit(): void {
    // this.getInfos()
    // this.getUserProfile()
    // this.groups = this.route.data.subscribe(
    //   (groups)=>{
    //     console.log(groups)
    //     this.groups = groups.groups
    //     console.log('this.groups', this.groups)
    //   }
    // )

    this.setUserEmail()
    this.setUserName()
    this.isUserLoggedIn = this.msalService.instance.getAllAccounts().length>0
        if(this.isUserLoggedIn){

          // this.spinner.show()
          // this.tokenService.getTokenFromGgp().then((res)=>{
          //   if(res){
          //     this.spinner.hide()
          //   }
          // }).catch((error)=>{
          //   console.log(error)
          //   this.spinner.hide()
          // })
          // console.log("teste")
          this.email = this.getUserEmail()
          // this.spinner.hide()
        }
        // if(!this.isUserLoggedIn){

        //   this.spinner.show()
        //   this.tokenService.getTokenFromGgp().then((res)=>{
        //     if(res){
        //       this.spinner.hide()
        //     }
        //   }).catch((error)=>{
        //     alert("Erro na function jwt")
        //     console.log(error)
        //     this.spinner.hide()
        //   })
        // }

        // mostra popup quando a tela de menu é iniciado
        // Swal.fire({
        //   width: '521px',
        //   padding: '172px 0 5px',
        //   background: 'url(../assets/img/novas/servicos-financeiros/mensagem-financeiro.png) transparent',
        //   customClass: {
        //     popup: 'modalMensagemFinanceiro'
        //   },
        //   position: 'bottom-end',
        //   confirmButtonColor: '#88D600',
        // });;
  }

  navegar(){
    this.router.navigate([''])
  }

  faq(){
    this.router.navigate(['/forms/ajuda'])
    // this.commomS.openNewWindow('https://teams.microsoft.com/l/channel/19%3aCLyOXydI8jXEU778giMgw_CdE7NNTjG39KT2CE6Gaw01%40thread.tacv2/General?groupId=33affcdb-311a-4c29-bda9-f80dfcf5e27d&tenantId=5e0b361b-59ed-466e-8759-030448046197');
  }

  goHome(){
    this.router.navigate([''])
  }

  goToUrl(url:string){
    this.commomS.openNewWindow(url);
  }

  // getInfos():string | undefined{

  //   this.userInfo.getUserProfile().subscribe((res)=>{
  //     console.log('userInfos',res)
  //   })

  //   this.userInfo.getUserGroupsFromAzure().subscribe((res)=>{
  //     console.log('userGroups', res)
  //   })
  //   console.log(this.msalService.instance.getAllAccounts()[0])
  //   console.log('name',this.msalService.instance.getAllAccounts()[0]?.name)
  //   console.log('username',this.msalService.instance.getAllAccounts()[0]?.username)
  //   console.log('roles',this.msalService.instance.getActiveAccount()?.idTokenClaims?.roles)
  //   console.log("roles2",this.msalService.instance.getAllAccounts()[0].idTokenClaims?.roles)
  //   const accessTokenRequest = {
  //     scopes:  ["user.read"],
  //     account: this.msalService.instance.getAllAccounts()[0],


  //   };
  //   const account2 = this.msalService.instance.getAllAccounts()
  //   const account3 = this.msalService.instance.acquireTokenSilent(accessTokenRequest)
  //   const token = account3.then((res)=>{
  //     const tokenId = res.accessToken
  //     const aio:any = res.idTokenClaims
  //     this.pipefyService.getUserInfos(tokenId).subscribe((res:any)=>{
  //       console.log(res)
  //     },
  //     (error:any)=>{
  //       console.error(error)
  //     })
  //     const aio2 = aio.aio
  //     console.log('access ', tokenId)
  //     console.log('aio=>', aio2)
  //   })
  //   // console.log('account2:', account2)
  //   // console.log('account3:', account3)


  //   return this.msalService.instance.getActiveAccount()?.name
  // }

  setUserName(): any{
    this.userInfo.setName(this.msalService.instance.getAllAccounts()[0]?.name)
  }

  setUserEmail(): any {
    this.userInfo.setUserEmail(this.msalService.instance.getAllAccounts()[0]?.username)
  }

  getUserEmail(){
    return this.userInfo.getUserEmail()
  }

  getUserName(){
    return this.userInfo.getName()
  }

  // verificaPerfil(group:any){
  //   console.log('perfil',this.userInfo.getUserGroups().includes(group))
  //   var perfil = this.userInfo.getUserGroups().includes(group)
  //   console.log("group:", group, perfil)
  //   return perfil
  // }

  // getUserProfile(){
  //   this.userInfo.getUserGroupsFromAzure().subscribe((res:any)=>{
  //     this.spinner.hide()
  //     console.log(res)
  //     console.log('tipo',(typeof(res)))
  //     this.userInfo.setUserGroups(res)
  //     console.log('getUser:',this.userInfo.getUserGroups())
  //   },
  //   (error:any)=>{
  //     this.spinner.hide()
  //   })
  // }

}


