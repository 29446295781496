export const empresas = [
    {
      nome:''
    },
    {
      nome:'Agrichem'
    },
    {
      nome:'Nutrien'
    },
    {
      nome:'TecAgro (FCR)'
    },
    {
      nome:'TecAgro (SGL)'
    }
]