<app-header></app-header>
<app-nbs-banner url="../../../assets/img/backgroundimpostos.png" descricao="teste"
  titulo="Fiscal">
</app-nbs-banner>


<div class="container">


  <app-formularios-titulo url="../../../assets/img/auditoria.png" descricao="Payment" titulo="Solicitação de documentos suporte fiscalização"></app-formularios-titulo>

  <div class="mensagem-topo">
    <h6 class="text-center">Preencha o formulário abaixo para enviar sua solicitação.</h6>
    <p class="text-center">Os campos marcados com (*) são de preenchimento obrigatório.</p>
  </div>

  <form [formGroup]="formulario" (submit)="enviarFormulario()">

           <div class="form-group row">
        <label for="nome_da_empresa" class="col-sm-4 col-form-label">*Nome da Empresa </label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="nome_da_empresa">
            <option *ngFor="let empresa of empresas" [value]="empresa.nome" >{{empresa.nome}}</option>
          </select>
        </div>
        <app-mensagem class ="col-form-label mensagem-erro" *ngIf="formulario.get('nome_da_empresa')?.errors?.required && formulario.get('nome_da_empresa')?.touched"  mensagem ="Campo Obrigatório"></app-mensagem>
      </div>


      <div class="form-group row">
        <label for="requisitante" class="col-sm-4 col-form-label">*Requisitante</label>
        <div class="col-sm-8">
          <input type="text"  class="form-control" formControlName="requisitante">
          <app-mensagem class ="col-form-label mensagem-erro" *ngIf="formulario.get('requisitante')?.errors?.required && formulario.get('requisitante')?.touched"  mensagem ="Campo Obrigatório"></app-mensagem>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('requisitante')?.errors?.maxlength && formulario.get('requisitante')?.touched"
          mensagem="A quantidade máxima de caracteres permitida é 1000"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="torre_area_departamento_loja" class="col-sm-4 col-form-label">*Torre/ Área/ Departamento/ Loja</label>
        <div class="col-sm-8">
          <input type="text"  class="form-control" formControlName="torre_area_departamento_loja">
          <app-mensagem class ="col-form-label mensagem-erro" *ngIf="formulario.get('torre_area_departamento_loja')?.errors?.required && formulario.get('torre_area_departamento_loja')?.touched"  mensagem ="Campo Obrigatório"></app-mensagem>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('torre_area_departamento_loja')?.errors?.maxlength && formulario.get('torre_area_departamento_loja')?.touched"
          mensagem="A quantidade máxima de caracteres permitida é 1000"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="prazo_para_entrega" class="col-sm-4 col-form-label">*Prazo para entrega </label>
        <div class="col-sm-8">
          <input type="date"  class="form-control" formControlName="prazo_para_entrega">
        </div>
        <app-mensagem class ="col-form-label mensagem-erro" *ngIf="formulario.get('prazo_para_entrega')?.errors?.required && formulario.get('prazo_para_entrega')?.touched"  mensagem ="Campo Obrigatório"></app-mensagem>
      </div>

      <div class="form-group row">
        <label for="data_de_vencimento" class="col-sm-4 col-form-label">*Data de vencimento </label>
        <div class="col-sm-8">
          <input type="date"  class="form-control" formControlName="data_de_vencimento">
        </div>
        <app-mensagem class ="col-form-label mensagem-erro" *ngIf="formulario.get('data_de_vencimento')?.errors?.required && formulario.get('data_de_vencimento')?.touched"  mensagem ="Campo Obrigatório"></app-mensagem>
      </div>

      <div class="form-group row">
        <label for="email" class="col-sm-4 col-form-label">*E-mail para quem deve ser enviado: </label>
        <div class="col-sm-8">
          <input type="email"  class="form-control" formControlName="email">
        </div>
        <app-mensagem class ="col-form-label mensagem-erro" *ngIf="formulario.get('email')?.errors?.required && formulario.get('email')?.touched"  mensagem ="Campo Obrigatório"></app-mensagem>
        <app-mensagem class ="col-form-label mensagem-erro" *ngIf="formulario.get('email')?.errors?.email && formulario.get('email')?.touched"  mensagem ="Formato de e-mail inválido"></app-mensagem>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('email')?.errors?.maxlength && formulario.get('email')?.touched"
          mensagem="A quantidade máxima de caracteres permitida é 1000"></app-mensagem>
      </div>

      <div class="form-group row">
        <label for="documento_suporte" class="col-sm-4 col-form-label">*Documento suporte </label>
        <div class="col-sm-8">
          <input type = "file" class="form-control form-anexo" aria-label="With textarea" formControlName = "documento_suporte" id="formFileMultiple"  (change)="save($event)" multiple="multiple">
        </div>
      </div>

      <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="myFiles.length!=0">
        <div class="card-header">Anexo - Documento Suporte</div>
        <ul class="list-group list-group-flush" *ngFor="let file of myFiles">
          <li class="list-group-item d-flex justify-content-between">
            <span>{{ file }}</span>
            <span>
              <i class='fa fa-trash remove-icon removeFile' (click)="removeFile(this.file)"></i>
            </span>
          </li>
        </ul>
      </div>

      <div class="form-group row">
        <label for="observacao" class="col-sm-4 col-form-label">Observação </label>
        <div class="col-sm-8">
          <textarea class="form-control" aria-label="With textarea" formControlName = "observacao"></textarea>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('observacao')?.errors?.maxlength && formulario.get('observacao')?.touched"
          mensagem="A quantidade máxima de caracteres permitida é 1000"></app-mensagem>
        </div>
      </div>

      <div class="form-group pt-1 col-lg-3 box-botao">
        <button type="submit" class="btn botao" [disabled]="formulario.invalid">Enviar</button>
      </div>
    </form>
    <!-- <div class="box-dificuldade">
      <p class="text-center">Está com dificuldades para enviar sua solicitação? <span class="link-pipe"
          (click)="abrirPipePublico()">Envie por aqui.</span></p>
    </div> -->
  </div>


<div class="footer">
  <app-footer link="taxes"></app-footer>
</div>
