<app-header></app-header>
<app-nbs-banner url="../../../assets/img/backgroundimpostos.png" descricao="teste"
  titulo="Contabilidade">
</app-nbs-banner>


<div class="container">


  <app-formularios-titulo url="../../../assets/img/auditoria.png" descricao="Payment" titulo="Solicitação de documentos suporte auditoria"></app-formularios-titulo>

  <div class="mensagem-topo">
    <h6 class="text-center">Preencha o formulário abaixo para enviar sua solicitação.</h6>
    <p class="text-center">Os campos marcados com (*) são de preenchimento obrigatório.</p>
  </div>

  <form [formGroup]="formulario" (submit)="enviarFormulario()">

      <div class="form-group row">
        <label for="email" class="col-sm-4 col-form-label">*E-mail: </label>
        <div class="col-sm-8">
          <input type="email"  class="form-control" formControlName="email">
        </div>
        <app-mensagem class ="col-form-label mensagem-erro" *ngIf="formulario.get('email')?.errors?.required && formulario.get('email')?.touched"  mensagem ="Campo Obrigatório"></app-mensagem>
        <app-mensagem class ="col-form-label mensagem-erro" *ngIf="formulario.get('email')?.errors?.email && formulario.get('email')?.touched"  mensagem ="Formato de e-mail inválido"></app-mensagem>

      </div>

      <div class="form-group row">
        <label for="nome_da_empresa" class="col-sm-4 col-form-label">*Nome da Empresa </label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="nome_da_empresa">
            <option *ngFor="let empresa of empresas" [value]="empresa.nome" >{{empresa.nome}}</option>
          </select>
        </div>
        <app-mensagem class ="col-form-label mensagem-erro" *ngIf="formulario.get('nome_da_empresa')?.errors?.required && formulario.get('nome_da_empresa')?.touched"  mensagem ="Campo Obrigatório"></app-mensagem>

      </div>

      <div class="form-group row">
        <label for="codigo_sap" class="col-sm-4 col-form-label">Cód Sap </label>
        <div class="col-sm-8">
          <input type="codigo_sap"  class="form-control" formControlName="codigo_sap">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('codigo_sap')?.errors?.maxlength && formulario.get('codigo_sap')?.touched"
          mensagem="A quantidade máxima de caracteres permitida é 1000"></app-mensagem>
        </div>
      </div>

      <div class="form-group row">
        <label for="torre" class="col-sm-4 col-form-label">Torre</label>
        <div class="col-sm-8">
          <select class="custom-select" formControlName="torre" >
            <option  *ngFor="let torre of torres" [value]="torre.tipo">{{torre.tipo}}</option>
          </select>
        </div>
      </div>

      <div class="form-group row">
        <label for="periodo_da_documentacao" class="col-sm-4 col-form-label">*Período da Documentação </label>
        <div class="col-sm-8">
          <input type="periodo_da_documentacao"  class="form-control" formControlName="periodo_da_documentacao">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('periodo_da_documentacao')?.errors?.maxlength && formulario.get('periodo_da_documentacao')?.touched"
          mensagem="A quantidade máxima de caracteres permitida é 1000"></app-mensagem>
        </div>
        <app-mensagem class ="col-form-label mensagem-erro" *ngIf="formulario.get('periodo_da_documentacao')?.errors?.required && formulario.get('periodo_da_documentacao')?.touched"  mensagem ="Campo Obrigatório"></app-mensagem>

      </div>

      <div class="form-group row">
        <label for="documentacao_solicitada" class="col-sm-4 col-form-label">*Qual documentação solicitada? </label>
        <div class="col-sm-8">
          <textarea class="form-control" aria-label="With textarea" formControlName = "documentacao_solicitada"></textarea>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('documentacao_solicitada')?.errors?.maxlength && formulario.get('documentacao_solicitada')?.touched"
          mensagem="A quantidade máxima de caracteres permitida é 1000"></app-mensagem>
        </div>
        <app-mensagem class ="col-form-label mensagem-erro" *ngIf="formulario.get('documentacao_solicitada')?.errors?.required && formulario.get('documentacao_solicitada')?.touched"  mensagem ="Campo Obrigatório"></app-mensagem>

      </div>

      <div class="form-group row">
        <label for="anexo" class="col-sm-4 col-form-label">Anexar Solicitação de Auditoria </label>
        <div class="col-sm-8">
          <input type = "file" class="form-control form-anexo" aria-label="With textarea" formControlName = "anexo" id="formFileMultiple"  (change)="save($event)" multiple="multiple" >
        </div>
      </div>

      <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="myFiles.length!=0">
        <div class="card-header">Anexo - Solicitação de Auditoria</div>
        <ul class="list-group list-group-flush" *ngFor="let file of myFiles">
          <li class="list-group-item d-flex justify-content-between">
            <span>{{ file }}</span>
            <span>
              <i class='fa fa-trash remove-icon removeFile' (click)="removeFile(this.file)"></i>
            </span>

          </li>
        </ul>
      </div>

      <div class="form-group row">
        <label for="observacao" class="col-sm-4 col-form-label">Observação </label>
        <div class="col-sm-8">
          <textarea class="form-control" aria-label="With textarea" formControlName = "observacao"></textarea>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('observacao')?.errors?.maxlength && formulario.get('observacao')?.touched"
          mensagem="A quantidade máxima de caracteres permitida é 1000"></app-mensagem>
        </div>
      </div>

      <div class="form-group pt-1 col-lg-3 box-botao">
        <button type="submit" class="btn botao" [disabled]="formulario.invalid">Enviar</button>
      </div>
    </form>
    <!-- <div class="box-dificuldade">
      <p class="text-center">Está com dificuldades para enviar sua solicitação? <span class="link-pipe"
          (click)="abrirPipePublico()">Envie por aqui.</span></p>
    </div> -->
  </div>


<div class="footer">
  <app-footer link="taxes"></app-footer>
</div>
