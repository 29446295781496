export const pipe_fields_id =[
  'empresa',
  'filial_tec_agro',
  'filial_nutrien',
  'filial_agrichem',
  'filial_agrosema',
  'impostos_taxas_e_despesas',
  'n_mero_do_pedido',
  'descri_o_da_solicita_o',
  'valor_da_guia_ou_nfe',
  'data_de_vencimento',
  'e_mail_para_quem_deve_ser_enviado_a_guia',
  'requisitante',
  'email_do_requisitante',
  'anexar_documento',
  'n_mero_de_documentos',
  'observa_o',
]
