import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormField } from 'src/app/componentes/formMat/form.interface';


@Component({
  selector: 'app-suporte-fiscal',
  templateUrl: './suporte-fiscal.component.html',
  styleUrls: ['./suporte-fiscal.component.css']
})
export class SuporteFiscalComponent implements OnInit {

  fields: FormField[] = [];


  constructor() { }

  ngOnInit(): void {

    this.fields = [
      {
        "label": "Nome do solicitante",
        "name": "nome_do_solicitante",
        "type": "nome-solicitante",
        "validators": Validators.required
      },
      {
        "label": "E-mail do solicitante",
        "name": "e_mail_do_solicitante",
        "type": "email-solicitante",
        "validators": Validators.required
      },
      {
        "label": "E-mail Nutrien - 2",
        "name": "e_mail_nutrien_2",
        "type": "email"
      },
      {
        "label": "E-mail Nutrien - 3",
        "name": "e_mail_nutrien_3",
        "type": "email"
      },
      {
        "label": "Empresa",
        "name": "empresa",
        "validators": Validators.required,
        "select": [
          {
            "type": "option",
            "name": "Agrichem",
            "value": "Agrichem",
            "fields": [
              {
                "label": "Filiais - Agrichem",
                "name": "filiais_agrichem",
                "validators": Validators.required,
                "select": [
                  {
                    "type": "option",
                    "name": "Agrichem",
                    "value": "Agrichem"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Agrichem -Ribeirão Preto-0004-35-Sp",
                    "value": "Filial-Agrichem -Ribeirão Preto-0004-35-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Matriz-Agrichem -Ribeirão Preto-0001-92-Sp",
                    "value": "Matriz-Agrichem -Ribeirão Preto-0001-92-Sp"
                  }
                ],
                "type": "select"
              }
            ]
          },
          {
            "type": "option",
            "name": "Nutrien",
            "value": "Nutrien",
            "fields": [
              {
                "label": "Filiais - Nutrien",
                "name": "filiais_nutrien",
                "select": [
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Alfenas-0043-09-Mg",
                    "value": "Filial-Nutrien-Alfenas-0043-09-Mg"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Angatuba-0014-74-Sp",
                    "value": "Filial-Nutrien-Angatuba-0014-74-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Aracatuba-0029-50-Sp",
                    "value": "Filial-Nutrien-Aracatuba-0029-50-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Araxa-0015-55-Mg",
                    "value": "Filial-Nutrien-Araxa-0015-55-Mg"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Araxa-0045-70-Mg",
                    "value": "Filial-Nutrien-Araxa-0045-70-Mg"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Assis-0026-08-Sp",
                    "value": "Filial-Nutrien-Assis-0026-08-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Avare-0018-06-Sp",
                    "value": "Filial-Nutrien-Avare-0018-06-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Avare-0057-04-Sp",
                    "value": "Filial-Nutrien-Avare-0057-04-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Campinas-0060-00-Sp",
                    "value": "Filial-Nutrien-Campinas-0060-00-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Candido Mota-0046-51-Sp",
                    "value": "Filial-Nutrien-Candido Mota-0046-51-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Capão Bonito-0041-47-Sp",
                    "value": "Filial-Nutrien-Capão Bonito-0041-47-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Coromandel-0033-37-Mg",
                    "value": "Filial-Nutrien-Coromandel-0033-37-Mg"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Cristalina-0020-12-Go",
                    "value": "Filial-Nutrien-Cristalina-0020-12-Go"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Elias Fausto-0039-22-Sp",
                    "value": "Filial-Nutrien-Elias Fausto-0039-22-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Elias Fausto-0042-28-Sp",
                    "value": "Filial-Nutrien-Elias Fausto-0042-28-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Goiânia-0061-90-Go",
                    "value": "Filial-Nutrien-Goiânia-0061-90-Go"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Ibiá-0025-27-Mg",
                    "value": "Filial-Nutrien-Ibiá-0025-27-Mg"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Ibiá-0034-18-Mg",
                    "value": "Filial-Nutrien-Ibiá-0034-18-Mg"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Indaiatuba-0056-23-Sp",
                    "value": "Filial-Nutrien-Indaiatuba-0056-23-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Itapetininga Col Novo-0022-84-Sp",
                    "value": "Filial-Nutrien-Itapetininga Col Novo-0022-84-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Itapetininga-0004-00-Sp",
                    "value": "Filial-Nutrien-Itapetininga-0004-00-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Itapetininga-0040-66-Sp",
                    "value": "Filial-Nutrien-Itapetininga-0040-66-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Itapeva-0005-83-Sp",
                    "value": "Filial-Nutrien-Itapeva-0005-83-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Itapeva-0048-13-Sp",
                    "value": "Filial-Nutrien-Itapeva-0048-13-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Itapolis-0049-02-Sp",
                    "value": "Filial-Nutrien-Itapolis-0049-02-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Ituiutaba-0035-07-Mg",
                    "value": "Filial-Nutrien-Ituiutaba-0035-07-Mg"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Limeira-0019-89-Sp",
                    "value": "Filial-Nutrien-Limeira-0019-89-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Limeira-0047-32-Sp",
                    "value": "Filial-Nutrien-Limeira-0047-32-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Mogi Mirim-0028-70-Sp",
                    "value": "Filial-Nutrien-Mogi Mirim-0028-70-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Mogi-Guaçu-0051-19-Sp",
                    "value": "Filial-Nutrien-Mogi-Guaçu-0051-19-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Morrinhos-0021-01-Go",
                    "value": "Filial-Nutrien-Morrinhos-0021-01-Go"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Paracatu-0027-99-Sp",
                    "value": "Filial-Nutrien-Paracatu-0027-99-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Patos De Minas-0036-80-Mg",
                    "value": "Filial-Nutrien-Patos De Minas-0036-80-Mg"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Patrocinio-0044-90-Mg",
                    "value": "Filial-Nutrien-Patrocinio-0044-90-Mg"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Paulinia-0024-46-Sp",
                    "value": "Filial-Nutrien-Paulinia-0024-46-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Paulinia-0038-41-Sp",
                    "value": "Filial-Nutrien-Paulinia-0038-41-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Ribeirão Preto-0055-42-Sp",
                    "value": "Filial-Nutrien-Ribeirão Preto-0055-42-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Rio Verde-0058-95-Go",
                    "value": "Filial-Nutrien-Rio Verde-0058-95-Go"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-São Gotardo-0031-75-Mg",
                    "value": "Filial-Nutrien-São Gotardo-0031-75-Mg"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-São Miguel Arcanjo-0052-08-Sp",
                    "value": "Filial-Nutrien-São Miguel Arcanjo-0052-08-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Taquarituba-0050-38-Sp",
                    "value": "Filial-Nutrien-Taquarituba-0050-38-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Uberaba-0032-56-Mg",
                    "value": "Filial-Nutrien-Uberaba-0032-56-Mg"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Uberaba-0053-80-Mg",
                    "value": "Filial-Nutrien-Uberaba-0053-80-Mg"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Uberaba-0054-61-Mg",
                    "value": "Filial-Nutrien-Uberaba-0054-61-Mg"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Uberlândia-0037-60-Mg",
                    "value": "Filial-Nutrien-Uberlândia-0037-60-Mg"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Unaí-0030-94-Mg",
                    "value": "Filial-Nutrien-Unaí-0030-94-Mg"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Vargem Grande Do Sul-0017-17-Sp",
                    "value": "Filial-Nutrien-Vargem Grande Do Sul-0017-17-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Nutrien-Votuporanga-0059-76-Sp",
                    "value": "Filial-Nutrien-Votuporanga-0059-76-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Matriz-Nutrien-Canadá-0001-26-Ex",
                    "value": "Matriz-Nutrien-Canadá-0001-26-Ex"
                  },
                  {
                    "type": "option",
                    "name": "Matriz-Nutrien-São Paulo-0001-25-Sp",
                    "value": "Matriz-Nutrien-São Paulo-0001-25-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Matriz-Nutrien-São Paulo-0001-50-Sp",
                    "value": "Matriz-Nutrien-São Paulo-0001-50-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Nutrien Participações Ltda",
                    "value": "Nutrien Participações Ltda"
                  },
                  {
                    "type": "option",
                    "name": "Nutrien Soluções Agrícolas Ltda",
                    "value": "Nutrien Soluções Agrícolas Ltda"
                  }
                ],
                "type": "select",
                "validators": Validators.required
              },
            ]
          },
          {
            "type": "option",
            "name": "Tec Agro",
            "value": "Tec Agro",
            "fields": [
              {
                "label": "Filialis - Tec Agro",
                "name": "filialis_tec_agro",
                "select": [
                  {
                    "type": "option",
                    "name": "Apms Participações Societárias Ltda",
                    "value": "Apms Participações Societárias Ltda"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Sementes Goiás - Tocantins-0004-90-To",
                    "value": "Filial-Sementes Goiás - Tocantins-0004-90-To"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Sementes Goiás - Unidade 02-0003-00-Go",
                    "value": "Filial-Sementes Goiás - Unidade 02-0003-00-Go"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Sementes Goiás -0002-29-Go",
                    "value": "Filial-Sementes Goiás -0002-29-Go"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Sementes Goiás-0005-71-Go",
                    "value": "Filial-Sementes Goiás-0005-71-Go"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Tec agro - Aparecida De Goiânia (Centro De Distribuição) -0022-98-Go",
                    "value": "Filial-Tec agro - Aparecida De Goiânia (Centro De Distribuição) -0022-98-Go"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Tec agro - Araxá-0010-54-Mg",
                    "value": "Filial-Tec agro - Araxá-0010-54-Mg"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Tec agro - Catalão-0006-78-Go",
                    "value": "Filial-Tec agro - Catalão-0006-78-Go"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Tec agro - Catalão-0034-21-Go",
                    "value": "Filial-Tec agro - Catalão-0034-21-Go"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Tec agro - Chapadão Do Ceu-0023-79-Go",
                    "value": "Filial-Tec agro - Chapadão Do Ceu-0023-79-Go"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Tec agro - Cristalina-0030-06-Go",
                    "value": "Filial-Tec agro - Cristalina-0030-06-Go"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Tec agro - Formosa-0012-16-Go",
                    "value": "Filial-Tec agro - Formosa-0012-16-Go"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Tec agro - Goiatuba-0029-64-Go",
                    "value": "Filial-Tec agro - Goiatuba-0029-64-Go"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Tec agro - Goiânia-0008-30-Go",
                    "value": "Filial-Tec agro - Goiânia-0008-30-Go"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Tec agro - Goiânia-0031-89-Go",
                    "value": "Filial-Tec agro - Goiânia-0031-89-Go"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Tec agro - Goiatuba-0007-59-Go",
                    "value": "Filial-Tec agro - Goiânia-0031-89-Go"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Tec agro - Guaraí-0020-26-To",
                    "value": "Filial-Tec agro - Guaraí-0020-26-To"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Tec agro - Gurupí-0014-88-To",
                    "value": "Filial-Tec agro - Gurupí-0014-88-To"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Tec agro - Itapetininga-0009-10-Sp",
                    "value": "Filial-Tec agro - Itapetininga-0009-10-Sp"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Tec agro - Jataí-0005-97-Go",
                    "value": "Filial-Tec agro - Jataí-0005-97-Go"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Tec agro - Jataí-0024-50-Go",
                    "value": "Filial-Tec agro - Jataí-0024-50-Go"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Tec agro - Jussara-0021-07-Go",
                    "value": "Filial-Tec agro - Jussara-0021-07-Go"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Tec agro - Lagoa Da Confusão-0032-60-To",
                    "value": "Filial-Tec agro - Lagoa Da Confusão-0032-60-To"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Tec agro - Mineiros-0015-69-Go",
                    "value": "Filial-Tec agro - Mineiros-0015-69-Go"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Tec agro - Montividiu-0002-44-Go",
                    "value": "Filial-Tec agro - Montividiu-0002-44-Go"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Tec agro - Morrinhos-0026-11-Go",
                    "value": "Filial-Tec agro - Morrinhos-0026-11-Go"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Tec agro - Palmas (Centro De Distribuição) -0019-92-To",
                    "value": "Filial-Tec agro - Palmas (Centro De Distribuição) -0019-92-To"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Tec agro - Palmas (Operador Logístico Verde log) -0018-01-To",
                    "value": "Filial-Tec agro - Palmas (Operador Logístico Verde log) -0018-01-To"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Tec agro - Paraíso Do Tocantins-0033-40-To",
                    "value": "Filial-Tec agro - Paraíso Do Tocantins-0033-40-To"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Tec agro - Paraúna-0003-25-Go",
                    "value": "Filial-Tec agro - Paraúna-0003-25-Go"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Tec agro - Porto Nacional-0013-05-To",
                    "value": "Filial-Tec agro - Porto Nacional-0013-05-To"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Tec agro - Rio Verde-0025-30-Go",
                    "value": "Filial-Tec agro - Rio Verde-0025-30-Go"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Tec agro - Rio Verde-0027-00-Go",
                    "value": "Filial-Tec agro - Rio Verde-0027-00-Go"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Tec agro - Rio Verde-0028-83-Go",
                    "value": "Filial-Tec agro - Rio Verde-0028-83-Go"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Tec agro - Shego-0004-06-Go",
                    "value": "Filial-Tec agro - Shego-0004-06-Go"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Tec agro - Silviania-0017-20-Go",
                    "value": "Filial-Tec agro - Silviania-0017-20-Go"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Tec agro - Uruaçú-0011-35-Go",
                    "value": "Filial-Tec agro - Uruaçú-0011-35-Go"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Tec agro - Vianópolis-0016-40-Go",
                    "value": "Filial-Tec agro - Vianópolis-0016-40-Go"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Tec agro Armazéns Gerais - Paraúna-0002-29-Go",
                    "value": "Filial-Tec agro Armazéns Gerais - Paraúna-0002-29-Go"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Tec Agro Armazéns Gerais - Shego-0002-10-Go",
                    "value": "Filial-Tec Agro Armazéns Gerais - Shego-0002-10-Go"
                  },
                  {
                    "type": "option",
                    "name": "Filial-Tec agro Armazéns Gerais - Shego-0005-71-Go",
                    "value": "Filial-Tec agro Armazéns Gerais - Shego-0005-71-Go"
                  },
                  {
                    "type": "option",
                    "name": "Fronteira Armazéns Gerais Ltda",
                    "value": "Fronteira Armazéns Gerais Ltda"
                  },
                  {
                    "type": "option",
                    "name": "Fronteira Comércio Ltda",
                    "value": "Fronteira Comércio Ltda"
                  },
                  {
                    "type": "option",
                    "name": "Matriz -Fronteira Armazéns Gerais-0001-30-Go",
                    "value": "Matriz -Fronteira Armazéns Gerais-0001-30-Go"
                  },
                  {
                    "type": "option",
                    "name": "Matriz -Holding Apms-0001-27-Go",
                    "value": "Matriz -Holding Apms-0001-27-Go"
                  },
                  {
                    "type": "option",
                    "name": "Matriz -Sementes Goiás-0001-48-Go",
                    "value": "Matriz -Sementes Goiás-0001-48-Go"
                  },
                  {
                    "type": "option",
                    "name": "Matriz -Tec agro - Rio Verde-0001-63-Go",
                    "value": "Matriz -Tec agro - Rio Verde-0001-63-Go"
                  },
                  {
                    "type": "option",
                    "name": "Sementes Goiás Ltda",
                    "value": "Sementes Goiás Ltda"
                  }
                ],
                "type": "select",
                "validators": Validators.required
              }
            ]
          }
        ],
        "type": "select",

      },
      {
        "label": "Opções",
        "name": "op_es",
        "validators": Validators.required,
        "select": [
          {
            "type": "option",
            "name": "Ajuste GRC - CTE Agrichem (Exclusivo fiscal)",
            "value": "Ajuste GRC - CTE Agrichem (Exclusivo fiscal)"
          },
          {
            "type": "option",
            "name": "CFOP",
            "value": "CFOP"
          },
          {
            "type": "option",
            "name": "Desbloqueio de período",
            "value": "Desbloqueio de período"
          },
          {
            "type": "option",
            "name": "Dúvida de abertura de chamado no portal NBS fiscal",
            "value": "Dúvida de abertura de chamado no portal NBS fiscal"
          },
          {
            "type": "option",
            "name": "Dúvida sobre o que solicitar na emissão de carta de correção",
            "value": "Dúvida sobre o que solicitar na emissão de carta de correção"
          },
          {
            "type": "option",
            "name": "Dúvidas sobre as retenções",
            "value": "Dúvidas sobre as retenções"
          },
          {
            "type": "option",
            "name": "Dúvidas sobre entrada de nota fiscal",
            "value": "Dúvidas sobre entrada de nota fiscal"
          },
          {
            "type": "option",
            "name": "Dúvidas sobre tipo de operações para emissão de notas fiscais de saída",
            "value": "Dúvidas sobre tipo de operações para emissão de notas fiscais de saída"
          },
          {
            "type": "option",
            "name": "Dúvidas sobre tipo de operação (Específico para Agrosema campo TO)",
            "value": "Dúvidas sobre tipo de operação (Específico para Agrosema campo TO)"
          },
          {
            "type": "option",
            "name": "Estorno de lançamento de nota fiscal (Entrada e Saida)",
            "value": "Estorno de lançamento de nota fiscal (Entrada e Saida)"
          },
          {
            "type": "option",
            "name": "Orientação relativa a emissão de notas fiscais de operações especificas Exemplos (Sucata, venda de ativo imobilizado, nota fiscal de remessa)",
            "value": "Orientação relativa a emissão de notas fiscais de operações especificas Exemplos (Sucata, venda de ativo imobilizado, nota fiscal de remessa)"
          },
          {
            "type": "option",
            "name": "Sequencial de nota fiscal",
            "value": "Sequencial de nota fiscal"
          },
          {
            "type": "option",
            "name": "Solicitação de relatórios fiscais exclusivo contabilidade NBS",
            "value": "Solicitação de relatórios fiscais exclusivo contabilidade NBS"
          },
          {
            "type": "option",
            "name": "Suporte para lançamentos de nota fiscais de entrada",
            "value": "Suporte para lançamentos de nota fiscais de entrada"
          }
        ],
        "type": "select"
      },
      {
        "label": "Descrição da Solicitação",
        "name": "descri_o_da_solicita_o",
        "type": "textArea",
        "validators": [Validators.required, Validators.maxLength(1000)]
      },
      {
        "type": "attachment",
        "label": "Documentos Suporte Anexo",
        "name": "documentos_suporte_anexo",
      },
      {
        "type": "buttonSubmit",
        "name": "Enviar"
      }
    ]
  }
}
