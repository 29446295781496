<app-header></app-header>
<app-nbs-banner url="../../../assets/img/backgroundcontabilidade2.png" descricao="teste" titulo="Contabilidade">
</app-nbs-banner>


<div class="container">


  <app-formularios-titulo url="../../../assets/img/auditoria.png" descricao="Payment" titulo="Imobilizado">
  </app-formularios-titulo>

  <div class="mensagem-topo">
    <h6 class="text-center">Preencha o formulário abaixo para enviar sua solicitação.</h6>
    <p class="text-center" style="margin-bottom: 0;">Os campos marcados com (*) são de preenchimento obrigatório.</p>

    <p class="text-center">Caso não tenha o template de solicitação de movimentação ativo imobilizado,
      <a href="./assets/baixa-imobilizado-v1.xlsx" target="_blank"> clique aqui</a>.</p>
  </div>

  <form [formGroup]="formulario" (submit)="enviarFormulario()">

    <div class="form-group row">
      <label for="nome" class="col-sm-4 col-form-label">*Digite seu nome: </label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="nome">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('nome')?.errors?.required && formulario.get('nome')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('nome')?.errors?.maxlength && formulario.get('nome')?.touched"
          mensagem="A quantidade máxima de caracteres permitida é 1000"></app-mensagem>

      </div>
    </div>

    <div class="form-group row">
      <label for="email" class="col-sm-4 col-form-label">*Digite seu e-mail: </label>
      <div class="col-sm-8">
        <input type="email" class="form-control" formControlName="email">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('email')?.errors?.required && formulario.get('email')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('email')?.errors?.email && formulario.get('email')?.touched"
          mensagem="Formato de e-mail inválido"></app-mensagem>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('email')?.errors?.maxlength && formulario.get('email')?.touched"
          mensagem="A quantidade máxima de caracteres permitida é 1000"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="nome_da_empresa" class="col-sm-4 col-form-label">*Nome da Empresa </label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="nome_da_empresa">
          <option *ngFor="let empresa of empresas" [value]="empresa.nome">{{empresa.nome}}</option>
        </select>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('nome_da_empresa')?.errors?.required && formulario.get('nome_da_empresa')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('nome_da_empresa')?.errors?.maxlength && formulario.get('nome_da_empresa')?.touched"
          mensagem="A quantidade máxima de caracteres permitida é 1000"></app-mensagem>
      </div>
    </div>

    <!-- <div class="form-group row">
      <label for="tratativa" class="col-sm-4 col-form-label">Tipo de Tratativa </label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="tratativa">
          <option *ngFor="let tratativa of tratativas" [value]="tratativa.nome">{{tratativa.nome}}</option>
        </select>
      </div>
    </div> -->


    <div class="form-group row">
      <label for="codigo_sap" class="col-sm-4 col-form-label">*Código Sap </label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="codigo_sap">
          <option *ngFor="let codigo of codigosSap" [value]="codigo.nome">{{codigo.nome}}</option>
        </select>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('codigo_sap')?.errors?.required && formulario.get('codigo_sap')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('codigo_sap')?.errors?.maxlength && formulario.get('codigo_sap')?.touched"
          mensagem="A quantidade máxima de caracteres permitida é 1000"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="data_da_baixa" class="col-sm-4 col-form-label">*Data da baixa</label>
      <div class="col-sm-8">
        <input type="date" class="form-control" formControlName="data_da_baixa">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('data_da_baixa')?.errors?.required && formulario.get('data_da_baixa')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="numero_do_bem" class="col-sm-4 col-form-label">Número do bem</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="numero_do_bem">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('numero_do_bem')?.errors?.maxlength && formulario.get('numero_do_bem')?.touched"
          mensagem="A quantidade máxima de caracteres permitida é 1000"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="codigo_de_ativo_no_sistema" class="col-sm-4 col-form-label">*Código de Ativo no Sistema</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="codigo_de_ativo_no_sistema">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('codigo_de_ativo_no_sistema')?.errors?.required && formulario.get('codigo_de_ativo_no_sistema')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('codigo_de_ativo_no_sistema')?.errors?.maxlength && formulario.get('codigo_de_ativo_no_sistema')?.touched"
          mensagem="A quantidade máxima de caracteres permitida é 1000"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="motivo_da_baixa" class="col-sm-4 col-form-label">*Motivo da baixa </label>
      <div class="col-sm-8">
        <textarea class="form-control" aria-label="With textarea" formControlName="motivo_da_baixa"></textarea>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('motivo_da_baixa')?.errors?.required && formulario.get('motivo_da_baixa')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('motivo_da_baixa')?.errors?.maxlength && formulario.get('motivo_da_baixa')?.touched"
          mensagem="A quantidade máxima de caracteres permitida é 1000"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="formulario_assinado" class="col-sm-4 col-form-label">*Formulário Assinado </label>
      <div class="col-sm-8">
        <input type="file" class="form-control form-anexo" aria-label="With textarea"
          formControlName="formulario_assinado" id="formFileMultiple" (change)="save($event)" multiple="multiple">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('formulario_assinado')?.errors?.required && formulario.get('formulario_assinado')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('formulario_assinado')?.errors?.maxlength && formulario.get('formulario_assinado')?.touched"
          mensagem="A quantidade máxima de caracteres permitida é 1000"></app-mensagem>
      </div>

    </div>

    <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="myFiles.length!=0">
      <div class="card-header">Anexo - Formulário Assinado</div>
      <ul class="list-group list-group-flush" *ngFor="let file of myFiles">
        <li class="list-group-item d-flex justify-content-between">
          <span>{{ file.name }}</span>
          <span>
            <i class='fa fa-trash remove-icon removeFile' (click)="removeFile(this.file)"></i>
          </span>
        </li>
      </ul>
    </div>

    <div class="form-group row">
      <label for="observacao" class="col-sm-4 col-form-label">Observação </label>
      <div class="col-sm-8">
        <textarea class="form-control" aria-label="With textarea" formControlName="observacao"></textarea>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('observacao')?.errors?.maxlength && formulario.get('observacao')?.touched"
          mensagem="A quantidade máxima de caracteres permitida é 1000"></app-mensagem>
      </div>
    </div>

    <div class="form-group pt-1 col-lg-3 box-botao">
      <button type="submit" class="btn botao" [disabled]="formulario.invalid">Enviar</button>
    </div>
    <!-- <div class="box-dificuldade">
        <p class="text-center">Está com dificuldades para enviar sua solicitação? <span class="link-pipe"
            (click)="abrirPipePublico()">Envie por aqui.</span></p>
      </div> -->

  </form>
</div>


<div class="footer">
  <app-footer link="accouting"></app-footer>
</div>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Aguarde. Sua solicitação está sendo processada.</p>
</ngx-spinner>
