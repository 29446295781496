<app-header></app-header>
<app-nbs-banner url="../../../assets/img/backgroundimpostos.png" descricao="teste" titulo="Fiscal">
</app-nbs-banner>


<div class="container">


  <app-formularios-titulo url="../../../assets/img/auditoria.png" descricao="Payment"
    titulo="Solicitação de pagamentos impostos apurados sobre remessas para exterior"></app-formularios-titulo>

    <div class="mensagem-topo">
      <h6 class="text-center">Preencha o formulário abaixo para enviar sua solicitação.</h6>
      <p class="text-center">Os campos marcados com (*) são de preenchimento obrigatório.</p>
    </div>

  <form [formGroup]="formulario" (submit)="enviarFormulario()">

    <div class="form-group row">
      <label for="nome_responsavel_nutrien" class="col-sm-4 col-form-label">*Nome Responsável Nutrien</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="nome_responsavel_nutrien">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('nome_responsavel_nutrien')?.errors?.required && formulario.get('nome_responsavel_nutrien')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('nome_responsavel_nutrien')?.errors?.maxlength && formulario.get('nome_responsavel_nutrien')?.touched"
          mensagem="A quantidade máxima de caracteres permitida é 1000"></app-mensagem>
      </div>

    </div>

    <div class="form-group row">
      <label for="email_do_responsavel_nutrien" class="col-sm-4 col-form-label">*E-mail do responsável Nutrien</label>
      <div class="col-sm-8">
        <input type="email" class="form-control" formControlName="email_do_responsavel_nutrien">
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('email_do_responsavel_nutrien')?.errors?.required && formulario.get('email_do_responsavel_nutrien')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('email_do_responsavel_nutrien')?.errors?.email && formulario.get('email_do_responsavel_nutrien')?.touched"
        mensagem="Formato de E-mail Inválido"></app-mensagem>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('email_do_responsavel_nutrien')?.errors?.maxlength && formulario.get('email_do_responsavel_nutrien')?.touched"
          mensagem="A quantidade máxima de caracteres permitida é 1000"></app-mensagem>
      </div>

    </div>

    <div class="form-group row">
      <label for="empresa" class="col-sm-4 col-form-label">*Empresa </label>
      <div class="col-sm-8">
        <select class="custom-select" formControlName="empresa">
          <option *ngFor="let empresa of empresas" [value]="empresa.nome">{{empresa.nome}}</option>
        </select>
        <app-mensagem class="col-form-label mensagem-erro"
        *ngIf="formulario.get('empresa')?.errors?.required && formulario.get('empresa')?.touched"
        mensagem="Campo Obrigatório"></app-mensagem>
      </div>

    </div>

    <div class="form-group row">
      <label for="data_de_vencimento_de_imposto" class="col-sm-4 col-form-label">*Data de Vencimento de Imposto</label>
      <div class="col-sm-8">
        <input type="date" class="form-control" formControlName="data_de_vencimento_de_imposto">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('data_de_vencimento_de_imposto')?.errors?.required && formulario.get('data_de_vencimento_de_imposto')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="data_para_pagamento_da_invoice" class="col-sm-4 col-form-label">*Data para pagamento da invoice</label>
      <div class="col-sm-8">
        <input type="date" class="form-control" formControlName="data_para_pagamento_da_invoice">
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('data_para_pagamento_da_invoice')?.errors?.required && formulario.get('data_para_pagamento_da_invoice')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>
    </div>

    <div class="form-group row">
      <label for="taxa_cambio" class="col-sm-4 col-form-label">*Taxa de Câmbio</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" formControlName="taxa_cambio"
          (keyup)="formataValorDecimal($event.target.value)" [value]="taxaCambioAlterado">
        <small>Informar apenas números. Ex: 1111</small>
        <app-mensagem class ="col-form-label mensagem-erro" *ngIf="formulario.get('taxa_cambio')?.errors?.required && formulario.get('taxa_cambio')?.touched"  mensagem ="Campo Obrigatório"></app-mensagem>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('taxa_cambio')?.errors?.maxlength && formulario.get('taxa_cambio')?.touched"
          mensagem="A quantidade máxima de caracteres permitida é 1000"></app-mensagem>
      </div>
    </div>


    <div class="form-group row">
      <label for="anexar_invoice" class="col-sm-4 col-form-label">*Anexar Invoice</label>
      <div class="col-sm-8">
        <input type="file" class="form-control form-anexo" aria-label="With textarea"
          formControlName="anexar_invoice" id="formFileMultiple" (change)="saveInvoices($event)" multiple="multiple">
          <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('anexar_invoice')?.errors?.required && formulario.get('anexar_invoice')?.touched"
          mensagem="Campo Obrigatório"></app-mensagem>
      </div>

    </div>

    <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="invoices.length!=0">
      <div class="card-header">Anexo - Invoice</div>
      <ul class="list-group list-group-flush" *ngFor="let file of invoices">
        <li class="list-group-item d-flex justify-content-between">
          <span>{{ file.name }}</span>
          <span>
            <i class='fa fa-trash remove-icon removeFile' (click)="removeFileInvoice(this.file)"></i>
          </span>
        </li>
      </ul>
    </div>



    <div class="form-group row">
      <label for="anexar_informacoes_de_contrato_ou_gross_up" class="col-sm-4 col-form-label">Anexar informações de contrato ou gross up</label>
      <div class="col-sm-8">
        <input type="file" class="form-control form-anexo" aria-label="With textarea"
          formControlName="anexar_informacoes_de_contrato_ou_gross_up" id="formFileMultiple" (change)="saveContratos($event)" multiple="multiple">
      </div>
    </div>

    <div class="shadow p-3 mb-5 bg-white rounded" *ngIf="contratos.length!=0">
      <div class="card-header">Anexo - Contrato</div>
      <ul class="list-group list-group-flush" *ngFor="let file of contratos">
        <li class="list-group-item d-flex justify-content-between">
          <span>{{ file.name }}</span>
          <span>
            <i class='fa fa-trash remove-icon removeFile' (click)="removeFileContratos(this.file)"></i>
          </span>
        </li>
      </ul>
    </div>

    <div class="form-group row">
      <label for="observacao" class="col-sm-4 col-form-label">Observação </label>
      <div class="col-sm-8">
        <textarea class="form-control" aria-label="With textarea" formControlName="observacao"></textarea>
        <app-mensagem class="col-form-label mensagem-erro"
          *ngIf="formulario.get('observacao')?.errors?.maxlength && formulario.get('observacao')?.touched"
          mensagem="A quantidade máxima de caracteres permitida é 1000"></app-mensagem>
      </div>
    </div>

    <div class="form-group pt-1 col-lg-3 box-botao">
      <button type="submit" class="btn botao" [disabled]="formulario.invalid">Enviar</button>
    </div>

  </form>
  <!-- <div class="box-dificuldade">
    <p class="text-center">Está com dificuldades para enviar sua solicitação? <span class="link-pipe"
        (click)="abrirPipePublico()">Envie por aqui.</span></p>
  </div> -->
</div>


<div class="footer">
  <app-footer link="taxes"></app-footer>
</div>
<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Aguarde. Sua solicitação está sendo processada.</p>
</ngx-spinner>
