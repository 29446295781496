<footer>
  <div class="wrapper">
  <!-- <div class="row">
    <div class="col-lg-12 box-duvidas" (click)="faq()" >
      <i class="fa fa-question-circle"></i>
      <span>Dúvidas</span>
    </div>
  </div> -->
  <div class="row linha2">
    <div class="col-3 box-voltar" >
      <i class="fa fa-chevron-circle-left icon-voltar" (click)="navegar()"></i>
      <span class="text-voltar" (click)="navegar()">&nbsp;Voltar</span>
    </div>
    <div class="col-6 row">
      <div class="col row">
        <div class="logo  box-logo"  (click)="goHome()">
          <img class="logo_nbs  box-logo" src="../../../assets/img/logo_nbs.png"  (click)="goHome()">

        </div>
      </div>



    </div>
    <!-- <div class="col box-duvidas"  >

    </div> -->
    <!-- <div class="col-lg-3 box-duvidas"  >
      <i class="fa fa-question-circle icon-duvidas" (click)="faq()"></i>
      <span class="text-duvidas" (click)="faq()">Ficou com alguma duvida? <br>É só mandar por aqui que a gente te ajuda!</span>
    </div> -->
    <!-- <div class="col-lg-9 box-texto">
      <p>
        Escolha a opção desejada<br>
        para ter acesso ao formulário de solicitação.
      </p>
    </div> -->
  </div>
  <div class="row" style="margin-top: -24px;">
    <div class="col-5" >

    </div>
    <div class="col row">
      <div class="col box-duvidas" (click)="termo()">
        <span class="text-duvidas" >Termo de Compromisso</span>

      </div>
    </div>
  </div>
</div>
</footer>
