import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormField } from 'src/app/componentes/formMat/form.component';

@Component({
  selector: 'app-movimentacao-uo-posicao-cargo',
  templateUrl: './movimentacao-uo-posicao-cargo.component.html',
  styleUrls: ['./movimentacao-uo-posicao-cargo.component.css']
})
export class MovimentacaoUoPosicaoCargo implements OnInit {


  fields: FormField[] = [];

  constructor() { }

  ngOnInit(): void {

    this.fields = [
      {
        "type": "text",
        "name": "nome_do_solicitante",
        "label": "Nome do solicitante",
        "validators": Validators.maxLength(1000)
      },
      {
        "type": "email",
        "name": "e_mail_do_solicitante",
        "label": "E-mail do solicitante"
      },
      {
        "type": "checklistVertical",
        "name": "tipo_de_solicita_o",
        "label": "Tipo de solicitação",
        "validators": Validators.required,
        "select": [
          {
            "type": "option",
            "name": "Unidade Organizacional",
            "value": "Unidade Organizacional ",
            "fields": [
              {
                type: "mensagem1",
                name: "mensagemUnidadeOrganizacional",
                typeMensagem1: {
                  subTitulo: "Unidade Organizacional"
                }
              },
              {
                "type": "text",
                "name": "descri_o_da_unidade_organizacional",
                "label": "Nomemclatura da unidade organizacional",
                "validators": [Validators.required, Validators.maxLength(1000)]
              },
              {
                "type": "textArea",
                "name": "justificativa",
                "label": "Justificativa",
                "validators": [Validators.required, Validators.maxLength(1000)]
              },
              {
                "type": "select",
                "name": "n_vel_da_unidade_organizacional",
                "label": "Nível da unidade organizacional",
                "validators": Validators.required,
                "select": [
                  {
                    "type": "option",
                    "name": "1 - SR VP",
                    "value": "1 - SR VP"
                  },
                  {
                    "type": "option",
                    "name": "2 - VP",
                    "value": "2 - VP"
                  },
                  {
                    "type": "option",
                    "name": "3 - DIRETORIA EXECUTIVA SR",
                    "value": "3 - DIRETORIA EXECUTIVA SR"
                  },
                  {
                    "type": "option",
                    "name": "5 - DIRETORIA EXECUTIVO",
                    "value": "5 - DIRETORIA EXECUTIVO"
                  },
                  {
                    "type": "option",
                    "name": "6 - DIRETORIA LOCAL",
                    "value": "6 - DIRETORIA LOCAL"
                  },
                  {
                    "type": "option",
                    "name": "8 - GERENCIA SR",
                    "value": "8 - GERENCIA SR"
                  },
                  {
                    "type": "option",
                    "name": "9 - GERENCIA",
                    "value": "9 - GERENCIA"
                  },
                  {
                    "type": "option",
                    "name": "10 - COORDENAÇÃO",
                    "value": "10 - COORDENAÇÃO"
                  },
                  {
                    "type": "option",
                    "name": "11 - COORDENAÇÃO LOCAL",
                    "value": "11 - COORDENAÇÃO LOCAL"
                  },
                  {
                    "type": "option",
                    "name": "12 - SUPERVISÃO",
                    "value": "12 - SUPERVISÃO"
                  },
                  {
                    "type": "option",
                    "name": "13 - ENCARREGADO / LIDERANÇA",
                    "value": "13 - ENCARREGADO / LIDERANÇA"
                  },
                  {
                    "type": "option",
                    "name": "14 - LIDERANÇA",
                    "value": "14 - LIDERANÇA"
                  },
                  {
                    "type": "option",
                    "name": "15 - EQUIPE",
                    "value": "15 - EQUIPE"
                  }
                ]
              },
              {
                "type": "text",
                "name": "unidade_organizacional_superior",
                "label": "Unidade organizacional superior",
                "validators": [Validators.required, Validators.maxLength(1000)]
              },
              {
                "type": "text",
                "name": "gestor_da_uo",
                "label": "Gestor da unidade organizacional",
                "validators": Validators.maxLength(1000)
              },
              {
                "type": "textArea",
                "name": "coment_rios",
                "label": "Comentários:",
                "validators": [Validators.required, Validators.maxLength(1000)]
              }
            ]
          },
          {
            "type": "option",
            "name": "Cargo",
            "value": "Cargo",
            "fields": [
              {
                type: "mensagem1",
                name: "mensagemCargo",
                typeMensagem1: {
                  subTitulo: "Cargo"
                }
              },
              {
                "type": "text",
                "name": "descri_o_do_cargo",
                "label": "Título do cargo",
                "validators": [Validators.required, Validators.maxLength(1000)]
              },
              {
                "type": "textArea",
                "name": "descri_o_do_cargo_1",
                "label": "Descrição do cargo",
                "validators": [Validators.required, Validators.maxLength(1000)]
              },
              {
                "type": "connector",
                "connector": "CBO-GOV",
                "name": "cbo_gov",
                "label": "CBO_Gov"
              },
              {
                "type": "select",
                "name": "n_vel_hier_rquico",
                "label": "Nível hierárquico",
                "validators": Validators.required,
                "select": [
                  {
                    "type": "option",
                    "name": "Presidente",
                    "value": "Presidente"
                  },
                  {
                    "type": "option",
                    "name": "VP",
                    "value": "VP"
                  },
                  {
                    "type": "option",
                    "name": "Diretor",
                    "value": "Diretor"
                  },
                  {
                    "type": "option",
                    "name": "Gerente Sr",
                    "value": "Gerente Sr"
                  },
                  {
                    "type": "option",
                    "name": "Gerente",
                    "value": "Gerente"
                  },
                  {
                    "type": "option",
                    "name": "Especialista",
                    "value": "Especialista"
                  },
                  {
                    "type": "option",
                    "name": "Coordenador",
                    "value": "Coordenador"
                  },
                  {
                    "type": "option",
                    "name": "Consultor",
                    "value": "Consultor"
                  },
                  {
                    "type": "option",
                    "name": "Supervisor",
                    "value": "Supervisor"
                  },
                  {
                    "type": "option",
                    "name": "Tecnico",
                    "value": "Tecnico"
                  },
                  {
                    "type": "option",
                    "name": "Senior",
                    "value": "Senior"
                  },
                  {
                    "type": "option",
                    "name": "Pleno",
                    "value": "Pleno"
                  },
                  {
                    "type": "option",
                    "name": "Junior",
                    "value": "Junior"
                  },
                  {
                    "type": "option",
                    "name": "AGD",
                    "value": "AGD"
                  },
                  {
                    "type": "option",
                    "name": "Assistente",
                    "value": "Assistente"
                  },
                  {
                    "type": "option",
                    "name": "Auxiliar",
                    "value": "Auxiliar"
                  },
                  {
                    "type": "option",
                    "name": "Lider",
                    "value": "Lider"
                  },
                  {
                    "type": "option",
                    "name": "Encarregado",
                    "value": "Encarregado"
                  },
                  {
                    "type": "option",
                    "name": "Promotor",
                    "value": "Promotor"
                  },
                  {
                    "type": "option",
                    "name": "Trainee",
                    "value": "Trainee"
                  },
                  {
                    "type": "option",
                    "name": "Estagiario",
                    "value": "Estagiario"
                  },
                  {
                    "type": "option",
                    "name": "Aprendiz",
                    "value": "Aprendiz"
                  },
                  {
                    "type": "option",
                    "name": "Operacional",
                    "value": "Operacional"
                  }
                ]
              },
              {
                "type": "radioHorizontal",
                "name": "comercial_campo",
                "label": "É comercial campo?",
                "validators": Validators.required,
                "select": [
                  {
                    "type": "option",
                    "name": "SIM",
                    "value": "SIM"
                  },
                  {
                    "type": "option",
                    "name": "NÃO",
                    "value": "NÃO"
                  }
                ]
              },
              {
                "type": "select",
                "name": "rea_comercial",
                "label": "Área comercial",
                "validators": Validators.required,
                "select": [
                  {
                    "type": "option",
                    "name": "Comercial",
                    "value": "Comercial"
                  },
                  {
                    "type": "option",
                    "name": "Não comercial",
                    "value": "Não comercial"
                  },
                  {
                    "type": "option",
                    "name": "Apoio vendas",
                    "value": "Apoio vendas"
                  }
                ]
              },
              {
                "type": "select",
                "name": "categoria_cargo",
                "label": "Categoria cargo",
                "validators": Validators.required,
                "select": [
                  {
                    "type": "option",
                    "name": "Gestor",
                    "value": "Gestor"
                  },
                  {
                    "type": "option",
                    "name": "Contribuidor individual",
                    "value": "Contribuidor individual"
                  },
                  {
                    "type": "option",
                    "name": "Operação",
                    "value": "Operação"
                  }
                ]
              },
              {
                "type": "select",
                "name": "copy_of_categoria_cargo",
                "label": "Escopo geográfico",
                "validators": Validators.required,
                "select": [
                  {
                    "type": "option",
                    "name": "LATAM",
                    "value": "LATAM"
                  },
                  {
                    "type": "option",
                    "name": "Brasil",
                    "value": "Brasil"
                  },
                  {
                    "type": "option",
                    "name": "Argentina",
                    "value": "Argentina"
                  },
                  {
                    "type": "option",
                    "name": "Chile",
                    "value": "Chile"
                  },
                  {
                    "type": "option",
                    "name": "Uruguai",
                    "value": "Uruguai"
                  },
                  {
                    "type": "option",
                    "name": "LAS",
                    "value": "LAS"
                  }
                ]
              },
              {
                "type": "number",
                "name": "b_nus_target_sal_rios_ano",
                "label": "Bônus target (Salários/ano)",
                "validators": Validators.required
              },
              {
                "type": "select",
                "name": "programa_de_b_nus",
                "label": "Programa de bônus",
                "validators": Validators.required,
                "select": [
                  {
                    "type": "option",
                    "name": "Bonus global",
                    "value": "Bonus global"
                  },
                  {
                    "type": "option",
                    "name": "Bonus global - Brazil Retail",
                    "value": "Bonus global - Brazil Retail"
                  },
                  {
                    "type": "option",
                    "name": "Bonus global - LATAM Func Scope",
                    "value": "Bonus global - LATAM Func Scope"
                  },
                  {
                    "type": "option",
                    "name": "Bonus global - LATAM Retail",
                    "value": "Bonus global - LATAM Retail"
                  },
                  {
                    "type": "option",
                    "name": "Bonus corporativo",
                    "value": "Bonus corporativo"
                  },
                  {
                    "type": "option",
                    "name": "Bonus corporativo LATAM",
                    "value": "Bonus corporativo LATAM"
                  },
                  {
                    "type": "option",
                    "name": "Bonus operacoes",
                    "value": "Bonus operacoes"
                  },
                  {
                    "type": "option",
                    "name": "Vendas AGRICHEM - AGD",
                    "value": "Vendas AGRICHEM - AGD"
                  },
                  {
                    "type": "option",
                    "name": "Vendas AGRICHEM - CTC",
                    "value": "Vendas AGRICHEM - CTC"
                  },
                  {
                    "type": "option",
                    "name": "Vendas AGRICHEM - GRV",
                    "value": "Vendas AGRICHEM - GRV"
                  },
                  {
                    "type": "option",
                    "name": "Vendas AGRICHEM - DM (Agronomo)",
                    "value": "Vendas AGRICHEM - DM (Agronomo)"
                  },
                  {
                    "type": "option",
                    "name": "Vendas AGRICHEM DM (Gerente)",
                    "value": "Vendas AGRICHEM DM (Gerente)"
                  },
                  {
                    "type": "option",
                    "name": "Vendas Retail - CTV",
                    "value": "Vendas Retail - CTV"
                  },
                  {
                    "type": "option",
                    "name": "Vendas Retail - GT",
                    "value": "Vendas Retail - GT"
                  },
                  {
                    "type": "option",
                    "name": "Não elegivel",
                    "value": "Não elegivel"
                  }
                ]
              },
              {
                "type": "textArea",
                "name": "coment_rios",
                "label": "Comentários:",
                "validators": [Validators.required, Validators.maxLength(1000)]
              }
            ]
          },
          {
            "type": "option",
            "name": "Posição",
            "value": "Posição",
            "fields": [
              {
                type: "mensagem1",
                name: "mensagemPosicao",
                typeMensagem1: {
                  subTitulo: "Posição"
                }
              },
              {
                "type": "text",
                "name": "descri_o_de_posi_o",
                "label": "Descrição da posição (cargo)",
                "validators": [Validators.required, Validators.maxLength(1000)]
              },
              {
                "type": "textArea",
                "name": "justificativa",
                "label": "Justificativa",
                "validators": [Validators.required, Validators.maxLength(1000)]
              },
              {
                "type": "select",
                "name": "empresa",
                "label": "Empresa",
                "validators": Validators.required,
                "select": [
                  {
                    "type": "option",
                    "name": "AGRICHEM DO BRASIL SA",
                    "value": "AGRICHEM DO BRASIL SA"
                  },
                  {
                    "type": "option",
                    "name": "BIO RURAL COMERCIO E REPRESENTACOES LTDA",
                    "value": "BIO RURAL COMERCIO E REPRESENTACOES LTDA"
                  },
                  {
                    "type": "option",
                    "name": "CONFIANÇA AGRÍCOLA LTDA",
                    "value": "CONFIANÇA AGRÍCOLA LTDA"
                  },
                  {
                    "type": "option",
                    "name": "FRONTEIRA COMÉRCIO E REPRESENTAÇÃO DE PRODUTOS AGROPECUÁRIOS LTDA",
                    "value": "FRONTEIRA COMÉRCIO E REPRESENTAÇÃO DE PRODUTOS AGROPECUÁRIOS LTDA"
                  },
                  {
                    "type": "option",
                    "name": "NUTRIEN SOLUÇÕES AGRÍCOLAS LTDA",
                    "value": "NUTRIEN SOLUÇÕES AGRÍCOLAS LTDA"
                  },
                  {
                    "type": "option",
                    "name": "SEMENTES GOIÁS LTDA",
                    "value": "SEMENTES GOIÁS LTDA"
                  },
                  {
                    "type": "option",
                    "name": "TERRA NOVA COM REP E ASSIS TÉC AGRIC LTD",
                    "value": "TERRA NOVA COM REP E ASSIS TÉC AGRIC LTD"
                  }
                ]
              },
              {
                "type": "connector",
                "connector": "Estabelecimento",
                "name": "estabelecimento_1",
                "label": "Estabelecimento",
                "validators": Validators.required,
              },
              {
                "type": "connector",
                "connector": "Centro de custo",
                "name": "centro_de_custo1",
                "label": "Centro de Custo",
                "validators": Validators.required,
              },
              {
                "type": "radioHorizontal",
                "name": "tem_u_o_criada",
                "label": "Tem U.O. criada?",
                "validators": Validators.required,
                "select": [
                  {
                    "type": "option",
                    "name": "SIM",
                    "value": "SIM",
                    "fields": [
                      {
                        "type": "connector",
                        "connector": "Unidade organizacional",
                        "name": "unidade_organizacional",
                        "label": "Unidade Organizacional",
                        "validators": Validators.required,
                      },
                    ]
                  },
                  {
                    "type": "option",
                    "name": "NÃO",
                    "value": "NÃO",
                    "fields": [
                      {
                        type: "mensagem1",
                        name: "weqweqwe",
                        typeMensagem1: {
                          titulo: "Não podemos seguir com a sua solicitação",
                          "subTitulo": "É necessário possuir Unidade Organizacional criada"
                        },
                      },
                      {
                        type: "text",
                        name: "desabilitar1",
                        hide: true,
                        "validators": [Validators.required, Validators.maxLength(1000)]
                      }
                    ]
                  }
                ]
              },
              {
                "type": "radioHorizontal",
                "name": "tem_cargo_criado",
                "label": "Tem Cargo criado?",
                "validators": Validators.required,
                "select": [
                  {
                    "type": "option",
                    "name": "SIM",
                    "value": "SIM",
                    "fields": [
                      {
                        "type": "connector",
                        "connector": "CBO",
                        "name": "cargo_1",
                        "label": "Cargo",
                        "validators": Validators.required
                      }
                    ]
                  },
                  {
                    "type": "option",
                    "name": "NÃO",
                    "value": "NÃO",
                    "fields": [
                      {
                        type: "mensagem1",
                        name: "adadasds",
                        typeMensagem1: {
                          titulo: "Não podemos seguir com a sua solicitação",
                          "subTitulo": "É necessário possuir Cargo criado"
                        },
                      },
                      {
                        type: "text",
                        name: "desabilitar2",
                        hide: true,
                        "validators": [Validators.required, Validators.maxLength(1000)]
                      }
                    ]
                  }
                ]
              },
              {
                "type": "textArea",
                "name": "coment_rios",
                "label": "Comentários:",
                "validators": [Validators.required, Validators.maxLength(1000)]

              }
            ]
          }
        ]
      },
      {
        type: "buttonSubmit",
        name: "Enviar"
      }
    ]
  }

  enviarFormulario(event: any) {
    // console.log(event);
  }
}
