export const pipe_fields_id = [
  'solicita_o',
  'voc_est_solicitando',
  'motivo_da_solicita_o',
  // 'n_mero_de_protocolo_de_cancelamento',
  'empresa',
  'cpf',
  'rg',
  'matr_cula',
  'nome_completo_do_funcion_rio_nutrien',
  'e_mail_do_solicitante',
  'e_mail_do_gestor',
  'quatro_primeiros_n_meros_do_cart_o',
  'quatro_ltimos_n_meros_do_cart_o',
  'quais_dados_deseja_atualizar',
  'centro_de_custo',
  'nome_a_ser_alterado',
  'data_de_nascimento',
  'nome_completo_da_m_e',
  'nome_completo_do_pai',
  'g_nero',
  // 'ddi_do_celular',
  // 'ddd_do_celular',
  'telefone_de_contato',
  // 'primeiro_nome_do_portador',
  // 'ltimo_nome_do_portador',
  'cargo',
  'endere_o',
  'n_mero',
  'complemento',
  'bairro',
  'cidade',
  'uf',
  'cep',
  'pa_s',
  'limite_do_cart_o',
  'novo_valor_do_limite',
  'pa_s_de_destino',
  'data_de_in_cio_da_viagem',
  'data_de_retorno_da_viagem',
  'primeiros_4_d_gitos_do_cart_o',
  'ltimos_4_d_gitos_do_cart_o',
  'data_de_desligamento_do_funcion_rio',
  'observa_o',
  'aceite_do_termo',
  'qual_o_cancelamento_ser_necess_rio',
  'gestor_demandante'
]
