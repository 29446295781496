import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { ControleDeVersoesService } from './../../services/controle-de-versoes.service';
import { environment } from "src/environments/environment";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  environment = environment;

  userName? :string ='';
  versao? : string = '';

  constructor(private router:Router , 
              private msalService: MsalService,
              private controleVersao: ControleDeVersoesService) { }

  ngOnInit(): void {

    this.userName = this.getUserName()
    this.versao = this.controleVersao.getVersao()

  }
  homePage():void{
    this.router.navigate([''])
  }

  getUserName() :string | undefined{
    return !!!environment.localhost ? this.msalService.instance.getAllAccounts()[0]?.name : environment.userNameMock;
  }

  logout() {
    localStorage.removeItem('https://nbs.accenture.com/');
    localStorage.removeItem('https://nbs.dev.accenture.com/');
    sessionStorage.removeItem('https://nbs.accenture.com/');
    sessionStorage.removeItem('https://nbs.dev.accenture.com/');
    localStorage.removeItem('https://nbs.hml.accenture.com/');
    sessionStorage.removeItem('https://nbs.hml.accenture.com/');
    
    this.msalService.logoutRedirect()
    localStorage.clear()
    sessionStorage.clear()
    this.router.navigate(['/'])
  }


}
