import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { PipefyService } from 'src/app/services/pipefy.service';
import { TrataInputsService } from 'src/app/services/trata-inputs.service';
import Swal from 'sweetalert2';
import { codigoSap } from './codigo-sap';
import { empresasBaixa } from './empresasBaixa';
import { pipe_fields_id } from './pipe_fields_id';
import { pipe_fields_incidentes } from '../../pipe_fields_incidentes';
import { MsalService } from '@azure/msal-angular';
import { UserInfosService } from 'src/app/autenticacao/usuario/user-infos.service';
import { tratativas } from './tratativas';
import { CommomServicesService } from 'src/app/services/commom-services.service';


@Component({
  selector: 'app-baixa-imobilizado',
  templateUrl: './baixa-imobilizado.component.html',
  styleUrls: ['./baixa-imobilizado.component.css']
})
export class BaixaImobilizadoComponent implements OnInit {

  formulario!: FormGroup;
  empresas = empresasBaixa;
  codigosSap = codigoSap
  tratativas = tratativas
  myFiles: any[] = [];
  formularios_assinado: any[] = [];
  error_counter: number = 0;
  erro: boolean = false


  emailApi: string = ''
  userNameApi: string = ''

  constructor(private formBuilder: FormBuilder,
              private trataInput: TrataInputsService,
              private pipefy: PipefyService,
              private spinner: NgxSpinnerService,
              private msalService: MsalService,
              private userInfo: UserInfosService,
              private commomS: CommomServicesService) { }

  ngOnInit(): void {

    this.formulario = this.formBuilder.group({
      nome: ['', [Validators.required, Validators.maxLength(1000)]],
      email: ['', [Validators.required, Validators.email, Validators.maxLength(1000)]],
      nome_da_empresa: ['', [Validators.required, Validators.maxLength(1000)]],
      tratativa: ['',[Validators.maxLength(1000)]],
      codigo_sap: ['', [Validators.required, Validators.maxLength(1000)]],
      data_da_baixa: ['', [Validators.required]],
      numero_do_bem: ['', [Validators.maxLength(1000)]],
      codigo_de_ativo_no_sistema: ['', [Validators.required, Validators.maxLength(1000)]],
      motivo_da_baixa: ['', [Validators.required, Validators.maxLength(1000)]],
      formulario_assinado: ['', [Validators.required]],
      observacao: ['', [Validators.maxLength(1000)]]
    })

    this.emailApi = this.userInfo.getUserEmail()
    // this.userNameApi = this.userInfo.getName()

    // this.formulario.get('email')?.setValue(this.emailApi)
    // this.formulario.get('nome')?.setValue(this.userNameApi)


    // if(this.formulario.getRawValue().email !=''){
    //   this.formulario.controls['email'].disable()
    // }
    // if(this.formulario.getRawValue().email == undefined){
    //   this.formulario.controls['email'].enable()
    // }

    // if(this.formulario.getRawValue().nome !=''){
    //   this.formulario.controls['nome'].disable()
    // }
    // if(this.formulario.getRawValue().nome == undefined){
    //   this.formulario.controls['nome'].enable()
    // }

  }




  enviarFormulario() {

    this.spinner.show()
    const criarBody = async () => {
      await this.getPipesUrl(this.myFiles);
      if (this.myFiles.length > 0) {
        while (this.myFiles.length != this.formularios_assinado.length) {
          // console.log("esperando....")
          await this.delay(100);
          if(this.erro){
            break;
          }
        }
        if(this.erro){
          return
        }
      }

      if (this.myFiles.length > 0) {
        var formularios_assinado2 = ''
        formularios_assinado2 = '['
        for (var i = 0; i < this.formularios_assinado.length; i++) {
          formularios_assinado2 = formularios_assinado2 + '"' + this.formularios_assinado[i] + '",'
        }
        formularios_assinado2 = formularios_assinado2.slice(0, -1)
        formularios_assinado2 = formularios_assinado2 + "]"

      } else {
        formularios_assinado2 = ''
      }


      let nome = this.trataInput.removeCaracters(this.formulario.getRawValue().nome)
      let email = this.trataInput.removeCaracters(this.formulario.getRawValue().email)
      let nome_da_empresa = this.trataInput.removeCaracters(this.formulario.getRawValue().nome_da_empresa)
      let tratativa = this.trataInput.removeCaracters(this.formulario.getRawValue().tratativa)
      let codigo_sap = this.trataInput.removeCaracters(this.formulario.getRawValue().codigo_sap)
      let data_da_baixa = this.trataInput.ajustaData(this.trataInput.removeCaracters(this.formulario.getRawValue().data_da_baixa))
      let numero_do_bem = this.trataInput.removeCaracters(this.formulario.getRawValue().numero_do_bem)
      let codigo_de_ativo_no_sistema = this.trataInput.removeCaracters(this.formulario.getRawValue().codigo_de_ativo_no_sistema)
      let motivo_da_baixa = this.trataInput.removeCaracters(this.formulario.getRawValue().motivo_da_baixa)
      let observacao = this.trataInput.removeCaracters(this.formulario.getRawValue().observacao)

      let pipe_fields = pipe_fields_id
      let fields_values = [nome, email, nome_da_empresa, tratativa, codigo_sap, data_da_baixa, numero_do_bem, codigo_de_ativo_no_sistema, motivo_da_baixa, formularios_assinado2, observacao]
      let pipe_id = "303797687"


      this.pipefy.createCard(this.pipefy.criaBody(pipe_fields, fields_values, pipe_id)).subscribe((res) => {
        // console.log(res)
        this.spinner.hide()

        if (res.data.result_creation_card.id != undefined) {
          let card_id = res.data.result_creation_card.id
          Swal.fire({
            title: 'Chamado aberto com Sucesso!',
            text: 'O chamado ' + card_id + ' foi aberto com sucesso. Nossa equipe irá tratar e retornar em breve. Obrigado!',
            icon: 'success',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#88D600',
          })
          this.resetform()
        }

        if (res.data.result_creation_card.errors != undefined) {
          var erro = res.data.result_creation_card.errors[0].message
          Swal.fire({
            title: 'Desculpe!',
            html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/FZZSofCP" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
            text: erro,
            icon: 'error',
            confirmButtonColor: '#88D600',
          })
          this.formularios_assinado = []

          let pipe_fields_incidentes_values = ['Incidente', 'Correção', 'Portal NBS', 'F&A', 'Contabilidade', "Contabilidade - criação de card -- Baixa Imobilizado", erro, 'Portal NBS Error', 'incidente@portal.com',
            'Sim', 'Outros', 'criação de card via portal', 'Página web com problemas',this.emailApi,this.userInfo.getVersion()]

          var pipe_fields_incidentes_values2 = []
          for (var i = 0; i < pipe_fields_incidentes_values.length; i++) {
            pipe_fields_incidentes_values2.push(this.trataInput.removeCaractersPipeIncidentes(pipe_fields_incidentes_values[i]))
          }
          // console.log(pipe_fields_incidentes_values2)

          let pipe_incidentes_id = '302487533'

          this.pipefy.createCard(this.pipefy.criaBody(pipe_fields_incidentes, pipe_fields_incidentes_values2, pipe_incidentes_id)).subscribe((res) => {
            // console.log(res)
          })
        }
      }, (error: any) => {
        this.spinner.hide()
        this.error_counter = this.error_counter + 1
        if (this.error_counter < 2) {
          if (error.status == 401) {
            // console.log("entrei em error.status")
            Swal.fire({
              title: 'Ops, sua sessão expirou!',
              text: 'Por favor, saia e logue novamente.',
              icon: 'error',
              confirmButtonText: 'Sair',
              confirmButtonColor: '#ff6961',
              allowOutsideClick: false,
              allowEscapeKey: false
            })
              .then(() => {
                this.msalService.logoutRedirect()
              }
              )
          } else {
            Swal.fire({
              title: 'Desculpe!',
              text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
              icon: 'error',
              confirmButtonText: 'Ok',
              confirmButtonColor: '#88D600',
              allowOutsideClick: false,
              allowEscapeKey: false
            })
          }
        }
        else {
          this.spinner.hide()
          Swal.fire({
            title: 'Desculpe!',
            html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/FZZSofCP" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
            icon: 'error',
            confirmButtonColor: '#88D600',
          })
          this.formularios_assinado = []
        }
      })
    }
    criarBody()
  }

  delay(time: any) {
    return new Promise(resolve => setTimeout(resolve, time));
  }

  save(event: any): void {
    var files = event.target.files;
    console.log(files)
    var extPermitidas = ['xlsx','csv','doc','pdf'];


    for (var i = 0; i < files.length; i++) {
      var extArquivo = files[i].name.split('.').pop();
      var extensionParts = files[i].name.split('.')
      console.log(extArquivo)
      if(typeof extPermitidas.find(function(ext){ return extArquivo == ext; }) == 'undefined' || extensionParts.length > 2) {
        console.log("entrei em extensao nao permitida")
        alert(files[i].name + " - Extensão Não permitida");
        this.formulario.get('formulario_assinado')?.setValue('')
      } else {
        this.myFiles.push(files[i])
      }
    }
  }



  async getPipesUrl(files: any) {
    this.erro = false
    for (var i = 0; i < files.length; i++) {
      var fileContent = files[i]
      let fileName = files[i].name
      const reader = new FileReader();
      reader.readAsDataURL(fileContent);
      reader.onload = () => {
        fileContent = reader.result?.toString()
        var fileContentStart = fileContent.indexOf(",")

        fileContent = fileContent.substring(fileContentStart + 1)
        fileContent = fileContent.toString()

        var binaryString = window.atob(fileContent);
        var byteArray = new Uint8Array(binaryString.length);
        for (var i = 0; i < binaryString.length; i++) {
          byteArray[i] += binaryString.charCodeAt(i);
        } (async () => {
          let urlResposta: any = await this.pipefy.getFileUrl3(byteArray, fileName)

          var fileUrlTemp = "";
          var startIndex = 0;

          if (urlResposta.indexOf("orgs") > 0) {
            startIndex = urlResposta.indexOf("orgs/");
          } else {
            startIndex = urlResposta.indexOf("uploads/");
          }

          var endIndex = urlResposta.indexOf("?");
          fileUrlTemp = urlResposta.substring(startIndex, endIndex);

          this.formularios_assinado.push(fileUrlTemp)
       })();
      },
        (error: any) => {
          this.erro = true
          this.spinner.hide()
          this.error_counter = this.error_counter + 1
          if (this.error_counter < 2) {
            if (error.status == 401) {
              // console.log("entrei em error.status")
              Swal.fire({
                title: 'Ops, sua sessão expirou!',
                text: 'Por favor, saia e logue novamente.',
                icon: 'error',
                confirmButtonText: 'Sair',
                confirmButtonColor: '#ff6961',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
                .then(() => {
                  this.msalService.logoutRedirect()
                }
                )
            } else {
              Swal.fire({
                title: 'Desculpe!',
                text: 'Ocorreu algum erro durante a sua solicitação. Por favor, tente novamente.',
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#88D600',
                allowOutsideClick: false,
                allowEscapeKey: false
              })
            }
          }
          else {
            this.spinner.hide()
            Swal.fire({
              title: 'Desculpe!',
              html: '<p>Ocorreu um erro.</p>' + '<a href="https://app.pipefy.com/public/form/FZZSofCP" target="_blank">Clique aqui </a>' + '<span>para fazer sua solicitação</span>',
              icon: 'error',
              confirmButtonColor: '#88D600',
            })
            this.formularios_assinado = []
          }
      }
    }
    return new Promise<void>((resolve) => {resolve(); });
  }

  removeFile(file: File) {
    const index: number = this.myFiles.indexOf(file);
    this.myFiles.splice(index, 1)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Arquivo Removido com Sucesso.',
      showConfirmButton: false,
      timer: 1500
    })
    if(this.myFiles.length == 0){
      this.formulario.controls['formulario_assinado'].markAsTouched()
      this.formulario.get('formulario_assinado')?.setValue('')
    }
  }


  resetform() {
    for (let field in this.formulario.controls) {
      this.formulario.controls[field].setValue('');
    }
    this.formulario.markAsUntouched()

    this.myFiles = []
    this.formularios_assinado = []
  }

  abrirPipePublico() {
    this.commomS.openNewWindow('https://app.pipefy.com/public/form/FZZSofCP');
  }
}
