export const pipe_fields_id = [
  "digite_seu_nome",
  "digite_seu_e_mail",
  "nome_da_empresa",
  "tipo_de_tratativa",
  "c_digo_sap",
  "data_da_baixa",
  "n_mero_do_bem",
  "c_digo_de_ativo_no_sistema", //codigo de ativo no sistema
  "motivo_da_baixa",
  "formul_rio_assinado",
  // "id_card_origem",
  "observa_o"
]
